import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Tabs,
  Tab
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CustomTabPanel from "../../../components/common/CustomTabPanel";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const MasterAreaEditProject = observer(() => {
  usePageTitle("Master Area - Project");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { masterAreaProjectsStore } = useStores();

  const [tabValue, setValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  
  const [projectNumber, setProjectNumber] = useState<NullableString>(null);
  const [projectCode, setProjectCode] = useState<NullableString>(null);
  const [projectDescription, setProjectDescription] = useState<NullableString>(null);

 
  useEffect(() => {
    (async () => {   
      await initProject();
    })()
  }, []);

  const initProject = async () => {

    const id = urlParams.get("id");
    await masterAreaProjectsStore.fetchProjectById(parseInt(id!));

    setProjectNumber(masterAreaProjectsStore.projectResponse?.projectNumber);
    setProjectCode(masterAreaProjectsStore.projectResponse?.projectCode);
    setProjectDescription(masterAreaProjectsStore.projectResponse?.projectDescription);
  }
  
  const handleCloseClick = () => {
    navigate(ROUTES.masterAreaGridProjects);
  };

  return (
    masterAreaProjectsStore.projectResponse?.projectNumber ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
    <div style={ areaboxStyle }>
      <Tabs
        value={tabValue}
        variant="scrollable"
        aria-label="New-Tag-Tabs"
        onChange={handleTabChange}
      >
        <Tab label="General" />
      </Tabs>    
    </div>
    <CustomTabPanel value={tabValue} index={0}>
        <Box
          sx={ areaboxStyle }
        >  
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Project Number"
            value={projectNumber + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Project Code"
            value={projectCode + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Project Description"
            value={projectDescription + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        </Box>
      </CustomTabPanel>
      </Box>
      }
      </form>
      :
      null
  );
});

export default MasterAreaEditProject;
