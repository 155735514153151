import { NullableBoolean, NullableString, NullableNumber } from "../../../../models/common/response/types";
import { TagCategories } from "../constants/constants";
import { RelationType } from "../../../../models/common/response/relationType";
import MasterAreaCardViewStore from "../../../../stores/MasterArea/MasterAreaCardViewStore";

export function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  export function isNullValueString(str: NullableString) {
    return str === null || str === undefined || str === ""; 
  }

  export function isNoStatus(statusId: NullableNumber) {
    return statusId === null || statusId === 8;
  }

  export function processBoolean(bool: NullableBoolean) {
    return bool ?? false;
  }

  export function processString(str: NullableString) {
    return str ?? "";
  }

  export function buildStringOption(param1: NullableString, param2: NullableString) {
    if(!param1 || !param2) return "";
    return param1 + " - " + param2;
  }

  export function validationColor(masterAreaCardViewStore: MasterAreaCardViewStore, optionValue: NullableString) {
    //const isValid = optionValue ? true: false;
    //if (isValid !== masterAreaCardViewStore.isValidForm) masterAreaCardViewStore.setIsValidForm(isValid);
    return optionValue ? "#fff" : "#ffd8d8";
  }

  export function validationColorWithControlAutonaming(masterAreaCardViewStore: MasterAreaCardViewStore, optionValue: NullableString, isControlAutonaming: boolean) {
    const isValid = optionValue || !isControlAutonaming ? true : false;
    if (isValid !== masterAreaCardViewStore.isValidForm) masterAreaCardViewStore.setIsValidForm(isValid);
    return isValid ? "#fff" : "#ffd8d8";
  }

  export function validationPrimaryTagColor(masterAreaCardViewStore: MasterAreaCardViewStore, primaryTagValue?: NullableString, tagCategoryCode?: NullableString, isControlAutonaming?: boolean) {
    const isValid = (tagCategoryCode === TagCategories.Instrument && isControlAutonaming) 
    || (tagCategoryCode === TagCategories.InstrumentCable && isControlAutonaming) 
    || (tagCategoryCode === TagCategories.ProcessTelecomPAGASecurityEquipment && isControlAutonaming)
    || tagCategoryCode === TagCategories.FireandGasInstrument || !isControlAutonaming || primaryTagValue ? true : false;

    if (isValid !== masterAreaCardViewStore.isValidForm) masterAreaCardViewStore.setIsValidForm(isValid);
    return  isValid? "#fff" : "#ffd8d8";
  }

  export function disciplineTagNumberColor(masterAreaCardViewStore: MasterAreaCardViewStore, isControlAutonaming: boolean, optionValue: NullableString) {
    const isValid = isControlAutonaming || optionValue ? true : false;
    if (isValid !== masterAreaCardViewStore.isValidForm) masterAreaCardViewStore.setIsValidForm(isValid);
    return isValid ? "#fff" : "#ffd8d8";
  }

  export function sequenceNumberColor(masterAreaCardViewStore: MasterAreaCardViewStore, isControlAutonaming: boolean, optionValue: NullableString, tagCategoryCode?: string) {
    const isValid = (tagCategoryCode === TagCategories.AuxiliaryElectricalEquipment || tagCategoryCode === TagCategories.AuxiliaryEquipmentCables 
      || tagCategoryCode === TagCategories.DistributionCircuit || tagCategoryCode === TagCategories.InstrumentLoop
      || tagCategoryCode === TagCategories.JunctionBox || tagCategoryCode === TagCategories.PrimaryEquipmentCable
      || !isControlAutonaming) && !optionValue ? true : false;

    if (isValid !== masterAreaCardViewStore.isValidForm) masterAreaCardViewStore.setIsValidForm(isValid);
    return isValid ? "#ffd8d8" : "#fff";
  }

  export function checkTagLimitCondition(isControlAutonaming: boolean, multipleTags: NullableNumber) {
    return !isControlAutonaming || !multipleTags || multipleTags < 2;
  }

  export function formatRelationType(value: string | number) {
    if (typeof value === "number")
      return RelationType[value];  
    else return value;
  }

  export function isPrimaryTagRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.AuxiliaryElectricalEquipment || tagCategoryCode === TagCategories.AuxiliaryEquipmentCables 
    || tagCategoryCode === TagCategories.DistributionCircuit || tagCategoryCode === TagCategories.Instrument   
    || tagCategoryCode === TagCategories.InstrumentCable || tagCategoryCode === TagCategories.InstrumentLoop
    || tagCategoryCode === TagCategories.JunctionBox || tagCategoryCode === TagCategories.PrimaryEquipmentCable
    || tagCategoryCode === TagCategories.ProcessTelecomPAGASecurityEquipment || tagCategoryCode === TagCategories.FireandGasInstrument;
  }

  export function getPrimaryTagLabel(tagCategoryCode?: string) {
    if (tagCategoryCode === TagCategories.AuxiliaryElectricalEquipment)
      return "Primary Equipment Tag Number";
    else if (tagCategoryCode === TagCategories.AuxiliaryEquipmentCables)
      return "Auxillary Equipment Tag";
    else if (tagCategoryCode === TagCategories.FireandGasInstrument)
      return "Loop number";
    else if (tagCategoryCode === TagCategories.Instrument)
      return "Loop/Group/Primary number";
    else if (tagCategoryCode === TagCategories.PrimaryEquipmentCable)
      return "Primary Equipment Tag Number";
    else return "Primary Tag"
  }

  export function isFittingNumberRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.DistributionCircuit;  
  }

  export function isCircuitNumberRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.DistributionCircuit;  
  }

  export function isBuildingRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.MechanicalEquipment || tagCategoryCode === TagCategories.OnPlotBuildServices;  
  }

  export function buildingColor(optionValue: NullableString, tagCategoryCode: NullableString, isControlAutonaming: boolean) {
    return tagCategoryCode !== TagCategories.OnPlotBuildServices || optionValue || !isControlAutonaming ? "#fff" : "#ffd8d8";
  }

  export function isLocationRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.Panel;  
  }

  export function isSubstationRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.PrimaryElectricalEquipment; 
  }

  export function isInsulationTypeRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.PipingLines;  
  }

  export function isNominalDiameterRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.PipingLines;  
  }

  export function isPipeClassRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.PipingLines || tagCategoryCode === TagCategories.SpecialPipingItems;  
  }

  export function isFluidRequired(tagCategoryCode?: string) {
    return tagCategoryCode === TagCategories.UndergroundServices;  
  }

  export function isServiceDescriptionRequired(tagCategoryCode?: string) {
    return tagCategoryCode !== TagCategories.PipingLines;  
  }