import { observer } from "mobx-react";
import React, { useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { useStores } from "../../../hooks/useStores";
import { Grid } from "@mui/material";
import { DataGridBox } from "../../../styles/dataGridBox";
import { DataGridPro } from "@mui/x-data-grid-pro";
import formatDate from "../../../utils/formatDate";
import CustomToolBarUserProjectsManagement from "./components/CustomToolBarUserProjectsManagement";

const UserProjectsManagementGridPage = observer(() => {
  usePageTitle("Admin Area - User Projects Management");

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "projectNumbers",
      headerName: "Projects",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 180,
      valueFormatter: formatDate,
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      flex: 1,
      minWidth: 180,
      valueFormatter: formatDate,
    },
  ];

  const { userProjectMapStore, dictionaryStore } = useStores();

  useEffect(() => {
    dictionaryStore.fetchUsersOptions("");
    dictionaryStore.fetchProjectOptions("");

    const fetchData = async () => {
      await userProjectMapStore.fetchUserProjectMaps();
    };

    fetchData();
  }, [
    userProjectMapStore,
    userProjectMapStore.createUserProjectMap,
    userProjectMapStore.deleteUserProjectMap,
    dictionaryStore,
  ]);

  return (
    <>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <DataGridBox>
            <DataGridPro
              //disableColumnFilter
              paginationMode="server"
              rows={userProjectMapStore.userProjectMapsResponse.items}
              columns={columns}
              rowCount={userProjectMapStore.userProjectMapsResponse.totalRecords}
              loading={userProjectMapStore.isLoading}
              pagination
              paginationModel={userProjectMapStore.paginationModel}
              pageSizeOptions={[userProjectMapStore.paginationModel.pageSize]}
              onPaginationModelChange={userProjectMapStore.setPaginationModel}
              sortingMode="server"
              sortModel={userProjectMapStore.sortModel}
              onSortModelChange={(model) => {
                userProjectMapStore.setSortModel(model);
                userProjectMapStore.fetchUserProjectMaps();
              }}
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                const selectedUserIds = ids
                  .map((id) => {
                    const row = userProjectMapStore.userProjectMapsResponse.items.find(
                      (item) => item.id === id
                    );
                    return row ? row.userId : undefined;
                  })
                  .filter(
                    (userId): userId is number => userId !== null && userId !== undefined
                  );

                userProjectMapStore.setSelectedIds(selectedUserIds);
              }}
              disableRowSelectionOnClick
              density="compact"
              sx={{ fontSize: "0.75rem" }}
              slots={{
                toolbar: CustomToolBarUserProjectsManagement,
              }}
              // columnVisibilityModel={columnVisibilityModel}
              // onColumnVisibilityModelChange={(newModel) =>
              //   setColumnVisibilityModel(newModel)
              // }
            />
          </DataGridBox>
        </Grid>
      </Grid>
    </>
  );
});

export default UserProjectsManagementGridPage;
