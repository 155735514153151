import { observer } from "mobx-react";
import TagDetailDeleteRelatedTag from "./TagDetailDeleteRelatedTag";
import TagDetailAddRelatedTag from "./TagDetailAddRelatedTag";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { MasterRelatedTag } from "../../../../../models/MasterArea/response/getMasterRelatedTagsByIdResponse";

interface TagDetailRelatedTagToolBarProps {
  RelatedTags: MasterRelatedTag[];
  handleAddRelatedTag: any;
  handleDeleteRelatedTag: any;
}

const TagDetailRelatedTagToolBar = observer((props: TagDetailRelatedTagToolBarProps) => {
  return (
    <GridToolbarContainer>
      {<TagDetailAddRelatedTag RelatedTags={props.RelatedTags} handleAddRelatedTag={props.handleAddRelatedTag} />}
      {<TagDetailDeleteRelatedTag RelatedTags={props.RelatedTags} handleDeleteRelatedTag={props.handleDeleteRelatedTag}/>}
    </GridToolbarContainer>
  );
});

export default TagDetailRelatedTagToolBar;
