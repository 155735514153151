import { makeAutoObservable, runInAction } from "mobx";
import { GetMasterAreaProjectsResponse } from "../../models/MasterArea/response/getMasterAreaProjectsResponse";
import { GetMasterAreaProjectByIdResponse } from "../../models/MasterArea/response/getMasterAreaProjectByIdResponse";
import { GetMasterAreaProjectsRequest } from "../../models/MasterArea/request/getMasterAreaProjectsRequest";
import apiClient from "../../api/clients";

class MasterAreaProjectsStore {
  projectsResponse: GetMasterAreaProjectsResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  projectResponse: GetMasterAreaProjectByIdResponse | null = null;

  paginationModel = {
    pageSize: 250,
    page: 0,
  };
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  fetchProjects = async () => {    
    const { page, pageSize } = this.paginationModel;

    const request: GetMasterAreaProjectsRequest = {
      pagination: { pageIndex: page, pageSize }
    };

    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAreaProjects(request);
      runInAction(() => {
        this.projectsResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchProjectById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAreaProjectById(id);
      runInAction(() => {
        this.projectResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

}

export default MasterAreaProjectsStore;
