import { makeAutoObservable } from "mobx";
import { BulkUpdateMasterAssetsRequest } from "../../models/MasterArea/request/bulkUpdateMasterAssetsRequest";
import apiClient from "../../api/clients";

class MasterAreaGridBulkUpdateStore {
  showBulkUpdateForm = false;
  selectedIds: number[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setShowBulkUpdateForm = (show: boolean) => {
    this.showBulkUpdateForm = show;
  };

  setSelectedIds = (ids: number[]) => {
    this.selectedIds = ids;
  };

  bulkUpdateAssets = async (
    request: BulkUpdateMasterAssetsRequest
  ): Promise<Response> => {
    try {
      const response = await apiClient.bulkUpdateMasterAssets(request);
      return response;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to update assets. Please try again later.");
    }
  };
}

export default MasterAreaGridBulkUpdateStore;
