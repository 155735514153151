import {  makeAutoObservable, runInAction } from "mobx";
import apiClient from "../../api/clients";
import {
  GetAssetResponse
} from "../../models/ProjectArea/response/getAssetsResponse";
import { SelectOption } from "../../models/common/selectOption";
import {
  Filter,
  GetAssetsRequest,
  ProjectGridViewFilterOptions,
  ProjectGridViewSortOptions,
} from "../../models/ProjectArea/request/getAssetsRequest";
import { SortOrder } from "../../models/common/request/sorting";
import { GridSortModel } from "@mui/x-data-grid";
import { getOptionId, getOptionLabel } from "../../utils/getOptionValue";

class ProjectAreaStore {
  selectedProjectOption: SelectOption | null = null;
  selectedAreaOption: SelectOption | null = null;
  selectedUnitOption: SelectOption | null = null;
  selectedFacilityOption: SelectOption | null = null;  
  selectedEquipmentTypeOption: SelectOption | null = null;
  selectedTagNumberOption: SelectOption | null = null;
  selectedParentTagNumberOption: SelectOption | null = null;
  selectedEquipmentStatusOption: SelectOption | null = null;
  selectedTagCategoryOption: SelectOption | null = null;
  selectedTagTypeOption: SelectOption | null = null;
  selectedServiceDescriptionOption: string | undefined = undefined;
  selectedRequestNumberOption: string | undefined = undefined;
  selectedSequenceNumberOption: string | undefined = undefined;
  selectedSuffixOption: string | undefined = undefined;
  selectedRemarksOption: string | undefined = undefined;  
  selectedSearchTextOption: string | undefined = undefined;
    
  assetsResponse: GetAssetResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  paginationModel = {
    pageSize: 100,
    page: 0,
  };
  sortModel: GridSortModel = [];
  isLoading = false;
  displayAssetDetail = null;

  constructor() {
    makeAutoObservable(this);
  }

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setSortModel = (sortModel: any) => {
    this.sortModel = sortModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setSelectedProjectOption = (option: SelectOption | null) => {
    this.selectedProjectOption = option;
  };

  setSelectedAreaOption = (option: SelectOption | null) => {
    this.selectedAreaOption = option;
  };

  setSelectedUnitOption = (option: SelectOption | null) => {
    this.selectedUnitOption = option;
  };

  setSelectedFacilityOption = (option: SelectOption | null) => {
    this.selectedFacilityOption = option;
  };

  setSelectedEquipmentTypeOption = (option: SelectOption | null) => {
    this.selectedEquipmentTypeOption = option;
  };

  setSelectedTagNumberOption = (option: SelectOption | null) => {
    this.selectedTagNumberOption = option;
  };

  setSelectedParentTagNumberOption = (option: SelectOption | null) => {
    this.selectedParentTagNumberOption = option;
  };
 
  setSelectedRequestNumberOption = (option: string | undefined) => {
    this.selectedRequestNumberOption = option;
  };

  setSelectedTagCategoryOption = (option: SelectOption | null) => {
    this.selectedTagCategoryOption = option;
  };

  setSelectedSequenceNumberOption = (option: string | undefined) => {
    this.selectedSequenceNumberOption = option;
  };

  setSelectedRemarksOption = (option: string | undefined) => {
    this.selectedRemarksOption = option;
  };

  setSelectedSuffixOption = (option: string | undefined) => {
    this.selectedSuffixOption = option;
  };

  setSelectedServiceDescriptionOption = (option: string | undefined) => {
    this.selectedServiceDescriptionOption = option;
  };

  setSelectedEquipmentStatusOption = (option: SelectOption | null) => {
    this.selectedEquipmentStatusOption = option;
  };

  setSelectedTagTypeOption = (option: SelectOption | null) => {
    this.selectedTagTypeOption = option;
  };

  setSelectedSearchTextOption = (option: string | undefined) => {
    this.selectedSearchTextOption = option;
  };
 
  fetchAssets = async () => {
    try {
      const { page, pageSize } = this.paginationModel;

      const filterOptions: ProjectGridViewFilterOptions = {
        projectNumberId: getOptionId(this.selectedProjectOption),
        tagNumber: getOptionLabel(this.selectedTagNumberOption),
        equipmentStatusId: getOptionId(this.selectedEquipmentStatusOption),
        areaId: getOptionId(this.selectedAreaOption),
        facilityId: getOptionId(this.selectedFacilityOption),
        unitId: getOptionId(this.selectedUnitOption),
        equipmentTypeId: getOptionId(this.selectedEquipmentTypeOption),
        tagTypeId: getOptionId(this.selectedTagTypeOption),
        parentTagNumber: getOptionLabel(this.selectedParentTagNumberOption),
        requestNumber: this.selectedRequestNumberOption,
        tagCategoryId: getOptionId(this.selectedTagCategoryOption),
        sequenceNumber: this.selectedSequenceNumberOption,
        remarks: this.selectedRemarksOption,
        suffix: this.selectedSuffixOption,
        serviceDescription: this.selectedServiceDescriptionOption,
        searchText: this.selectedSearchTextOption
      };

      const sortOptions: ProjectGridViewSortOptions = {
        projectNumber: 'projectNumber',
        tagNumber: 'tagNumber',
        area: 'area',
        facility: 'facility',
        unit: 'unit',
        status: 'status',
        category: 'category',
        serviceDescription: 'serviceDescription',
        type: 'type',
        parentTag: 'parentTag',
        remarks: 'remarks',
        requestNumber: 'requestNumber',
        suffix: 'suffix',
        sequenceNumber: 'sequenceNumber',
        disciplineTagNumber: 'disciplineTagNumber'
      };

      const sortField = this.sortModel[0]?.field;
      const sortOrder =
        this.sortModel[0]?.sort === "asc" ? SortOrder.ASC : SortOrder.DESC;
      const sortProperty = sortField
        ? sortOptions[sortField as keyof ProjectGridViewSortOptions]
        : undefined;
      const filter = Object.keys(filterOptions).reduce((acc, key) => {
        const filterValue =
          filterOptions[key as keyof ProjectGridViewFilterOptions];
        if (filterValue !== undefined) {
          acc[key as keyof Filter] = { filterValue: filterValue as never };
        }
        return acc;
      }, {} as Filter);
      const sort = sortProperty
        ? {
            [sortProperty]: { sortOrder },
          }
        : undefined;
      const request: GetAssetsRequest = {
        pagination: { pageIndex: page, pageSize },
        filter,
        sort,
      };
      this.setIsLoading(true);

      const data = await apiClient.getAssets(request);

      runInAction(() => {
        this.assetsResponse = data;
        this.isLoading = false;
      });
    } finally {
      this.setIsLoading(false);
    }
  }; 
}

export default ProjectAreaStore;
