import { makeAutoObservable } from "mobx";
import {
  ColumnConfigArea,
  GetColumnConfigResponse,
} from "../../models/Configuration/response/getColumnConfigResponse";
import apiClient from "../../api/clients";

class ProjectColumnStore {
  lastUpdated: number | null = null;
  columnsData: GetColumnConfigResponse[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setColumns(columns: GetColumnConfigResponse[]) {
    this.columnsData = columns;
    this.lastUpdated = Date.now();
  }

  get getEditableColumnsCardView() {
    const editableColumns: any = {};
    this.columnsData.forEach((column) => {
      if (column.area === ColumnConfigArea.ProjectCardArea) {
        editableColumns[column.name] = column.isEditable;
      }
    });

    return editableColumns;
  }

  get getEditableColumnsGridView() {
    const editableColumns: any = {};
    this.columnsData.forEach((column) => {
      if (column.area === ColumnConfigArea.ProjectGridArea) {
        editableColumns[column.name] = column.isEditable;
      }
    });

    return editableColumns;
  }

  get getVisibleColumnsMasterView() {
    const visibleColumns: any = {};
    this.columnsData.forEach((column) => {
      if (column.area === ColumnConfigArea.MasterGridArea) {
        visibleColumns[column.name] = column.isVisible;
      }
    });
    return visibleColumns;
  }

  get getVisibleColumnsProjectGridView() {
    const visibleColumns: any = {};
    this.columnsData.forEach((column) => {
      if (column.area === ColumnConfigArea.ProjectGridArea) {
        visibleColumns[column.name] = column.isVisible;
      }
    });
    return visibleColumns;
  }

  get getEditableColumnsMasterCardView() {
    const editableColumns: any = {};
    this.columnsData.forEach((column) => {
      if (column.area === ColumnConfigArea.MasterCardArea) {
        editableColumns[column.name] = column.isEditable;
      }
    });

    return editableColumns;
  }

  get getEditableColumnsMasterGridView() {
    const editableColumns: any = {};
    this.columnsData.forEach((column) => {
      if (column.area === ColumnConfigArea.MasterGridArea) {
        editableColumns[column.name] = column.isEditable;
      }
    });

    return editableColumns;
  }

  fetchColumnConfigs = async () => {
    // if since last update is less than 1 hour, don't update
    if (this.lastUpdated && Date.now() - this.lastUpdated < 60 * 60 * 1000)
      return;
    try {
      const response = await apiClient.fetchColumnConfigs();
      this.setColumns(response);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };
}

export default ProjectColumnStore;
