import { useAccount } from '@azure/msal-react';
import { ROLES } from '../../constants/roles';
import { useCallback, useMemo } from 'react';

export function useUserData() {
  const account = useAccount();

  const hasPermission = useCallback(
    (requiredRoles: string[]) => {
      const userRoles = account?.idTokenClaims?.roles ?? [];

      return requiredRoles.every((role) => userRoles.includes(role));
    },
    [account]
  );

  const { 
    hasUserRole, 
    hasProjectUserRole,
    hasMasterUserRole,
    name, 
    username 
  } = useMemo(() => {
    const hasUserRole = hasPermission([ROLES.arsUserRole]);
    const hasProjectUserRole = hasPermission([ROLES.arsProjectUserRole]);
    const hasMasterUserRole = hasPermission([ROLES.arsMasterUserRole]);

    return {
      username: account?.username,
      name: account?.name,
      hasUserRole,
      hasProjectUserRole,
      hasMasterUserRole
    };
  }, [hasPermission, account]);

  return {
    hasUserRole,
    hasProjectUserRole,
    hasMasterUserRole,
    name,
    username,
    hasPermission
  };
}
