import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStores } from "../../../../hooks/useStores";
import { RejectTagAuditRequest } from "../../../../models/TagAudit/request/rejectTagAuditRequest";
import { NOTIFICATION_TYPES } from "../../../../constants/notificationTypes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const RejectHandoverButtonModal = observer(() => {
  const { tagAuditStore, notificationStore } = useStores();

  const [remarks, setRemarks] = useState<string>("");

  const handleSaveClick = () => {
    const request: RejectTagAuditRequest = {
      ids: tagAuditStore.selectedIds,
      remarks: remarks,
    };

    tagAuditStore.rejectHandover(request).then(() => {
      if (tagAuditStore.validationErrors) {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
        notificationStore.setNotificationMessage(
          tagAuditStore.validationErrors
        );
        notificationStore.setShowNotification(true);
      } else {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
        notificationStore.setNotificationMessage(
          "Tags rejected to handover successfully"
        );
        notificationStore.setShowNotification(true);
      }
      tagAuditStore.setshowRejectHandoverModal(false);
    });
  };

  const handleCloseClick = () => {
    setRemarks("");
    tagAuditStore.setshowRejectHandoverModal(false);
  };

  return (
    <Modal
      open={tagAuditStore.showRejectHandoverModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="reject-handover-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Reject Handover
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Remarks"
              value={remarks}
              sx={{ mr: 1, mb: 2, width: "100%" }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex", width: "98%" },
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            disabled={remarks == null}
            sx={{ ml: 2 }}
            onClick={handleSaveClick}
          >
            <span>Save</span>
          </Button>
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
            <span>Close</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default RejectHandoverButtonModal;
