import { useEffect } from "react";
import DictionaryStore from "../../../stores/Dictionary/DictionaryStore";

export const useEditTagFetchOptionsData = (
  dictionaryStore: DictionaryStore,
  selectedTagRequestCategoryId: number,
  selectedTagRequestAreaId?: number
) => {
  useEffect(() => {
    dictionaryStore.fetchProjectOptions("");
    dictionaryStore.fetchAreaOptions("");
    dictionaryStore.fetchUnitOptions("", selectedTagRequestAreaId);
    dictionaryStore.fetchEqTypeOptions("", selectedTagRequestCategoryId); 
    dictionaryStore.fetchBuildingOptions("");
    dictionaryStore.fetchWellOptions("");
    dictionaryStore.fetchEquipmentStatusOptions("");
  }, [selectedTagRequestAreaId, selectedTagRequestCategoryId]);
};

export const useCreateTagFetchOptionsData = (
  dictionaryStore: DictionaryStore,
  selectedTagRequestCategoryId: number
) => {
  useEffect(() => {
    dictionaryStore.fetchProjectOptions("");
    dictionaryStore.fetchAreaOptions("");
    dictionaryStore.fetchUnitOptions("");
    dictionaryStore.fetchEqTypeOptions("", selectedTagRequestCategoryId); 
    dictionaryStore.fetchBuildingOptions("");
    dictionaryStore.fetchWellOptions("");
  }, []);
};
