import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  Stack,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { SelectOption } from "../../../models/common/selectOption";
import { useCreateTagFetchOptionsData } from "./hooks/useFetchOptionsData";
import { editBBTag, checkDuplicateBBTag, updateCounter } from "./hooks/useFetchRequests";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams, useBlocker } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CustomTabPanel from "../../../components/common/CustomTabPanel"
import { a11yProps, processString, buildStringOption, validationColor, validationColorWithControlAutonaming, isPrimaryTagRequired, getPrimaryTagLabel,
  isFittingNumberRequired, isCircuitNumberRequired, isBuildingRequired, buildingColor, isLocationRequired, isInsulationTypeRequired,
  isNominalDiameterRequired, isPipeClassRequired, isFluidRequired, isServiceDescriptionRequired, disciplineTagNumberColor, validationPrimaryTagColor,
  isSubstationRequired } from "./utils/utils"
import MasterTagDetailAttributesTab from "./tabs/MasterTagDetailAttributesTab";
import MasterTagDetailDocumentsTab from "./tabs/MasterTagDetailDocumentsTab";
import MasterRelatedTagsTab from "./tabs/MasterTagDetailRelatedTagsTab";
import MasterTagDetailHistoryTab from "./tabs/MasterTagDetailHistoryTab";
import { EditBBTagRequest, EditRelatedTags, EditTagAttributes } from "../../../models/TagRequest/request/editBBTagRequest";
import { NullableString, NullableNumber } from "../../../models/common/response/types";
import ConfirmationDialogModal from "../../../components/common/ConfirmationDialog";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CheckDuplicateBBTag } from "../../../models/TagRequest/request/checkDuplicateBBTag";
import { UpdateCounterRequest } from "../../../models/MasterArea/request/updateCounterRequest";
import DuplicateTagWarningModal from "../gridView/components/DuplicateTagWarningModal";
import ValidateSequenceNumberWarningModal from "../gridView/components/ValidateSequenceNumberWarningModal";

const MasterAreaEditTag = observer(() => {
  usePageTitle("Master Area - Edit Tag");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { masterAreaCardViewStore, masterAssetsStore, notificationStore, dictionaryStore, userStore } = useStores();

  const [tabValue, setValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [isRefreshInProgress, setIsRefreshInProgress] = useState<boolean>(false);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

  const [showSaveAndCloseConfirmationDialog, setShowSaveAndCloseConfirmationDialog] = useState<boolean>(false);

  const [tagId, setTagId] = useState<NullableNumber>(null);
  const [isControlAutonaming, setIsControlAutonaming] = useState<boolean>(true);
  const [createdOn, setCreatedOn] = useState<NullableString>(null);
  const [createdBy, setCreatedBy] = useState<NullableString>(null);
  const [tagNumber, setTagNumber] = useState<NullableString>(null);
  const [disciplineTagNumber, setDisciplineTagNumber] = useState<NullableString>(null);
  const [serviceDescription, setServiceDescription] = useState<NullableString>(null);
  const [suffix, setSuffix] = useState<NullableString>(null);
  const [remarks, setRemarks] = useState<NullableString>(null);
  const [documents, setDocuments] = useState<NullableString>(null);
  const [statusOption, setStatusOption] = useState<SelectOption | null>(null);
  const [primaryTagOption, setPrimaryTagOption] = useState<SelectOption | null>(null);
  const [tagCategoryOption, setTagCategoryOption] = useState<SelectOption | null>(null);
  const [projectOption, setProjectsOption] = useState<SelectOption | null>(null);
  const [facilityOption, setFacilityOption] = useState<SelectOption | null>(null);
  const [areaOption, setAreaOption] = useState<SelectOption | null>(null);
  const [unitOption, setUnitOption] = useState<SelectOption | null>(null);
  const [equipmentTypeOption, setEquipmentTypeOption] = useState<SelectOption | null>(null);
  const [buildingOption, setBuildingOption] = useState<SelectOption | null>(null);
  const [wellOption, setWellOption] = useState<SelectOption | null>(null);
  const [parentTagOption, setParentTagOption] = useState<SelectOption | null>(null);
  const [insulationType, setInsulationType] = useState<NullableString>(null);
  const [circuitNumber, setCircuitNumber] = useState<NullableString>(null);
  const [fluidOption, setFluidOption] = useState<SelectOption | null>(null);
  const [fittingNumber, setFittingNumber] = useState<NullableString>(null);
  const [pipeClassOption, setPipeClassOption] = useState<SelectOption | null>(null);
  const [nominalDiameter, setNominalDiameter] = useState<NullableString>(null);
  const [sequenceNumber, setSequenceNumber] = useState<NullableString>(null);
  const [requestNumber, setRequestNumber] = useState<NullableString>(null);
  const [locationOption, setLocationOption] = useState<SelectOption | null>(null);
  const [substationOption, setSubstationOption] = useState<SelectOption | null>(null);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && !showSaveAndCloseConfirmationDialog && isFormChanged
  );
 
  useEffect(() => {
    (async () => {   
      await initTag();
    })()
  }, []);

  const initTag = async () => {
    setIsRefreshInProgress(true);

    const id = urlParams.get("id");
    const tag = await masterAreaCardViewStore.getMasterAssetById(parseInt(id!));

    setTagId(tag?.id);
    setCreatedOn(tag?.createdOn.toString().slice(0,10));
    setCreatedBy(tag?.createdBy);
    setTagNumber(tag?.tagNumber);
    setDisciplineTagNumber(tag?.disciplineTagNumber);
    setServiceDescription(tag?.serviceDescription);
    setSuffix(tag?.suffix);
    setRemarks(tag?.remarks);
    setSequenceNumber(tag?.sequenceNumber);
    setRequestNumber(tag?.requestNumber);
    setIsControlAutonaming(tag?.controlAutonaming ?? false);

    setStatusOption({ id: tag?.equipmentStatusId!,  label: processString(tag?.equipmentStatus) });
    setTagCategoryOption({ id: tag?.tagCategoryId!,  label: processString(tag?.tagCategoryName), code: processString(tag?.tagCategoryCode) });
    setEquipmentTypeOption({ id: tag?.equipmentTypeId!,  label: processString(tag?.equipmentType) });
    setFacilityOption({ id: tag?.facilityId!,  label: buildStringOption(tag?.facilityCode, tag?.facilityDescription) });
    setAreaOption({ id: tag?.areaId!,  label: buildStringOption(tag?.areaCode, tag?.areaDescription) });
    setUnitOption({ id: tag?.unitId!,  label: buildStringOption(tag?.unitCode, tag?.unitDescription) });
    setPrimaryTagOption({ id: tag?.primaryTagNumberId!,  label: processString(tag?.primaryTagNumber) });
    setProjectsOption({ id: tag?.projectId!,  label: processString(tag?.projectNumber) });
    setBuildingOption({ id: tag?.buildingId!,  label: buildStringOption(tag?.buildingNumber, tag?.buildingDescription) });
    setWellOption({ id: tag?.wellId!,  label: processString(tag?.wellCode) });
    setParentTagOption({ id: tag?.parentTagNumberId!,  label: processString(tag?.parentTagNumber) });
    setPipeClassOption({ id: tag?.pipeClassId!,  label: processString(tag?.pipeClassCode) });
    setFluidOption({ id: tag?.fuildId!,  label: processString(tag?.fluidCode) });     
    setLocationOption({ id: tag?.locationId!,  label: tag?.locationId ? processString(tag?.locationCode + " - " + tag?.locationDescription) : "" });
    setSubstationOption({ id: tag?.substationId!,  label: tag?.substationId ? processString(tag?.substationCode) : "" });
    
    setInsulationType(tag?.insulationType);
    setNominalDiameter(tag?.nominalDiameter);
    setCircuitNumber(tag?.circuitNumber);
    setFittingNumber(tag?.fittingNumber);

    masterAreaCardViewStore.setMasterHistory(tag?.changeHistories!);
    masterAreaCardViewStore.setMasterAttributes(tag?.attributes!);
    masterAreaCardViewStore.setMasterDocuments(tag?.documents!);
    masterAreaCardViewStore.setMasterRelatedTags(tag?.relatedTags!);

    setIsRefreshInProgress(false);
  }

  useCreateTagFetchOptionsData(dictionaryStore); 

  const debouncedHandleInputChange = useDebounce(500);

  const handlePrimaryTagChange = async (selectedOption: SelectOption) => {
    setPrimaryTagOption(selectedOption);
    if(selectedOption) {
      const primaryTag = dictionaryStore.primaryTagExtendedOptions.filter(p => p.id === selectedOption.id)[0];
      setSequenceNumber(primaryTag.sequence);
      /*if (primaryTag.tagTypeId) {
        const eqType = await dictionaryStore.fetchEqTypeById(primaryTag.tagTypeId);
        setEquipmentTypeOption({id: primaryTag.tagTypeId, label: eqType});
      } */
      if (primaryTag.facilityId) {
        const facility = dictionaryStore.facilityOptions.filter(f => f.id === primaryTag.facilityId)[0];
        setFacilityOption(facility);
        if (primaryTag.areaId) {
          const area = dictionaryStore.areaOptions.filter(a => a.id === primaryTag.areaId)[0];
          setAreaOption(area);
          if (primaryTag.unitId) {
            await dictionaryStore.fetchUnitOptions("", area?.id);
            const unit = dictionaryStore.unitOptions.filter(u => u.id === primaryTag.unitId)[0];   
            setUnitOption(unit);
          } 
        }
      }      
    }
  };

  const handleProjectInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectOptions(inputValue);
    });
  };

  const handleFacilityInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFacilityOptions(inputValue);
    });
  };

  const updateFacilityOption = async (selectedValue: SelectOption) => {
    if(!selectedValue) {
      setFacilityOption(null);
      setAreaOption(null);
      setUnitOption(null);
      setBuildingOption(null);
      setWellOption(null);
    }
    else setFacilityOption(selectedValue);
  };

  const handleAreaInputChange = async (inputValue: string, facilityId?: number, categoryCode?: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchAreaOptions(inputValue, facilityId, categoryCode);
    });
  };

  const updateAreaOption = async (selectedValue: SelectOption) => {
    if(!selectedValue) {
      setAreaOption(null);
      setUnitOption(null);
      setBuildingOption(null);
      setWellOption(null);
    }
    else setAreaOption(selectedValue);
  };

  const handleUnitInputChange = async (inputValue: string, areaId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOptions(inputValue, areaId);
    });
  };

  const updateUnitOption = async (selectedValue: SelectOption) => {
    if(!selectedValue) {
      setUnitOption(null);
      setBuildingOption(null);
      setWellOption(null);
    }
    else setUnitOption(selectedValue);
  };

  const handleEquipmentTypeAndPrimaryTagChange = async (inputValue: string, tagRequestCategoryId?: number, tagRequestCategoryCode?: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEqTypeOptions(inputValue, tagRequestCategoryId);
      dictionaryStore.fetchPrimaryTagNumberByCategoryOptions(inputValue, tagRequestCategoryCode);
    });
  };

  const handleEquipmentTypeInputChange = async (inputValue: string, tagRequestCategoryId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEqTypeOptions(inputValue, tagRequestCategoryId);
    });
  };

  const handleBuildingInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchBuildingOptions(inputValue);
    });
  };

  const handleWellInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchWellOptions(inputValue);
    });
  };

  const handleParentTagInputChange = async (inputValue: string, categoryId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchParentTagNumberByCategoryOptions(inputValue, categoryId);
    });
  };

 const handleTagCategoryInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagCategoryOptions(inputValue);
    });
  }; 

  const updateTagCategoryOption = async (selectedValue: SelectOption) => {
    if(!selectedValue) {
      setTagNumber("-");
      setTagCategoryOption(null);
      setPrimaryTagOption(null);
      setEquipmentTypeOption(null);
      setDisciplineTagNumber(null);
      setServiceDescription(null);
      setSuffix(null);
      setRemarks(null);
      setSequenceNumber(null);
      setRequestNumber(null);
      setFacilityOption(null);
      setAreaOption(null);
      setUnitOption(null);
      setProjectsOption(null);
      setBuildingOption(null);
      setWellOption(null);
      setParentTagOption(null);
      setPipeClassOption(null);
      setFluidOption(null);     
      setLocationOption(null);
      setSubstationOption(null);  
      setInsulationType(null);
      setNominalDiameter(null);
      setCircuitNumber(null);
      setFittingNumber(null);
    }
    else setTagCategoryOption(selectedValue);
  };

  const handlePrimaryTagInputChange = async (inputValue: string, categoryCode?: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchPrimaryTagNumberByCategoryOptions(inputValue, categoryCode);
    });
  };

  const handleStatusInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEquipmentStatusOptions(inputValue);
    });
  }; 

  const handlePipeClassInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchPipeClassesOptions(inputValue);
    });
  };

  const handleFluidInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFluidsOptions(inputValue);
    });
  };

  const handleLocationInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchLocationOptions(inputValue);
    });
  };

  const handleSubstationInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchSubstationOptions(inputValue);
    });
  };
  
  const handleCloseClick = () => {
    if(isFormChanged) {
      blocker?.reset?.();
    }
    navigate(ROUTES.masterAreaGrid);
  };

  const buildDuplicateBBTag = () : CheckDuplicateBBTag => {
    const duplicateBBTag: CheckDuplicateBBTag = {
      masterEquipmentId: tagId,
      categoryId: tagCategoryOption?.id,
      categoryCode: tagCategoryOption?.code,
      tagTypeId: equipmentTypeOption?.id,
      facilityId: facilityOption?.id,
      areaId: areaOption?.id,
      unitId: unitOption?.id,
      buildingId: buildingOption?.id,
      wellId: wellOption?.id,
      suffix: suffix,
      multipleTags: 0,
      tagLimit: null,
      sequenceNumber: sequenceNumber,
      locationId: locationOption?.id,
      disciplineTagNumber: disciplineTagNumber,
      controlAutonaming: isControlAutonaming,
      primaryTagId: primaryTagOption?.id,
      nominalDiameter: nominalDiameter,
      pipeClassId: pipeClassOption?.id,
      insulationType: insulationType,
      circuitNumber: circuitNumber,
      fittingNumber: fittingNumber,
      fluidId: fluidOption?.id
    };

    return duplicateBBTag;
  };

  const confirmSave = async () => {  
    const duplicateBBTag = buildDuplicateBBTag();
    const isDuplicate = await checkDuplicateBBTag(masterAssetsStore, duplicateBBTag);
    
    if(isDuplicate) {
      masterAreaCardViewStore.setShowDuplicateWarningModal(true);
    }
    else handleSaveClick();
  }; 

  const confirmSaveAndClose = async () => {  
    const duplicateBBTag = buildDuplicateBBTag();
    const isDuplicate = await checkDuplicateBBTag(masterAssetsStore, duplicateBBTag);
    
    if(isDuplicate) {
      masterAreaCardViewStore.setShowDuplicateWarningModal(true);
    }
    else setShowSaveAndCloseConfirmationDialog(true);
  };

  const buildBBTag = () : EditBBTagRequest => {
    const documentsIds: number[] = [];
    for (const document of masterAreaCardViewStore.masterDocuments)
      documentsIds.push(document.documentId);

    const editRelatedTags: EditRelatedTags[] = [];
    for (const relatedTag of masterAreaCardViewStore.masterRelatedTags)
    editRelatedTags.push({
      id: relatedTag.status === "new" ? null : relatedTag.id,
      relationType: relatedTag.relationTypeId!
    });

    const editAttributes: EditTagAttributes[] = [];
    for (const attribute of masterAreaCardViewStore.masterTagAttributes)
    editAttributes.push({
      id: attribute.category === "new" ? null : attribute.id,
      tagAttributeId: attribute.tagAttributeId,
      tagAttributeValue: attribute.tagAttributeValue,
      uomId: attribute.uomId
    });

    const editTag: EditBBTagRequest = {
      id: tagId!,
      categoryId: tagCategoryOption?.id,
      categoryCode: tagCategoryOption?.code,
      tagTypeId: equipmentTypeOption?.id,
      facilityId: facilityOption?.id,
      areaId: areaOption?.id,
      unitId: unitOption?.id,
      buildingId: buildingOption?.id,
      wellId: wellOption?.id,
      locationId: locationOption?.id,
      fluidId: fluidOption?.id,
      documentId: null,
      projectId: projectOption?.id,
      serviceDescription: serviceDescription,
      remarks: remarks,
      parentTagId: parentTagOption?.id,
      primaryTagId: primaryTagOption?.id,
      suffix: suffix,
      sequenceNumber: sequenceNumber,
      controlAutonaming: isControlAutonaming,
      disciplineTagNumber: disciplineTagNumber,
      nominalDiameter: nominalDiameter,
      pipeClassId: pipeClassOption?.id,
      insulationType: insulationType,
      circuitNumber: circuitNumber,
      fittingNumber: fittingNumber,
      requestNumber: requestNumber,
      documentsIds: documentsIds,
      majorEquipmentTagMapId: parentTagOption?.id,
      equipmentStatusId: statusOption?.id,
      relatedTags: editRelatedTags,
      tagAttributes: editAttributes,
      substationId: substationOption?.id
    };

    return editTag;
  };

  const updateParentTag = (selectedValue: SelectOption) => {
    setParentTagOption(selectedValue);
    if(selectedValue?.id) {
      const parentTag = masterAreaCardViewStore.masterRelatedTags.filter(pTag => pTag.relationType?.toLowerCase() === "parent")[0];
      if (parentTag) {
        parentTag.id = selectedValue.id;
        parentTag.tagNumber = selectedValue?.label;
      }
      else  {
        masterAreaCardViewStore.masterRelatedTags.push({ 
                                                        id: selectedValue.id, 
                                                        relationTypeId: 2, 
                                                        relationType: "Parent",
                                                        tagNumber: selectedValue?.label,
                                                        isControlAutonaming: null,
                                                        category: null,
                                                        disciplineTagNumber: null,
                                                        status: null,
                                                        createdBy: userStore.currentUserAccount?.name,
                                                        createdOn: new Date()});
      }
    }
  };

  const updateServiceDescription = (inputValue: string) => {
    setServiceDescription(inputValue);
    if(inputValue) {
      const attrTag = masterAreaCardViewStore.masterTagAttributes.filter(aTag => aTag.attributeClass?.toLowerCase() === "service description")[0];
      if (attrTag) {
        attrTag.tagAttributeValue = inputValue;
      }
    }
  };

  const updateRequestNumber = (inputValue: string) => {
    setRequestNumber(inputValue);
    if(inputValue) {
      const attrTag = masterAreaCardViewStore.masterTagAttributes.filter(aTag => aTag.attributeClass?.toLowerCase() === "request number")[0];
      if (attrTag) {
        attrTag.tagAttributeValue = inputValue;
      }
    }
  };

  const updateSequenceCounter = () => {
    const uCounter: UpdateCounterRequest = {
      masterEquipmentId: tagId,
      categoryCode: tagCategoryOption?.code,
      categoryId: tagCategoryOption?.id,
      sequenceNumber: sequenceNumber,
      areaId: areaOption?.id,
      unitId: unitOption?.id,
      tagTypeId: equipmentTypeOption?.id,
      buildingId: buildingOption?.id,
      locationId: locationOption?.id
    };
    setIsRefreshInProgress(true);
    updateCounter(masterAssetsStore, notificationStore, uCounter);
    setIsRefreshInProgress(false);
  }

  const handleSaveClick = async () => {  
    await masterAssetsStore.validateSequenceNumber({sequenceNumber: sequenceNumber, categoryCode: tagCategoryOption?.code, primaryTagId: primaryTagOption?.id});
    if (!masterAssetsStore.validateSequenceNumberMessage.isValid && isControlAutonaming) {
      masterAreaCardViewStore.setShowValidateSequenceNumberModal(true);
    }
    else {
      const editTag = buildBBTag();
      await editBBTag(masterAssetsStore, notificationStore, editTag);
      
      initTag();
      setIsFormChanged(false);
    }
  }; 

  const handleSaveAndCloseClick = async () => {  
    await masterAssetsStore.validateSequenceNumber({sequenceNumber: sequenceNumber, categoryCode: tagCategoryOption?.code, primaryTagId: primaryTagOption?.id});
    if (!masterAssetsStore.validateSequenceNumberMessage.isValid && isControlAutonaming) {
      masterAreaCardViewStore.setShowValidateSequenceNumberModal(true);
    }
    else {
      const editTag = buildBBTag()
      editBBTag(masterAssetsStore, notificationStore, editTag);

      navigate(ROUTES.masterAreaGrid);
    }
  }; 

  const handleSaveOnLeaveClick = async () => {  
    if(masterAreaCardViewStore.isValidForm) {
      const editTag = buildBBTag();  
      editBBTag(masterAssetsStore, notificationStore, editTag);

      blocker?.proceed?.();
    }
    else {
      blocker?.reset?.();
    }
  }; 

  const handleLeaveWithoutSavingClick = async () => {  
    blocker?.proceed?.();
  }; 

  return (
    tagNumber ?
    <form onChange={() => setIsFormChanged(true)}>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              disabled={!masterAreaCardViewStore.isValidForm} 
              sx={{ ml: 2 }} 
              onClick={() => confirmSave()}>
              <span>Save</span>
            </Button> 
            <Button 
              variant="contained" 
              disabled={!masterAreaCardViewStore.isValidForm} 
              sx={{ ml: 2 }} 
              onClick={() => confirmSaveAndClose()}>
              <span>Save & Close</span>
            </Button>
            <Button
              variant="contained"
              size="small"
              startIcon={<RefreshIcon />}
              sx={{ ml: 2 }} 
              onClick={() => initTag()}
              >
              Refresh
            </Button>
            <Button 
              variant="contained" 
              disabled={!masterAssetsStore.validateSequenceNumberMessage.isValid || !isControlAutonaming} 
              sx={{ ml: 2 }} 
              onClick={() => updateSequenceCounter()}>
              <span>Update Counter</span>
            </Button>
          </Box>
          <Box
            sx={{
              display: { xs: "block", md: "flex", width: "50%" },
              justifyContent: "flex-end",
            }}
          >
          <div style={{ fontSize: "12px", margin: "0 10px 0 0", display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
            Created On:&nbsp;<b>{createdOn}</b>, Created By:&nbsp;<b>{createdBy}</b>, Status:&nbsp;<Autocomplete
                                                                                                      disablePortal
                                                                                                      size="small"
                                                                                                      id="status"
                                                                                                      options={dictionaryStore.equipmentStatusOptions}
                                                                                                      value={statusOption}
                                                                                                      getOptionLabel={(option) => option.label}
                                                                                                      sx={{ mr: 1, mb: 2, width: "30%", margin: "0px", "& .MuiInputBase-input": {height: "15px"}, "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}
                                                                                                      renderInput={(params) => <TextField {...params} label="Status" />}
                                                                                                      onInputChange={(event, newInputValue) => {
                                                                                                        handleStatusInputChange(newInputValue);
                                                                                                      }}
                                                                                                      onChange={(event, selectedValue) => {
                                                                                                        setIsFormChanged(true);
                                                                                                        setStatusOption(selectedValue);
                                                                                                      }}
                                                                                                      isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                                                    /> 
          </div>
          </Box>
        </Stack>
      </Box>
    {  
    isRefreshInProgress ? <div style={{ margin: "0 10px 0 0", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
      <CircularProgress /></div> 
    :   
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
    <div style={ areaboxStyle }>
      <Tabs
        value={tabValue}
        variant="scrollable"
        aria-label="New-Tag-Tabs"
        onChange={handleTabChange}
      >
        <Tab label="General" {...a11yProps(0)} />
        <Tab label="Related Tags" {...a11yProps(1)} />
        <Tab label="Attributes" {...a11yProps(2)}/>
        <Tab label="Documents" {...a11yProps(3)} />
        <Tab label="History" {...a11yProps(4)} />
      </Tabs>    
    </div>
    <CustomTabPanel value={tabValue} index={0}>
        <Box
          sx={ areaboxStyle }
        >  
        <div style={{ display: "flex", justifyContent: "space-between", margin: "0 0 0 10px" }}> 
          <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Edit Tag
          </Typography>          
        </div>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Tag Number"
            value={tagNumber + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
          <TextField
            variant="outlined"
            size="small"
            value={sequenceNumber ? sequenceNumber + "" : ""}
            label="Sequence Number"
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            onChange={(e) => setSequenceNumber(e.target.value)}
            disabled={primaryTagOption?.id != null}
          />
        </Stack>
        <Stack direction="row">  
        {
        !isControlAutonaming ? 
          <TextField
            variant="outlined"
            size="small"
            label="Discipline Tag Number"
            value={disciplineTagNumber}
            sx={{ mr: 1, mb: 2, width: "50%", "background-color": disciplineTagNumberColor(masterAreaCardViewStore, isControlAutonaming, disciplineTagNumber), "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
            onChange={(e) => setDisciplineTagNumber(e.target.value)} 
          />
          : <div style={{ width: "51%" }}></div>
        }    
          <TextField
            variant="outlined"
            size="small"
            label="Suffix"
            value={suffix}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            onChange={(e) => setSuffix(e.target.value)}
          />
          
        </Stack>
        <Stack direction="row">
        {
        isPrimaryTagRequired(tagCategoryOption?.code) ?
          <Autocomplete
            disablePortal
            size="small"
            id="primaryTag"
            options={dictionaryStore.primaryTagOptions}
            value={primaryTagOption}
            getOptionLabel={(option) => option.label}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}}}
            renderInput={(params) => <TextField {...params} label={getPrimaryTagLabel(tagCategoryOption?.code)} 
            sx={{"background-color": validationPrimaryTagColor(masterAreaCardViewStore, primaryTagOption?.label, tagCategoryOption?.code, isControlAutonaming)}}/>}
            onInputChange={(event, newInputValue) => {
              handlePrimaryTagInputChange(newInputValue, tagCategoryOption?.code);
            }}
            onChange={(event, selectedValue) => {
              setIsFormChanged(true);
              handlePrimaryTagChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          /> : <div style={{ width: "51%" }}></div>
          }
          <FormControlLabel control={
              <Checkbox defaultChecked={isControlAutonaming} onChange={(e) => setIsControlAutonaming(e.target.checked) } />
            } label="Control Autonaming"  sx={{ width: "50%" }} 
          /> 
        </Stack>
        </Box>
        <Box
          sx={ areaboxStyle }
        > 
          <Typography
            id="classification-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Classification
          </Typography> 
          <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="tagCategory"
              options={dictionaryStore.tagCategoryOptions}
              value={tagCategoryOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label="Tag Category" sx={{"background-color": validationColor(masterAreaCardViewStore, tagCategoryOption?.label)}}/>}
              onInputChange={(event, newInputValue) => {
                handleTagCategoryInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                updateTagCategoryOption(selectedValue);
                handleEquipmentTypeAndPrimaryTagChange("", selectedValue?.id, selectedValue?.code);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {
            isInsulationTypeRequired(tagCategoryOption?.code) ?
            <TextField
              variant="outlined"
              size="small"
              label="Insulation Type"
              value={insulationType}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
              onChange={(e) => setInsulationType(e.target.value)}
            /> : null
            }
            {
            isCircuitNumberRequired(tagCategoryOption?.code) ?
            <TextField
                variant="outlined"
                size="small"
                label="Circuit Number"
                value={circuitNumber}
                sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColorWithControlAutonaming(masterAreaCardViewStore, circuitNumber, isControlAutonaming) }}
                onChange={(e) => setCircuitNumber(e.target.value)}
              /> : null
            }         
          </Stack>
          <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="eqiupmentType"
              options={dictionaryStore.eqTypeOptions}
              value={equipmentTypeOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
              renderInput={(params) => <TextField {...params} label="Type" sx={{"background-color": validationColor(masterAreaCardViewStore, equipmentTypeOption?.label)}}/>}
              onInputChange={(event, newInputValue) => {
                handleEquipmentTypeInputChange(newInputValue, tagCategoryOption?.id);
              }}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                setEquipmentTypeOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {
            isFittingNumberRequired(tagCategoryOption?.code) ?
            <TextField
              variant="outlined"
              size="small"
              label="Fitting Number"
              value={fittingNumber}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, "background-color": validationColorWithControlAutonaming(masterAreaCardViewStore, fittingNumber, isControlAutonaming)  }}
              onChange={(e) => setFittingNumber(e.target.value)}
            /> : null
            }
            {
            isNominalDiameterRequired(tagCategoryOption?.code) ?
            <TextField
              variant="outlined"
              size="small"
              label="Nominal Diameter"
              value={nominalDiameter}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
              onChange={(e) => setNominalDiameter(e.target.value)}
            /> : null 
            }
          </Stack>
          <Stack direction="row">
          { 
          isFluidRequired(tagCategoryOption?.code) ?
          <Autocomplete
            disablePortal
            size="small"
            id="fluid"
            options={dictionaryStore.fluidOptions}
            value={fluidOption}
            getOptionLabel={(option) => option.label}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            renderInput={(params) => <TextField {...params} label="Fluid" sx={{"background-color": validationColorWithControlAutonaming(masterAreaCardViewStore, fluidOption?.label, isControlAutonaming)}}/>}
            onInputChange={(event, newInputValue) => {
              handleFluidInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              setIsFormChanged(true);
              setFluidOption(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          /> : null
          }        
        </Stack>
        <Stack direction="row">
          {
          isPipeClassRequired(tagCategoryOption?.code) ?
          <Autocomplete
            disablePortal
            size="small"
            id="pipeClass"
            options={dictionaryStore.pipeClassesOptions}
            value={pipeClassOption}
            getOptionLabel={(option) => option.label}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            renderInput={(params) => <TextField {...params} label="Pipe Class"/>}
            onInputChange={(event, newInputValue) => {
              handlePipeClassInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              setIsFormChanged(true);
              setPipeClassOption(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          /> : null
          }
          
        </Stack>
        </Box>
        <Box
          sx={ areaboxStyle }
        > 
          <Typography
            id="classification-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Location
          </Typography>
          <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="facility"
              options={dictionaryStore.facilityOptions}
              value={facilityOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
              renderInput={(params) => <TextField {...params} label="Facility" sx={{"background-color": validationColor(masterAreaCardViewStore, facilityOption?.label)}}/>}
              onInputChange={(event, newInputValue) => {
                handleFacilityInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                updateFacilityOption(selectedValue);
                handleAreaInputChange("", selectedValue?.id, tagCategoryOption?.code);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disabled={primaryTagOption?.id != null}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="well"
              options={dictionaryStore.wellOptions}
              value={wellOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
              renderInput={(params) => <TextField {...params} label="Well" />}
              onInputChange={(event, newInputValue) => {
                handleWellInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                setWellOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={primaryTagOption?.id != null}
            /> 
          </Stack>
          <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="area"
              options={dictionaryStore.areaOptions}
              value={areaOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
              renderInput={(params) => <TextField {...params} label="Area" sx={{"background-color": validationColor(masterAreaCardViewStore, areaOption?.label)}}/>}
              onInputChange={(event, newInputValue) => {
                handleAreaInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                updateAreaOption(selectedValue);
                handleUnitInputChange("", selectedValue?.id);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disabled={primaryTagOption?.id != null}
            />
            {
            isBuildingRequired(tagCategoryOption?.code) ?
            <Autocomplete
              disablePortal
              size="small"
              id="building"
              options={dictionaryStore.buildingOptions}
              value={buildingOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
              renderInput={(params) => <TextField {...params} label="Building" sx={{"background-color": buildingColor(buildingOption?.label, tagCategoryOption?.code, isControlAutonaming)}} />}
              onInputChange={(event, newInputValue) => {
                handleBuildingInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                setBuildingOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            /> : null
            }
             { isLocationRequired(tagCategoryOption?.code) ?
            <Autocomplete
              disablePortal
              size="small"
              id="location"
              options={dictionaryStore.locationOptions}
              value={locationOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label="Location" sx={{"background-color": validationColorWithControlAutonaming(masterAreaCardViewStore, locationOption?.label, isControlAutonaming)}}/>}
              onInputChange={(event, newInputValue) => {
                handleLocationInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                setLocationOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            /> : null
            }   
            { isSubstationRequired(tagCategoryOption?.code) ?
             <Autocomplete
                disablePortal
                size="small"
                id="substation"
                options={dictionaryStore.substationOptions}
                value={substationOption}
                getOptionLabel={(option) => option.label}
                sx={{ mr: 1, mb: 2, width: "50%" }}
                renderInput={(params) => <TextField {...params} label="Substation"/>}
                onInputChange={(event, newInputValue) => {
                  handleSubstationInputChange(newInputValue);
                }}
                onChange={(event, selectedValue) => {
                  setIsFormChanged(true);
                  setSubstationOption(selectedValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              /> : null
            }   
          </Stack>
          <Stack direction="row"> 
            <Autocomplete
              disablePortal
              size="small"
              id="unit"
              options={dictionaryStore.unitOptions}
              value={unitOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
              renderInput={(params) => <TextField {...params} label="Unit" sx={{"background-color": validationColor(masterAreaCardViewStore, unitOption?.label)}}/>}
              onInputChange={(event, newInputValue) => {
                handleUnitInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                updateUnitOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disabled={primaryTagOption?.id != null}
            />     
                     
          </Stack>
        </Box>
        <Box
          sx={ areaboxStyle }
        > 
          <Typography
            id="classification-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Details
          </Typography>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Request Number"
              value={requestNumber}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              onChange={(e) => updateRequestNumber(e.target.value)}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="parentTag"
              options={dictionaryStore.parentTagNumberByCategoryOptions}
              value={parentTagOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Parent Tag" />}
              onInputChange={(event, newInputValue) => {
                handleParentTagInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                updateParentTag(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Stack>
          <Stack direction="row">
          <Autocomplete
              disablePortal
              size="small"
              id="project"
              options={dictionaryStore.projectOptions}
              value={projectOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Project Number" />}
              onInputChange={(event, newInputValue) => {
                handleProjectInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setIsFormChanged(true);
                setProjectsOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Stack>
          <Stack direction="row">
          { 
            isServiceDescriptionRequired(tagCategoryOption?.code) ?
            <TextField
              variant="outlined"
              size="small"
              label="Service Description"
              value={serviceDescription}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              onChange={(e) => updateServiceDescription(e.target.value)}
            />
            : null
          }
          </Stack>
          <Stack direction="row">
          <TextField
              variant="outlined"
              size="small"
              label="Documents"
              value={documents}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              onChange={(e) => setDocuments(e.target.value)}
            />           
          </Stack>       
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Remarks"
              value={remarks}
              multiline
              rows={3}
              sx={{ mr: 1, mb: 2, width: "98%" }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Stack>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <MasterRelatedTagsTab Toolbar={true} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <MasterTagDetailAttributesTab Toolbar={true} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <MasterTagDetailDocumentsTab Toolbar={true} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={4}>
        <MasterTagDetailHistoryTab />
      </CustomTabPanel>
      </Box>
      }
      <ConfirmationDialogModal 
        handleOkClick={handleSaveAndCloseClick} 
        handleCancelClick={() => setShowSaveAndCloseConfirmationDialog(false)} 
        showConfirmationDialog={showSaveAndCloseConfirmationDialog} 
        headerText="Confirm Saving Changes"
        confirmationText="Are you sure you want to save all changes?"
        okButtonText="Ok"
        cancelButtonText="Cancel"
      />
      {
      blocker ? 
        <ConfirmationDialogModal 
          handleOkClick={handleSaveOnLeaveClick} 
          handleCancelClick={handleLeaveWithoutSavingClick} 
          showConfirmationDialog={blocker.state === "blocked"} 
          headerText="Unsaved Changes"
          confirmationText="Do you want to save your changes before leaving this page?"
          okButtonText="Save and Continue"
          cancelButtonText="Discard Changes"
          blocker={blocker}
        />
      : null
      }
      <DuplicateTagWarningModal />
      <ValidateSequenceNumberWarningModal />
      </form>
      :
      null
  );
});

export default MasterAreaEditTag;
