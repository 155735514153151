export enum TagLimit
{
    None,
    "1-99",
    "A-Z",
    "Combined1_99_A_Z",
    "Combined1A_1B_1C",
    "Combined1A_2A_3A",
    "CombinedA1_A2_A3",
    "CombinedA1_B1_C1"
}