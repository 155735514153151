import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Popover,
  TextField,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useStores } from "../../../../hooks/useStores";
import { useDebounce } from "../../../../hooks/useDebounce";

const CustomFilterButtonMasterArea = observer(() => {
  const { masterAssetsStore, dictionaryStore, masterAreaGridViewExportStore } =
    useStores();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [serviceDescriptionInput, setServiceDescriptionInput] = useState("");
  const [sequenceNumberInput, setSequenceNumberInput] = useState("");
  const [remarksInput, setRemarksInput] = useState("");
  const [requestNumberInput, setRequestNumberInput] = useState("");
  const [suffixInput, setSuffixInput] = useState("");
  const [tagNumberInput, setTagNumberInput] = useState("");
  const [parentTagNumberInput, setParentTagNumberInput] = useState("");

  useEffect(() => {
    dictionaryStore.fetchAllOptions();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const debouncedHandleInputChange = useDebounce(500);

  const handleProjectInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectOptions(inputValue);
    });
  };

  const handleAreaInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchAreaOptions(inputValue);
    });
  };

  const handleUnitInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOptions(inputValue);
    });
  };

  const handleTagCategoryInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagCategoryOptions(inputValue);
    });
  };

  const handleFacilityInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFacilityOptions(inputValue);
    });
  };

  const handleTagNumberInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchMasterTagNumberOptions(inputValue);
    });
  };

  const handleParentTagNumberInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchParentTagNumberOptions(inputValue);
    });
  };

  const handleEquipmentStatusInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEquipmentStatusOptions(inputValue);
    });
  };

  const handleTagTypeInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagTypeOptions(inputValue);
    });
  };

  const handleDebouncedInputChange = useDebounce(500);

  const handleServiceDescriptionInputChange = (inputValue: string) => {
    masterAssetsStore.setSelectedServiceDescriptionOption(inputValue);
    masterAreaGridViewExportStore.setSelectedServiceDescriptionOption(
      inputValue
    );
  };

  const handleRemarksInputChange = (inputValue: string) => {
    masterAssetsStore.setSelectedRemarksOption(inputValue);
    masterAreaGridViewExportStore.setSelectedRemarksOption(inputValue);
  };

  const handleRequestNumberInputChange = (inputValue: string) => {
    masterAssetsStore.setSelectedRequestNumberOption(inputValue);
    masterAreaGridViewExportStore.setSelectedRequestNumberOption(inputValue);
  };

  const handleSuffixInputChange = (inputValue: string) => {
    masterAssetsStore.setSelectedSuffixOption(inputValue);
    masterAreaGridViewExportStore.setSelectedSuffixOption(inputValue);
  };

  const handleSequenceNumberInputChange = (inputValue: string) => {
    masterAssetsStore.setSelectedSequenceNumberOption(inputValue);
    masterAreaGridViewExportStore.setSelectedSequenceNumberOption(inputValue);
  };

  const handleTagNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      const inputValue = (event.target as HTMLInputElement).value;

      masterAssetsStore.setSelectedTagNumberOption({
        id: 0,
        label: inputValue,
      });
      masterAreaGridViewExportStore.setSelectedTagNumberOption({
        id: 0,
        label: inputValue,
      });
    }
  };

  const resetFilters = () => {
    masterAssetsStore.setSelectedProjectOption(null);
    masterAssetsStore.setSelectedTagNumberOption(null);
    masterAssetsStore.setSelectedEquipmentStatusOption(null);
    masterAssetsStore.setSelectedFacilityOption(null);
    masterAssetsStore.setSelectedAreaOption(null);
    masterAssetsStore.setSelectedUnitOption(null);
    masterAssetsStore.setSelectedCategoryOption(null);
    masterAssetsStore.setSelectedServiceDescriptionOption("");
    masterAssetsStore.setSelectedTagTypeOption(null);
    masterAssetsStore.setSelectedParentTagNumberOption(null);
    masterAssetsStore.setSelectedSequenceNumberOption("");
    masterAssetsStore.setSelectedRemarksOption("");
    masterAssetsStore.setSelectedRequestNumberOption("");
    masterAssetsStore.setSelectedSuffixOption("");

    setServiceDescriptionInput("");
    setSequenceNumberInput("");
    setRemarksInput("");
    setRequestNumberInput("");
    setSuffixInput("");
    setTagNumberInput("");
    setParentTagNumberInput("");
  };

  const handleResetButtonClick = () => {
    resetFilters();
  };

  return (
    <>
      <Button
        startIcon={<FilterListIcon />}
        variant="text"
        size="small"
        onClick={handleClick}
      >
        <span>Filter</span>
      </Button>
      <Popover
        keepMounted
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ mt: "5px" }}
      >
        <Box
          sx={{
            pl: 2,
            pt: 2,
            pr: 2,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Autocomplete
            size="small"
            id="projectNumber"
            options={dictionaryStore.projectOptions}
            value={masterAssetsStore.selectedProjectOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: 178,
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Project Number" />
            )}
            onInputChange={(event, newInputValue) => {
              handleProjectInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedProjectOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedProjectOption(
                selectedValue
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            id="tagNumber"
            size="small"
            freeSolo
            sx={{ width: 178, mr: 1, mb: 1 }}
            options={dictionaryStore.masterTagNumberOptions}
            value={masterAssetsStore.selectedTagNumberOption}
            inputValue={tagNumberInput}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tag Number"
                onKeyDown={handleTagNumberKeyDown}
                onChange={(event) => setTagNumberInput(event.target.value)}
              />
            )}
            onInputChange={(event, newInputValue) => {
              handleTagNumberInputChange(newInputValue);
              setTagNumberInput(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedTagNumberOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedTagNumberOption(
                selectedValue
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="status"
            options={dictionaryStore.equipmentStatusOptions}
            value={masterAssetsStore.selectedEquipmentStatusOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: 178,
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => <TextField {...params} label="Status" />}
            onInputChange={(event, newInputValue) => {
              handleEquipmentStatusInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedEquipmentStatusOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedEquipmentStatusOption(
                selectedValue
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="facility"
            options={dictionaryStore.facilityOptions}
            value={masterAssetsStore.selectedFacilityOption}
            getOptionLabel={(option) => option.label}
            sx={{ width: 178, mr: 1, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="Facility" />}
            onInputChange={(event, newInputValue) => {
              handleFacilityInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedFacilityOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedFacilityOption(
                selectedValue
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>
        <Box
          sx={{
            pl: 2,
            pr: 2,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Autocomplete
            size="small"
            id="area"
            options={dictionaryStore.areaOptions}
            value={masterAssetsStore.selectedAreaOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: 178,
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => <TextField {...params} label="Area" />}
            onInputChange={(event, newInputValue) => {
              handleAreaInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedAreaOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedAreaOption(
                selectedValue
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="unit"
            options={dictionaryStore.unitOptions}
            value={masterAssetsStore.selectedUnitOption}
            getOptionLabel={(option) => option.label}
            sx={{ width: 178, mr: 1, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="Unit " />}
            onInputChange={(event, newInputValue) => {
              handleUnitInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedUnitOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedUnitOption(
                selectedValue
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="category"
            options={dictionaryStore.tagCategoryOptions}
            value={masterAssetsStore.selectedCategoryOption}
            getOptionLabel={(option) => option.label}
            sx={{ width: 178, mr: 1, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="Category" />}
            onInputChange={(event, newInputValue) => {
              handleTagCategoryInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedCategoryOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedTagCategoryOption(
                selectedValue
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <TextField
            id="serviceDescription"
            label="Service Description"
            size="small"
            sx={{ width: 178, mr: 1, mb: 1 }}
            value={serviceDescriptionInput}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setServiceDescriptionInput(selectedValue);
              handleDebouncedInputChange(() => {
                handleServiceDescriptionInputChange(selectedValue);
              });
              masterAreaGridViewExportStore.setSelectedServiceDescriptionOption(
                selectedValue
              );
            }}
          />
        </Box>
        <Box
          sx={{
            pl: 2,
            pr: 2,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Autocomplete
            size="small"
            id="tagType"
            options={dictionaryStore.tagTypeOptions}
            value={masterAssetsStore.selectedTagTypeOption}
            getOptionLabel={(option) => option.label}
            sx={{ width: 178, mr: 1, mb: 1 }}
            renderInput={(params) => <TextField {...params} label="Type" />}
            onInputChange={(event, newInputValue) => {
              handleTagTypeInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedTagTypeOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedTagTypeOption(
                selectedValue
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="parentTagNumber"
            freeSolo
            options={dictionaryStore.parentTagNumberOptions}
            value={masterAssetsStore.selectedParentTagNumberOption}
            inputValue={parentTagNumberInput}
            getOptionLabel={(option) => option.label}
            sx={{ width: 178, mr: 1, mb: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Parent Tag Number"
                onChange={(event) =>
                  setParentTagNumberInput(event.target.value)
                }
              />
            )}
            onInputChange={(event, newInputValue) => {
              handleParentTagNumberInputChange(newInputValue);
              setParentTagNumberInput(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedParentTagNumberOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedParentTagNumberOption(
                selectedValue
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <TextField
            id="sequenceNumber"
            label="Sequence Number"
            size="small"
            sx={{ width: 178, mr: 1, mb: 1 }}
            value={sequenceNumberInput}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSequenceNumberInput(selectedValue);
              handleDebouncedInputChange(() => {
                handleSequenceNumberInputChange(selectedValue);
              });
              masterAreaGridViewExportStore.setSelectedSequenceNumberOption(
                selectedValue
              );
            }}
          />
          <TextField
            id="remarks"
            label="Remarks"
            size="small"
            sx={{ width: 178, mr: 1, mb: 1 }}
            value={remarksInput}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setRemarksInput(selectedValue);
              handleDebouncedInputChange(() => {
                handleRemarksInputChange(selectedValue);
              });
              masterAreaGridViewExportStore.setSelectedRemarksOption(
                selectedValue
              );
            }}
          />
        </Box>
        <Box
          sx={{
            pl: 2,
            pr: 2,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <TextField
            id="requestNumber"
            label="Request Number"
            size="small"
            sx={{ width: 178, mr: 1, mb: 1 }}
            value={requestNumberInput}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setRequestNumberInput(selectedValue);
              handleDebouncedInputChange(() => {
                handleRequestNumberInputChange(selectedValue);
              });
              masterAreaGridViewExportStore.setSelectedRequestNumberOption(
                selectedValue
              );
            }}
          />
          <TextField
            id="suffix"
            label="Suffix"
            size="small"
            sx={{ width: 178, mr: 1, mb: 1 }}
            value={suffixInput}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSuffixInput(selectedValue);
              handleDebouncedInputChange(() => {
                handleSuffixInputChange(selectedValue);
              });
              masterAreaGridViewExportStore.setSelectedSuffixOption(
                selectedValue
              );
            }}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            pt: 1,
            pl: 2,
            pr: 2,
            pb: 1,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Button variant="contained" onClick={handleResetButtonClick}>
            Clear
          </Button>
        </Box>
      </Popover>
    </>
  );
});

export default CustomFilterButtonMasterArea;
