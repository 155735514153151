import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { MasterRelatedTag } from "../../../../../models/MasterArea/response/getMasterRelatedTagsByIdResponse";

interface TagDetailDeleteRelatedTagProps {
  RelatedTags: MasterRelatedTag[];
  handleDeleteRelatedTag: any;
}

const TagDetailDeleteRelatedTag = observer((props: TagDetailDeleteRelatedTagProps) => {
  const {
    masterAreaCardViewRelatedTagStore
  } = useStores();

  const handleDeleteClick = async () => {
    props.handleDeleteRelatedTag();

    masterAreaCardViewRelatedTagStore.setShowAddRelatedTagModal(false);
  };

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<DeleteIcon />}
      disabled={masterAreaCardViewRelatedTagStore.selectedIds.length < 1}
      onClick={handleDeleteClick}
    >
      Delete
    </Button>
  );
});

export default TagDetailDeleteRelatedTag;
