import { useMemo, useEffect } from "react";
import { debounce } from "lodash-es";

type DebounceFn = (...args: any[]) => void;

function makeDebouncedHook(debounceFn: typeof debounce) {
  return function useDebounce(ms: number) {
    const debouncedFn = useMemo(
      () =>
        debounceFn(
          async (...args: any[]) => {
            const [cb] = args;
            await cb();
          },
          ms
        ),
      [ms]
    );

    useEffect(() => () => debouncedFn.cancel(), [debouncedFn]);

    return debouncedFn as DebounceFn;
  };
}

export const useDebounce = makeDebouncedHook(debounce);
