import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { Box, Modal, Stack, TextField, Typography } from "@mui/material";
import {
  longTextFieldStyle,
  remarksStyle,
  smallTextFieldStyle,
  textFieldStyle,
} from "../../../projectArea/cardView/styles/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1200px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const TagAuditPackagesTagDetailView = observer(() => {
  const {
    tagAuditStore,
    projectAreaCardViewStore: { assetDetail },
  } = useStores();

  const handleCloseClick = () => {
    tagAuditStore.setShowDisplayTagDetailsModal(false);
  };

  return (
    <Modal
      open={tagAuditStore.showDisplayTagDetailsModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="tag-details" variant="h6" component="h2" sx={{ mb: 4 }}>
          Display Tag Details
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {assetDetail?.tagNumber}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Stack direction="column">
              <Stack direction="row">
                <TextField
                  variant="outlined"
                  size="small"
                  label="Facility"
                  value={assetDetail?.facilityCode ?? "-"}
                  sx={smallTextFieldStyle}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  label="Area"
                  value={assetDetail?.areaCode ?? "-"}
                  sx={smallTextFieldStyle}
                />
              </Stack>
              <TextField
                variant="outlined"
                size="small"
                label="Service Description"
                value={assetDetail?.serviceDescription ?? "-"}
                sx={longTextFieldStyle}
              />
            </Stack>
            <Stack direction="column">
              <TextField
                variant="outlined"
                size="small"
                label="Unit"
                value={assetDetail?.unitCode ?? "-"}
                sx={smallTextFieldStyle}
              />
              <TextField
                variant="outlined"
                size="small"
                label="Equipment Status"
                value={assetDetail?.equipmentStatus}
                sx={smallTextFieldStyle}
              />
            </Stack>
            <Stack direction="row">
              <Stack direction="column">
                <Stack direction="row">
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Tag Category"
                    value={assetDetail?.tagCategoryName ?? "-"}
                    sx={textFieldStyle}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Equipment Type"
                    value={assetDetail?.equipmentTypeCode ?? "-"}
                    sx={textFieldStyle}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="System Name"
                    value={assetDetail?.systemName ?? "-"}
                    sx={textFieldStyle}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Sub System"
                    value={assetDetail?.subSystem ?? "-"}
                    sx={textFieldStyle}
                  />
                </Stack>
                <Stack direction="row">
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Ex Register"
                    value={assetDetail?.exRegister}
                    sx={textFieldStyle}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Ex Inspection Required"
                    value={assetDetail?.exInspectionRequired}
                    sx={textFieldStyle}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Remarks"
              multiline
              rows="3"
              value={assetDetail?.remarks ?? "-"}
              sx={remarksStyle}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagAuditPackagesTagDetailView;
