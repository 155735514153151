import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useStores } from "../../../../../hooks/useStores";
import TagDetailAddTagAttributesModal from "../tagAttributes/TagDetailAddTagAttributesModal";
import TagDetailAddDocumentModal from "../documents/TagDetailAddDocumentModal";
import TagDetailAddRelatedTag from "../tagRelated/TagDetailAddRelatedTag";
import { SelectOption } from "../../../../../models/common/selectOption";

const TagDetailEditTagValidation = observer(() => {
  const {
    projectAreaCardViewStore,
    projectAreaCardViewTagValidationStore,
    projectAreaCardViewDocumentStore: documentStore,
    projectAreaCardViewTagAttributesStore: tagAttributesStore,
    projectAreaCardViewRelatedTagStore: relatedTagsStore,
  } = useStores();

  const handleEditClick = () => {
    const selectedItem = projectAreaCardViewStore.tagValidations.find(
      (item) => item.id === projectAreaCardViewTagValidationStore.selectedId
    );

    if (selectedItem) {
      const selectOption: SelectOption = {
        id: selectedItem.id,
        label: selectedItem?.name!,
      };

      switch (selectedItem.validation) {
        case "Attribute":
          tagAttributesStore.setSelectedEclClassOption(selectOption);
          tagAttributesStore.setShowAddTagAttributesModal(true);
          break;
        case "Document":
          documentStore.setShowAddDocumentModal(true);
          documentStore.setSelectedProjectDocTypeOption(selectOption);
          break;
        case "Relation":
          relatedTagsStore.setShowAddRelatedTagModal(true);
          break;
        case "Equipment":
          break;
        default:
      }
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<EditIcon />}
        onClick={handleEditClick}
        disabled={projectAreaCardViewTagValidationStore.selectedIds.length === 0}
      >
        Edit
      </Button>
      {tagAttributesStore.showAddTagAttributesModal && <TagDetailAddTagAttributesModal />}
      {documentStore.showAddDocumentModal && <TagDetailAddDocumentModal />}
      {relatedTagsStore.showAddRelatedTagModal && <TagDetailAddRelatedTag />}
    </>
  );
});

export default TagDetailEditTagValidation;
