import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack
} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import ProjectColumnStore from "../../../../stores/Configuration/ProjectColumnStore";
import { AssetDetail } from "../../../../models/ProjectArea/response/getAssetsDetailResponse";
import DictionaryStore from "../../../../stores/Dictionary/DictionaryStore";
import { smallTextFieldStyle, textFieldStyle, photoStyle, remarksStyle, longTextFieldStyle } from "../styles/styles"

interface TagDetailEditFormProps {
  projectColumnStore: ProjectColumnStore;
  assetDetail: AssetDetail;
  dictionaryStore: DictionaryStore;
  selectedEqStatusOption: any;
  setSelectedEqStatusOption: (value: any) => void;
  handleEqTypeInputChange: (value: string) => void;
  selectedEqTypeOption: any;
  setSelectedEqTypeOption: (value: any) => void;
  serviceDescriptionRef: React.RefObject<HTMLInputElement>;
  RemarksRef: React.RefObject<HTMLInputElement>;
  selectedLocationOption: any;
  handleLocationInputChange: (value: string) => void;
  setSelectedLocationOption: (value: any) => void;
  setSelectedBuildingOption: (value: any) => void;
  selectedBuildingOption: any;
  handleBuildingInputChange: (value: string) => void;
  fittingNumberRef: React.RefObject<HTMLInputElement | null>;
  circuitNumberRef: React.RefObject<HTMLInputElement | null>;
  documentsRef: React.RefObject<HTMLInputElement | null>;
  insulationTypeRef: React.RefObject<HTMLInputElement | null>;
  subSystemRef: React.RefObject<HTMLInputElement | null>;
  systemNameRef: React.RefObject<HTMLInputElement | null>;
  selectedParentTagOption: any;
  setSelectedParentTagOption: (value: any) => void;
  handleParentTagInputChange: (value: string) => void;
  selectedDocumentDatasheetOption: any;
  setSelectedDocumentDatasheetOption: (value: any) => void;
  handleDocumentDatasheetInputChange: (value: string) => void;
  selectedDocumentPAIDOption: any;
  setSelectedDocumentPAIDOption: (value: any) => void;
  handleDocumentPAIDInputChange: (value: string) => void;
}

const TagDetailEditForm: React.FC<TagDetailEditFormProps> = observer(
  ({
    projectColumnStore,
    assetDetail,
    dictionaryStore,
    selectedEqStatusOption,
    selectedEqTypeOption,
    handleEqTypeInputChange,
    setSelectedEqStatusOption,
    setSelectedEqTypeOption,
    serviceDescriptionRef,
    RemarksRef,
    subSystemRef,
    systemNameRef,
    selectedParentTagOption,
    setSelectedParentTagOption,
    handleParentTagInputChange,
    selectedDocumentDatasheetOption,
    setSelectedDocumentDatasheetOption,
    handleDocumentDatasheetInputChange,
    selectedDocumentPAIDOption,
    setSelectedDocumentPAIDOption,
    handleDocumentPAIDInputChange
  }) => {
    return (
      <>
      <TextField
        id="TagNumber"
        disabled={!projectColumnStore.getEditableColumnsCardView.TagNumber}
        variant="outlined"
        size="small"
        label="Tag Number"
        defaultValue={assetDetail.tagNumber}
        sx={{ mr: 1, mb: 2, width: "98%" }}
      />
      <Stack direction="row">
        <Stack direction="column">
          <Stack direction="row">
            <TextField
              id="Facility"
              disabled={!projectColumnStore.getEditableColumnsCardView.Facility}
              variant="outlined"
              size="small"
              label="Facility"
              defaultValue={assetDetail.facilityCode}
              sx={smallTextFieldStyle}
            />
            <TextField
              id="Area"
              disabled={!projectColumnStore.getEditableColumnsCardView.Area}
              variant="outlined"
              size="small"
              label="Area"
              defaultValue={assetDetail.areaCode}
              sx={smallTextFieldStyle}
            />        
          </Stack>
          <TextField
            id="ServiceDescription"
            variant="outlined"
            size="small"
            label="Service Description"
            defaultValue={assetDetail.serviceDescription}
            inputRef={serviceDescriptionRef}
            sx={longTextFieldStyle}
          />
        </Stack>
        <Stack direction="column">
        <TextField
          id="Unit"
          disabled={!projectColumnStore.getEditableColumnsCardView.Unit}
          variant="outlined"
          size="small"
          label="Unit"
          defaultValue={assetDetail.unitCode}
          sx={smallTextFieldStyle}
        />    
        <Autocomplete
          disabled={
            !projectColumnStore.getEditableColumnsCardView.EquipmentStatus
          }
          size="small"
          id="EquipmentStatus"
          options={dictionaryStore.equipmentStatusOptions}
          value={selectedEqStatusOption}
          getOptionLabel={(option) => option.label}
          sx={smallTextFieldStyle}
          renderInput={(params) => <TextField {...params} label="Status" />}
          onChange={(event, selectedValue) => {
            setSelectedEqStatusOption(selectedValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        </Stack>
        <TextField
          variant="outlined"
          size="small"
          label="Placeholder for photo..."  
          multiline
          rows="3" 
          sx={photoStyle}
        />
      </Stack>
      <Stack direction="row">
      <TextField
          id="TagCategory"
          disabled={!projectColumnStore.getEditableColumnsCardView.TagCategory}
          variant="outlined"
          size="small"
          label="Tag Category"
          defaultValue={assetDetail.tagCategoryName}
          sx={textFieldStyle}
        />
        <Autocomplete
          size="small"
          id="EquipmentType"
          disabled={
            !projectColumnStore.getEditableColumnsCardView.EquipmentType
          }
          options={dictionaryStore.eqTypeOptions ?? []}
          value={selectedEqTypeOption}
          getOptionLabel={(option) => option.label}
          sx={textFieldStyle} 
          renderInput={(params) => (
            <TextField {...params} label="Type" variant="outlined" />
          )}
          onInputChange={(event, newInputValue) => {
            handleEqTypeInputChange(newInputValue);
          }}
          onChange={(event, selectedValue) => {
            setSelectedEqTypeOption(selectedValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <TextField
          id="SystemName"
          disabled={!projectColumnStore.getEditableColumnsCardView.SystemName}
          variant="outlined"
          size="small"
          label="System Name"
          defaultValue={assetDetail.systemName}
          inputRef={systemNameRef}
          sx={textFieldStyle}
        />
        <TextField
          id="SubSystem"
          disabled={!projectColumnStore.getEditableColumnsCardView.SubSystem}
          variant="outlined"
          size="small"
          label="Sub System"
          defaultValue={assetDetail.subSystem}
          inputRef={subSystemRef}
          sx={textFieldStyle}
        />
      </Stack>
      <Stack direction="row">
      <Autocomplete
          size="small"
          id="ParentTagNumber"
          options={dictionaryStore.projectTagNumberCategoryOptions ?? []}
          value={selectedParentTagOption ?? null}
          getOptionLabel={(option) => option.label}
          sx={textFieldStyle}
          renderInput={(params) => (
            <TextField {...params} label="Parent Tag Number" />
          )}
          onInputChange={(event, newInputValue) => {
            handleParentTagInputChange(newInputValue);
          }}
          onChange={(event, selectedValue) => {
            setSelectedParentTagOption(selectedValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Manufacturer"
          defaultValue={assetDetail.manufacturer}
          onChange={(e) => assetDetail.manufacturer = e.target.value}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Model Number"
          defaultValue={assetDetail.modelNumber}
          onChange={(e) => assetDetail.modelNumber = e.target.value}
          sx={textFieldStyle}
        />
        <TextField
          variant="outlined"
          size="small"
          label="Purchase Order Number"
          defaultValue={assetDetail.purchaseOrderNumber}
          onChange={(e) => assetDetail.purchaseOrderNumber = e.target.value}
          sx={textFieldStyle}
        />
      </Stack>
      <Stack direction="row">
        <TextField
          variant="outlined"
          size="small"
          label="Serial Number"
          defaultValue={assetDetail.serialNumber}
          onChange={(e) => assetDetail.serialNumber = e.target.value}
          sx={textFieldStyle}
        />
        <FormControl sx={textFieldStyle}>
          <InputLabel id="isMaintainable-label">Is Maintainable</InputLabel>
          <Select
            labelId="IsMaintainable-label"
            id="IsMaintainable"
            variant="outlined"
            label="Is Maintainable"
            size="small"
            value={assetDetail.isMaintainable}
            onChange={(e) => assetDetail.isMaintainable = e.target.value}
            disabled={assetDetail.exRegister === "Yes"}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={textFieldStyle}>
          <InputLabel id="ExRegister-label">Ex Register</InputLabel>
          <Select
            labelId="ExRegister-label"
            id="ExRegister"
            variant="outlined"
            label="Ex Register"
            size="small"
            value={assetDetail.exRegister}
            onChange={(e) => 
              {
                assetDetail.exRegister = e.target.value;
                if (assetDetail.exRegister === "Yes") assetDetail.isMaintainable = "Yes";
              }}
            disabled={assetDetail.exInspectionRequired === "Yes"}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={textFieldStyle}>
          <InputLabel id="ExInspectionRequired-label">
            Ex Inspection Required
          </InputLabel>
          <Select
            labelId="ExInspectionRequired-label"
            id="ExInspectionRequired"
            variant="outlined"
            label="Ex Inspection Required"
            size="small"
            value={assetDetail.exInspectionRequired}
            onChange={(e) =>
              {
                assetDetail.exInspectionRequired = e.target.value;
                if (assetDetail.exInspectionRequired === "Yes") 
                  {
                    assetDetail.isMaintainable = "Yes";
                    assetDetail.exRegister = "Yes";
                  }
              }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row">
        <TextField
          id="Remarks"
          disabled={!projectColumnStore.getEditableColumnsCardView.Remarks}
          variant="outlined"
          size="small"
          label="Remarks"
          defaultValue={assetDetail.remarks}
          inputRef={RemarksRef}
          sx={remarksStyle}
          multiline
          rows="3"
        />
        <Stack direction="column">
        <Autocomplete
          size="small"
          id="Datasheet"
          options={dictionaryStore.documentDatasheets ?? []}
          value={selectedDocumentDatasheetOption}
          getOptionLabel={(option) => option.label}
          sx={longTextFieldStyle} 
          renderInput={(params) => (
            <TextField {...params} label="Datasheet" variant="outlined" />
          )}
          onInputChange={(event, newInputValue) => {
            handleDocumentDatasheetInputChange(newInputValue);
          }}
          onChange={(event, selectedValue) => {
            setSelectedDocumentDatasheetOption(selectedValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
         <Autocomplete
          size="small"
          id="P&ID"
          options={dictionaryStore.documentPAID ?? []}
          value={selectedDocumentPAIDOption}
          getOptionLabel={(option) => option.label}
          sx={longTextFieldStyle} 
          renderInput={(params) => (
            <TextField {...params} label="P&ID" variant="outlined" />
          )}
          onInputChange={(event, newInputValue) => {
            handleDocumentPAIDInputChange(newInputValue);
          }}
          onChange={(event, selectedValue) => {
            setSelectedDocumentPAIDOption(selectedValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        </Stack>
      </Stack>       
      </>
    );
  }
);

export default TagDetailEditForm;
