import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStores } from "../../../../../hooks/useStores";
import { DeleteDocumentRequest } from "../../../../../models/ProjectArea/request/deleteDocumentRequest";
import { useState } from "react";
import ConfirmationDialogModal from "../../../../../components/common/ConfirmationDialog";

const TagDetailDeleteDocuments = observer(() => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const {
    projectAreaCardViewDocumentStore,
    projectAreaCardViewStore,
    notificationStore,
  } = useStores();

  const handleDeleteClick = async () => {
    const projectDocumentIds = projectAreaCardViewDocumentStore.selectedIds;
    const projectEquipmentId = projectAreaCardViewStore.assetDetail?.id;

    const deleteDocumentRequest: DeleteDocumentRequest = {
      projectEquipmentId: projectEquipmentId ?? 0,
      projectDocumentId: projectDocumentIds,
    };

    await projectAreaCardViewDocumentStore.deleteDocument(
      deleteDocumentRequest
    );
    projectAreaCardViewStore.fetchDocumentsById(projectEquipmentId ?? 0);
    notificationStore.setNotificationMessage('Document deleted successfully');
    notificationStore.setShowNotification(true);
    setShowConfirm(false);
  };

  return (
    <>
    <Button
      variant="outlined"
      size="small"
      startIcon={<DeleteIcon />}
      disabled={projectAreaCardViewDocumentStore.selectedIds.length < 1}
      onClick={() => setShowConfirm(true)}
      >
      Delete
    </Button>
    <ConfirmationDialogModal
        handleOkClick={handleDeleteClick}
        handleCancelClick={() => setShowConfirm(false)}
        showConfirmationDialog={showConfirm}
        headerText="Confirm Deleting Documents"
        confirmationText="Are you sure you want to delete selected documents?"
        okButtonText="Ok"
        cancelButtonText="Cancel"
      />
    </>
  );
});

export default TagDetailDeleteDocuments;
