import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import Button from "@mui/material/Button";
import FileCopy from "@mui/icons-material/FileCopy";
import MasterAreaWorkingCopyTagModal from "./MasterAreaWorkingCopyTagModal";

const MasterAreaWorkingCopyTag = observer(() => {
  const { masterAreaGridViewExportStore, masterAreaCardViewStore, dictionaryStore } = useStores();

  const handleWorkingCopyClick = () => {
    dictionaryStore.fetchProjectOptions("");
    masterAreaCardViewStore.setShowWorkingCopyModal(true);
  }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<FileCopy />}
        onClick={handleWorkingCopyClick}
        disabled={masterAreaGridViewExportStore.selectedIds.length < 1}
        >
        Working Copy
      </Button>
      <MasterAreaWorkingCopyTagModal />
    </>
  );
});

export default MasterAreaWorkingCopyTag;
