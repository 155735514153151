import { Grid } from "@mui/material";
import { DataGridPro, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import usePageTitle from "../../../hooks/usePageTitle";
import useMasterGridColumns from "./hooks/useMasterGridColumns";
import { DataGridBox } from "../../../styles/dataGridBox";

const MasterAreaDocumentsPage = observer(() => {
  usePageTitle("Master Area - Tag Attributes");

  const columns = useMasterGridColumns();

  useEffect(() => {    
    masterAreaDocumentsStore.fetchDocuments();
  }, []);

  const { masterAreaDocumentsStore } =
    useStores();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();

  return (
    <>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <DataGridBox>
            <DataGridPro
              //disableColumnFilter
              paginationMode="server"
              rows={masterAreaDocumentsStore.documentsResponse.items ?? []}
              getRowId={(row) => row.masterDocumentsId} 
              columns={columns}
              rowCount={masterAreaDocumentsStore.documentsResponse.totalRecords}
              loading={masterAreaDocumentsStore.isLoading}
              pagination
              paginationModel={masterAreaDocumentsStore.paginationModel}
              pageSizeOptions={[masterAreaDocumentsStore.paginationModel.pageSize]}
              onPaginationModelChange={masterAreaDocumentsStore.setPaginationModel}
              density="compact"
              sx={{ fontSize: "0.75rem" }}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            />
          </DataGridBox>
        </Grid>
      </Grid>
    </>
  );
});

export default MasterAreaDocumentsPage;
