import { observer } from "mobx-react";
import { useEffect } from "react";
import { useStores } from "../../../../hooks/useStores";
import { useDebounce } from "../../../../hooks/useDebounce";
import { Autocomplete, TextField } from "@mui/material";

const CustomQuickSearchBarMasterArea = observer(() => {
  const { masterAssetsStore, dictionaryStore } = useStores();

  useEffect(() => {    
    masterAssetsStore.setSelectedSearchTextOption("");
    dictionaryStore.fetchMasterTagNumberOptions("");
  }, []);

  const debouncedHandleTagNumberInputChange = useDebounce(500);
  const handleTagNumberInputChange = async (inputValue: string) => {
    debouncedHandleTagNumberInputChange(() => {
      dictionaryStore.fetchMasterTagNumberOptions(inputValue);
      if (inputValue === "") {
        masterAssetsStore.setSelectedSearchTextOption(inputValue);
      }
    });
  };

  const handleTagNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      const inputValue = (event.target as HTMLInputElement).value;
      masterAssetsStore.setSelectedSearchTextOption(inputValue);
      masterAssetsStore.fetchMasterAssets();   
    }
  };

  return (
    <Autocomplete
      id="tagNumber"
      size="small"
      freeSolo
      sx={{ width: 178, mr: 1, mb: 1 }}
      options={dictionaryStore.masterTagNumberOptions}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          placeholder="Search..."
          onKeyDown={handleTagNumberKeyDown}
        />
      )}
      onInputChange={(event, newInputValue) => {
        handleTagNumberInputChange(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
});

export default CustomQuickSearchBarMasterArea;
