import React from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useStores } from "../../hooks/useStores";
import { observer } from "mobx-react";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotificationAlert = observer(() => {
  const { notificationStore } = useStores();

  return (
    <Snackbar
      open={notificationStore.showNotificatioin}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={notificationStore.handleNotificationClose}
    >
      <Alert
        onClose={notificationStore.handleNotificationClose}
        severity={notificationStore.notificationSeverity}
        sx={{ width: "100%" }}
      >
        {notificationStore.notificationMessage}
      </Alert>
    </Snackbar>
  );
});

export default NotificationAlert;
