import { makeAutoObservable } from "mobx";
import apiClient from "../../api/clients";
import { UpdateAssetByIdRequest } from "../../models/ProjectArea/request/updateAssetByIdRequest";

class ProjectAreaUpdateStore {
  showEditMode = false;
  
  constructor() {
    makeAutoObservable(this);
  }

  setShowEditMode = (showEditMode: boolean) => {
    this.showEditMode = showEditMode;
  };

  updateAssetById = async (request: UpdateAssetByIdRequest) : Promise<Response> => {
    try {    
      const response = await apiClient.updateAssetById(request);
      return response;
    } catch (error) { 
      throw new Error("Failed to update asset. Please try again later.");
    }
  };
}

export default ProjectAreaUpdateStore;
