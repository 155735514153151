import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const WellItem = observer(() => {
  usePageTitle("References - Well");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { referencesStore } = useStores();
  
  const [wellShort, setWellShort] = useState<NullableString>(null);
  const [wellCode, setWellCode] = useState<NullableString>(null);
  const [wellRemarks, setWellRemarks] = useState<NullableString>(null);

 
  useEffect(() => {
    (async () => {   
      await initWell();
    })()
  }, []);

  const initWell = async () => {

    const id = urlParams.get("id");
    await referencesStore.fetchWellById(parseInt(id!));

    setWellShort(referencesStore.well?.wellShort ?? "");
    setWellCode(referencesStore.well?.wellCode ?? "");
    setWellRemarks(referencesStore.well?.wellRemarks ?? "");
  }
  
  const handleCloseClick = () => {
    navigate(ROUTES.referencesWells);
  };

  return (
    referencesStore.well ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
        <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Well
        </Typography>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Well"
            value={wellShort + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Well Code"
            value={wellCode + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Well Remarks"
            value={wellRemarks + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        </Box> 
      </Box>
      }
      </form>
      :
      null
  );
});

export default WellItem;
