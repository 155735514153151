import { Typography, Grid } from '@mui/material';
import React from 'react';
import usePageTitle from '../../hooks/usePageTitle';

export const SUPPORT_EMAIL = 'tco-fgp-qulager@chevron.com';

function SupportPage() {
  usePageTitle('Support');
  
  return (
    <Grid container sx={{ justifyContent: 'center', height: '100%' }}>
      <Grid item container direction="column" justifyContent="center" alignItems="center" spacing={2} xs={6}>
        <Typography align="center" sx={{ fontSize: '23px' }}>
          If you have any issues or suggestions regarding Asset Register Solution, please contact us at&nbsp;
          <a href={`mailto:${SUPPORT_EMAIL}`}>tco-fgp-qulager@chevron.com</a>.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SupportPage;
