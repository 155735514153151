import { Box } from '@mui/material';
import { ROLES } from '../../constants/roles';
import { FC, PropsWithChildren } from 'react';
import { PermissionDenied } from './PermissionDenied';
import { useUserData } from './useUserData';

const AuthProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { hasPermission } = useUserData();

  if (!hasPermission([ROLES.arsUserRole])) {
    return (
      <Box
        component="main"
        sx={{
          px: 3,
          pb: 3,
          pt: 11
        }}
      >
        <PermissionDenied requiredRoles={[ROLES.arsUserRole]} />
      </Box>
    );
  }

  return <>{children}</>;
};

export default AuthProvider;
