import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { TagRequestStatus } from "../../../models/common/response/tagRequestFormStatus";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Stack
} from "@mui/material";
import PermissionButton from "../../../components/common/PermissionButton";
import { ROLES } from "../../../constants/roles";
import { modalstyle } from "../constants/constants";
import { rejectTagRequest, approveWorkingCopy } from "../hooks/useFetchRequests";

const columns: GridColDef[] = [
  {
    field: "title",
    headerName: "Tag",
    flex: 1,
    minWidth: 150
  },
  {
    field: "value",
    headerName: "Service Description",
    flex: 1,
    minWidth: 150
  }
];

const TagRequestFormsDisplayWorkingCopyTagModal = observer(() => {
  let rId = 0;

  const { tagRequestFormStore, notificationStore, userStore } = useStores();

  const handleCloseClick = () => {
    tagRequestFormStore.setShowDisplayRequestWorkingCopyTagFormModal(false);
  };

  const handleApproveClick = async () => {   
     await approveWorkingCopy(tagRequestFormStore, notificationStore);

    tagRequestFormStore.setShowDisplayRequestWorkingCopyTagFormModal(false);
  }; 

  const handleRejectClick = async () => {   
    await rejectTagRequest(tagRequestFormStore, notificationStore);

    tagRequestFormStore.setShowDisplayRequestWorkingCopyTagFormModal(false);
  };

  return (
    <Modal
      open={tagRequestFormStore.showDisplayRequestWorkingCopyTagFormModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalstyle}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Display Working Copy Form
        </Typography>
        <Box
        id="add-tag-attributes-box"
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Tag Request Id"
              value={tagRequestFormStore.workingCopyForm.tagRequestId + ""}
              sx={{ mr: 1, mb: 2, width: "100%", "margin-right" : "20px", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
            />
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Status"
              value={TagRequestStatus[tagRequestFormStore.workingCopyForm.status!] + ""}
              sx={{ mr: 1, mb: 2, width: "100%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Project"
              value={tagRequestFormStore.workingCopyForm.projectNumber + " - " + tagRequestFormStore.workingCopyForm.projectDescription}
              sx={{ mr: 1, mb: 2, width: "100%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
          </Stack>       
          <Stack minHeight={0} width="99%" height="300px" sx={{ border: "1px solid gray" }}>
            <DataGrid
              getRowId={(row) => rId++} 
              disableColumnFilter
              paginationMode="server"
              columns={columns}
              rows={tagRequestFormStore.workingCopyListOfTags}
              rowCount={tagRequestFormStore.workingCopyListOfTags.length}          
            />
          </Stack>
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Remarks"
              value={tagRequestFormStore.tagRequestForm.remarks + ""}
              multiline
              rows={3}
              sx={{ mr: 1, mt: 2, mb: 2, width: "99%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              onChange={(e) => tagRequestFormStore.tagRequestForm.remarks = e.target.value}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex", width: "99%" },
            justifyContent: "flex-end",
          }}
        >
          { 
            tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Submitted ?  null :
            <PermissionButton
              buttonStyle={{ ml: 2 }} 
              buttonText="Approve"
              buttonVariant="contained"
              buttonPermission={userStore.hasCurrentUserRole(ROLES.arsMasterUserRole)}
              handleButtonClick={handleApproveClick}
            />
          }
          { 
            tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Submitted ?  null :
            <PermissionButton
              buttonStyle={{ ml: 2 }} 
              buttonText="Reject"
              buttonVariant="contained"
              buttonPermission={userStore.hasCurrentUserRole(ROLES.arsMasterUserRole)}
              handleButtonClick={handleRejectClick}
            />
          }        
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
            <span>Close</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagRequestFormsDisplayWorkingCopyTagModal;
