import { Grid, Typography } from "@mui/material";

const Settings = () => {
  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid item pt={2}>
        <Typography
          variant="h3"
          align="center"
          sx={{
            fontWeight: "200",
            fontSize: "6em",
            mb: 2,
            background: 'rgb(189, 189, 189)',
            color: "black",
            backgroundClip: "text",
            textShadow: "0px 1.63px 5.7px rgba(255, 255, 255)",
            backdropFilter: 'blur(0.407492px)'
          }}
        >
          Coming soon...
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Settings;
