import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Stack
} from "@mui/material";
import { observer } from "mobx-react";
import DictionaryStore from "../../../../stores/Dictionary/DictionaryStore";
import MasterAreaGridBulkUpdateStore from "../../../../stores/MasterArea/MasterAreaGridBulkUpdateStore";
import PermissionButton from "../../../../components/common/PermissionButton";
import { ROLES } from "../../../../constants/roles";
import UserStore from "../../../../stores/UserStore/UserStore";

interface CustomBulkUpdateFormModalMasterAreaProps {
  dictionaryStore: DictionaryStore;
  userStore: UserStore;
  masterAreaGridBulkUpdateStore: MasterAreaGridBulkUpdateStore;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  handleProjectInputChange: (newInputValue: string) => void;
  selectedProjectOption: any;
  setSelectedProjectOption: (value: any) => void;
  handleParentTagInputChange: (newInputValue: string) => void;
  selectedParentTagOption: any;
  setSelectedParentTagOption: (value: any) => void;
  handleStatusInputChange: (newInputValue: string) => void;
  selectedStatusOption: any;
  setSelectedStatusOption: (value: any) => void;
  serviceDescription: any;
  setServiceDescription: (value: any) => void;
  remarks: any;
  setRemarks: (value: any) => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const CustomBulkUpdateFormModalMasterArea: React.FC<CustomBulkUpdateFormModalMasterAreaProps> =
  observer(
    ({
      dictionaryStore,
      userStore,
      masterAreaGridBulkUpdateStore,
      handleCancelClick,
      handleSaveClick,
      handleProjectInputChange,
      selectedProjectOption,
      setSelectedProjectOption,
      handleParentTagInputChange,
      selectedParentTagOption,
      setSelectedParentTagOption,
      handleStatusInputChange,
      selectedStatusOption,
      setSelectedStatusOption,
      serviceDescription,
      setServiceDescription,
      remarks,
      setRemarks
    }) => {
      return (
        <Modal
          open={masterAreaGridBulkUpdateStore.showBulkUpdateForm}
          onClose={handleCancelClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="bulk-update-title"
              variant="h6"
              component="h2"
              sx={{ mb: 4 }}
            >
              Bulk Update Form
            </Typography>
            <Box
              sx={{
                backgroundColor: "#fff"
              }}
            >
            <Stack direction="row">
              <Autocomplete
                size="small"
                id="ProjectNumber"
                options={dictionaryStore.projectOptions}
                value={selectedProjectOption}
                getOptionLabel={(option) => option.label}
                sx={{ mr: 1, mb: 2, width: "50%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Project Number" />
                )}
                onInputChange={(event, newInputValue) => {
                  handleProjectInputChange(newInputValue);
                }}
                onChange={(event, selectedValue) => {
                  setSelectedProjectOption(selectedValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <Autocomplete
                disablePortal
                size="small"
                id="parentTag"
                options={dictionaryStore.parentTagNumberByCategoryOptions}
                value={selectedParentTagOption}
                getOptionLabel={(option) => option.label}
                sx={{ mr: 1, mb: 2, width: "50%" }}
                renderInput={(params) => <TextField {...params} label="Parent Tag" />}
                onInputChange={(event, newInputValue) => {
                  handleParentTagInputChange(newInputValue);
                }}
                onChange={(event, selectedValue) => {
                  setSelectedParentTagOption(selectedValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Stack>
            <Stack direction="row">
              <Autocomplete
                disablePortal
                size="small"
                id="status"
                options={dictionaryStore.equipmentStatusOptions}
                value={selectedStatusOption}
                getOptionLabel={(option) => option.label}
                sx={{ mr: 1, mb: 2, width: "50%", display: "inline-flex" }}
                renderInput={(params) => <TextField {...params} label="Status" />}
                onInputChange={(event, newInputValue) => {
                  handleStatusInputChange(newInputValue);
                }}
                onChange={(event, selectedValue) => {
                  setSelectedStatusOption(selectedValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              /> 
              <TextField
                variant="outlined"
                size="small"
                label="Service Description"
                value={serviceDescription}
                sx={{ mr: 1, mb: 2, width: "50%" }}
                onChange={(e) => setServiceDescription(e.target.value)}
              />
            </Stack>
            <Stack direction="row">
              <TextField
                variant="outlined"
                size="small"
                label="Remarks"
                value={remarks}
                multiline
                rows={3}
                sx={{ mr: 1, mb: 2, width: "100%" }}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Stack>
            </Box>
            <Box
              sx={{
                backgroundColor: "#fff",
                display: { xs: "block", md: "flex" },
                justifyContent: "flex-end"
              }}
            >
              <PermissionButton
                buttonText="Save"
                buttonVariant="contained"
                buttonPermission={userStore.hasCurrentUserRole(ROLES.arsMasterUserRole)}                
                handleButtonClick={handleSaveClick}
              />
              <Button
                variant="outlined"
                sx={{ ml: 2, mr: 3 }}
                onClick={handleCancelClick}
              >
                <span>Cancel</span>
              </Button>
            </Box>
          </Box>
        </Modal>
      );
    }
  );

export default CustomBulkUpdateFormModalMasterArea;
