import { GridSortModel } from "@mui/x-data-grid";
import { SelectOption } from "../../models/common/selectOption";
import { makeAutoObservable } from "mobx";
import { Filter, MasterAssetsFilterOptions, MasterAssetsSortOptions } from "../../models/MasterArea/request/getMasterAssetsRequest";
import { SortOrder } from "../../models/common/request/sorting";
import { GetMasterAssetsExportRequest } from "../../models/MasterArea/request/getMasterAssetsExportRequest";
import apiClient from "../../api/clients";
import { getOptionId, getOptionLabel } from "../../utils/getOptionValue";

class MasterAreaGridViewExportStore {
  isLoading = false;
  selectedProjectOption: SelectOption | null = null;
  selectedAreaOption: SelectOption | null = null;
  selectedUnitOption: SelectOption | null = null;
  selectedFacilityOption: SelectOption | null = null;
  selectedMocOption: SelectOption | null = null;
  selectedEclDisciplineOption: SelectOption | null = null;
  selectedEquipmentTypeOption: SelectOption | null = null;
  selectedTagNumberOption: SelectOption | null = null;
  selectedParentTagNumberOption: SelectOption | null = null;
  selectedEquipmentStatusOption: SelectOption | null = null;
  selectedTagCategoryOption: SelectOption | null = null;
  selectedTagTypeOption: SelectOption | null = null;
  selectedPipeClassOption: SelectOption | null = null;
  selectedServiceDescriptionOption: string | undefined = undefined;
  selectedRequestNumberOption: string | undefined = undefined;
  selectedSequenceNumberOption: string | undefined = undefined;
  selectedSuffixOption: string | undefined = undefined;
  selectedRemarksOption: string | undefined = undefined;
  selectedDisciplineTagNumberOption: string | undefined = undefined;
  selectedHistoricalRefNoOption: string | undefined = undefined;
  selectedInsulationTypeOption: string | undefined = undefined;
  selectedWellOption: string | undefined = undefined;
  selectedNominalDiameterOption: string | undefined = undefined;
  selectedCompositeKeyOption: string | undefined = undefined;
  selectedExRegisterOption: boolean | undefined = undefined;
  selectedExInspectionRequiredOption: boolean | undefined = undefined;
  selectedControlAutonamingOption: boolean | undefined = undefined;
  sortModel: GridSortModel = [];
  selectedIds: number[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setSortModel = (sortModel: any) => {
    this.sortModel = sortModel;
  };

  setSelectedProjectOption = (selectedProjectOption: SelectOption | null) => {
    this.selectedProjectOption = selectedProjectOption;
  };

  setSelectedAreaOption = (selectedAreaOption: SelectOption | null) => {
    this.selectedAreaOption = selectedAreaOption;
  };

  setSelectedUnitOption = (selectedUnitOption: SelectOption | null) => {
    this.selectedUnitOption = selectedUnitOption;
  };

  setSelectedFacilityOption = (selectedFacilityOption: SelectOption | null) => {
    this.selectedFacilityOption = selectedFacilityOption;
  };

  setSelectedMocOption = (selectedMocOption: SelectOption | null) => {
    this.selectedMocOption = selectedMocOption;
  };

  setSelectedEclDisciplineOption = (
    selectedEclDisciplineOption: SelectOption | null
  ) => {
    this.selectedEclDisciplineOption = selectedEclDisciplineOption;
  };

  setSelectedEquipmentTypeOption = (
    selectedEquipmentTypeOption: SelectOption | null
  ) => {
    this.selectedEquipmentTypeOption = selectedEquipmentTypeOption;
  };

  setSelectedTagNumberOption = (
    selectedTagNumberOption: SelectOption | null
  ) => {
    this.selectedTagNumberOption = selectedTagNumberOption;
  };

  setSelectedParentTagNumberOption = (
    selectedParentTagNumberOption: SelectOption | null
  ) => {
    this.selectedParentTagNumberOption = selectedParentTagNumberOption;
  };

  setSelectedRequestNumberOption = (
    selectedRequestNumberOption: string | undefined
  ) => {
    this.selectedRequestNumberOption = selectedRequestNumberOption;
  };

  setSelectedTagCategoryOption = (
    selectedTagCategoryOption: SelectOption | null
  ) => {
    this.selectedTagCategoryOption = selectedTagCategoryOption;
  };

  setSelectedSequenceNumberOption = (
    selectedSequenceNumberOption: string | undefined
  ) => {
    this.selectedSequenceNumberOption = selectedSequenceNumberOption;
  };

  setSelectedRemarksOption = (selectedRemarksOption: string | undefined) => {
    this.selectedRemarksOption = selectedRemarksOption;
  };

  setSelectedSuffixOption = (selectedSuffixOption: string | undefined) => {
    this.selectedSuffixOption = selectedSuffixOption;
  };

  setSelectedServiceDescriptionOption = (
    selectedServiceDescriptionOption: string | undefined
  ) => {
    this.selectedServiceDescriptionOption = selectedServiceDescriptionOption;
  };

  setSelectedEquipmentStatusOption = (option: SelectOption | null) => {
    this.selectedEquipmentStatusOption = option;
  };

  setSelectedTagTypeOption = (option: SelectOption | null) => {
    this.selectedTagTypeOption = option;
  };

  setSelectedPipeClassOption = (option: SelectOption | null) => {
    this.selectedPipeClassOption = option;
  };

  setSelectedDisciplineTagNumberOption = (selectedDisciplineTagNumberOption: string | undefined) => {
    this.selectedDisciplineTagNumberOption = selectedDisciplineTagNumberOption;
  };

  setSelectedHistoricalRefNoOption = (selectedHistoricalRefNoOption: string | undefined) => {
    this.selectedHistoricalRefNoOption = selectedHistoricalRefNoOption;
  };

  setSelectedInsulationTypeOption = (selectedInsulationTypeOption: string | undefined) => {
    this.selectedInsulationTypeOption = selectedInsulationTypeOption;
  };

  setSelectedWellOption = (selectedWellOption: string | undefined) => {
    this.selectedWellOption = selectedWellOption;
  };

  setSelectedNominalDiameterOption = (selectedNominalDiameterOption: string | undefined) => {
    this.selectedNominalDiameterOption = selectedNominalDiameterOption;
  };

  setSelectedCompositeKeyOption = (selectedCompositeKeyOption: string | undefined) => {
    this.selectedCompositeKeyOption = selectedCompositeKeyOption;
  };

  setSelectedExRegisterOption = (selectedExRegisterOption: boolean | undefined) => {
    this.selectedExRegisterOption = selectedExRegisterOption;
  };

  setSelectedExInspectionRequiredOption = (selectedExInspectionRequiredOption: boolean | undefined) => {
    this.selectedExInspectionRequiredOption = selectedExInspectionRequiredOption;
  };

  setSelectedControlAutonamingOption = (selectedControlAutonamingOption: boolean | undefined) => {
    this.selectedControlAutonamingOption = selectedControlAutonamingOption;
  };


  setSelectedIds = (selectedIds: number[]) => {
    this.selectedIds = selectedIds;
  };

  exportToExcel = async () => {
    const filterOptions: MasterAssetsFilterOptions = {
      projectNumberId: getOptionId(this.selectedProjectOption),
      tagNumber:  getOptionLabel(this.selectedTagNumberOption),
      equipmentStatusId: getOptionId(this.selectedEquipmentStatusOption),
      areaId: getOptionId(this.selectedAreaOption),
      facilityId: getOptionId(this.selectedFacilityOption),
      unitId: getOptionId(this.selectedUnitOption),
      equipmentTypeId: getOptionId(this.selectedEquipmentTypeOption),
      tagTypeId: getOptionId(this.selectedTagTypeOption),
      parentTagNumber:  getOptionLabel(this.selectedParentTagNumberOption),
      requestNumber: this.selectedRequestNumberOption,
      categoryId: getOptionId(this.selectedTagCategoryOption),
      sequenceNumber: this.selectedSequenceNumberOption,
      remarks: this.selectedRemarksOption,
      suffix: this.selectedSuffixOption,
      serviceDescription: this.selectedServiceDescriptionOption,
    };

    const sortOptions: MasterAssetsSortOptions = {
      projectNumber: "projectNumber",
      tagNumber: "tagNumber",
      area: "area",
      facility: "facility",
      unit: "unit",
      status: "status",
      category: "category",
      serviceDescription: "serviceDescription",
      type: "type",
      parentTag: "parentTag",
      remarks: "remarks",
      requestNumber: "requestNumber",
      suffix: "suffix",
      sequenceNumber: 'sequenceNumber',
      disciplineTagNumber: 'disciplineTagNumber',
      createdBy: "createdBy",
      createdOn: "createdDate"
    };

    const sortField = this.sortModel[0]?.field;
    const sortOrder =
      this.sortModel[0]?.sort === "asc" ? SortOrder.ASC : SortOrder.DESC;
    const sortProperty = sortField
      ? sortOptions[sortField as keyof MasterAssetsSortOptions]
      : undefined;
    const filter = Object.keys(filterOptions).reduce((acc, key) => {
      const filterValue =
        filterOptions[key as keyof MasterAssetsFilterOptions];
      if (filterValue !== undefined) {
        acc[key as keyof Filter] = { filterValue: filterValue as never };
      }
      return acc;
    }, {} as Filter);
    const sort = sortProperty
      ? {
          [sortProperty]: { sortOrder },
        }
      : undefined;

    const request: GetMasterAssetsExportRequest = {
      filter,
      sort,
      maxRows: 1000000,
    };

    try {
      this.setIsLoading(true);
      await apiClient.getMasterAssetsExport(request);
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error export assets:", error);
    }
  };

  exportSelectedToExcel = async () => {
    try {
      this.setIsLoading(true);
      await apiClient.getSelectedMasterAssetsExport(this.selectedIds);
      this.setIsLoading(false);      
    } catch (error) {
      console.error("Error export assets:", error);
      this.setIsLoading(false);        
    }
  };

}

export default MasterAreaGridViewExportStore;