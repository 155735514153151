import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../../api/clients";
import { SelectOption } from "../../models/common/selectOption";
import { GetDocumentByIdResponse } from "../../models/ProjectArea/response/getDocumentByIdResponse";

class MasterAreaCardViewDocumentStore {
  showAddDocumentModal = false;
  showCreateDocumentModal = false;
  showCreateDocumentAlert = false;
  masterDocsOption: SelectOption[] = [];
  selectedMasterDocOption: SelectOption[] = [];
  selectedIds: number[] = [];
  showEditDocumentModal = false;
  selectedId: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setShowAddDocumentModal = (value: boolean) => {
    this.showAddDocumentModal = value;
  };

  setShowCreateDocumentModal = (value: boolean) => {
    this.showCreateDocumentModal = value;
  };

  setShowCreateDocumentAlert = (value: boolean) => {
    this.showCreateDocumentAlert = value;
  };

  setSelectedMasterDocOption = (value: SelectOption[]) => {
    this.selectedMasterDocOption = value;
  };

  setSelectedIds = (ids: number[]) => {
    this.selectedIds = ids;
    this.setSelectedId(ids.length > 0 ? ids[0] : null);
  };

  setShowEditDocumentModal = (value: boolean) => {
    this.showEditDocumentModal = value;
  };

  setSelectedId(id: number | null) {
    this.selectedId = id;
  }

  async getMasterDocOptions(docNo: string): Promise<void> {
    try {
      const response = await apiClient.getProjectDocuments(docNo);
      const newOptions = response.map((option) => ({
        id: option.id,
        label: option.documentNumber,
      })) as SelectOption[];

      runInAction(() => {
        this.masterDocsOption = newOptions;
      });

      if (newOptions.length === 0) {
        this.setShowCreateDocumentAlert(true);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  }

  async getMasterDocDetails(id?: number): Promise<GetDocumentByIdResponse | null> {
      try {
      const response = await apiClient.getProjectDocumentById(id!);
      return response;
    } catch (error) {
      console.error("Error while getting document details:", error);
    }
    return null;
  }

}

export default MasterAreaCardViewDocumentStore;
