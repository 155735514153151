import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { Box, Modal, Typography, Button } from "@mui/material";
import { NOTIFICATION_TYPES } from "../../../../constants/notificationTypes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const ApproveHandoverButtonModal = observer(() => {
  const { tagAuditStore, notificationStore } = useStores();

  // Get the count of selectedIds
  const selectedTagsCount = tagAuditStore.selectedIds.length;

  const handleCloseClick = () => {
    tagAuditStore.setshowApproveHandoverModal(false);
  };

  const handleSaveClick = () => {
    const tagsIds = tagAuditStore.selectedIds;

    tagAuditStore.approveHandover(tagsIds).then(() => {
      if (tagAuditStore.validationErrors) {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
        notificationStore.setNotificationMessage(tagAuditStore.validationErrors);
        notificationStore.setShowNotification(true);
      } else {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
        notificationStore.setNotificationMessage(
          "Tags approved to handover successfully"
        );
        notificationStore.setShowNotification(true);
      }
      tagAuditStore.setshowApproveHandoverModal(false);
    });
  };

  return (
    <Modal
      open={tagAuditStore.showApproveHandoverModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="approve-handover-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Approve Handover & Publish to Master
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Typography
            id="approve-handover-subtitle"
            variant="subtitle1"
            component="h2"
            sx={{ mb: 4 }}
          >
            Do you want to approve the handover of <strong>{selectedTagsCount}</strong>{" "}
            tags and publish the changes to the Master Area?
          </Typography>
        </Box>
        <Button variant="contained" sx={{ ml: 2 }} onClick={handleSaveClick}>
          <span>Yes</span>
        </Button>
        <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
          <span>No</span>
        </Button>
      </Box>
    </Modal>
  );
});

export default ApproveHandoverButtonModal;
