import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridBox } from "../../../../styles/dataGridBox";
import { RelationType } from "../../../../models/common/response/relationType";

const RelatedTagsPopupTab = observer(() => {

  const columns: GridColDef[] = [
    {
      field: "projectNumber",
      headerName: "Project Number",
      flex: 1,
      minWidth: 150,
    },
    { 
      field: "tagNumber", 
      headerName: "Tag Number", 
      flex: 1, 
      minWidth: 180,
      renderCell: (params) => {
        return (
          <>
            <a href="#" rel="noreferrer" onClick={() => handleShowCardModalClick(params.row.id)}>
              {params.value}
            </a>
          </>
        );
      }
    },
    {
      field: "relationType",
      headerName: "Relation Type",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <span>{RelationType[params.value]}</span>
    },
    { field: "status", headerName: "Status", flex: 1, minWidth: 150 },
    {
      field: "serviceDescription",
      headerName: "Service Description",
      flex: 1,
      minWidth: 200,
    },
  ];
  
  const {
    projectAreaPopupCardViewStore: { relatedTags, fetchAssetById, setShowCardModal },
    projectAreaPopupCardViewRelatedTagStore: relatedTagStore
  } = useStores();
  
  const handleShowCardModalClick = (id: number) => {
    fetchAssetById(id);
    setShowCardModal(true);
  };

  return (
    <>
      <DataGridBox>
        <DataGrid 
          columns={columns} 
          rows={relatedTags ?? []} 
          density="comfortable"
          onRowSelectionModelChange={(ids) => {
            const selectedIds = new Set(ids);
            relatedTagStore.setSelectedIds(
              Array.from(selectedIds).map((id) => parseInt(id as string))
            );
          }}
          />
      </DataGridBox>
      {/*<TagDetailPopup /> */}
    </>
  );
});

export default RelatedTagsPopupTab;
