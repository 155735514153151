import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import TagDetailAddRelatedTagModal from "./TagDetailAddRelatedTagModal";
import { MasterRelatedTag } from "../../../../../models/MasterArea/response/getMasterRelatedTagsByIdResponse";

interface TagDetailAddRelatedTagProps {
  RelatedTags: MasterRelatedTag[];
  handleAddRelatedTag: any;
}

const TagDetailAddRelatedTag = observer((props: TagDetailAddRelatedTagProps) => {
  const {
    masterAreaCardViewRelatedTagStore: relatedTagStore,
    dictionaryStore
  } = useStores();

  const handleAddClick = () => {
    relatedTagStore.setShowAddRelatedTagModal(true);
    relatedTagStore.setSelectedRelationType(null);
    relatedTagStore.setSelectedRelatedTag(null);

    dictionaryStore.fetchMasterTagNumberOptions("");
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
      >
        <span>Add</span>
      </Button>
      <TagDetailAddRelatedTagModal RelatedTags={props.RelatedTags} handleAddRelatedTag={props.handleAddRelatedTag}/>
    </>
  );
});

export default TagDetailAddRelatedTag;
