import { AuthenticationResult, Configuration, EventType, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: '/'
  },
  system: {
    iframeHashTimeout: 10000
  }
};

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount((event.payload as AuthenticationResult).account);
  }
});

export const loginRequest: RedirectRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE ?? '']  
};

export default msalInstance;
