import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { SxProps, Theme } from "@mui/material";
import { memo, useCallback } from "react";

interface PermissionButtonProps {
  buttonText: string;
  buttonVariant: "text" | "outlined" | "contained";
  buttonPermission: boolean;
  buttonStyle?: SxProps<Theme>;
  buttonDisabled?: boolean;
  handleButtonClick: () => void;
}

const PermissionButton = ({
  buttonText,
  buttonVariant,
  buttonPermission,
  buttonStyle,
  buttonDisabled,
  handleButtonClick,
}: PermissionButtonProps) => { 

  const hasRequiredPermission = buttonPermission;
  
  const setTooltipTitle = () => {
    if(!hasRequiredPermission) return "You do not have permission to perform this action";
    else if(buttonDisabled) return "Check all required fields";
    else return "";
  };

  const handleClick = useCallback(() => {
    if (hasRequiredPermission) {
      handleButtonClick();
    }
  }, [hasRequiredPermission, handleButtonClick]);

  return (
    <Tooltip title={setTooltipTitle()}>
      <span>
        <Button
          variant={buttonVariant}
          onClick={handleClick}
          disabled={!hasRequiredPermission || buttonDisabled}
          sx={buttonStyle}
        >
          {buttonText}
        </Button>
      </span>
    </Tooltip>
  );
};

export default memo(PermissionButton);
