export const ROUTES = { 
    masterAreaGrid: "/master-area-grid", 
    masterAreaGridAttributes: "/master-area-grid/attributes", 
    masterAreaGridProjects: "/master-area-grid/projects", 
    masterAreaGridDocuments: "/master-area-grid/documents", 
    masterAreaNewTag: "/master-area-new-tag", 
    masterAreaEditTag: "/master-area-edit-tag", 
    masterAreaCopyTag: "/master-area-copy-tag",
    masterAreaEditDocument: "/master-area-edit-document", 
    masterAreaEditTagAttribute: "/master-area-edit-tagattribute",
    masterAreaEditProject: "/master-area-edit-project", 
    referencesFacilities: "/references/facilities",
    referencesFacility: "/references/facility",
    referencesAreas: "/references/areas",
    referencesArea: "/references/area",
    referencesUnits: "/references/units",
    referencesUnit: "/references/unit",
    referencesBuildings: "/references/buildings",
    referencesBuilding: "/references/building",
    referencesWells: "/references/wells",
    referencesWell: "/references/well",
    referencesSubstations: "/references/substations",
    referencesSubstation: "/references/substation",
    referencesPipeClasses: "/references/pipeclasses",
    referencesPipeClass: "/references/pipeclass",
    referencesFluids: "/references/fluids",
    referencesFluid: "/references/fluid",
    referencesLocations: "/references/locations",
    referencesLocation: "/references/location",
}