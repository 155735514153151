import { SelectOption } from "../models/common/selectOption";

export const getOptionId = (option: SelectOption | null): number =>
  option?.id ?? 0;

export const getNullableOptionId = (
  option: SelectOption | null
): number | null => option?.id ?? null;

export const getOptionLabel = (option: SelectOption | string | undefined | null): string =>
  typeof option === 'string' ? option : option?.label ?? "";
