import { observer } from "mobx-react";
import TagRequestFormsDeleteTag from "./TagRequestFormsDeleteTag";
import TagRequestFormsAddTag from "./TagRequestFormsAddTag";
import TagRequestFormsEditTag from "./TagRequestFormsEditTag";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { ROLES } from "../../../constants/roles";
import { useStores } from "../../../hooks/useStores";

const TagRequestFormsToolBar = observer(() => {
  const { userStore } = useStores();

  return (
    userStore.hasCurrentUserRole(ROLES.arsProjectUserRole) 
    ?
      <GridToolbarContainer sx={{ margin: "0 0 10px 10px"}}>
        {/* {<TagRequestFormsAddTag />} disabled for the tech release */}
        {<TagRequestFormsEditTag />}
        {<TagRequestFormsDeleteTag />}
      </GridToolbarContainer>
    : null
  );
});

export default TagRequestFormsToolBar;
