import { useEffect } from "react";
import DictionaryStore from "../../../../stores/Dictionary/DictionaryStore";

export const useEditTagFetchOptionsData = (
  dictionaryStore: DictionaryStore,
  selectedTagRequestAreaId?: number
) => {
  useEffect(() => {
    dictionaryStore.fetchTagCategoryOptions("");
    dictionaryStore.fetchProjectOptions("");
    dictionaryStore.fetchAreaOptions("");
    dictionaryStore.fetchUnitOptions("", selectedTagRequestAreaId);
    dictionaryStore.fetchEqTypeOptions(""); 
    dictionaryStore.fetchBuildingOptions("");
    dictionaryStore.fetchWellOptions("");
    dictionaryStore.fetchParentTagNumberByCategoryOptions("");
    dictionaryStore.fetchFacilityOptions("");
    dictionaryStore.fetchMasterDocumentsByNumber("");
    dictionaryStore.fetchPrimaryTagNumberByCategoryOptions("");
  }, [dictionaryStore, selectedTagRequestAreaId]);
};

export const useCreateTagFetchOptionsData = (
  dictionaryStore: DictionaryStore
) => {
  useEffect(() => {
    dictionaryStore.fetchTagCategoryOptions("");
    dictionaryStore.fetchProjectOptions("");
    dictionaryStore.fetchAreaOptions("");
    dictionaryStore.fetchUnitOptions("");
    dictionaryStore.fetchBuildingOptions("");
    dictionaryStore.fetchWellOptions("");
    dictionaryStore.fetchParentTagNumberByCategoryOptions("");
    dictionaryStore.fetchFacilityOptions("");
    dictionaryStore.fetchMasterDocumentsByNumber("");
    dictionaryStore.fetchPrimaryTagNumberByCategoryOptions("");
    dictionaryStore.fetchPipeClassesOptions("");
    dictionaryStore.fetchFluidsOptions("");
    dictionaryStore.fetchLocationOptions("");
    dictionaryStore.fetchSubstationOptions("");
  }, [dictionaryStore]);
};
