import {
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import PermissionButton from "../../components/common/PermissionButton";
import { observer } from "mobx-react";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface ConfirmationDialogModalProps {
  handleOkClick: any;
  handleCancelClick: any;
  showConfirmationDialog: boolean;
  headerText: string;
  confirmationText: string;
  okButtonText: string;
  cancelButtonText: string;
  blocker?: any;
}

const ConfirmationDialogModal = observer((props: ConfirmationDialogModalProps) => {
  return (
    <Modal
      open={props.showConfirmationDialog}
      onClose={props.handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="confirm-dialog"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          {props.headerText}
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >      
        <Typography
          id="add-doc-title"
          fontSize="12"
          component="h2"
          sx={{ mb: 4 }}
        >
          {props.confirmationText}
        </Typography>  
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <PermissionButton
            buttonText={props.okButtonText}
            buttonVariant="contained"
            buttonPermission={true} 
            handleButtonClick={props.handleOkClick}
          />
          <Button variant="outlined" sx={{ ml: 2 }} onClick={props.handleCancelClick}>
            <span>{props.cancelButtonText}</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default ConfirmationDialogModal;
