import React, { useState } from "react";
import { observer } from "mobx-react";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useStores } from "../../../../../hooks/useStores";

const ImportFromExcelMasterTagAttributesButton = observer(() => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { masterAreaImportFromExcel } = useStores();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
    masterAreaImportFromExcel.setImportResponse("");
  };

  const handleImport = async () => {
    if (selectedFile) {
      await masterAreaImportFromExcel.importFromExcelMasterTagAttributes(
        selectedFile
      );
      // Reset the selected file after import
      setSelectedFile(null);
    }
  };

  return (
    <div>
      <input
        accept=".xlsx, .xls"
        type="file"
        id="excel-file-input"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <label htmlFor="excel-file-input">
        <LoadingButton
          loadingPosition="start"
          variant="text"
          size="small"
          component="span"
          loading={masterAreaImportFromExcel.isLoading}
          startIcon={<UploadFileIcon />}
        >
          Import Tag Attributes
        </LoadingButton>
      </label>
      {selectedFile && (
        <div>
          <p>File selected: {selectedFile.name}</p>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleImport}
            loading={masterAreaImportFromExcel.isLoading}
            startIcon={<UploadFileIcon />}
          >
            Import
          </LoadingButton>
        </div>
      )}
      {!masterAreaImportFromExcel.isLoading &&
        masterAreaImportFromExcel.importResponse && (
          <p>{masterAreaImportFromExcel.importResponse}</p>
        )}
    </div>
  );
});

export default ImportFromExcelMasterTagAttributesButton;
