import TagRequestFormStore from "../../../stores/TagRequest/TagRequestFormStore";
import NotificationStore from "../../../stores/Common/NotificationStore";
import { CreateTagRequest } from "../../../models/TagRequest/request/createTagRequest";
import { EditTagRequestByIdRequest } from "../../../models/TagRequest/request/editTagRequestByIdRequest";
import { TagRequestStatus } from "../../../models/common/response/tagRequestFormStatus";
import { CreateBBTagRequest } from "../../../models/TagRequest/request/createBBTagRequest";
import { NOTIFICATION_TYPES } from "../../../constants/notificationTypes";

export const deleteTagRequests = async (tagRequestFormStore: TagRequestFormStore, notificationStore: NotificationStore) => {
  try {
    for(const selectedId of tagRequestFormStore.selectedIds) {
      await tagRequestFormStore.fetchTagRequestById(selectedId);
      if(tagRequestFormStore.tagRequestForm.status === TagRequestStatus.Completed) {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
        notificationStore.setNotificationMessage("Requested tag in 'Completed' status cannot be deleted");
      }
      else {
        await tagRequestFormStore.deleteTagRequestById(selectedId);
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
        notificationStore.setNotificationMessage("Requested tag deleted successfully");  
      }
    } 
  }
  catch(error) {
    notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
    notificationStore.setNotificationMessage("Requested tag(-s) cannot be deleted");
  }
  finally {
    notificationStore.setShowNotification(true);
  }
}

 export const createTagRequest = async (tagRequestFormStore: TagRequestFormStore, notificationStore: NotificationStore, createTag: CreateTagRequest) => {
    try {
      await tagRequestFormStore.createTagRequest(createTag);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Requested tags created successfully");   
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error creating tag request form: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }

  export const submitNewTagRequest = async (tagRequestFormStore: TagRequestFormStore, notificationStore: NotificationStore, createTag: CreateTagRequest) => {
    try {
      await tagRequestFormStore.createTagRequest(createTag);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Tag request submitted successfully"); 
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error submitting tag request: " + error);  
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }

  export const editTagRequest = async (tagRequestFormStore: TagRequestFormStore, notificationStore: NotificationStore) => {
    const editTag: EditTagRequestByIdRequest = {
        id: tagRequestFormStore.tagRequestForm.id,  
        projectId: tagRequestFormStore.tagRequestForm.projectId,
        areaId: tagRequestFormStore.tagRequestForm.areaId,
        unitId: tagRequestFormStore.tagRequestForm.unitId,
        equipmentTypeId: tagRequestFormStore.tagRequestForm.equipmentTypeId,
        buildingId: tagRequestFormStore.tagRequestForm.buildingId,
        wellId: tagRequestFormStore.tagRequestForm.wellId,
        documents: tagRequestFormStore.tagRequestForm.documents,
        serviceDescription: tagRequestFormStore.tagRequestForm.serviceDescription,
        suffix: tagRequestFormStore.tagRequestForm.suffix,
        multipleTags: tagRequestFormStore.tagRequestForm.multipleTags,
        tagLimit: tagRequestFormStore.tagRequestForm.tagLimit,
        remarks: tagRequestFormStore.tagRequestForm.remarks,
        isDatasheetNumber: tagRequestFormStore.tagRequestForm.isDatasheetNumber,
        isEquipmentTagNumberForMotor: tagRequestFormStore.tagRequestForm.isEquipmentTagNumberForMotor,
        isMotorDatasheetNumber: tagRequestFormStore.tagRequestForm.isMotorDatasheetNumber,
        requiredDate: tagRequestFormStore.tagRequestForm.requiredDate,
        status: TagRequestStatus.Draft
      };

    try {
      await tagRequestFormStore.editTagRequestById(editTag);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      if(editTag.status === TagRequestStatus.Draft) notificationStore.setNotificationMessage("Tag request edited successfully");
      else if(editTag.status === TagRequestStatus.Submitted) notificationStore.setNotificationMessage("Tag request submitted successfully"); 
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      if(editTag.status === TagRequestStatus.Draft) notificationStore.setNotificationMessage("Error editing tag request: " + error);
      else if(editTag.status === TagRequestStatus.Submitted) notificationStore.setNotificationMessage("Error submitting tag request: " + error);  
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }

  export const rejectTagRequest = async (tagRequestFormStore: TagRequestFormStore, notificationStore: NotificationStore) => {
    try {
      await tagRequestFormStore.rejectTagRequestById(tagRequestFormStore.tagRequestForm.id);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Tag request rejected successfully");   
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error rejecting  request: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }

  export const approveWorkingCopy = async (tagRequestFormStore: TagRequestFormStore, notificationStore: NotificationStore) => {
    try {
      await tagRequestFormStore.approveWorkingCopyById(tagRequestFormStore.tagRequestForm.id);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Working copy approved successfully");   
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error approving working copy: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }

  export const submitTagRequest = async (tagRequestFormStore: TagRequestFormStore, notificationStore: NotificationStore) => {
    const editTag: EditTagRequestByIdRequest = {
        id: tagRequestFormStore.tagRequestForm.id,  
        projectId: tagRequestFormStore.tagRequestForm.projectId,
        areaId: tagRequestFormStore.tagRequestForm.areaId,
        unitId: tagRequestFormStore.tagRequestForm.unitId,
        equipmentTypeId: tagRequestFormStore.tagRequestForm.equipmentTypeId,
        buildingId: tagRequestFormStore.tagRequestForm.buildingId,
        wellId: tagRequestFormStore.tagRequestForm.wellId,
        documents: tagRequestFormStore.tagRequestForm.documents,
        serviceDescription: tagRequestFormStore.tagRequestForm.serviceDescription,
        suffix: tagRequestFormStore.tagRequestForm.suffix,
        multipleTags: tagRequestFormStore.tagRequestForm.multipleTags,
        tagLimit: tagRequestFormStore.tagRequestForm.tagLimit,
        remarks: tagRequestFormStore.tagRequestForm.remarks,
        isDatasheetNumber: tagRequestFormStore.tagRequestForm.isDatasheetNumber,
        isEquipmentTagNumberForMotor: tagRequestFormStore.tagRequestForm.isEquipmentTagNumberForMotor,
        isMotorDatasheetNumber: tagRequestFormStore.tagRequestForm.isMotorDatasheetNumber,
        requiredDate: tagRequestFormStore.tagRequestForm.requiredDate,
        status: TagRequestStatus.Submitted
      };

    try {
      await tagRequestFormStore.editTagRequestById(editTag);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Tag request submitted successfully"); 
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error submitting tag request: " + error);  
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }

 export const createBBTag = async (tagRequestFormStore: TagRequestFormStore, notificationStore: NotificationStore) => {
    const createBBTag: CreateBBTagRequest = {
        tagRequestId: tagRequestFormStore.tagRequestForm.id, 
        categoryId: 3, //mechanical category 
        categoryCode: "", //mechanical category 
        projectId: tagRequestFormStore.tagRequestForm.projectId,
        parentTagId: null,
        facilityId: 6, // replace with actual value
        tagTypeId: tagRequestFormStore.tagRequestForm.equipmentTypeId,
        areaId: tagRequestFormStore.tagRequestForm.areaId,
        unitId: tagRequestFormStore.tagRequestForm.unitId,
        buildingId: tagRequestFormStore.tagRequestForm.buildingId,
        wellId: tagRequestFormStore.tagRequestForm.wellId,
        documents: tagRequestFormStore.tagRequestForm.documents,
        serviceDescription: tagRequestFormStore.tagRequestForm.serviceDescription,
        suffix: tagRequestFormStore.tagRequestForm.suffix,
        multipleTags: tagRequestFormStore.tagRequestForm.multipleTags,
        tagLimit: tagRequestFormStore.tagRequestForm.tagLimit,
        remarks: tagRequestFormStore.tagRequestForm.remarks,
        sequenceNumber: null,
        locationId: null,
        disciplineTagNumber: null,
        controlAutonaming: false,
        documentId: null,
        primaryTagId: null,
        nominalDiameter: tagRequestFormStore.tagRequestForm.nominalDiameter,
        pipeClassId: null,
        insulationType: tagRequestFormStore.tagRequestForm.insulationType,
        circuitNumber: tagRequestFormStore.tagRequestForm.circuitNumber,
        fittingNumber: tagRequestFormStore.tagRequestForm.fittingNumber,
        requestNumber: tagRequestFormStore.tagRequestForm.requestNumber,
        fluidId: null,
        substationId: null
      };

    try {
      await tagRequestFormStore.createBBTag(createBBTag);
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("Tag created successfully");   
    }
    catch(error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Error creating tag: " + error);
    }
    finally {
      notificationStore.setShowNotification(true);
    }
  }