import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteUserProjectMapButton = observer(() => {
  const { userProjectMapStore, notificationStore } = useStores();

  const handleDeleteClick = async () => {
    const usersIds = userProjectMapStore.selectedIds;

    await userProjectMapStore.deleteUserProjectMap({
      usersIds: usersIds
    });

    userProjectMapStore.fetchUserProjectMaps();
    notificationStore.setNotificationMessage("Users projects map deleted successfully");
    notificationStore.setShowNotification(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<DeleteIcon />}
        onClick={handleDeleteClick}
        disabled={userProjectMapStore.selectedIds.length < 1}
      >
        <span>Delete</span>
      </Button>
    </>
  );
});

export default DeleteUserProjectMapButton;
