import { makeAutoObservable } from "mobx";
import apiClient from "../../api/clients";
import { UpdateMasterAssetByIdRequest } from "../../models/MasterArea/request/updateMasterAssetByIdRequest";

class MasterAreaUpdateStore {
  showEditMode = false;
  
  constructor() {
    makeAutoObservable(this);
  }

  setShowEditMode = (showEditMode: boolean) => {
    this.showEditMode = showEditMode;
  };

  updateMasterAssetById = async (request: UpdateMasterAssetByIdRequest) : Promise<Response> => {
    try {    
      const response = await apiClient.updateMasterAssetById(request);
      return response;
    } catch (error) { 
      throw new Error("Failed to update asset. Please try again later.");
    }
  };
}

export default MasterAreaUpdateStore;
