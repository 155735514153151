import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { SelectOption } from "../../../../../models/common/selectOption";
import { RelationType } from "../../../../../models/common/response/relationType";
import { MasterRelatedTag } from "../../../../../models/MasterArea/response/getMasterRelatedTagsByIdResponse";
import RelatedTagExistsWarningModal from "../../../gridView/components/RelatedTagExistsWarningModal";

interface TagDetailsAddRelatedTagModalProps {
  RelatedTags: MasterRelatedTag[];
  handleAddRelatedTag: any;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const TagDetailAddRelatedTagModal = observer((props: TagDetailsAddRelatedTagModalProps) => {
  const {
    masterAreaCardViewStore,
    masterAreaCardViewRelatedTagStore: relatedTagStore,
    dictionaryStore,
    userStore
  } = useStores();

  const [hasParent, setHasParent] = useState<boolean>(props.RelatedTags.filter(r => r.relationTypeId === 2).length >= 1);

  const handleCancelClick = () => {
    relatedTagStore.setShowAddRelatedTagModal(false);
  };

  const handleAddClick = () => {
    const duplicateTags = masterAreaCardViewStore.masterRelatedTags.filter(t => t.tagNumber === relatedTagStore.selectedRelatedTag?.label 
                           && t.relationTypeId === relatedTagStore.selectedRelationType?.id 
                           || (t.relationTypeId === 1 && relatedTagStore.selectedRelationType?.id === 2) 
                           || (t.relationTypeId === 2 && relatedTagStore.selectedRelationType?.id === 1)
                           || (t.relationTypeId === 6 && relatedTagStore.selectedRelationType?.id === 7)
                           || (t.relationTypeId === 7 && relatedTagStore.selectedRelationType?.id === 6));
    if(duplicateTags.length > 0) {
      masterAreaCardViewStore.setShowDuplicateRelatedTagModal(true);
    }
    else {
      const rTag: MasterRelatedTag = {
        id: relatedTagStore.idCounter++,
        tagNumber: relatedTagStore.selectedRelatedTag?.label,
        projectNumber: null,
        status: "new",
        serviceDescription: null,
        relationType: relatedTagStore.selectedRelationType?.label,
        relationTypeId: relatedTagStore.selectedRelationType?.id,
        createdBy: userStore.currentUserAccount?.name,
        createdOn: new Date()
      };

      props.handleAddRelatedTag(rTag);

      if(relatedTagStore.selectedRelationType?.id === 2) setHasParent(true); // only one parent

      relatedTagStore.setShowAddRelatedTagModal(false);
    }
  }; 

  const handleRelationTypeChange = (selectedValue: SelectOption | null) => {
    relatedTagStore.setSelectedRelationType(selectedValue);
  };

  const handleRelatedTagChange = (selectedValue: SelectOption | null) => {
    relatedTagStore.setSelectedRelatedTag(selectedValue);
  };

  const debouncedHandleInputChange = useDebounce(500);
  const handleRelatedTagInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchMasterTagNumberOptions(inputValue);
    });
  };

  const relationTypeOptions: SelectOption[] = [];
  for (let relation in RelationType) {
    let isValueProperty = (Number(relation) === 1 || (Number(relation) === 2 && !hasParent) || Number(relation) === 3 || Number(relation) === 4 || 
                          Number(relation) === 5 || Number(relation) === 6 || Number(relation) === 7)
    if (isValueProperty) {
      relationTypeOptions.push({
        id: Number(relation),
        label: RelationType[relation]
      });
    }
 }

  return (
    <Modal
      open={relatedTagStore.showAddRelatedTagModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Add Related Tag
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Autocomplete
          size="small"
          id="RelatedTag"         
          options={dictionaryStore.masterTagNumberOptions ?? []}
          value={relatedTagStore.selectedRelatedTag}
          getOptionLabel={(option) => option.label}
          sx={{ width: "100%", mr: 1, mb: 2, display: "inline-flex" }}
          renderInput={(params) => (
            <TextField {...params} label="Related Tag" />
          )}
          onInputChange={(event, newInputValue) => {
            handleRelatedTagInputChange(newInputValue);
          }}
          onChange={(event, selectedValue) => {
            handleRelatedTagChange(selectedValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="RelationType"
            options={relationTypeOptions}
            value={relatedTagStore.selectedRelationType}
            getOptionLabel={(option) => option.label}
            sx={{ width: "100%", mr: 1, mb: 2 }}
            renderInput={(params) => (
              <TextField {...params} label="Relation Type" />
            )}
            onChange={(event, selectedValue) => {
              handleRelationTypeChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Button 
            variant="contained" 
            disabled={false} 
            sx={{ ml: 2 }} 
            onClick={handleAddClick}>
            <span>Add</span>
          </Button>
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelClick}>
            <span>Cancel</span>
          </Button>
        </Box>
        <RelatedTagExistsWarningModal />
      </Box>
      
    </Modal>
  );
});

export default TagDetailAddRelatedTagModal;
