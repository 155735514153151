import { useRef } from 'react';

export function useTagDetailRefs() {
  const serviceDescriptionRef = useRef<HTMLInputElement>(null);
  const RemarksRef = useRef<HTMLInputElement>(null);
  const circuitNumberRef = useRef<HTMLInputElement>(null);
  const documentsRef = useRef<HTMLInputElement>(null);
  const fittingNumberRef = useRef<HTMLInputElement>(null);
  const insulationTypeRef = useRef<HTMLInputElement>(null);
  const subSystemRef = useRef<HTMLInputElement>(null);
  const systemNameRef = useRef<HTMLInputElement>(null);

  return {
    serviceDescriptionRef,
    RemarksRef,
    circuitNumberRef,
    documentsRef,
    fittingNumberRef,
    insulationTypeRef,
    subSystemRef,
    systemNameRef,
  };
}
