import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import TagDetailAddTagAttributesModal from "./TagDetailAddTagAttributesModal";
import { MasterTagAttributes } from "../../../../../models/MasterArea/response/getMasterAssetsTagAttributesResponse";

interface TagDetailAddAttributeProps {
  Attributes: MasterTagAttributes[];
  handleAddAttribute: any;
}

const TagDetailAddTagAttributes = observer((props: TagDetailAddAttributeProps) => {
  const {
    masterAreaCardViewStore,
    masterAreaTagAttributesStore: tagAttributesStore,
    dictionaryStore,
  } = useStores();

  const handleAddClick = () => {
    dictionaryStore.fetcEclTagAttributesOptions("");
    dictionaryStore.fetchUnitOfMeasuresOptions("");

    tagAttributesStore.setSelectedEclTagAttributeOption(null);
    tagAttributesStore.setSelectedUOMOption(null);
    tagAttributesStore.setTagAttributeValue("");

    tagAttributesStore.setShowAddTagAttributesModal(true);   
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
      >
        <span>Add</span>
      </Button>
      <TagDetailAddTagAttributesModal Attributes={props.Attributes} handleAddAttribute={props.handleAddAttribute}/>
    </>
  );
});

export default TagDetailAddTagAttributes;
