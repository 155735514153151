import { useEffect } from "react";
import DictionaryStore from "../../../../stores/Dictionary/DictionaryStore";
import ProjectColumnStore from "../../../../stores/Configuration/ProjectColumnStore";

export const useFetchOptionsData = (
  dictionaryStore: DictionaryStore,
  projectColumnStore: ProjectColumnStore  
) => {
  useEffect(() => {
    dictionaryStore.fetchTagNumberOptions("");
    dictionaryStore.fetchProjectOptions("");
    dictionaryStore.fetchAreaOptions("");
    dictionaryStore.fetchUnitOptions("");
    dictionaryStore.fetchFacilityOptions("");
    projectColumnStore.fetchColumnConfigs();
  }, []);
};
