import { GridToolbarContainer } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import TagDetailAddDocument from "./TagDetailAddDocument";
import TagDetailDeleteDocuments from "./TagDetailDeleteDocuments";
import { useStores } from "../../../../../hooks/useStores";
import { TagRequestStatus } from "../../../../../models/common/response/tagRequestFormStatus";

const TagDetailDocumentToolBar = observer(() => {
  const {
    projectAreaCardViewStore: { assetDetail },
  } = useStores();

  const isCompleted = assetDetail?.status === TagRequestStatus.Completed;

  return (
    <GridToolbarContainer>
      {!isCompleted && (
        <>
          <TagDetailAddDocument />
          <TagDetailDeleteDocuments />
        </>
      )}
    </GridToolbarContainer>
  );
});

export default TagDetailDocumentToolBar;
