import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import TagDetailEditTagAttributesModal from "./TagDetailEditTagAttributesModal";
import { MasterTagAttributes } from "../../../../../models/MasterArea/response/getMasterAssetsTagAttributesResponse";

interface TagDetailEditTagAttributeProps {
  Attributes: MasterTagAttributes[];
  handleEditAttribute: any;
}

const TagDetailEditTagAttributes = observer((props: TagDetailEditTagAttributeProps) => {
  const { masterAreaTagAttributesStore, masterAreaCardViewStore, dictionaryStore } = useStores();

  const handleEditClick = async () => {
    masterAreaTagAttributesStore.setShowEditTagAttributesModal(true);

    dictionaryStore.fetchUnitOfMeasuresOptions("");

    const attrs = masterAreaCardViewStore.masterAssetDetail?.attributes?.filter(d => d.tagAttributeId === masterAreaTagAttributesStore.selectedId);
    if(attrs && attrs.length > 0) {
      const editedAttr = attrs[0];
      masterAreaTagAttributesStore.setTagAttributeId(editedAttr.tagAttributeId!);
      masterAreaTagAttributesStore.setTagAttributeValue(editedAttr.tagAttributeValue!);
      masterAreaTagAttributesStore.setUom(editedAttr.uom!);
      masterAreaTagAttributesStore.setUomId(editedAttr.uomId!);
      masterAreaTagAttributesStore.setSelectedUOMOption({id: editedAttr.uomId!, label: editedAttr.uom ?? ""});
    } 
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<EditIcon />}
        onClick={() => handleEditClick()}
        disabled={masterAreaTagAttributesStore.selectedIds.length !== 1}
        >
        Edit
      </Button>
      <TagDetailEditTagAttributesModal Attributes={props.Attributes} handleEditAttribute={props.handleEditAttribute}/>
    </>
  );
});

export default TagDetailEditTagAttributes;
