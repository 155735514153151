import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { useMemo } from "react";
import { useStores } from "../../../hooks/useStores";
import formatDate from "../../../utils/formatDate";
import { TagRequestStatus } from "../../../models/common/response/tagRequestFormStatus";

const useTagRequestFormsColumns = () => {
  const {
    tagRequestFormStore
  } = useStores();

  const handleShowTagRequestFormModalClick = async (id: number) => {
    await tagRequestFormStore.fetchTagRequestById(id);
    
    if (tagRequestFormStore.tagRequestForm.type == 2) // Working Copy
    {
      await tagRequestFormStore.fetchWorkingCopyById(id);
      let tagList: any[] = [];
      if(tagRequestFormStore.workingCopyForm.tagNumberServiceDescription) {
        Object.keys(tagRequestFormStore.workingCopyForm.tagNumberServiceDescription).forEach((key, index) => {
          tagList.push({title: key, value: Object.values(tagRequestFormStore.workingCopyForm.tagNumberServiceDescription)[index]});
        });
      }
      tagRequestFormStore.setWorkingCopyListOfTags(tagList);
      tagRequestFormStore.setShowDisplayRequestWorkingCopyTagFormModal(true);
    }
    else // New Tag Request
      tagRequestFormStore.setShowDisplayRequestTagFormModal(true);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "Tag Request Id",
        flex: 1,
        minWidth: 180,
        renderCell: (params) => {
          return (
            <>
              <a style={{ cursor: "pointer"}} href="#" rel="noreferrer" onClick={() => handleShowTagRequestFormModalClick(params.row.id)}>
                {params.value}
              </a>
            </>
          );
        }
      },
      { field: "project", headerName: "Project Name", flex: 1, minWidth: 150 },
      { field: "type", headerName: "Type", flex: 1, minWidth: 150, renderCell: (params) => {
        return (
          <>
            {params.value === 2 ? "Working Copy" : "New Tag Request" }
          </>
        );
      } },
      { field: "category", headerName: "Tag Category", flex: 1, minWidth: 150 },
      { 
        field: "status", 
        headerName: "Status", 
        flex: 1, 
        minWidth: 150, 
        renderCell: (params) => {
        return (
          <>
            {TagRequestStatus[params.value]}
          </>
        );
      } },
      //{ field: "submittedDate", headerName: "Submitted Date", flex: 1, minWidth: 150 },
      { field: "requiredDate", headerName: "Required Date", flex: 1, minWidth: 150, valueFormatter: formatDate },
      { field: "modifiedDate", headerName: "Last Modified Date", flex: 1, minWidth: 150, valueFormatter: formatDate },
      { field: "modifiedBy", headerName: "Last Modified By", flex: 1, minWidth: 150 }
    ],
    []
  );

  return columns;
};

export default useTagRequestFormsColumns;
