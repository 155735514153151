import { useContext } from "react";
import { RootStoreContext } from "../stores/RootStoreContext";

export const useStores = () => {
    const store = useContext(RootStoreContext);
  
    if (!store) {
      throw new Error("useStores must be used within a StoreProvider.");
    }
    
    return store;
  };
  