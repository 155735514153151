import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import TagDetailEditTagAttributesModal from "./TagDetailEditTagAttributesModal";

const TagDetailEditTagAttributes = observer(() => {
  const { projectAreaCardViewTagAttributesStore } = useStores();

  const handleEditClick = () => {
    projectAreaCardViewTagAttributesStore.setShowEditTagAttributesModal(true);
    projectAreaCardViewTagAttributesStore.getTagAttributeDetails(
      projectAreaCardViewTagAttributesStore.selectedId!
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<EditIcon />}
        onClick={() => handleEditClick()}
        disabled={projectAreaCardViewTagAttributesStore.selectedIds.length !== 1}
        >
        Edit
      </Button>
      <TagDetailEditTagAttributesModal />
    </>
  );
});

export default TagDetailEditTagAttributes;
