import { observer } from "mobx-react";
import React from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button } from "@mui/material";
import { useStores } from "../../../../../hooks/useStores";
import ImportFromExcelMasterAreaModal from "./ImportFromExcelMasterAreaModal";

const ImportFromExcelMasterAreaButton = observer(() => {
  const { masterAreaImportFromExcel } = useStores();

  const handleOpenModal = () => {
    masterAreaImportFromExcel.setShowImportFromExcelModal(true);
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        component="span"
        startIcon={<UploadFileIcon />}
        onClick={handleOpenModal}
      >
        Import from Excel
      </Button>
      <ImportFromExcelMasterAreaModal />
    </>
  );
});

export default ImportFromExcelMasterAreaButton;
