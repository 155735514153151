import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../../../api/clients";
import { GetFacilitiesResponse } from "../../../models/Dictionaries/response/getFacilitiesResponse";
import { GetFluidsResponse } from "../../../models/Dictionaries/response/getFluidsResponse";
import { GetLocationsResponse } from "../../../models/Dictionaries/response/getLocationsResponse";
import { GetAreasResponse } from "../../../models/Dictionaries/response/getAreasResponse";
import { GetUnitsResponse } from "../../../models/Dictionaries/response/getUnitsResponse";
import { GetBuildingsResponse } from "../../../models/Dictionaries/response/getBuildingsResponse";
import { GetPipeClassesResponse } from "../../../models/Dictionaries/response/getPipeClassesResponse";
import { GetSubstationsResponse } from "../../../models/Dictionaries/response/getSubstationsResponse";
import { GetWellsResponse } from "../../../models/Dictionaries/response/getWellsResponse";

class ReferencesStore {
  isLoading = false;

  area: GetAreasResponse | null = null;
  areas: GetAreasResponse[] = [];
  building: GetBuildingsResponse | null = null;
  buildings: GetBuildingsResponse[] = [];
  facility: GetFacilitiesResponse | null = null;
  facilities: GetFacilitiesResponse[] = [];
  fluid: GetFluidsResponse | null = null;
  fluids: GetFluidsResponse[] = [];
  location: GetLocationsResponse | null = null;
  locations: GetLocationsResponse[] = [];
  pipeClass: GetPipeClassesResponse | null = null;
  pipeClasses: GetPipeClassesResponse[] = [];
  substation: GetSubstationsResponse | null = null;
  substations: GetSubstationsResponse[] = [];
  unit: GetUnitsResponse | null = null;
  units: GetUnitsResponse[] = [];
  well: GetWellsResponse | null = null;
  wells: GetWellsResponse[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  fetchAreas = async (inputValue: string) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchAreas(inputValue);

      runInAction(() => {
        this.areas = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching areas:", error);
    }
  };

  fetchBuildings = async (inputValue: string) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchBuildings(inputValue);

      runInAction(() => {
        this.buildings = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching buildings:", error);
    }
  };

  fetchBuildingById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchBuildingById(id);
      runInAction(() => {
        this.building = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchFacilities = async (inputValue: string) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchFacilities(inputValue);

      runInAction(() => {
        this.facilities = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching facilities:", error);
    }
  };

  fetchFluids = async (inputValue: string) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchFluids(inputValue);

      runInAction(() => {
        this.fluids = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching fluids:", error);
    }
  };

  fetchFluidById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchFluidById(id);
      runInAction(() => {
        this.fluid = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchLocations = async (inputValue: string) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchLocations(inputValue);
      runInAction(() => {
        this.locations = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching locations:", error);
    }
  };

  fetchLocationById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchLocationById(id);
      runInAction(() => {
        this.location = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchPipeClasses = async (inputValue: string) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchPipeClasses(inputValue);

      runInAction(() => {
        this.pipeClasses = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching pipe classes:", error);
    }
  };

  fetchPipeClassById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchPipeClassById(id);
      runInAction(() => {
        this.pipeClass = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchSubstations = async (inputValue: string) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchSubstations(inputValue);

      runInAction(() => {
        this.substations = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching substations:", error);
    }
  };

  fetchSubstationById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchSubstationById(id);
      runInAction(() => {
        this.substation = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchUnits = async (inputValue: string) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchUnits(inputValue);

      runInAction(() => {
        this.units = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching units:", error);
    }
  };

  fetchWells = async (inputValue: string) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.fetchWells(inputValue);

      runInAction(() => {
        this.wells = response;
        this.setIsLoading(false);
      });
    } catch (error) {
      this.setIsLoading(false);
      console.error("Error fetching wells:", error);
    }
  };

  fetchWellById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.fetchWellById(id);
      runInAction(() => {
        this.well = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

}

export default ReferencesStore;
