import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "../../../../hooks/useStores";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import formatDate from "../../../../utils/formatDate";
import { DataGridBox } from "../../../../styles/dataGridBox";
import TagDetailDocumentToolBar from "../components/documents/TagDetailDocumentToolBar"
import { MasterDocuments } from "../../../../models/MasterArea/response/getAssetsDetailResponse";

const columns: GridColDef<MasterDocuments>[] = [
  {
    field: "documentCode",
    headerName: "Document Number",
    flex: 1,
    minWidth: 300
  },
  {
    field: "documentId",
    headerName: "Document Code",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "documentType",
    headerName: "Document Type",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "createdOn",
    headerName: "Created On",
    flex: 1,
    minWidth: 120,
    valueFormatter: formatDate,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "modifiedOn",
    headerName: "Modified On",
    flex: 1,
    minWidth: 120,
    valueFormatter: formatDate,
  },
  {
    field: "modifiedBy",
    headerName: "Modified By",
    flex: 1,
    minWidth: 200,
  }
];

interface MasterTagDetailDocumentsTabProps {
  Toolbar: boolean;
}

const MasterTagDetailDocumentsTab = observer((props: MasterTagDetailDocumentsTabProps) => {
  const {
    masterAreaCardViewDocumentStore,
    masterAreaCardViewStore: { masterDocuments, setMasterDocuments },
  } = useStores();

  const [documents, setDocuments] = useState<MasterDocuments[]>(masterDocuments);

  const handleAddDocuments = (masterDocuments: MasterDocuments[]) => {
    const docs = Object.assign([], documents);
    for (const masterDocument of masterDocuments) {
      if (documents?.filter(d => masterDocument.documentId === d.documentId).length === 0 ) {      
        docs.push(masterDocument);      
      }
    }
    setDocuments([...docs]);
    setMasterDocuments(docs);
  };

  const handleDeleteDocument = () => {
    const docs = documents?.filter(d => masterAreaCardViewDocumentStore.selectedIds.indexOf(d.documentId) < 0 );
    setDocuments([...docs]);
    setMasterDocuments(docs);
  };

  return (
    <DataGridBox>
      <DataGrid
        columns={columns}
        rows={documents}
        getRowId={(row) => row.documentId}
        checkboxSelection
        density="comfortable"
        onRowSelectionModelChange={(ids) => {
          const selectedIds = new Set(ids);
          masterAreaCardViewDocumentStore.setSelectedIds(
            Array.from(selectedIds).map((id) => parseInt(id as string))
          );  
        }}
        slots={{
          toolbar: props.Toolbar ? TagDetailDocumentToolBar : null,
        }}
        slotProps={{ toolbar: { Documents: documents, handleAddDocuments: handleAddDocuments, handleDeleteDocument: handleDeleteDocument } }}
      />
    </DataGridBox>
  );
});

export default MasterTagDetailDocumentsTab;
