import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack
} from "@mui/material";
import { TagRequestStatus } from "../../../models/common/response/tagRequestFormStatus";
import PermissionButton from "../../../components/common/PermissionButton";
import { ROLES } from "../../../constants/roles";
import { useEditTagFetchOptionsData } from "../hooks/useFetchOptionsData";
import { createBBTag } from "../hooks/useFetchRequests";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1200px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const TagRequestFormsCreateEquipmentTagModal = observer(() => { 

  const { tagRequestFormStore, notificationStore, dictionaryStore, userStore } = useStores();

  useEditTagFetchOptionsData(dictionaryStore, tagRequestFormStore.tagRequestForm.categoryId!, tagRequestFormStore.tagRequestForm.areaId!); 

  const handleCloseClick = () => {
    tagRequestFormStore.setShowCreateBBTagFormModal(false);
  };

  const handleCreateTagClick = async () => {   
    if(!tagRequestFormStore.showCreateBBTagFormModal) {
      tagRequestFormStore.setShowCreateBBTagFormModal(true);
      return;
    }
    await createBBTag(tagRequestFormStore, notificationStore);

    tagRequestFormStore.setShowCreateBBTagFormModal(false);
  }; 

  return (
    <Modal
      open={tagRequestFormStore.showCreateBBTagFormModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          {!tagRequestFormStore.showCreateBBTagFormModal ? "Edit Request Tag Form" : "Tag Create Form"}
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Tag Request Id"
              value={tagRequestFormStore.tagRequestForm.id + ""}
              sx={{ mr: 1, mb: 2, width: "24%", "margin-right" : "20px", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
            />
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Status"
              value={TagRequestStatus[tagRequestFormStore.tagRequestForm.status] + ""}
              sx={{ mr: 1, mb: 2, width: "24%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Tag Category"
              value={tagRequestFormStore.tagRequestForm.category + ""}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}           
            />
          </Stack>
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Project"
              value={tagRequestFormStore.tagRequestForm.projectNo + " - " + tagRequestFormStore.tagRequestForm.projectTitle}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Area"
              value={tagRequestFormStore.tagRequestForm.area + ""}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Unit"
              value={tagRequestFormStore.tagRequestForm.unit + ""}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Equipment Type"
              value={tagRequestFormStore.tagRequestForm.equipmentType + ""}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            /> 
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Building"
              value={tagRequestFormStore.tagRequestForm.building}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />  
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Well"
              value={tagRequestFormStore.tagRequestForm.well}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />  
          </Stack>
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Service Description"
              value={tagRequestFormStore.tagRequestForm.serviceDescription}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Documents"
              value={tagRequestFormStore.tagRequestForm.documents}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Suffix"
              value={tagRequestFormStore.tagRequestForm.suffix}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Multiple Tags"
              type="number"
              value={tagRequestFormStore.tagRequestForm.multipleTagsAsString}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
            {
              !tagRequestFormStore.tagRequestForm.multipleTags || tagRequestFormStore.tagRequestForm.multipleTags < 1 ? null :
              <TextField
                disabled
                variant="outlined"
                size="small"
                label="Tag Limit"
                type="number"
                value={tagRequestFormStore.tagRequestForm.tagLimitAsString}
                sx={{ mr: 1, mb: 2, width: "19%", float: "right", "margin-right" : "22px", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              />
            }
          </Stack>
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Required Date"
              value={tagRequestFormStore.tagRequestForm.requiredDateAsString}
              sx={{ mr: 1, mb: 2, width: "20%", "margin-right" : "50px", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
            <FormControlLabel control={
              <Checkbox disabled checked={tagRequestFormStore.tagRequestForm.isDatasheetNumber} />
            } label="Data Sheet Number"  sx={{ "margin-right" : "50px" }} />
            <FormControlLabel control={
              <Checkbox disabled checked={tagRequestFormStore.tagRequestForm.isEquipmentTagNumberForMotor} />
            } label="Equipment Tag Number for Motor"  sx={{ "margin-right" : "50px" }} />
            <FormControlLabel control={
              <Checkbox disabled checked={tagRequestFormStore.tagRequestForm.isMotorDatasheetNumber} />
            } label="Motor Data Sheet Number"  sx={{ "margin-right" : "50px" }} />
          </Stack>
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Remarks"
              value={tagRequestFormStore.tagRequestForm.remarks}
              multiline
              rows={3}
              sx={{ mr: 1, mb: 2, width: "98%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Issued Tag Number(s)"
              value={tagRequestFormStore.tagRequestForm.tagNumber}
              sx={{ mr: 1, mb: 2, width: "98%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              disabled
            />
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex", width: "98%" },
            justifyContent: "flex-end",
          }}
        >
          <PermissionButton
            buttonStyle={{ ml: 2 }} 
            buttonText="Create Tag"
            buttonVariant="contained"
            buttonPermission={userStore.hasCurrentUserRole(ROLES.arsMasterUserRole)}
            handleButtonClick={handleCreateTagClick}
          />
           <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
            <span>Close</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagRequestFormsCreateEquipmentTagModal;
