import { AlertColor } from "@mui/material";
import { makeAutoObservable } from "mobx";

class NotificationStore {
  showNotificatioin: boolean = false;
  notificationMessage: string = "";
  notificationSeverity: AlertColor | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setShowNotification = (show: boolean) => {
    this.showNotificatioin = show;    
  };

  setNotificationMessage = (message: string) => {
    this.notificationMessage = message;    
  };

  setNotificationSeverity = (severity: AlertColor | undefined) => {
    this.notificationSeverity = severity;    
  };

  handleNotificationClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    this.setShowNotification(false);
  };
}

export default NotificationStore;
