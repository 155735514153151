import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import { useDebounce } from "../../../../../hooks/useDebounce";
import PermissionButton from "../../../../../components/common/PermissionButton";
import { ROLES } from "../../../../../constants/roles";
import { CreateTagAttributesRequest } from "../../../../../models/ProjectArea/request/createTagAttributesRequest";
import { NOTIFICATION_TYPES } from "../../../../../constants/notificationTypes";
import { ExtendedSelectOption } from "../../../../../models/common/extendedSelectOption";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const TagDetailAddTagAttributesModal = observer(() => {
  const {
    projectAreaCardViewTagAttributesStore: tagAttributesStore,
    dictionaryStore,
    projectAreaCardViewStore: { fetchTagAttributesById, assetDetail, fetchExTagAttributesById, fetchCmmsTagAttributesById  },
    notificationStore,
    userStore
  } = useStores();


  const equipmentTagRef = useRef<HTMLInputElement>(null);
  const tagAttributeValueRef = useRef<HTMLInputElement>(null);

  const handleCancelClick = () => {
    tagAttributesStore.setShowAddTagAttributesModal(false);
  };

  const handleSaveClick = () => {
    const projectEquipmentId = assetDetail?.id;
    const attributeClassId = tagAttributesStore.selectedEclClassOption?.id;
    const tagNumberId = assetDetail?.tagId;
    const tagCategoryId = assetDetail?.tagCategoryId;
    const tagAttributeValue = tagAttributesStore.tagAttributeValue;
    const uomId = tagAttributesStore.selectedUOMOption ? tagAttributesStore.selectedUOMOption.id : null;

    const data: CreateTagAttributesRequest = {
      projectEquipmentId: projectEquipmentId!,
      attributeClassId: attributeClassId!,
      tagNumberId: tagNumberId!,
      tagCategoryId: tagCategoryId!,
      tagAttributeValue: tagAttributeValue,
      uomId: uomId === 0 ? null : uomId,
    };

    tagAttributesStore.createTagAttributes(data).then(() => {
      if (tagAttributesStore.validationErrors) {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
        notificationStore.setNotificationMessage(
          tagAttributesStore.validationErrors
        );
        notificationStore.setShowNotification(true);
      } else {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
        notificationStore.setNotificationMessage(
          "Tag Attributes added successfully"
        );
        notificationStore.setShowNotification(true);
      }
      fetchTagAttributesById(assetDetail?.id!);
      fetchExTagAttributesById(assetDetail?.id!);
      fetchCmmsTagAttributesById(assetDetail?.id!);
      tagAttributesStore.setTagAttributeValue("");
      tagAttributesStore.setIsRefreshed(true);
    });
  };

  const debouncedHandleInputChange = useDebounce(500);

  const handleAttributeUOMInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOfMeasuresOptions(inputValue);
    });
  };

  const handleAttributeClassInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagAttributesNamesByTypeOptions(inputValue, 0);
    });
  };

  const handleAttributeClassChange = (
    selectedValue: ExtendedSelectOption | null
  ) => {
    tagAttributesStore.setSelectedEclClassOption(selectedValue);
  };

  const handleAttributeUOMChange = (
    selectedValue: ExtendedSelectOption | null
  ) => {
    tagAttributesStore.setSelectedUOMOption(selectedValue);
  };
  

  return (
    <Modal
      open={tagAttributesStore.showAddTagAttributesModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Add Tag Attributes
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            label="Equipment Tag"
            disabled
            value={assetDetail?.tagNumber}
            inputRef={equipmentTagRef}
            sx={{ mr: 1, mb: 2, width: "100%" }}
          />
          <Autocomplete
            size="small"
            id="AttributeName"
            options={dictionaryStore.tagAttributesNamesOptions}
            value={tagAttributesStore.selectedEclClassOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: "100%",
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Attribute Name" />
            )}
            onInputChange={(event, newInputValue) => {
              handleAttributeClassInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleAttributeClassChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <TextField
            variant="outlined"
            size="small"
            label="Attribute Value"
            value={tagAttributesStore.tagAttributeValue}
            onChange={(e) =>
              tagAttributesStore.setTagAttributeValue(e.target.value)
            }
            inputRef={tagAttributeValueRef}
            sx={{ mr: 1, mb: 2, width: "100%" }}
          />
        <Autocomplete
            size="small"
            id="UOM"
            options={dictionaryStore.uomOptions}
            value={tagAttributesStore.selectedUOMOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: "100%",
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="UOM" />
            )}
            onInputChange={(event, newInputValue) => {
              handleAttributeUOMInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleAttributeUOMChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />

        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <PermissionButton
            buttonText="Save"
            buttonVariant="contained"
            buttonPermission={userStore.hasCurrentUserRole(ROLES.arsProjectUserRole)}
            handleButtonClick={handleSaveClick}
          />
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelClick}>
            <span>Cancel</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagDetailAddTagAttributesModal;
