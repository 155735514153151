import { makeAutoObservable, runInAction } from "mobx";
import { UpdateTagAttributesByIdRequest } from "../../models/ProjectArea/request/editTagAttributesRequest";
import { CreateTagAttributesRequest } from "../../models/ProjectArea/request/createTagAttributesRequest";

class ProjectAreaCardViewTagValidationStore {
  selectedIds: number[] = [];
  showAddTagValidationModal = false;
  showEditTagValidationModal = false;
  selectedId: number | null = null;


  constructor() {
    makeAutoObservable(this);
  }

  setSelectedIds = (ids: number[]) => {
    this.selectedIds = ids;
    this.setSelectedId(ids.length > 0 ? ids[0] : null);
  };

  setSelectedId(id: number | null) {
    this.selectedId = id;
  }

  setShowAddTagValidationModal = (value: boolean) => {
    this.showAddTagValidationModal = value;
  };

  setShowEditTagValidationModal = (value: boolean) => {
    this.showEditTagValidationModal = value;
  };

  async getTagValidationDetails(id: number): Promise<void> {
    if (!id) return;

    try {
      //const response = await apiClient.getTagValidationsById(id);
      runInAction(() => {
  
      });
    } catch (error) {
      console.error("Error while getting tag validation details:", error);
    }
  }

  async deleteTagValidation(ids: number[]) {
    try {
      //await apiClient.deleteTagAttributes(ids);
    } catch (error) {
      console.error(error);
    }
  }

  async updateTagValidation(request: UpdateTagAttributesByIdRequest) {
    try {
      //await apiClient.updateTagAttributes(request);
    } catch (error) {
      console.error("Error editing tag attributes:", error);
      throw error;
    }
  }

  async createTagValidation(data: CreateTagAttributesRequest): Promise<void> {
    try {
      //const response = await apiClient.createTagAttributes(data);
    } catch (error) {
      console.error("Error adding project tag attributes:", error);
      throw error;
    }
  }
}

export default ProjectAreaCardViewTagValidationStore;
