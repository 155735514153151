import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { DataGridBox } from "../../../../styles/dataGridBox";
import TagDetailTagValidationToolBar from "../components/tagValidation/TagDetailTagValidationToolBar";

const columns: GridColDef[] = [
  {
    field: "validation",
    headerName: "Type",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    minWidth: 100,
  }
];

const TagDetailValidationTab = observer(() => {

  const {
    projectAreaCardViewStore, projectAreaCardViewTagValidationStore
  } = useStores();

  return (
    <DataGridBox>
      <DataGrid
        columns={columns}
        rows={projectAreaCardViewStore.tagValidations ?? []}
        density="comfortable"
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
          const selectedIds = new Set(ids);
          projectAreaCardViewTagValidationStore.setSelectedIds(
            Array.from(selectedIds).map((id) => parseInt(id as string))
          );
        }}
        slots={{
          toolbar: TagDetailTagValidationToolBar,
        }}
        sx={{height: "700px"}}
      />
    </DataGridBox>
  );
});

export default TagDetailValidationTab;
