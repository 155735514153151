import { GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import formatDate from "../../../../utils/formatDate";
import { DataGridBox } from "../../../../styles/dataGridBox";
import TagDetailTagAttributesToolBar from "../components/tagAttributes/TagDetailTagAttributesToolBar";
import { DataGridPro } from "@mui/x-data-grid-pro";

const columns: GridColDef[] = [
  {
    field: "tagAttributeValue",
    headerName: "Attribute Value",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "uom",
    headerName: "UOM",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "attributeClass",
    headerName: "Attribute Name",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "createdDate",
    headerName: "Created On",
    flex: 1,
    minWidth: 150,
    valueFormatter: formatDate,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "updatedDate",
    headerName: "Modified On",
    flex: 1,
    minWidth: 150,
    valueFormatter: formatDate,
  },
  {
    field: "updatedBy",
    headerName: "Modified By",
    flex: 1,
    minWidth: 200,
  },
];

const TagDetailAttributesPopupTab = observer(() => {
  const {
    projectAreaPopupCardViewStore: { tagAttributes },
    projectAreaPopupCardViewTagAttributesStore: tagAttributeStore,
  } = useStores();

  return (
    <DataGridBox>
      <DataGridPro
        columns={columns}
        rows={tagAttributes ?? []}
        density="comfortable"
        onRowSelectionModelChange={(ids) => {
          const selectedIds = new Set(ids);
          tagAttributeStore.setSelectedIds(
            Array.from(selectedIds).map((id) => parseInt(id as string))
          );
          tagAttributeStore.setTagAttributeValue("");
          tagAttributeStore.setUom("");
          tagAttributeStore.setSelectedId(tagAttributeStore.selectedId);
        }}
        slots={{
          toolbar: TagDetailTagAttributesToolBar,
        }}
      />
    </DataGridBox>
  );
});

export default TagDetailAttributesPopupTab;
