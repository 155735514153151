import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import { useDebounce } from "../../../../../hooks/useDebounce";
import PermissionButton from "../../../../../components/common/PermissionButton";
import { ExtendedSelectOption, LinkedSelectOption } from "../../../../../models/common/extendedSelectOption";
import { MasterTagAttributes } from "../../../../../models/MasterArea/response/getMasterAssetsTagAttributesResponse";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface TagDetailAddAttributeModalProps {
  Attributes: MasterTagAttributes[];
  handleAddAttribute: any;
}

const TagDetailAddTagAttributesModal = observer((props: TagDetailAddAttributeModalProps) => {
  const {
    masterAreaCardViewStore,
    masterAreaTagAttributesStore,
    dictionaryStore,
    userStore
  } = useStores();

  const tagAttributeValueRef = useRef<HTMLInputElement>(null);

  const handleCancelClick = () => {
    masterAreaTagAttributesStore.setTagAttributeValue(null);
    masterAreaTagAttributesStore.setSelectedEclTagAttributeOption(null);
    masterAreaTagAttributesStore.setSelectedUOMOption(null);
    masterAreaTagAttributesStore.setShowAddTagAttributesModal(false);
  };

  const handleAddClick = () => {
    const attr: MasterTagAttributes = { 
      id: masterAreaTagAttributesStore.idCounter++,
      attributeClass: masterAreaTagAttributesStore.selectedEclTagAttributeOption?.label,
      tagAttributeValue: masterAreaTagAttributesStore.tagAttributeValue,
      tagAttributeId: masterAreaTagAttributesStore.selectedEclTagAttributeOption?.id,
      uom: masterAreaTagAttributesStore.selectedUOMOption?.label,
      uomId: masterAreaTagAttributesStore.selectedUOMOption?.id,
      category: "new",
      tagNumber: null,
      attributeCompositeKey: null,
      compositeKey: null,
      createdDate: new Date(),
      createdOn: new Date(),
      createdBy: userStore.currentUserAccount?.name,
      updatedDate: new Date(),
      updatedBy: userStore.currentUserAccount?.name,
      modifiedOn: new Date(),
      modifiedBy: userStore.currentUserAccount?.name
    };

    props.handleAddAttribute(attr);

    masterAreaTagAttributesStore.setShowAddTagAttributesModal(false);
  };

  const debouncedHandleInputChange = useDebounce(500);

  const handleAttributeUOMInputChange = async (inputValue: string, uomClassId? : number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOfMeasuresOptions(inputValue, uomClassId);
    });
  };

  const handleAttributeClassInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetcEclTagAttributesOptions(inputValue);
    });
  };

  const handleAttributeClassChange = (
    selectedValue: LinkedSelectOption | null
  ) => {
    masterAreaTagAttributesStore.setSelectedEclTagAttributeOption(selectedValue);
  };

  const handleAttributeUOMChange = (
    selectedValue: ExtendedSelectOption | null
  ) => {
    masterAreaTagAttributesStore.setSelectedUOMOption(selectedValue);
  };
  

  return (
    <Modal
      open={masterAreaTagAttributesStore.showAddTagAttributesModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Add Tag Attributes
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Autocomplete
            size="small"
            id="AttributeClass"
            options={dictionaryStore.eclTagAttributesOptions}
            value={masterAreaTagAttributesStore.selectedEclTagAttributeOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: "100%",
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Attribute Class" />
            )}
            onInputChange={(event, newInputValue) => {
              handleAttributeClassInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleAttributeClassChange(selectedValue);
              handleAttributeUOMInputChange("", selectedValue?.additionalId)
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <TextField
            variant="outlined"
            size="small"
            label="Tag Attribute Value"
            value={masterAreaTagAttributesStore.tagAttributeValue}
            onChange={(e) =>
              masterAreaTagAttributesStore.setTagAttributeValue(e.target.value)
            }
            inputRef={tagAttributeValueRef}
            sx={{ mr: 1, mb: 2, width: "100%" }}
          />
        <Autocomplete
            size="small"
            id="UOM"
            options={dictionaryStore.uomOptions}
            value={masterAreaTagAttributesStore.selectedUOMOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: "100%",
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="UOM" />
            )}
            onInputChange={(event, newInputValue) => {
              handleAttributeUOMInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleAttributeUOMChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />

        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <PermissionButton
            buttonText="Add"
            buttonVariant="contained"
            buttonPermission={masterAreaTagAttributesStore.tagAttributeValue != "" && masterAreaTagAttributesStore.selectedEclTagAttributeOption != null} //userStore.hasCurrentUserRole(ROLES.arsProjectUserRole)
            handleButtonClick={handleAddClick}
          />
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelClick}>
            <span>Cancel</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagDetailAddTagAttributesModal;
