import { makeAutoObservable } from "mobx";
import apiClient from "../../api/clients";
import { BulkUpdateAssetsRequest } from "../../models/ProjectArea/request/bulkUpdateAssetsRequest";

class ProjectAreaGridBulkUpdateStore {
  showBulkUpdateForm = false;
  selectedIds: number[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setShowBulkUpdateForm = (show: boolean) => {
    this.showBulkUpdateForm = show;
  }

  setSelectedIds = (ids: number[]) => {
    this.selectedIds = ids;
  }

  bulkUpdateAssets = async (request: BulkUpdateAssetsRequest) : Promise<Response> => {
    try {    
      const response = await apiClient.bulkUpdateAssets(request);
      return response;
    } catch (error) { 
      console.log(error);
      throw new Error("Failed to update assets. Please try again later.");
    }
  };
}

export default ProjectAreaGridBulkUpdateStore;
