import {
  Autocomplete,
  Box,
  Button,
  Link,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import PermissionButton from "../../../../../components/common/PermissionButton";
import { useStores } from "../../../../../hooks/useStores";
import { SelectOption } from "../../../../../models/common/selectOption";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { ROLES } from "../../../../../constants/roles";
import { observer } from "mobx-react";
import { AddDocumentRequest } from "../../../../../models/ProjectArea/request/addDocumentRequest";
import { NOTIFICATION_TYPES } from "../../../../../constants/notificationTypes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const TagDetailAddDocumentModal = observer(() => {
  const {
    projectAreaCardViewDocumentStore,
    dictionaryStore,
    projectAreaCardViewStore: { fetchDocumentsById, assetDetail },
    notificationStore,
    userStore
  } = useStores();

  const handleCancelClick = () => {
    projectAreaCardViewDocumentStore.setShowAddDocumentModal(false);
  };

  const handleSaveClick = () => {
    projectAreaCardViewDocumentStore.setShowAddDocumentModal(false);
    const assetId = assetDetail?.id;
    const projectId =
      projectAreaCardViewDocumentStore.selectedProjectDocOption?.id;

    if (assetId && projectId) {
      const request: AddDocumentRequest = {
        projectEquipmentId: assetId,
        masterDocumentId: projectId,
      };
      projectAreaCardViewDocumentStore.addProjectDocument(request).then(() => {
        if (projectAreaCardViewDocumentStore.validationErrors) {
          notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
          notificationStore.setNotificationMessage(
            projectAreaCardViewDocumentStore.validationErrors
          );
          notificationStore.setShowNotification(true);
        } else {
          notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
          notificationStore.setNotificationMessage(
            "Document added successfully"
          );
          notificationStore.setShowNotification(true);

          fetchDocumentsById(assetId);
          projectAreaCardViewDocumentStore.getProjDocOptions("");
        }
      });
    }
  };

  const handleDocumentNumberChange = (selectedValue: SelectOption | null) => {
    projectAreaCardViewDocumentStore.setSelectedProjectDocOption(selectedValue);
    if (selectedValue) {
      projectAreaCardViewDocumentStore.getProjDocDetails(selectedValue.id);
    }
    else {
      projectAreaCardViewDocumentStore.setSelectedProjectDocTypeOption(null);
      projectAreaCardViewDocumentStore.setProjectDocUrl();
    }
  };

  const debouncedHandleInputChange = useDebounce(500);

  const handleDocumentNumberInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      projectAreaCardViewDocumentStore.getProjDocOptions(inputValue);
    });
  };

  return (
    <Modal
      open={projectAreaCardViewDocumentStore.showAddDocumentModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-doc-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Add Document to Tag
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Autocomplete
            size="small"
            id="DocumentNumber"
            options={projectAreaCardViewDocumentStore.projectDocsOption}
            value={projectAreaCardViewDocumentStore.selectedProjectDocOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: "100%",
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Document Number" />
            )}
            onInputChange={(event, newInputValue) => {
              handleDocumentNumberInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleDocumentNumberChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />

          <Autocomplete
            size="small"
            id="DocumentType"
            disabled
            options={dictionaryStore.docTypeOptions}
            value={
              projectAreaCardViewDocumentStore.selectedProjectDocTypeOption
            }
            getOptionLabel={(option) => option.label}
            sx={{
              width: "100%",
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Document Type" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Link href={projectAreaCardViewDocumentStore.projectDocUrl}>
            {projectAreaCardViewDocumentStore.projectDocUrl}
          </Link>
        </Box>

        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <PermissionButton
            buttonText="Save"
            buttonVariant="contained"
            buttonPermission={userStore.hasCurrentUserRole(ROLES.arsProjectUserRole)}
            handleButtonClick={handleSaveClick}
          />
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelClick}>
            <span>Cancel</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagDetailAddDocumentModal;
