import { Box, Modal, Stack, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TagAuditPackagesTagDetailView from "./TagAuditPackagesTagDetailView";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1200px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const TagAuditPackagesDisplayModal = observer(() => {
  const { tagAuditStore, projectAreaCardViewStore } =
    useStores();

  const handleCloseClick = () => {
    tagAuditStore.setShowDisplayRequestTagFormModal(false);
  };

  const handleTagNumberClick = (projectEquipmentId: number) => {
    projectAreaCardViewStore.fetchAssetById(projectEquipmentId);
    tagAuditStore.setShowDisplayTagDetailsModal(true);
    console.log(
      `Fetching asset details for projectEquipmentId: ${projectEquipmentId}`
    );
  };

  return (
    <>
      <Modal
        open={tagAuditStore.showDisplayRequestTagFormModal}
        onClose={handleCloseClick}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="tag-audit-packages-details"
            variant="h6"
            component="h2"
            sx={{ mb: 4 }}
          >
            Display Tag Audit Package
          </Typography>
          <Box
            sx={{
              backgroundColor: "#fff",
            }}
          >
            <Stack direction="row">
              <TextField
                disabled
                variant="outlined"
                size="small"
                label="Project Handover Package Name"
                value={tagAuditStore.tagAuditPackage.title + ""}
                sx={{
                  mr: 1,
                  mb: 2,
                  width: "24%",
                  "margin-right": "20px",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Stack>
            <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
              Tag Numbers:
            </Typography>
            <List dense={true}>
              {Array.isArray(tagAuditStore.tagAuditPackage.tagNumbers) ? (
                tagAuditStore.tagAuditPackage.tagNumbers.map(
                  (tagNumber, index) => (
                    <ListItem
                      key={tagNumber}
                      button // Make the list item clickable
                      onClick={() =>
                        handleTagNumberClick(
                          tagAuditStore.tagAuditPackage.projectEquipmentIds[
                            index
                          ]
                        )
                      }
                    >
                      <ListItemText primary={tagNumber} />
                    </ListItem>
                  )
                )
              ) : (
                <Typography variant="subtitle1">
                  No tag numbers available.
                </Typography>
              )}
            </List>
          </Box>
        </Box>
      </Modal>
      <TagAuditPackagesTagDetailView />
    </>
  );
});

export default TagAuditPackagesDisplayModal;
