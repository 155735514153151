import { useEffect } from "react";
import { useStores } from "../../../../hooks/useStores";
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";

const useFetchData = (
  setColumnVisibilityModel: React.Dispatch<
    React.SetStateAction<GridColumnVisibilityModel | undefined>
  >
) => {
  const { dictionaryStore, projectColumnStore } = useStores();

  useEffect(() => {
    const fetchOptions = async () => {
      await Promise.all([
        dictionaryStore.fetchTagCategoryOptions(""),
        dictionaryStore.fetchMasterTagNumberOptions(""),
        projectColumnStore.fetchColumnConfigs(),
      ]);
    };

    const setColumnVisibility = () => {
      setColumnVisibilityModel({
        tagNumber: projectColumnStore.getVisibleColumnsMasterView.TagNumber,
        category: projectColumnStore.getVisibleColumnsMasterView.Category,
        status: projectColumnStore.getVisibleColumnsMasterView.Status,
        facility: projectColumnStore.getVisibleColumnsMasterView.Facility,
        area: projectColumnStore.getVisibleColumnsMasterView.Area,
        areaDescription:
          projectColumnStore.getVisibleColumnsMasterView.AreaDescription,
        unit: projectColumnStore.getVisibleColumnsMasterView.Unit,
        unitDescription:
          projectColumnStore.getVisibleColumnsMasterView.UnitDescription,
        equipmentTypeCode:
          projectColumnStore.getVisibleColumnsMasterView.EquipmentTypeCode,
        equipmentTypeDescription:
          projectColumnStore.getVisibleColumnsMasterView
            .EquipmentTypeDescription,
        disciplineTagNumber:
          projectColumnStore.getVisibleColumnsMasterView.DisciplineTagNumber,
        primaryTagNumber:
          projectColumnStore.getVisibleColumnsMasterView.PrimaryTagNumber,
        parentTagNumber:
          projectColumnStore.getVisibleColumnsMasterView.ParentTagNumber,
        sequenceNumber:
          projectColumnStore.getVisibleColumnsMasterView.SequenceNumber,
        historicalRefNo:
          projectColumnStore.getVisibleColumnsMasterView.HistoricalRefNo,
        standardizedTagNumber:
          projectColumnStore.getVisibleColumnsMasterView.StandardizedTagNumber,
        compositeKey:
          projectColumnStore.getVisibleColumnsMasterView.CompositeKey,
        projectNumber:
          projectColumnStore.getVisibleColumnsMasterView.ProjectNumber,
        requestNumber:
          projectColumnStore.getVisibleColumnsMasterView.RequestNumber,
        serviceDescription:
          projectColumnStore.getVisibleColumnsMasterView.ServiceDescription,
        remarks: projectColumnStore.getVisibleColumnsMasterView.Remarks,
        historicalDaluid:
          projectColumnStore.getVisibleColumnsMasterView.HistoricalDaluid,
        controlAutoNaming:
          projectColumnStore.getVisibleColumnsMasterView.ControlAutoNaming,
        exInspectionRequired:
          projectColumnStore.getVisibleColumnsMasterView.ExInspectionRequired,
        exRegister: projectColumnStore.getVisibleColumnsMasterView.ExRegister,
        suffix: projectColumnStore.getVisibleColumnsMasterView.Suffix,
        well: projectColumnStore.getVisibleColumnsMasterView.Well,
        substation: projectColumnStore.getVisibleColumnsMasterView.Substation,
        nominalDiameter:
          projectColumnStore.getVisibleColumnsMasterView.NominalDiameter,
        pipeClass: projectColumnStore.getVisibleColumnsMasterView.PipeClass,
        fluid: projectColumnStore.getVisibleColumnsMasterView.Fluid,
        location: projectColumnStore.getVisibleColumnsMasterView.Location,
        building: projectColumnStore.getVisibleColumnsMasterView.Building,
        insulationType:
          projectColumnStore.getVisibleColumnsMasterView.InsulationType,
        circuitNumber:
          projectColumnStore.getVisibleColumnsMasterView.CircuitNumber,
        fittingNumber:
          projectColumnStore.getVisibleColumnsMasterView.FittingNumber,
        subSystem: projectColumnStore.getVisibleColumnsMasterView.SubSystem,
        systemName: projectColumnStore.getVisibleColumnsMasterView.SystemName,
        generateMultipleTags:
          projectColumnStore.getVisibleColumnsMasterView.GenerateMultipleTags,
        mainTagNumber:
          projectColumnStore.getVisibleColumnsMasterView.MainTagNumber,
        parallelTagNumber:
          projectColumnStore.getVisibleColumnsMasterView.ParallelTagNumber,
        documents: projectColumnStore.getVisibleColumnsMasterView.Documents,
        documentList:
          projectColumnStore.getVisibleColumnsMasterView.DocumentList,
        categoryTagLimit:
          projectColumnStore.getVisibleColumnsMasterView.CategoryTagLimit,
        recordCreatedOn:
          projectColumnStore.getVisibleColumnsMasterView.RecordCreatedOn,
        createdOn: projectColumnStore.getVisibleColumnsMasterView.CreatedOn,
        createdBy: projectColumnStore.getVisibleColumnsMasterView.CreatedBy,
        mocNumber: projectColumnStore.getVisibleColumnsMasterView.MocNumber,
      });
    };
    fetchOptions().then(setColumnVisibility);
  }, []);
};

export default useFetchData;
