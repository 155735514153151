import { observer } from "mobx-react";
import React, { useState } from "react";
import { NullableString } from "../../../../models/common/response/types";
import { useStores } from "../../../../hooks/useStores";
import { SelectOption } from "../../../../models/common/selectOption";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getNullableOptionId } from "../../../../utils/getOptionValue";
import { BulkUpdateMasterAssetsRequest } from "../../../../models/MasterArea/request/bulkUpdateMasterAssetsRequest";
import { NOTIFICATION_TYPES } from "../../../../constants/notificationTypes";
import CustomBulkUpdateFormModalMasterArea from "./CustomBulkUpdateFormModalMasterArea";

const CustomBulkUpdateFormMasterArea = observer(() => {
  const {
    masterAreaUpdateStore,
    dictionaryStore,
    masterAreaGridViewExportStore,
    notificationStore,
    masterAreaGridBulkUpdateStore,
    masterAssetsStore,
    userStore
  } = useStores();

  const [selectedProjectOption, setSelectedProjectOption] = useState<SelectOption | null>(null);
  const [selectedParentTagOption, setSelectedParentTagOption] = useState<SelectOption | null>(null);
  const [selectedStatusOption, setSelectedStatusOption] = useState<SelectOption | null>(null);
  const [serviceDescription, setServiceDescription] = useState<NullableString>(null);
  const [remarks, setRemarks] = useState<NullableString>(null);

  const debouncedHandleInputChange = useDebounce(500);

  const handleProjectInputChange = async (inputValue: string) => {    
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectOptions(inputValue);
    });
  };

  const handleParentTagInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchParentTagNumberByCategoryOptions(inputValue);
    });
  };

  const handleStatusInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEquipmentStatusOptions(inputValue);
    });
  }; 

  const handleCancelClick = () => {
    masterAreaGridBulkUpdateStore.setShowBulkUpdateForm(false);
  };

  const prepareUpdateData = () => {
    const data: BulkUpdateMasterAssetsRequest = {
      ids: masterAreaGridViewExportStore.selectedIds,
      projectId: getNullableOptionId(selectedProjectOption),
      equipmentStatusId: getNullableOptionId(selectedStatusOption),
      majorEquipmentTagMapId: getNullableOptionId(selectedParentTagOption),
      serviceDescription: serviceDescription,
      remarks: remarks
    };

    return data;
  };

  const handleUpdate = async (data: BulkUpdateMasterAssetsRequest) => {
    const res = await masterAreaGridBulkUpdateStore.bulkUpdateAssets(data);

    if (res?.status === 200) {
      handleUpdateSuccess();
    } else {
      handleUpdateError();
    }

    masterAreaUpdateStore.setShowEditMode(false);
  };

  const handleUpdateSuccess = () => {
    const successMessage = "Asset(s) updated successfully.";
    notificationStore.setNotificationMessage(successMessage);
    notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
    notificationStore.setShowNotification(true);
    masterAssetsStore.fetchMasterAssets();
  };

  const handleUpdateError = () => {
    const errorMessage =
      "Error while updating the asset(s). Please try again later.";
    notificationStore.setNotificationMessage(errorMessage);
    notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
    notificationStore.setShowNotification(true);
  };

  const handleSaveClick = async () => {
    if (masterAreaGridViewExportStore.selectedIds.length === 0) {
      notificationStore.setNotificationMessage(
        "Please select at least one item to update"
      );
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.info);
      notificationStore.setShowNotification(true);
    } else {
      const data = prepareUpdateData();
      await handleUpdate(data);
    }
    masterAreaGridBulkUpdateStore.setShowBulkUpdateForm(false);
  };

  return (
    <CustomBulkUpdateFormModalMasterArea
      masterAreaGridBulkUpdateStore={masterAreaGridBulkUpdateStore}
      dictionaryStore={dictionaryStore}
      userStore={userStore}
      handleCancelClick={handleCancelClick}
      handleSaveClick={handleSaveClick}
      handleProjectInputChange={handleProjectInputChange}
      selectedProjectOption={selectedProjectOption}
      setSelectedProjectOption={setSelectedProjectOption}
      handleParentTagInputChange={handleParentTagInputChange}
      selectedParentTagOption={selectedParentTagOption}
      setSelectedParentTagOption={setSelectedParentTagOption}
      handleStatusInputChange={handleStatusInputChange}
      selectedStatusOption={selectedStatusOption}
      setSelectedStatusOption={setSelectedStatusOption}
      serviceDescription={serviceDescription}
      setServiceDescription={setServiceDescription}
      remarks={remarks}
      setRemarks={setRemarks}
    />
  );
});

export default CustomBulkUpdateFormMasterArea;
