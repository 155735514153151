import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "../../../../hooks/useStores";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridBox } from "../../../../styles/dataGridBox";
import TagDetailRelatedTagToolBar from "../components/tagRelated/TagDetailRelatedTagToolBar"
import { formatRelationType } from "../utils/utils"
import { MasterAssetDetailRelatedTags } from "../../../../models/MasterArea/response/getAssetsDetailResponse";

const columns: GridColDef<MasterAssetDetailRelatedTags>[] = [
  { field: "tagNumber", headerName: "Tag Number", flex: 1, minWidth: 180 },
  {
    field: "relationType",
    headerName: "Relation Type",
    flex: 1,
    minWidth: 150,
    renderCell: (params) => <span>{formatRelationType(params.value)}</span>,
  },
  { field: "createdOn", headerName: "Created On", flex: 1, minWidth: 150 },
  { field: "createdBy", headerName: "Created By", flex: 1, minWidth: 150 },
  { field: "category", headerName: "Category", flex: 1, minWidth: 150 },
  { field: "status", headerName: "Status", flex: 1, minWidth: 150 },
  { field: "controlAutonaming", headerName: "Control Autonaming", flex: 1, minWidth: 150 },
  
  { field: "disciplineTagNumber", headerName: "Discipline Tag Number", flex: 1, minWidth: 180 }
];

interface MasterTagDetailRelatedTagTabProps {
  Toolbar: boolean;
}

const MasterRelatedTagsTab = observer((props: MasterTagDetailRelatedTagTabProps) => {
  const {
    masterAreaCardViewStore: { masterRelatedTags, setMasterRelatedTags },
    masterAreaCardViewRelatedTagStore: relatedTagStore
  } = useStores();

  const [relatedTags, setRelatedTags] = useState<MasterAssetDetailRelatedTags[]>(masterRelatedTags);

  const handleAddRelatedTag = (rTag: MasterAssetDetailRelatedTags) => {
    if (relatedTags?.filter(r => rTag.id === r.id).length === 0 ) {
      const rTags = Object.assign([], relatedTags);
      rTags.push(rTag);
      setRelatedTags([...rTags]);
      setMasterRelatedTags(rTags);
    }
  };

  const handleDeleteRelatedTag = () => {
    const rTags = relatedTags?.filter(r => relatedTagStore.selectedIds.indexOf(r.id) < 0 );
    setRelatedTags([...rTags]);
    setMasterRelatedTags(rTags);
  };

  return (
    <DataGridBox>
        <DataGrid 
          columns={columns} 
          rows={relatedTags ?? []} 
          getRowId={(row) => row.id} 
          density="comfortable"
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            const selectedIds = new Set(ids);
            relatedTagStore.setSelectedIds(
              Array.from(selectedIds).map((id) => parseInt(id as string))
            );
          }}
          slots={{
            toolbar: props.Toolbar ? TagDetailRelatedTagToolBar : null,
          }}
          slotProps={{ toolbar: { RelatedTags: relatedTags, handleAddRelatedTag: handleAddRelatedTag, handleDeleteRelatedTag: handleDeleteRelatedTag } }}
          />
      </DataGridBox>
  );
});

export default MasterRelatedTagsTab;
