import { GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "../../../../hooks/useStores";
import { DataGridBox } from "../../../../styles/dataGridBox";
import { formatDateTime } from "../../../../utils/formatDate";
import TagDetailTagAttributesToolBar from "../components/tagAttributes/TagDetailTagAttributesToolBar"
import { MasterAssetDetailAttributes } from "../../../../models/MasterArea/response/getAssetsDetailResponse";
import { DataGridPro } from "@mui/x-data-grid-pro";

const columns: GridColDef<MasterAssetDetailAttributes>[] = [
  {
    field: "tagAttributeValue",
    headerName: "Tag Attribute Value",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "uom",
    headerName: "UOM",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "attributeClass",
    headerName: "Attribute Class",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "createdOn",
    headerName: "Created On",
    flex: 1,
    minWidth: 150,
    valueFormatter: formatDateTime,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "modifiedOn",
    headerName: "Modified On",
    flex: 1,
    minWidth: 150,
    valueFormatter: formatDateTime,
  },
  {
    field: "modifiedBy",
    headerName: "Modified By",
    flex: 1,
    minWidth: 200,
  },
];

interface MasterTagDetailAttributesTabProps {
  Toolbar: boolean;
}

const MasterTagDetailAttributesTab = observer((props: MasterTagDetailAttributesTabProps) => {
  let rowId = 0;

  const {
    masterAreaCardViewStore: { masterTagAttributes, setMasterAttributes },
    masterAreaTagAttributesStore
  } = useStores();

  const [attributes, setAttributes] = useState<MasterAssetDetailAttributes[]>(masterTagAttributes);

  const handleAddAttribute = (attr: MasterAssetDetailAttributes) => {
    const attrs = Object.assign([], attributes);
    attrs.push(attr);
    setAttributes([...attrs]);
    setMasterAttributes(attrs);
  };

  const handleDeleteAttribute = () => {
    const attrs = attributes?.filter(d => masterAreaTagAttributesStore.selectedIds.indexOf(d.id) < 0);
    setAttributes([...attrs]);
    setMasterAttributes(attrs);
  };

  const handleEditAttribute = (attr: MasterAssetDetailAttributes) => {
    const attrs = attributes?.filter(d => d.id === attr.id);
    if(attrs.length > 0) {
      const editedAttr = attrs[0];
      editedAttr.tagAttributeValue = attr.tagAttributeValue;
      editedAttr.uom = attr.uom;
      editedAttr.uomId = attr.uomId;
      editedAttr.modifiedBy = attr.modifiedBy;
      editedAttr.modifiedOn = attr.modifiedOn;

      const newAttrs = attributes?.filter(d => d.id !== attr.id);
      const newObjAttrs = Object.assign([], newAttrs);
      newObjAttrs.push(editedAttr);
      setAttributes([...newObjAttrs]);
      setMasterAttributes(newObjAttrs);
    }
    return true;
  };

  return (
    <DataGridBox>
      <DataGridPro
        columns={columns}
        rows={attributes ?? []}
        getRowId={(row) => row.id ?? rowId++} 
        density="comfortable"
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
          const selectedIds = new Set(ids);
          masterAreaTagAttributesStore.setSelectedIds(
            Array.from(selectedIds).map((id) => parseInt(id as string))
          );
          masterAreaTagAttributesStore.setTagAttributeValue("");
          masterAreaTagAttributesStore.setUom("");
          masterAreaTagAttributesStore.setSelectedId(masterAreaTagAttributesStore.selectedId);
        }}
        slots={{
          toolbar: props.Toolbar ? TagDetailTagAttributesToolBar : null,
        }}
        slotProps={{ toolbar: { Attributes: attributes, handleAddAttribute: handleAddAttribute, handleDeleteAttribute: handleDeleteAttribute, handleEditAttribute: handleEditAttribute } }}
      />
    </DataGridBox>
  );
});

export default MasterTagDetailAttributesTab;
