import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Collapse, Divider, Grid, Paper, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link as RouterLink, useLocation } from "react-router-dom";
import sidebarItems from "./sidebarItems";
import React, { useState } from "react";
import { useStores } from "../../hooks/useStores";

export interface SidebarProps {
  open: boolean;
}

const menuItems = {
  MasterArea: 0,
  ProjectArea: 1,
  AdminArea: 2,
  References: 2.1
};

const Sidebar = (props: SidebarProps) => {
  const {  userStore  } = useStores();
  const location = useLocation(); 

  const [openMenu, setOpenMenu] = useState({
    MasterArea: true,
    ProjectArea: false,
    AdminArea: false,
    References: false
  });

  const nestedIconMaster: React.ReactNode = openMenu.MasterArea ? (
    <ExpandLess />
  ) : (
    <ExpandMore />
  );

  const nestedIconProject: React.ReactNode = openMenu.ProjectArea ? (
    <ExpandLess />
  ) : (
    <ExpandMore />
  );

  const nestedIconAdmin: React.ReactNode = openMenu.AdminArea ? (
    <ExpandLess />
  ) : (
    <ExpandMore />
  );

  const nestedIconReferences: React.ReactNode = openMenu.References ? (
    <ExpandLess />
  ) : (
    <ExpandMore />
  );

  // Function to check if the sidebar item should be visible based on user roles
  const isVisibleBasedOnRole = (itemRoles?: string[]): boolean => {
    // If no roles are defined for the item, it's visible to everyone
    if (!itemRoles) {
      return true;
    }
    // Check if the user has any of the roles required by the item
    return itemRoles.some(role => userStore.hasCurrentUserRole(role));
  };

  // Filter the sidebar items based on the user's roles
  const visibleSidebarItems = sidebarItems.filter(item => isVisibleBasedOnRole(item.roles));

  const handleMenuClick = (id: number) => {
    switch (id) {
      case menuItems.MasterArea:
        setOpenMenu({ ...openMenu, MasterArea: !openMenu.MasterArea });
        break;
      case menuItems.ProjectArea:
        setOpenMenu({ ...openMenu, ProjectArea: !openMenu.ProjectArea });
        break;
      case menuItems.AdminArea:
        setOpenMenu({ ...openMenu, AdminArea: !openMenu.AdminArea });
        break;
      case menuItems.References:
        setOpenMenu({ ...openMenu, References: !openMenu.References });
        break;
      default:
        break;
    }    
  };

  const getNestedIcon = (id: number) => {
    switch (id) {
      case menuItems.MasterArea:
        return nestedIconMaster;
      case menuItems.ProjectArea:
        return nestedIconProject;
      case menuItems.AdminArea:
        return nestedIconAdmin;
      case menuItems.References:
        return nestedIconReferences;
      default:
        return null;
    }
  }

  const getToggleValue = (id: number) => {
    switch (id) {
      case menuItems.MasterArea:
        return openMenu.MasterArea;
      case menuItems.ProjectArea:
        return openMenu.ProjectArea;
      case menuItems.AdminArea:
      return openMenu.AdminArea;
      case menuItems.References:
      return openMenu.References;
      default:
        return false;
    }
  }  

  return (
    <Collapse
      in={props.open}
      orientation="horizontal"
      sx={{ position: "fixed", height: "100vh", pt: 8, overflowY: "auto" }}
    >
      <Paper
        elevation={0}
        square
        sx={{ height: "100%", minHeight: "600px", width: 256, pb: 1 }}
      >
        <Grid container sx={{ height: "100%" }} direction="row">
          <Grid item xs>
            <List sx={{ px: 1 }}>
              {visibleSidebarItems.map((item) => (
                <React.Fragment key={item.id}>
                  <ListItemButton
                    sx={{ borderRadius: (theme) => theme.shape.borderRadius }}
                    component={item.subItems ? "div" : RouterLink}
                    to={item.subItems ? "" : item.route}
                    selected={
                      (location.pathname.startsWith(item.route) &&
                        item.route !== "/") ||
                      location.pathname === item.route
                    }
                    disabled={item.disabled}
                    onClick={item.subItems ? ()=> handleMenuClick(item.id) : undefined}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{ fontWeight: 700 }}
                    />
                    {item.subItems ? getNestedIcon(item.id) : null}
                  </ListItemButton>
                  {item.subItems ? (
                    <Collapse in={getToggleValue(item.id)} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.subItems.map((subItem) => (
                          <>
                          <ListItemButton
                            key={subItem.id}
                            sx={{ pl: 4 }}
                            component={subItem.subItems ? "div" : RouterLink}
                            to={subItem.subItems ? "" : subItem.route}
                            selected={location.pathname === subItem.route}
                            onClick={subItem.subItems ? ()=> handleMenuClick(subItem.id) : undefined}
                          >
                            <ListItemIcon>{subItem.icon}</ListItemIcon>
                            <ListItemText secondary={subItem.label} />
                            {subItem.subItems ? getNestedIcon(subItem.id) : null}
                          </ListItemButton>
                          {subItem.subItems?.map((subsubItem) => (
                          <>
                          <Collapse in={getToggleValue(subItem.id)} sx={{ pl: 4 }} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton
                                  key={subsubItem.id}
                                  sx={{ pl: 4 }}
                                  component={RouterLink}
                                  to={subsubItem.route}
                                  selected={location.pathname === subsubItem.route}
                                >
                                  <ListItemIcon>{subsubItem.icon}</ListItemIcon>
                                  <ListItemText secondary={subsubItem.label} />
                                </ListItemButton>
                              </List>
                            </Collapse>
                            </>
                          ))}
                          </>
                        ))}
                      </List>
                    </Collapse>
                  ) : null}
                </React.Fragment>
              ))}
            </List>
          </Grid>
          <Grid item xs="auto" sx={{ width: "100%!important" }}>
            <Divider />
            <List>
              <ListItemButton
                sx={{ borderRadius: (theme) => theme.shape.borderRadius }}
                component={RouterLink}
                to="/support"
              >
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Support"
                  primaryTypographyProps={{ fontWeight: 700 }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ borderRadius: (theme) => theme.shape.borderRadius }}
                component={RouterLink}
                to="/about"
              >
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText
                  primary="About"
                  primaryTypographyProps={{ fontWeight: 700 }}
                />
              </ListItemButton>
            </List>
            <Box sx={{ textAlign: "center" }}>
              <Typography fontSize={12}>
                Asset Register Solution V 2.2
              </Typography>
              <img
                style={{ maxWidth: 50, margin: "4px 0" }}
                src="/images/tco_logo.svg"
                alt=""
              />
              <Typography fontSize={10}>
                &copy;TENGIZCHEVROIL {new Date().getFullYear()}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Collapse>
  );
};

export default Sidebar;
