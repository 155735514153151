import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import TagDetailAddTagAttributesModal from "./TagDetailAddTagAttributesModal";

const TagDetailAddTagAttributes = observer(() => {
  const {
    projectAreaCardViewStore: { assetDetail },
    projectAreaCardViewTagAttributesStore: tagAttributesStore,
    dictionaryStore,
  } = useStores();

  useEffect(() => {
    dictionaryStore.fetchUnitOfMeasuresOptions("");
  }, [dictionaryStore]);

  const handleAddClick = () => {
    tagAttributesStore.setTagAttributeValue("");
    tagAttributesStore.setSelectedEclClassOption(null);
    tagAttributesStore.setSelectedUOMOption({id:0, label: ""});
    tagAttributesStore.setShowAddTagAttributesModal(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        disabled={tagAttributesStore.selectedIds.length > 0}
        onClick={handleAddClick}
      >
        <span>Add</span>
      </Button>
      <TagDetailAddTagAttributesModal />
    </>
  );
});

export default TagDetailAddTagAttributes;
