import React from 'react'
import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { useDebounce } from "../../../../hooks/useDebounce";
import {  Autocomplete, TextField } from "@mui/material";

const MasterAreaCategoryFilter = observer(() => {
  const { masterAssetsStore, dictionaryStore, masterAreaGridViewExportStore } = useStores();

  const debouncedHandleInputChange = useDebounce(500);

  const handleTagCategoryInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagCategoryOptions(inputValue);
    });
  };

  return (
    <>
      <Autocomplete
            size="small"
            id="category"
            options={dictionaryStore.tagCategoryOptions}
            value={masterAssetsStore.selectedCategoryOption}
            getOptionLabel={(option) => option.label}
            sx={{ width: 170, mr: 1, mb: 1, height: 22.5, "& .MuiInputBase-root": {height: "30px"}, 
                "& .MuiFormLabel-root": {"margin-top": "-3px", "font-size": "14px"}, "& .MuiInputBase-input": {"font-size": "12px"}, 
                "& .css-andd64-MuiFormLabel-root-MuiInputLabel-root": {"margin-top": "0px!important", "font-size": "1rem!important"} }}
            renderInput={(params) => <TextField {...params} label="Category" />}
            onInputChange={(event, newInputValue) => {
              handleTagCategoryInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              masterAssetsStore.setSelectedCategoryOption(selectedValue);
              masterAreaGridViewExportStore.setSelectedTagCategoryOption(
                selectedValue
              );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
    </>
  );
});

export default MasterAreaCategoryFilter;
