import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Stack
} from "@mui/material";
import { useState } from "react"
import { useDebounce } from "../../../hooks/useDebounce";
import { TagRequestStatus } from "../../../models/common/response/tagRequestFormStatus";
import { TagLimit } from "../../../models/common/response/tagRequestFormLimit";
import { TagLimitOptions } from "../constants/constants";
import { formatDBDate } from "../../../utils/formatDate";
import PermissionButton from "../../../components/common/PermissionButton";
import { ROLES } from "../../../constants/roles";
import { useEditTagFetchOptionsData } from "../hooks/useFetchOptionsData";
import { editTagRequest, rejectTagRequest, submitTagRequest } from "../hooks/useFetchRequests";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1200px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const TagRequestFormsEditTagModal = observer(() => { 
  const [multiTagInputType, setmultiTagInputType] = useState<string>("text");
  const [requiredDateInputType, setRequiredDateInputType] = useState<string>("text");

  const { tagRequestFormStore, notificationStore, dictionaryStore, userStore } = useStores();

  useEditTagFetchOptionsData(dictionaryStore, tagRequestFormStore.tagRequestForm.categoryId!, tagRequestFormStore.tagRequestForm.areaId!); 

  const debouncedHandleInputChange = useDebounce(500);

  const handleTagRequestCategoryChange = async (inputValue: string) => {
    return true;
  };

  const handleProjectInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectOptions(inputValue);
    });
  };

  const handleAreaInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchAreaOptions(inputValue);
    });
  };

  const handleUnitInputChange = async (inputValue: string, areaId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOptions(inputValue, areaId);
    });
  };

  const handleEquipmentTypeInputChange = async (inputValue: string, tagRequestCategoryId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEqTypeOptions(inputValue, tagRequestCategoryId);
    });
  };

  const handleBuildingInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchBuildingOptions(inputValue);
    });
  };

  const handleWellInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchWellOptions(inputValue);
    });
  };

  const handleCloseClick = () => {
    tagRequestFormStore.setShowEditRequestTagFormModal(false);
  };

  const handleCreateTagClick = async () => {   
      tagRequestFormStore.setShowCreateBBTagFormModal(true); 
      tagRequestFormStore.setShowEditRequestTagFormModal(false);
  }; 

  const handleSaveClick = async () => {   
    await editTagRequest(tagRequestFormStore, notificationStore);

    tagRequestFormStore.setShowEditRequestTagFormModal(false);
  }; 

  const handleSubmitClick = async () => {   
    await submitTagRequest(tagRequestFormStore, notificationStore);

    tagRequestFormStore.setShowEditRequestTagFormModal(false);
  }; 

  const handleRejectClick = async () => {   
    await rejectTagRequest(tagRequestFormStore, notificationStore);

    tagRequestFormStore.setShowEditRequestTagFormModal(false);
  };

  return (
    <Modal
      open={tagRequestFormStore.showEditRequestTagFormModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          {!tagRequestFormStore.showCreateBBTagFormModal ? "Edit Request Tag Form" : "Tag Create Form"}
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Stack direction="row">
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Tag Request Id"
              value={tagRequestFormStore.tagRequestForm.id + ""}
              sx={{ mr: 1, mb: 2, width: "24%", "margin-right" : "20px", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}  }}
            />
            <TextField
              disabled
              variant="outlined"
              size="small"
              label="Status"
              value={tagRequestFormStore.tagRequestForm.status + ""}
              sx={{ mr: 1, mb: 2, width: "24%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            />
          </Stack>
           <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="tagCategory"
              options={dictionaryStore.tagRequestCategoriesOpitons}
              value={{id: 3, label: "Mechanical Equipment"}}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label="Tag Category" />}
              onInputChange={(event, newInputValue) => {
                handleTagRequestCategoryChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {           
                handleEquipmentTypeInputChange("", selectedValue?.id);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled
            />
          </Stack>
          <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="projectNumber"
              options={dictionaryStore.projectOptions}
              value={{
                id: tagRequestFormStore.tagRequestForm.projectId!, 
                label: tagRequestFormStore.tagRequestForm.projectNo!
              }}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "margin-right" : "13px", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label="Project Number" />}
              onInputChange={(event, newInputValue) => {
                tagRequestFormStore.tagRequestForm.projectNo = newInputValue;
                handleProjectInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                tagRequestFormStore.tagRequestForm.projectId = selectedValue?.id;
                tagRequestFormStore.tagRequestForm.projectNo = selectedValue?.label;
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
            />
          </Stack>
          <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="area"
              options={dictionaryStore.areaOptions}
              value={{
                id: tagRequestFormStore.tagRequestForm.areaId!, 
                label: tagRequestFormStore.tagRequestForm.area + ""
              }}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label="Location (WBS)" />}
              onInputChange={(event, newInputValue) => {       
                tagRequestFormStore.tagRequestForm.area = newInputValue;
                handleAreaInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {            
                tagRequestFormStore.tagRequestForm.areaId = selectedValue?.id;
                tagRequestFormStore.tagRequestForm.area = selectedValue?.label;    
                handleUnitInputChange("", selectedValue?.id);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="unit"
              options={dictionaryStore.unitOptions}
              value={{
                id: tagRequestFormStore.tagRequestForm.unitId!, 
                label: tagRequestFormStore.tagRequestForm.unit + ""
              }}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label="Sub Location (WBS Unit Code)" />}
              onInputChange={(event, newInputValue) => {
                tagRequestFormStore.tagRequestForm.unit = newInputValue;
                handleUnitInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {        
                tagRequestFormStore.tagRequestForm.unitId = selectedValue?.id;
                tagRequestFormStore.tagRequestForm.unit = selectedValue?.label;
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="eqiupmentType"
              options={dictionaryStore.eqTypeOptions}
              value={{
                id: tagRequestFormStore.tagRequestForm.equipmentTypeId!, 
                label: tagRequestFormStore.tagRequestForm.equipmentType + ""
              }}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label="Equipment Type" />}
              onInputChange={(event, newInputValue) => {
                tagRequestFormStore.tagRequestForm.equipmentType = newInputValue;
                handleEquipmentTypeInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                tagRequestFormStore.tagRequestForm.equipmentTypeId = selectedValue?.id;
                tagRequestFormStore.tagRequestForm.equipmentType = selectedValue?.label;
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
            /> 
            <Autocomplete
              disablePortal
              size="small"
              id="building"
              options={dictionaryStore.buildingOptions}
              value={{
                id: tagRequestFormStore.tagRequestForm.buildingId!, 
                label: tagRequestFormStore.tagRequestForm.building + ""
              }}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label="Building" />}
              onInputChange={(event, newInputValue) => {
                tagRequestFormStore.tagRequestForm.building = newInputValue;
                handleBuildingInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                tagRequestFormStore.tagRequestForm.buildingId = selectedValue?.id;
                tagRequestFormStore.tagRequestForm.building = selectedValue?.label;
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
            />  
            <Autocomplete
              disablePortal
              size="small"
              id="well"
              options={dictionaryStore.wellOptions}
              value={{
                id: tagRequestFormStore.tagRequestForm.wellId!, 
                label: tagRequestFormStore.tagRequestForm.well + ""
              }}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label="Well" />}
              onInputChange={(event, newInputValue) => {
                tagRequestFormStore.tagRequestForm.well = newInputValue;
                handleWellInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                tagRequestFormStore.tagRequestForm.wellId = selectedValue?.id;
                tagRequestFormStore.tagRequestForm.well = selectedValue?.label;
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
            />  
          </Stack>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Service Description"
              value={tagRequestFormStore.tagRequestForm.serviceDescription + ""}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              onChange={(e) => tagRequestFormStore.tagRequestForm.serviceDescription = e.target.value}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Documents"
              value={tagRequestFormStore.tagRequestForm.documents + ""}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              onChange={(e) => tagRequestFormStore.tagRequestForm.documents = e.target.value}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Suffix"
              value={tagRequestFormStore.tagRequestForm.suffix + ""}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              onChange={(e) => tagRequestFormStore.tagRequestForm.suffix = e.target.value}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
            />
            <TextField
              variant="outlined"
              size="small"
              id="multipleTags"
              label="Multiple Tags"
              type={multiTagInputType}
              value={tagRequestFormStore.tagRequestForm.multipleTags ? tagRequestFormStore.tagRequestForm.multipleTags + "" : ""}
              sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              onChange={(e) => tagRequestFormStore.tagRequestForm.multipleTags = parseInt(e.target.value)}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
              onFocus={(e) => setmultiTagInputType("number")}
            />
            { 
            !tagRequestFormStore.tagRequestForm.multipleTags || tagRequestFormStore.tagRequestForm.multipleTags < 1 ? null :
              <Autocomplete
                disablePortal
                size="small"
                id="tagLimit"
                options={TagLimitOptions}
                value={{
                  id: tagRequestFormStore.tagRequestForm.tagLimit ?? 0, 
                  label: TagLimit[tagRequestFormStore.tagRequestForm.tagLimit ?? 0]
                }}
                getOptionLabel={(option) => option.label}
                sx={{ mr: 1, mb: 2, width: "19%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
                renderInput={(params) => <TextField {...params} label="Tag Limit" />}
                onChange={(event, selectedValue) => {
                  tagRequestFormStore.tagRequestForm.tagLimit = selectedValue?.id;
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft || !tagRequestFormStore.tagRequestForm.multipleTags || tagRequestFormStore.tagRequestForm.multipleTags < 1}
              />  
            }   
          </Stack>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              type={requiredDateInputType}
              label="Required Date"
              value={tagRequestFormStore.tagRequestForm.requiredDate ? formatDBDate(tagRequestFormStore.tagRequestForm.requiredDate) + "" : ""}
              sx={{ mr: 1, mb: 2, width: "20%", "margin-right" : "50px", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
              onFocus={(e) => setRequiredDateInputType("date")}
              onBlur={(e) => {setRequiredDateInputType("text")}}
              onChange={(e) => {if (e.target.value) tagRequestFormStore.tagRequestForm.requiredDate = new Date(e.target.value)}}
            />
            <FormControlLabel control={
              <Checkbox 
                checked={tagRequestFormStore.tagRequestForm.isDatasheetNumber} 
                value={tagRequestFormStore.tagRequestForm.isDatasheetNumber} 
                onChange={(e) => tagRequestFormStore.tagRequestForm.isDatasheetNumber = e.target.checked}
                disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
              />
            } label="Data Sheet Number"  sx={{ "margin-right" : "50px" }} />
            <FormControlLabel control={
              <Checkbox 
                checked={tagRequestFormStore.tagRequestForm.isEquipmentTagNumberForMotor} 
                value={tagRequestFormStore.tagRequestForm.isEquipmentTagNumberForMotor} 
                onChange={(e) => tagRequestFormStore.tagRequestForm.isEquipmentTagNumberForMotor = e.target.checked} 
                disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
              />
            } label="Equipment Tag Number for Motor"  sx={{ "margin-right" : "50px" }} />
            <FormControlLabel control={
              <Checkbox 
                checked={tagRequestFormStore.tagRequestForm.isMotorDatasheetNumber} 
                value={tagRequestFormStore.tagRequestForm.isMotorDatasheetNumber} 
                onChange={(e) => tagRequestFormStore.tagRequestForm.isMotorDatasheetNumber = e.target.checked} 
                disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
              />
            } label="Motor Data Sheet Number"  sx={{ "margin-right" : "50px" }} />
          </Stack>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Remarks"
              value={tagRequestFormStore.tagRequestForm.remarks + ""}
              multiline
              rows={3}
              sx={{ mr: 1, mb: 2, width: "98%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              onChange={(e) => tagRequestFormStore.tagRequestForm.remarks = e.target.value}
              disabled={tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Issued Tag Number(s)"
              value={tagRequestFormStore.tagRequestForm.tagNumber + ""}
              sx={{ mr: 1, mb: 2, width: "98%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              disabled
            />
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex", width: "98%" },
            justifyContent: "flex-end",
          }}
        >
          { 
            tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Submitted ?  null :
            <PermissionButton
              buttonStyle={{ ml: 2 }} 
              buttonText="Create Tag"
              buttonVariant="contained"
              buttonPermission={userStore.hasCurrentUserRole(ROLES.arsMasterUserRole)}
              handleButtonClick={handleCreateTagClick}
            />
          }
          { 
            tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Submitted ?  null :
            <PermissionButton
              buttonStyle={{ ml: 2 }} 
              buttonText="Reject"
              buttonVariant="contained"
              buttonPermission={userStore.hasCurrentUserRole(ROLES.arsMasterUserRole)}
              handleButtonClick={handleRejectClick}
            />
          }
          { 
            tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft ?  null :
            <PermissionButton
              buttonStyle={{ ml: 2 }} 
              buttonText="Save"
              buttonVariant="contained"
              buttonPermission={userStore.hasCurrentUserRole(ROLES.arsProjectUserRole)}
              handleButtonClick={handleSaveClick}
              buttonDisabled={!tagRequestFormStore.tagRequestForm.projectNo || !tagRequestFormStore.tagRequestForm.area || !tagRequestFormStore.tagRequestForm.unit || !tagRequestFormStore.tagRequestForm.equipmentType}   
            />
          }
          { 
            tagRequestFormStore.tagRequestForm.status !== TagRequestStatus.Draft ?  null :
            <PermissionButton
              buttonStyle={{ ml: 2 }} 
              buttonText="Submit"
              buttonVariant="contained"
              buttonPermission={userStore.hasCurrentUserRole(ROLES.arsProjectUserRole)}
              handleButtonClick={handleSubmitClick}
              buttonDisabled={!tagRequestFormStore.tagRequestForm.projectNo || !tagRequestFormStore.tagRequestForm.area || !tagRequestFormStore.tagRequestForm.unit || !tagRequestFormStore.tagRequestForm.equipmentType}   
            />
          }
           <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
            <span>Close</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagRequestFormsEditTagModal;
