import { SelectOption } from "../../../../models/common/selectOption";

export const TagLimitOptions: SelectOption[] = [
    //{id: 0, label: "None"}, 
    {id: 1, label: "1-99"}, 
    {id: 2, label: "A-Z"},
    {id: 2, label: "Combined1_99_A_Z"},
    {id: 2, label: "Combined1A_1B_1C"},
    {id: 2, label: "Combined1A_2A_3A"},
    {id: 2, label: "CombinedA1_A2_A3"},
    {id: 2, label: "CombinedA1_B1_C1"}]

export const TagCategories = { 
    AuxiliaryElectricalEquipment: "01",
    AuxiliaryEquipmentCables: "02",
    DistributionCircuit: "03",
    FireandGasInstrument: "04",
    Instrument: "05",
    InstrumentAirManifoldJBFrame: "06",
    InstrumentCable: "07",
    InstrumentLoop: "08",
    JunctionBox: "09",
    Manifold: "10",
    MechanicalEquipment: "11",
    OnPlotBuildServices: "12",
    OtherElectricalCable: "13",
    Panel: "14",
    PipeRack: "15",
    PipingLines: "16",
    PrimaryElectricalEquipment: "17",
    PrimaryEquipmentCable: "18",
    ProcessTelecomPAGASecurityEquipment: "19",
    SpecialPipingItems: "20",
    Structures: "23",
    UndergroundServices: "21",
    WirelessEquipment: "22"
}
            