import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import TagRequestFormsEditTagModal from "./TagRequestFormsEditTagModal";

const TagRequestFormsEditTag = observer(() => {
  const { tagRequestFormStore } = useStores();

  const handleEditClick = () => {
    tagRequestFormStore.fetchTagRequestById(tagRequestFormStore.selectedId!);
    tagRequestFormStore.setShowEditRequestTagFormModal(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<EditIcon />}
        onClick={() => handleEditClick()}
        disabled={tagRequestFormStore.selectedIds.length !== 1}
        >
        Edit
      </Button>
      {tagRequestFormStore.showEditRequestTagFormModal ? <TagRequestFormsEditTagModal /> : null }
    </>
  );
});

export default TagRequestFormsEditTag;
