import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Autocomplete,
  Stack
} from "@mui/material";
import { useState } from "react";
import { useDebounce } from "../../../../hooks/useDebounce";
import { SelectOption } from "../../../../models/common/selectOption";
import { WorkingCopyBBTagRequest } from "../../../../models/TagRequest/request/workingCopyBBTagRequest";
import { workingCopyTagRequests } from "../hooks/useFetchRequests";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const MasterAreaWorkingCopyTagModal = observer(() => {
  const { masterAreaGridViewExportStore, masterAreaCardViewStore, masterAssetsStore, notificationStore, dictionaryStore} = useStores();

  const [remarks, setRemarks] = useState<string | null>(null);
  const [projectOption, setProjectsOption] = useState<SelectOption | null>(null);

  const debouncedHandleInputChange = useDebounce(500);

  const handleProjectInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectOptions(inputValue);
    });
  };

  const clearFields = () => {
    setRemarks(null);
    setProjectsOption(null);
  };
  
  const handleCloseClick = () => {
    clearFields();
    masterAreaCardViewStore.setShowWorkingCopyModal(false);
  };


  const handleSaveClick = async () => {  
    const workingCopy: WorkingCopyBBTagRequest = {
        tagIds: masterAreaGridViewExportStore.selectedIds,
        projectId: projectOption?.id,
        remarks: remarks
    }

    workingCopyTagRequests(masterAssetsStore, notificationStore, workingCopy);
    
    clearFields();
    masterAreaCardViewStore.setShowWorkingCopyModal(false);
  }; 

  return (
    <Modal
      open={masterAreaCardViewStore.showWorkingCopyModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Working Copy
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >     
            <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="unit"
              options={dictionaryStore.projectOptions}
              value={projectOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Project Number" />}
              onInputChange={(event, newInputValue) => {
                handleProjectInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setProjectsOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Stack>
          <Stack direction="row">
          <TextField
              variant="outlined"
              size="small"
              label="Remarks"
              value={remarks}
              multiline
              rows={3}
              sx={{ mr: 1, mb: 2, width: "100%" }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex", width: "98%" },
            justifyContent: "flex-end",
          }}
        >
          <Button 
            variant="contained" 
            disabled={projectOption == null} 
            sx={{ ml: 2 }} 
            onClick={handleSaveClick}>
            <span>Save</span>
          </Button> 
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
            <span>Close</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default MasterAreaWorkingCopyTagModal;
