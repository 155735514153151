import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const UnitItem = observer(() => {
  usePageTitle("References - Unit");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { referencesStore } = useStores();
  
  const [unitCode, setUnitCode] = useState<NullableString>(null);
  const [unitDescription, setUnitDescription] = useState<NullableString>(null);

 
  useEffect(() => {
    (async () => {   
      await initUnit();
    })()
  }, []);

  const initUnit = async () => {

    const id = urlParams.get("id");
    //await masterUnitDocumentsStore.fetchDocumentById(parseInt(id!));

    setUnitCode(null);
    setUnitDescription(null);
  }
  
  const handleCloseClick = () => {
    navigate(ROUTES.referencesFacilities);
  };

  return (
    referencesStore.unit ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
      <Box
          sx={ areaboxStyle }
        >  
         <Typography
            id="add-tag-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Unit
          </Typography> 
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit Code"
            value={unitCode + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit Description"
            value={unitDescription + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        </Box> 
      </Box>
      }
      </form>
      :
      null
  );
});

export default UnitItem;
