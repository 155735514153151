import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { useMemo } from "react";
import { formatDateWithTime } from "../../../../utils/formatDate";
import { ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";

const useMasterGridColumns = () => {
    
  const navigate = useNavigate();

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "createdDate", headerName: "Created On", flex: 1, minWidth: 150, valueFormatter: formatDateWithTime },
      { field: "createdBy", headerName: "Created By", flex: 1, minWidth: 200 },
      { field: "documentNumber", headerName: "Document Number", flex: 1, minWidth: 250,
        renderCell: (params) => {
          return (
            <>
              <a href="#" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditDocument + "?id=" + params.row.masterDocumentsId)}>
                {params.value}
              </a>
            </>
          );
        } },
      { field: "documentType", headerName: "Document Type", flex: 1, minWidth: 150 },
      { field: "documentTitle", headerName: "Document Title", flex: 1, minWidth: 250},
      { field: "documentUrl", headerName: "Document Url", flex: 1, minWidth: 250},
      { field: "updatedDate", headerName: "Modified On", flex: 1, minWidth: 150, valueFormatter: formatDateWithTime },
      { field: "updatedBy", headerName: "Modified By", flex: 1, minWidth: 200 }
    ],
    []
  );

  return columns;
};

export default useMasterGridColumns;
