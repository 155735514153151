import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import { Box, Button, Modal, TextField, Typography, Autocomplete } from "@mui/material";
import PermissionButton from "../../../../../components/common/PermissionButton";
import { ROLES } from "../../../../../constants/roles";
import { useRef } from "react";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { UpdateTagAttributesByIdRequest } from "../../../../../models/ProjectArea/request/editTagAttributesRequest";

import { ExtendedSelectOption } from "../../../../../models/common/extendedSelectOption";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const TagDetailEditTagAttributesModal = observer(() => {
  const {
    projectAreaCardViewTagAttributesStore,
    projectAreaCardViewStore: {
      fetchTagAttributesById,
      assetDetail,
      fetchExTagAttributesById,
      fetchCmmsTagAttributesById,
    },
    notificationStore,
    userStore,
    dictionaryStore,
  } = useStores();

  const tagAttributeValueRef = useRef<HTMLInputElement>(null);

  const handleCancelClick = () => {
    projectAreaCardViewTagAttributesStore.setShowEditTagAttributesModal(false);
  };

  const handleSaveClick = async () => {
    const tagAttributeId = projectAreaCardViewTagAttributesStore.selectedId;

    const data: UpdateTagAttributesByIdRequest = {
      id: tagAttributeId,
      tagAttributeValue: projectAreaCardViewTagAttributesStore.tagAttributeValue,
      uom: projectAreaCardViewTagAttributesStore.selectedUOMOption?.id,
    };

    await projectAreaCardViewTagAttributesStore.updateTagAttributes(data);
    fetchTagAttributesById(assetDetail?.id!);
    projectAreaCardViewTagAttributesStore.setShowEditTagAttributesModal(false);

    notificationStore.setNotificationMessage("Tag Attributes edited successfully");
    notificationStore.setShowNotification(true);
    fetchExTagAttributesById(assetDetail?.id!);
    fetchCmmsTagAttributesById(assetDetail?.id!);
  };

  const debouncedHandleInputChange = useDebounce(500);

  const handleAttributeUOMInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOfMeasuresOptions(inputValue);
    });
  };

  const handleAttributeUOMChange = (selectedValue: ExtendedSelectOption | null) => {
    projectAreaCardViewTagAttributesStore.setSelectedUOMOption(selectedValue);
  };

  return (
    <Modal
      open={projectAreaCardViewTagAttributesStore.showEditTagAttributesModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="edit-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Edit Tag Attributes
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            label="Attribute Name"
            value={projectAreaCardViewTagAttributesStore.tagAttributeName}
            sx={{ mr: 1, mb: 2, width: "100%" }}
            disabled
          />
          <TextField
            variant="outlined"
            size="small"
            label="Attribute Value"
            value={projectAreaCardViewTagAttributesStore.tagAttributeValue}
            onChange={(e) =>
              projectAreaCardViewTagAttributesStore.setTagAttributeValue(e.target.value)
            }
            inputRef={tagAttributeValueRef}
            sx={{ mr: 1, mb: 2, width: "100%" }}
          />
          <Autocomplete
            size="small"
            id="UOM"
            options={dictionaryStore.uomOptions}
            value={projectAreaCardViewTagAttributesStore.selectedUOMOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: "100%",
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => <TextField {...params} label="UOM" />}
            onInputChange={(event, newInputValue) => {
              handleAttributeUOMInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleAttributeUOMChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <PermissionButton
            buttonText="Save"
            buttonVariant="contained"
            buttonPermission={userStore.hasCurrentUserRole(ROLES.arsProjectUserRole)}
            handleButtonClick={handleSaveClick}
          />
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelClick}>
            <span>Cancel</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagDetailEditTagAttributesModal;
