import { Grid } from "@mui/material";
import { DataGridPro} from "@mui/x-data-grid-pro";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../hooks/useStores";
import usePageTitle from "../../hooks/usePageTitle";
import TagRequestFormsToolBar from "./components/TagRequestFormsToolBar";
import useTagRequestFormsColumns from "./hooks/useTagRequestFormsColumns";
import { DataGridBox } from "../../styles/dataGridBox";
import { ROLES } from "../../constants/roles";
import TagRequestFormsDisplayWorkingCopyTagModal from "./components/TagRequestFormsDisplayWorkingCopyTagModal";
import TagRequestFormsDisplayTagModal from "./components/TagRequestFormsDisplayTagModal";
import TagRequestFormsCreateEquipmentTagModal from "./components/TagRequestFormsCreateEquipmentTagModal";

const TagRequestForms = observer(() => {
  usePageTitle("Tag Request Forms");

  const columns = useTagRequestFormsColumns();

  const { tagRequestFormStore, userStore  } = useStores();

  useEffect(() => {    
    tagRequestFormStore.fetchTagRequestForms();
  }, [
    tagRequestFormStore.paginationModel,
    tagRequestFormStore
  ]);

  return (
    <>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <DataGridBox>
            <DataGridPro
              //disableColumnFilter
              paginationMode="server"
              rows={tagRequestFormStore.tagRequestFormResponse.items}
              columns={columns}
              rowCount={tagRequestFormStore.tagRequestFormResponse.totalRecords}
              loading={tagRequestFormStore.isLoading}
              pagination
              paginationModel={tagRequestFormStore.paginationModel}
              onPaginationModelChange={tagRequestFormStore.setPaginationModel}
              sortingMode="server"
              sortModel={tagRequestFormStore.sortModel}
              onSortModelChange={model => {tagRequestFormStore.setSortModel(model); tagRequestFormStore.fetchTagRequestForms();}}
              checkboxSelection          
              disableRowSelectionOnClick
              density="compact"
              sx={{ fontSize: "0.75rem" }}
              slots={{
                toolbar: TagRequestFormsToolBar,
              }}
              onRowSelectionModelChange={(ids) => {
                const selectedIds = new Set(ids);
                tagRequestFormStore.setSelectedIds(
                  Array.from(selectedIds).map((id) => parseInt(id as string))
                );
              }}
              rowSelectionModel={tagRequestFormStore.selectedIds}
            />
          </DataGridBox>
        </Grid>
      </Grid>
      <TagRequestFormsDisplayTagModal />
      <TagRequestFormsDisplayWorkingCopyTagModal />
      {
        userStore.hasCurrentUserRole(ROLES.arsMasterUserRole) 
        ?
        <TagRequestFormsCreateEquipmentTagModal />
        : null
      }
    </>
  );
});

export default TagRequestForms;
