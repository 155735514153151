import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteTagRequests } from "../hooks/useFetchRequests";

const MasterAreaDeleteTag = observer(() => {
  const { masterAssetsStore, masterAreaGridViewExportStore, notificationStore } = useStores();

  const handleDeleteClick = async () => {
    deleteTagRequests(masterAreaGridViewExportStore.selectedIds, masterAssetsStore, notificationStore);
  };

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<DeleteIcon />}
      disabled={masterAreaGridViewExportStore.selectedIds.length < 1}
      onClick={handleDeleteClick}
    >
      Delete
    </Button>
  );
});

export default MasterAreaDeleteTag;
