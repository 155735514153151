import { useEffect } from 'react';

 const usePageTitle = (title: string, override = false) => {
  useEffect(() => {
    document.title = override ? title : `${title} | TCO ARS`;

    return () => {
      document.title = 'TCO Asset Register Solution';
    };
  }, [title, override]);
}

export default usePageTitle;