import { GridSortModel } from "@mui/x-data-grid";
import { makeAutoObservable, runInAction } from "mobx";
import {
  Filter,
  GetTagRequestFormsRequest,
  TagRequestFormsFilterOptions,
  TagRequestFormsSortOptions,
} from "../../models/TagRequest/request/getTagRequestFormsRequest";
import { SortOrder } from "../../models/common/request/sorting";
import apiClient from "../../api/clients";
import { CreateTagRequest } from "../../models/TagRequest/request/createTagRequest";
import { EditTagRequestByIdRequest } from "../../models/TagRequest/request/editTagRequestByIdRequest";
import { GetTagRequestByIdResponse } from "../../models/TagRequest/response/getTagRequestByIdResponse";
import { GetTagRequestFormsResponse } from "../../models/TagRequest/response/getTagRequestFormsResponse";
import { CreateBBTagRequest } from "../../models/TagRequest/request/createBBTagRequest";
import { GetWorkingCopy } from "../../models/TagRequest/request/getWorkingCopy";
import { formatDBDate } from "../../utils/formatDate";
import { NullableString } from "../../models/common/response/types";

interface ListOfTag {
  title: NullableString;
  value: NullableString;
}

class TagRequestFormStore {
  selectedIds: number[] = [];
  selectedId: number | null = null;
  selectedStatusOption: string | undefined = undefined;
  showAddRequestTagFormModal = false;
  showEditRequestTagFormModal = false;
  showDisplayRequestTagFormModal = false;
  showDisplayRequestWorkingCopyTagFormModal = false;
  showCreateBBTagFormModal = false;
  tagRequestForm: GetTagRequestByIdResponse = {} as GetTagRequestByIdResponse;
  workingCopyForm: GetWorkingCopy = {} as GetWorkingCopy;
  workingCopyListOfTags: ListOfTag[] = [];

  tagRequestFormResponse: GetTagRequestFormsResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  paginationModel = {
    pageSize: 100,
    page: 0,
  };
  sortModel: GridSortModel = [{field: "id", sort: "desc"}];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedIds = (ids: number[]) => {
    this.selectedIds = ids;
    this.setSelectedId(ids.length > 0 ? ids[0] : null);
  };

  setSelectedId(id: number | null) {
    this.selectedId = id;
  }

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setSortModel = (sortModel: any) => {
    this.sortModel = sortModel;
  };

  setSelectedStatusOption = (option: string | undefined) => {
    this.selectedStatusOption = option;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setShowAddRequestTagFormModal = (value: boolean) => {
    this.showAddRequestTagFormModal = value;
  };

  setShowEditRequestTagFormModal = (value: boolean) => {
    this.showEditRequestTagFormModal = value;
  };

  setShowDisplayRequestTagFormModal = (value: boolean) => {
    this.showDisplayRequestTagFormModal = value;
  };

  setShowDisplayRequestWorkingCopyTagFormModal = (value: boolean) => {
    this.showDisplayRequestWorkingCopyTagFormModal = value;
  };

  setShowCreateBBTagFormModal = (value: boolean) => {
    this.showCreateBBTagFormModal = value;
  };

  setWorkingCopyListOfTags = (value: ListOfTag[]) => {
    this.workingCopyListOfTags = value;
  };

  fixTagRequestFormDisplaying = () => {
    this.tagRequestForm.building = this.tagRequestForm.building ?? "";
    this.tagRequestForm.well = this.tagRequestForm.well ?? "";
    this.tagRequestForm.serviceDescription = this.tagRequestForm.serviceDescription ?? "";
    this.tagRequestForm.documents = this.tagRequestForm.documents ?? "";
    this.tagRequestForm.suffix = this.tagRequestForm.suffix ?? "";
    this.tagRequestForm.remarks = this.tagRequestForm.remarks ?? "";
    this.tagRequestForm.tagNumber = this.tagRequestForm.tagNumber ?? "";
    if (this.tagRequestForm?.multipleTags) this.tagRequestForm.multipleTagsAsString = this.tagRequestForm.multipleTags + ""; else this.tagRequestForm.multipleTagsAsString = "";
    if (this.tagRequestForm?.tagLimit) this.tagRequestForm.tagLimitAsString = this.tagRequestForm.tagLimit + ""; else this.tagRequestForm.tagLimitAsString = "";
    if (this.tagRequestForm?.requiredDate) this.tagRequestForm.requiredDateAsString = formatDBDate(this.tagRequestForm.requiredDate) + ""; else this.tagRequestForm.requiredDateAsString = "";
  };

  async fetchTagRequestById(id: number): Promise<void> {
    if (!id) return;
 
    try {
      const response = await apiClient.getTagRequestById(id);
 
      runInAction(() => {
        this.tagRequestForm = response;
        this.fixTagRequestFormDisplaying();
      });
    } catch (error) {
      console.error("Error while getting tag request details:", error);
    }
  } 

  async fetchWorkingCopyById(id: number): Promise<void> {
    if (!id) return;
 
    try {
      const response = await apiClient.getWorkingCopyById(id);
 
      runInAction(() => {
        this.workingCopyForm = response;
      });
    } catch (error) {
      console.error("Error while getting working copy details:", error);
    }
  } 

  fetchTagRequestForms = async () => {
    const { page, pageSize } = this.paginationModel;

    const sortOptions: TagRequestFormsSortOptions = {
      status: "status",
      modifiedDate: "modifiedDate",
      id: "id"
    };

    const filterOptions: TagRequestFormsFilterOptions = {
      status: this.selectedStatusOption,
    };

    const sortField = this.sortModel?.[0]?.field;
    const sortOrder =
      this.sortModel?.[0]?.sort === "asc" ? SortOrder.ASC : SortOrder.DESC;
    const sortProperty = sortField
      ? sortOptions[sortField as keyof TagRequestFormsSortOptions]
      : undefined;

    const sort = sortProperty
      ? {
          [sortProperty]: { sortOrder },
        }
      : undefined;

    const filter: Filter = Object.keys(filterOptions).reduce((acc, key) => {
      const filterValue =
        filterOptions[key as keyof TagRequestFormsFilterOptions];
      if (filterValue !== undefined) {
        acc[key as keyof Filter] = { filterValue: filterValue as never };
      }
      return acc;
    }, {} as Filter);

    const request: GetTagRequestFormsRequest = {
      pagination: { pageIndex: page, pageSize },
      filter,
      sort,
    };

    try {
      this.setIsLoading(true);
      const data = await apiClient.getTagRequestForms(request);
      runInAction(() => {
        this.tagRequestFormResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  };

  async createTagRequest(request: CreateTagRequest) {
    try {
      await apiClient.createTagRequest(request);
    } catch (error) {
      console.error("Error creating tag request form: ", error);
      throw error;
    }

    await this.fetchTagRequestForms();
  }

  async createBBTag(request: CreateBBTagRequest) {
    try {
      await apiClient.createBBTag(request);
    } catch (error) {
      console.error("Error creating BB tag: ", error);
      throw error;
    }

    await this.fetchTagRequestForms();
  }

  async editTagRequestById(request: EditTagRequestByIdRequest) {
    try {
      await apiClient.editTagRequestById(request);
    } catch (error) {
      console.error("Error editing tag request form: ", error);
      throw error;
    }
    await this.fetchTagRequestForms();
  }

  async deleteSelectedTagRequests() {
    try {
      for (const selectedId of this.selectedIds){
        await apiClient.deleteTagRequestById(selectedId);
      }
    } catch (error) {
      console.error(error);
    }
    this.setSelectedIds([]);
    this.setSelectedId(null);

    await this.fetchTagRequestForms();
  }

  async deleteTagRequestById(id: number) {
    try {
      await apiClient.deleteTagRequestById(id);
    } catch (error) {
      console.error(error);
    }
    await this.fetchTagRequestForms();
  }

  async rejectTagRequestById(id: number) {
    try {
      await apiClient.rejectTagRequestById(id);
    } catch (error) {
      console.error(error);
    }
    await this.fetchTagRequestForms();
  }

  async approveWorkingCopyById(id: number) {
    try {
      await apiClient.approveWorkingCopyById(id);
    } catch (error) {
      console.error(error);
    }
    await this.fetchTagRequestForms();
  }

}

export default TagRequestFormStore;
