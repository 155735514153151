import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { MasterTagAttributes } from "../../../../../models/MasterArea/response/getMasterAssetsTagAttributesResponse";

interface TagDetailDeleteTagAttributesProps {
  Attributes: MasterTagAttributes[];
  handleDeleteAttribute: any;
}


const TagDetailDeleteTagAttributes = observer((props: TagDetailDeleteTagAttributesProps) => {
  const {
    masterAreaTagAttributesStore
  } = useStores();

  const handleDeleteClick = async () => {
    props.handleDeleteAttribute();
  };

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<DeleteIcon />}
      disabled={masterAreaTagAttributesStore.selectedIds.length < 1}
      onClick={handleDeleteClick}
    >
      Delete
    </Button>
  );
});

export default TagDetailDeleteTagAttributes;
