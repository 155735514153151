import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../../api/clients";
import { SelectOption } from "../../models/common/selectOption";
import { ExtendedSelectOption, LinkedSelectOption } from "../../models/common/extendedSelectOption";
import { GetPrimaryTagsResponse } from "../../models/Dictionaries/response/getPrimaryTagsResponse";
import { NullableNumber } from "../../models/common/response/types";

class DictionaryStore {
  projectOptions: any[] = [];
  areaOptions: any[] = [];
  unitOptions: any[] = [];
  facilityOptions: any[] = [];
  tagNumberOptions: any[] = [];
  masterTagNumberOptions: any[] = [];
  masterTagNumberCategoryOptions: any[] = [];
  eqTypeOptions: any[] = [];
  tagCategoryOptions: any[] = [];
  parentTagNumberOptions: any[] = [];
  equipmentStatusOptions: any[] = [];
  tagTypeOptions: any[] = [];
  buildingOptions: any[] = [];
  locationOptions: any[] = [];
  docTypeOptions: any[] = [];
  eclClassesOptions: any[] = [];
  projectTagNumberCategoryOptions: any[] = [];
  disciplineCodeOptions: any[] = [];
  tagRequestCategoriesOpitons: any[] = [];
  wellOptions: any[] = [];
  uomOptions: any[] = [];
  parentTagNumberByCategoryOptions: any[] = [];
  masterTagDocumentsOptions: any[] = [];
  primaryTagOptions: any[] = [];
  primaryTagExtendedOptions: GetPrimaryTagsResponse[] = [];
  projectTagAuditOptions: any[] = [];
  pipeClassesOptions: any[] = [];
  fluidOptions: any[] = [];
  substationOptions: any[] = [];
  projectSystemnameOptions: any[] = [];
  projectSubsystemOptions: any[] = [];
  locatonOptions: any[] = [];
  eclTagAttributesOptions: LinkedSelectOption[] = [];
  userOptions: any[] = [];
  documentDatasheets: any[] = [];
  documentPAID: any[] = [];
  tagAttributesNamesOptions: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchProjectOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchProjectNumbers(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.projectNumber,
      }));

      runInAction(() => {
        this.projectOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchAreaOptions = async (inputValue: string, facilityId?: number, categoryCode?: string) => {
    try {
      const response = await apiClient.fetchAreas(inputValue, facilityId, categoryCode);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: `${option.areaCode} - ${option.areaDescription}`,
      }));

      runInAction(() => {
        this.areaOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchUnitOptions = async (inputValue: string, areaId?: number) => {
    try {
      const response = await apiClient.fetchUnits(inputValue, areaId);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.unit + "-" + option.unitDescription,
      }));

      runInAction(() => {
        this.unitOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchFacilityOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchFacilities(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: `${option.facilityCode} - ${option.facilityDescription}`,
      }));

      runInAction(() => {
        this.facilityOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchTagNumberOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchMasterTagNumbers(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.tagNumberCategory,
      }));

      runInAction(() => {
        this.tagNumberOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchMasterTagNumberOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchMasterTagNumbers(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.tagNumberCategory,
      }));

      runInAction(() => {
        this.masterTagNumberOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchMasterTagCategoriesNumberOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchTagRequestCategories(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.tagNumberCategory,
      }));

      runInAction(() => {
        this.masterTagNumberCategoryOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchEqTypeOptions = async (inputValue: string, categoryId?: number) => {
    try {
      const response = await apiClient.fetchTagTypes(inputValue, categoryId);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: `${option.tagType}`,
      }));

      runInAction(() => {
        this.eqTypeOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchEqTypeById = async (id: number) : Promise<string> => {
    try {
      const response = await apiClient.fetchTagTypeById(id);
      const eqType = await response.tagType;
      return eqType;
    } catch (error) {
      console.error("Error fetching options:", error);
    }
    return "";
  };

  fetchDisciplineCodeOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchDisciplineCodes(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: `${option.tagDisciplineCode} - ${option.tagDisciplineDescription}`,
      }));

      runInAction(() => {
        this.disciplineCodeOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchTagCategoryOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchTagCategories(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.tagCategoryName,
        code: option.tagCategoryCode
      }));

      runInAction(() => {
        this.tagCategoryOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchParentTagNumberOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchMasterTagNumbers(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.tagNumberCategory,
      }));

      runInAction(() => {
        this.parentTagNumberOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchParentTagNumberByCategoryOptions = async (inputValue: string, categoryId?: number) => {
    try {
      const response = await apiClient.fetchMasterParentTagsByCategory(inputValue, categoryId);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: `${option.tagNumberCategory}`,
      }));

      runInAction(() => {
        this.parentTagNumberByCategoryOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching parent tag options:", error);
    }
  };

  fetchPrimaryTagNumberByCategoryOptions = async (inputValue: string, categoryCode?: string) => {
    try {
      const response = await apiClient.fetchPrimaryTags(inputValue, categoryCode);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.tagNumber,
      }));

      runInAction(() => {
        this.primaryTagOptions = newOptions;
        this.primaryTagExtendedOptions = response;
      });
    } catch (error) {
      console.error("Error fetching primary tags options:", error);
    }
  };
  
  fetchEquipmentStatusOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchEquipmentStatuses();
      const newOptions: SelectOption[] = 
            response.filter(st => st.equipmentStatus !== "-- No status" && st.equipmentStatus !== "Inactive" && st.equipmentStatus !== "10 Design / Evaluation"
                                && st.equipmentStatus !== "40 Construction" && st.equipmentStatus !== "60 Commissioning" && st.equipmentStatus !== "70 RFO / MOC")
            .map((option) => ({
              id: option.id,
              label: option.equipmentStatus,
            }));

      runInAction(() => {
        this.equipmentStatusOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchTagTypeOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchTagTypes(inputValue);
      const filteredResponse = response.filter((value, index, self) => {
        return self.findIndex(v => v.tagTypeCode === value.tagTypeCode) === index;
      });
      const newOptions: SelectOption[] = filteredResponse.map((option) => ({
        id: option.id,
        label: option.tagTypeCode,
      }));

      runInAction(() => {
        this.tagTypeOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchLocationOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchLocations(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.locationCode,
      }));

      runInAction(() => {
        this.locationOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchBuildingOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchBuildings(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: `${option.buildingNumber} - ${option.buildingDescription}`,
      }));

      runInAction(() => {
        this.buildingOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchWellOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchWells(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: `${option.wellCode}`,
      }));

      runInAction(() => {
        this.wellOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchDocTypeOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchDocTypes(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.docTypeCode,
      }));

      runInAction(() => {
        this.docTypeOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetcEclClassesOptions = async (inputValue: string, type?: NullableNumber) => {
    try {
      const response = await apiClient.fetchEclTagAttributes(inputValue, type);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.attributeName
      }));

      runInAction(() => {
        this.eclClassesOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  // Fetch tag attributes by type
  fetchTagAttributesNamesByTypeOptions = async (inputValue: string, type?: NullableNumber) => {
    try {
      const response = await apiClient.fetchTagAttributesByType(inputValue, type);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.attributeName
      }));

      runInAction(() => {
        this.tagAttributesNamesOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetcEclTagAttributesOptions = async (inputValue: string, type? : NullableNumber) => {
    try {
      const response = await apiClient.fetchEclTagAttributes(inputValue, type);
      const newOptions: LinkedSelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.attributeName,
        additionalId: option.uomClassId,
      }));

      runInAction(() => {
        this.eclTagAttributesOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchProjectTagNumberCategoryOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchProjectTagNumberCategory(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.tagNumberCategoryProject,
      }));

      runInAction(() => {
        this.projectTagNumberCategoryOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchUnitOfMeasuresOptions = async (inputValue: string, uomClassId?: number) => {
    try {
      const response = await apiClient.fetchUnitOfMeasures(inputValue, uomClassId);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.uomName,
      }));

      runInAction(() => {
        this.uomOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching uom options:", error);
    }
  };

  fetchMasterDocumentsByNumber = async (documentNumber?: string) => {
    try {
      const response = await apiClient.getMasterDocumentsByDocNumber(documentNumber);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.documentNumber,
      }));
      runInAction(() => {
        this.masterTagDocumentsOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching master documents options:", error);
    }
  };

  
  fetchProjectsFromProjectEquipments = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchProjectsFromProjectEquipments(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.projectNumber,
      }));

      runInAction(() => {
        this.projectTagAuditOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchPipeClassesOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchPipeClasses(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.pipeClass,
      }));

      runInAction(() => {
        this.pipeClassesOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchFluidsOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchFluids(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.fluid!,
      }));

      runInAction(() => {
        this.fluidOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchSubstationOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchSubstations(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.substationCode,
      }));

      runInAction(() => {
        this.substationOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchProjectSystemnames = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchProjectSystemnames(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.projectTagAttributeId,
        label: option.tagAttributeValue,
      }));

      runInAction(() => {
        this.projectSystemnameOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };


  fetchProjectSubsystems = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchProjectSubsystems(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.projectTagAttributeId,
        label: option.tagAttributeValue,
      }));

      runInAction(() => {
        this.projectSubsystemOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchLocations = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchLocations(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: `${option.locationCode} - ${option.locationDescription}`,
      }));

      runInAction(() => {
        this.locationOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchUsersOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchUsers(inputValue);
      const newOptions: ExtendedSelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.fullName,
        additionalLabel: option.email
      }));

      runInAction(() => {
        this.userOptions = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchDocumentDatasheetsOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchDocumentDatasheets(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.docNumber,
      }));

      runInAction(() => {
        this.documentDatasheets = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  fetchDocumentPAIDOptions = async (inputValue: string) => {
    try {
      const response = await apiClient.fetchDocumentPAID(inputValue);
      const newOptions: SelectOption[] = response.map((option) => ({
        id: option.id,
        label: option.docNumber,
      }));

      runInAction(() => {
        this.documentPAID = newOptions;
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

fetchAllOptions = async () => {
    try {
        await runInAction(async () => {            
            await this.fetchProjectOptions('');
            await this.fetchAreaOptions('');
            await this.fetchUnitOptions('');
            await this.fetchFacilityOptions('');
            await this.fetchTagNumberOptions('');
            await this.fetchEqTypeOptions('');
            await this.fetchTagCategoryOptions('');
            await this.fetchParentTagNumberOptions('');
            await this.fetchTagTypeOptions('');
            await this.fetchEquipmentStatusOptions('');
            await this.fetchLocationOptions('');
            await this.fetchBuildingOptions('');
            await this.fetchWellOptions('');
            await this.fetchProjectTagNumberCategoryOptions('');
            await this.fetchUnitOfMeasuresOptions('');
            await this.fetchProjectsFromProjectEquipments('');
            await this.fetchUsersOptions('');
            await this.fetchDocumentDatasheetsOptions('');
            await this.fetchDocumentPAIDOptions('');
        });  
    } catch (error) {
        console.error("Error fetching options:", error);
    }
};
}

export default DictionaryStore;
