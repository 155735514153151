import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { DataGridBox } from "../../../../styles/dataGridBox";

const columns: GridColDef[] = [
  {
    field: "validation",
    headerName: "Type",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    minWidth: 100,
  },
];

const TagDetailValidationPopupTab = observer(() => {
  let id = 0;

  const { projectAreaCardViewStore } = useStores();

  return (
    <DataGridBox>
      <DataGrid
        getRowId={(row) => id++}
        columns={columns}
        rows={projectAreaCardViewStore.tagValidations ?? []}
        density="comfortable"
        checkboxSelection
      />
    </DataGridBox>
  );
});

export default TagDetailValidationPopupTab;
