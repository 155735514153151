import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { useMemo } from "react";
import { formatDateWithTime } from "../../../../utils/formatDate";
import formatBoolean from "../../../../utils/formatBoolean";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";

const useMasterGridColumns = () => {
  
  const navigate = useNavigate();

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "createdOn", headerName: "Created On", flex: 1, minWidth: 120, valueFormatter: formatDateWithTime },
      { field: "createdBy", headerName: "Created by", flex: 1, minWidth: 200 },
      { field: "category", headerName: "Category", flex: 1, minWidth: 170 },
      { field: "status", headerName: "Status", flex: 1, minWidth: 150 },
      { field: "controlAutonaming", headerName: "Control Autonaming", flex: 1, minWidth: 150, valueFormatter: formatBoolean },
      {
        field: "tagNumber",
        headerName: "Tag Number",
        flex: 1,
        minWidth: 250,
        renderCell: (params) => {
          return (
            <>
              <a href="#" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditTag + "?id=" + params.row.id)}>
                {params.value}
              </a>
            </>
          );
        }
      },
      { field: "disciplineTagNumber", headerName: "Discipline Tag Number", flex: 1, minWidth: 250 },
      { field: "primaryTagNumber", headerName: "Primary Tag Number", flex: 1, minWidth: 250 },
      { field: "parentTagNumber", headerName: "Parent Tag Number", flex: 1, minWidth: 180, },
      { field: "facility", headerName: "Facility", flex: 1, minWidth: 120 },
      { field: "area", headerName: "Area", flex: 1, minWidth: 80 },
      { field: "unit", headerName: "Unit", flex: 1, minWidth: 80 },
      {
        field: "equipmentType",
        headerName: "Equipment Type",
        flex: 1,
        minWidth: 200,
      },
      { field: "sequenceNumber", headerName: "Sequence Number", flex: 1, minWidth: 150 },
      { field: "suffix", headerName: "Suffix", flex: 1, minWidth: 50 },
      { field: "well", headerName: "Well", flex: 1, minWidth: 100 },
      { field: "substation", headerName: "Substation", flex: 1, minWidth: 100 },
      { field: "nominalDiameter", headerName: "Nominal Diameter", flex: 1, minWidth: 150 },
      { field: "pipeClass", headerName: "Pipe Class", flex: 1, minWidth: 100 },
      { field: "fluid", headerName: "Fluid", flex: 1, minWidth: 100 },
      { field: "location", headerName: "Location", flex: 1, minWidth: 100 },
      { field: "building", headerName: "Building", flex: 1, minWidth: 100 },
      { field: "insulationType", headerName: "Insulation Type", flex: 1, minWidth: 120 },
      { field: "circuitNumber", headerName: "Circuit Number", flex: 1, minWidth: 120, },
      { field: "fittingNumber", headerName: "Fitting Number", flex: 1, minWidth: 120 },
      { field: "serviceDescription", headerName: "Service Description", flex: 1, minWidth: 150 },
      { field: "projectNumber", headerName: "Project Number", flex: 1, minWidth: 150 },
      { field: "requestNumber", headerName: "Request Number", flex: 1, minWidth: 150 },
      { field: "parallelTagNumber", headerName: "Parallel Tag Number", flex: 1, minWidth: 150 },
      { field: "mocNumber", headerName: "MOC Number", flex: 1, minWidth: 120, },
      { field: "remarks", headerName: "Remarks", flex: 1, minWidth: 250 },
      { field: "exInspectionRequired", headerName: "Ex Inspection Required", flex: 1, minWidth: 180 },
      { field: "exRegister", headerName: "Ex Register", flex: 1, minWidth: 150 },
      { field: "modifiedOn", headerName: "Modified On", flex: 1, minWidth: 120 },
      { field: "modifiedBy", headerName: "Modified by", flex: 1, minWidth: 200 }   
    ],
    []
  );

  return columns;
};

export default useMasterGridColumns;
