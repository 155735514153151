import { makeAutoObservable, runInAction } from "mobx";
import { GetMasterAreaTagAttributesResponse } from "../../models/MasterArea/response/getMasterAreaTagAttributesResponse";
import { GetMasterAreaTagAttributeByIdResponse } from "../../models/MasterArea/response/getMasterAreaTagAttributeByIdResponse";
import { GetMasterAreaTagAttributesRequest } from "../../models/MasterArea/request/getMasterAreaTagAttributesRequest";
import apiClient from "../../api/clients";

class MasterAreaTagAttributesListStore {
  tagAttributesResponse: GetMasterAreaTagAttributesResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  tagAttributeResponse: GetMasterAreaTagAttributeByIdResponse | null = null;

  paginationModel = {
    pageSize: 250,
    page: 0,
  };
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  fetchTagAttributes = async () => {    
    const { page, pageSize } = this.paginationModel;

    const request: GetMasterAreaTagAttributesRequest = {
      pagination: { pageIndex: page, pageSize }
    };

    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAreaTagAttributes(request);
      runInAction(() => {
        this.tagAttributesResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchTagAttributeById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAreaTagAttributeById(id);
      runInAction(() => {
        this.tagAttributeResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

}

export default MasterAreaTagAttributesListStore;
