import { useState } from "react";
import { Typography, Stack, TextField, Tabs, Tab, Box, Modal } from "@mui/material";
import CustomTabPanel from "./CustomTabPanel";

import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import RelatedTagsPopupTab from "../popup tabs/RelatedTagsPopupTab";
import TagDetailDocumentationPopupTab from "../popup tabs/TagDetailDocumentationPopupTab";
import TagDetailExPopupTab from "../popup tabs/TagDetailExPopupTab";
import TagDetailCMMSPopupTab from "../popup tabs/TagDetailCMMSPopupTab";
import TagDetailAttributesPopupTab from "../popup tabs/TagDetailAttributesPopupTab";
import {
  smallTextFieldStyle,
  textFieldStyle,
  photoStyle,
  remarksStyle,
  documentNumberFieldStyle,
  longTextFieldStyle,
} from "../styles/styles";

import Close from "@mui/icons-material/Close";
import TagDetailValidationPopupTab from "../popup tabs/TagDetailValidationPopupTab";

export const modalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1200px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const innerbox = {
  height: "550px",
  width: "1150px",
  overflow: "auto",
  padding: "10px 0 0 0",
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TagDetailPopup = observer(() => {
  const [value, setValue] = useState(4);
  const {
    projectAreaPopupCardViewStore: { assetDetail, setShowCardModal, showCardModal },
  } = useStores();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCancelClick = () => {
    setShowCardModal(false);
  };

  return assetDetail ? (
    <Modal
      open={showCardModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalstyle}>
        <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {assetDetail.tagNumber}
          </Typography>
          <Close
            sx={{ position: "absolute", top: "35px", right: "15px", cursor: "pointer" }}
            onClick={handleCancelClick}
          />
        </Stack>
        <Box sx={innerbox}>
          <Stack direction="row">
            <Stack direction="column">
              <Stack direction="row">
                <TextField
                  variant="outlined"
                  size="small"
                  label="Facility"
                  value={assetDetail.facilityCode ?? "-"}
                  sx={smallTextFieldStyle}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  label="Area"
                  value={assetDetail.areaCode ?? "-"}
                  sx={smallTextFieldStyle}
                />
              </Stack>
              <TextField
                variant="outlined"
                size="small"
                label="Service Description"
                value={assetDetail.serviceDescription ?? "-"}
                sx={longTextFieldStyle}
              />
            </Stack>
            <Stack direction="column">
              <TextField
                variant="outlined"
                size="small"
                label="Unit"
                value={assetDetail.unitCode ?? "-"}
                sx={smallTextFieldStyle}
              />
              <TextField
                variant="outlined"
                size="small"
                label="Equipment Status"
                value={assetDetail.equipmentStatus}
                sx={smallTextFieldStyle}
              />
            </Stack>
            <TextField
              variant="outlined"
              size="small"
              label="Placeholder for photo..."
              multiline
              rows="3"
              sx={photoStyle}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Tag Category"
              value={assetDetail.tagCategoryName ?? "-"}
              sx={textFieldStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Equipment Type"
              value={assetDetail.equipmentTypeCode ?? "-"}
              sx={textFieldStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="System Name"
              value={assetDetail.systemName ?? "-"}
              sx={textFieldStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Sub System"
              value={assetDetail.subSystem ?? "-"}
              sx={textFieldStyle}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Parent Tag Number"
              value={assetDetail.parentTagNumber ?? "-"}
              sx={textFieldStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Manufacturer"
              value={assetDetail.manufacturer ?? "-"}
              sx={textFieldStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Model Number"
              value={assetDetail.modelNumber ?? "-"}
              sx={textFieldStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Purchase Order Number"
              value={assetDetail.purchaseOrderNumber ?? "-"}
              sx={textFieldStyle}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Serial Number"
              value={assetDetail.serialNumber ?? "-"}
              sx={textFieldStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Is Maintainable"
              value={assetDetail.isMaintainable ?? "-"}
              sx={textFieldStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Ex Register"
              value={assetDetail.exRegister}
              sx={textFieldStyle}
            />
            <TextField
              variant="outlined"
              size="small"
              label="Ex Inspection Required"
              value={assetDetail.exInspectionRequired}
              sx={textFieldStyle}
            />
          </Stack>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Remarks"
              multiline
              rows="3"
              value={assetDetail.remarks ?? "-"}
              sx={remarksStyle}
            />
            <Stack direction="column">
              <TextField
                variant="outlined"
                size="small"
                label="Datasheet"
                value={assetDetail.datasheet ?? "-"}
                sx={documentNumberFieldStyle}
              />
              <TextField
                variant="outlined"
                size="small"
                label="P&ID"
                value={assetDetail.pipingAndInstrumentationDiagram ?? "-"}
                sx={documentNumberFieldStyle}
              />
            </Stack>
          </Stack>
          <Box sx={{ width: "100%", marginBottom: 4 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="Detail-Scrollable-Tags"
              >
                <Tab label="RELATED TAGS" {...a11yProps(0)} />
                <Tab label="ATTRIBUTES" {...a11yProps(1)} />
                <Tab label="DOCUMENTATION" {...a11yProps(2)} />
                <Tab label="EX" {...a11yProps(3)} />
                <Tab label="VALIDATION" {...a11yProps(4)} />
                <Tab label="CMMS" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <RelatedTagsPopupTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TagDetailAttributesPopupTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <TagDetailDocumentationPopupTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <TagDetailExPopupTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <TagDetailValidationPopupTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <TagDetailCMMSPopupTab />
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </Modal>
  ) : null;
});

export default TagDetailPopup;
