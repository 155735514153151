import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import Button from "@mui/material/Button";
import RefreshIcon from '@mui/icons-material/Refresh';

const MasterAreaRefreshPage = observer(() => {
  const { masterAssetsStore } = useStores();

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<RefreshIcon />}
        onClick={() => masterAssetsStore.fetchMasterAssets()}
        >
        Refresh
      </Button>
    </>
  );
});

export default MasterAreaRefreshPage;
