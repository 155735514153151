import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid item pt={2}>
        <Typography
          variant="h1"
          align="center"
          sx={{
            fontWeight: "900",
            fontSize: "12em",
            mb: 2,
            background: 'rgb(189, 189, 189)',
            color: "transparent",
            backgroundClip: "text",
            textShadow: "0px 1.63px 5.7px rgba(255, 255, 255)",
            backdropFilter: 'blur(0.407492px)'
          }}
        >
          404
        </Typography>
        <Typography align="center" sx={{ fontSize: "23px" }}>
          The page you were looking for was moved or doesn&apos;t exist
        </Typography>
      </Grid>
      <Grid item pt={2}>
        <Button variant="contained" component={Link} to="/">
          To the start page
        </Button>
      </Grid>
    </Grid>
  );
};

export default Error404;
