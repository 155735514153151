import { observer } from "mobx-react";
import TagDetailEditTagValidation from "./TagDetailEditTagValidation";
import { GridToolbarContainer } from "@mui/x-data-grid";

const TagDetailTagValidationToolBar = observer(() => {
  return (
    <GridToolbarContainer>
      {<TagDetailEditTagValidation />}
    </GridToolbarContainer>
  );
});

export default TagDetailTagValidationToolBar;
