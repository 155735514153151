import { makeAutoObservable } from "mobx";
import apiClient from "../../api/clients";
import { CreateRelatedTagRequest } from "../../models/ProjectArea/request/createRelatedTagRequest";
import { DeleteRelatedTagRequest } from "../../models/ProjectArea/request/deleteRelatedTagRequest";
import { SelectOption } from "../../models/common/selectOption";

class ProjectAreaCardViewRelatedTagStore {
  selectedIds: number[] = [];
  selectedId: number | null = null;
  validationErrors?: string;
  showAddRelatedTagModal = false;
  selectedRelatedTag: SelectOption | null = null;
  selectedRelationType: SelectOption | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedIds = (ids: number[]) => {
    this.selectedIds = ids;
    this.setSelectedId(ids.length > 0 ? ids[0] : null);
  };

  setSelectedId(id: number | null) {
    this.selectedId = id;
  }

  setValidationErrors = (value?: string) => {
    this.validationErrors = value;
  };

  setShowAddRelatedTagModal = (value: boolean) => {
    this.showAddRelatedTagModal = value;
  };

  setSelectedRelationType = (value: SelectOption | null) => {
    this.selectedRelationType = value;
  };

  setSelectedRelatedTag = (value: SelectOption | null) => {
    this.selectedRelatedTag = value;
  };

  private handleSuccessfulResponse(): void {
    this.setShowAddRelatedTagModal(false);
    this.setValidationErrors();
    this.setSelectedRelationType(null);
    this.setSelectedRelatedTag(null);
  }

  async deleteRelatedTag(data: DeleteRelatedTagRequest) {
    try {
      await apiClient.deleteRelatedTag(data);
    } catch (error) {
      console.error(error);
    }
  }

  async createRelatedTag(data: CreateRelatedTagRequest): Promise<void> {
    try {
      const response = await apiClient.createRelatedTag(data);
      if (response.ok) {
        this.handleSuccessfulResponse();
      } 
      else if(response.errors?.errorParentTagAlreadyExists?.length > 0) {
        if(data.relationType === 1) {
          this.setValidationErrors("Parent Tag for selected Child Tag already exists!");
        }
        else {
          this.setValidationErrors("Parent Tag for selected Tag already exists!");
        }
      }
      else {
        this.setValidationErrors("Unexpected error while creating related Tag!");
      }
    } 
    catch (error) {
      this.setValidationErrors("Error while creating related Tag. " + error);
    }
  }
}

export default ProjectAreaCardViewRelatedTagStore;
