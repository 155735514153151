export interface GetColumnConfigResponse {
    id: number;
    name: string;
    isEditable: boolean;
    isVisible: boolean;
    area: ColumnConfigArea | null;
}

export enum ColumnConfigArea {
    MasterCardArea = 0,
    MasterGridArea = 1,
    ProjectCardArea = 2,
    ProjectGridArea = 3
}