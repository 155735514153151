import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Popover,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useState } from "react";
import { useStores } from "../../../hooks/useStores";
import { useDebounce } from "../../../hooks/useDebounce";

const CustomFilterButtonTagAudit = observer(() => {
  const { tagAuditStore, dictionaryStore } = useStores();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const debouncedHandleInputChange = useDebounce(500);

  const handleTagAuditProjectInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectsFromProjectEquipments(inputValue);
    });
  };

  const handleProjectSystemnameInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectSystemnames(inputValue);
    });
  };

  const handleProjectSubsystemInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectSubsystems(inputValue);
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetFilters = () => {
    dictionaryStore.fetchProjectsFromProjectEquipments("");
    dictionaryStore.fetchProjectSubsystems("");
    dictionaryStore.fetchProjectSystemnames("");

    tagAuditStore.setSelectedTagAuditProjectOption(null);
    tagAuditStore.setSelectedProjectSystemnameOption("");
    tagAuditStore.setSelectedProjectSubsystemOption("");
  };

  const handleResetButtonClick = () => {
    resetFilters();
  };

  return (
    <>
      <Button
        startIcon={<FilterListIcon />}
        variant="text"
        size="small"
        onClick={handleClick}
      >
        <span>Filter</span>
      </Button>
      <Popover
        keepMounted
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ mt: "5px" }}
      >
        <Box
          sx={{
            pl: 2,
            pt: 2,
            pr: 2,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Autocomplete
            size="small"
            id="projects"
            options={dictionaryStore.projectTagAuditOptions}
            value={tagAuditStore.selectedTagAuditProjectOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: 170,
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => <TextField {...params} label="Projects" />}
            onInputChange={(event, newInputValue) => {
              handleTagAuditProjectInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              tagAuditStore.setSelectedTagAuditProjectOption(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="systemname"
            options={dictionaryStore.projectSystemnameOptions}
            value={tagAuditStore.selectedProjectSystemnameOption ?? ""}
            getOptionLabel={(option) => (option ? option.label : "")}
            sx={{
              width: 170,
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => <TextField {...params} label="System" />}
            onInputChange={(event, newInputValue) => {
              handleProjectSystemnameInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              tagAuditStore.setSelectedProjectSystemnameOption(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <Autocomplete
            size="small"
            id="subsystem"
            options={dictionaryStore.projectSubsystemOptions}
            value={tagAuditStore.selectedProjectSubsystemOption ?? ""}
            getOptionLabel={(option) => (option ? option.label : "")}
            sx={{
              width: 170,
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Subsystem" />
            )}
            onInputChange={(event, newInputValue) => {
              handleProjectSubsystemInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              tagAuditStore.setSelectedProjectSubsystemOption(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            pt: 1,
            pl: 2,
            pr: 2,
            pb: 1,
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Button variant="contained" onClick={handleResetButtonClick}>
            Clear
          </Button>
        </Box>
      </Popover>
    </>
  );
});

export default CustomFilterButtonTagAudit;
