import { observer } from "mobx-react";
import React from "react";
import { useStores } from "../../../../../hooks/useStores";
import { Box, Button, Modal } from "@mui/material";
import ImportFromExcelMasterTagAttributesButton from "./ImportFromExcelMasterTagAttributesButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const ImportFromExcelMasterAreaModal = observer(() => {
  const { masterAreaImportFromExcel } = useStores();

  const handleClose = () => {
    masterAreaImportFromExcel.setShowImportFromExcelModal(false);
    masterAreaImportFromExcel.setImportResponse("");
  };

  return (
    <Modal
      open={masterAreaImportFromExcel.showImportFromExcelModal}
      onClose={handleClose}
      aria-labelledby="import-from-excel-modal"
      aria-describedby="import-from-excel-modal-description"
    >
      <Box sx={style}>
        <ImportFromExcelMasterTagAttributesButton />
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            sx={{ ml: 2, mr: 3 }}
            onClick={handleClose}
          >
            <span>Cancel</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default ImportFromExcelMasterAreaModal;
