import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Tabs,
  Tab
} from "@mui/material";
import { useState, useEffect } from "react";
import usePageTitle from "../../../hooks/usePageTitle";
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CustomTabPanel from "../../../components/common/CustomTabPanel";
import { NullableString } from "../../../models/common/response/types";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const MasterAreaEditTagAttribute = observer(() => {
  usePageTitle("Master Area - Tag Attribute");

  const navigate = useNavigate();

  const [urlParams] = useSearchParams()

  const { masterAreaTagAttributesListStore } = useStores();

  const [tabValue, setValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [equipmentTag, setEquipmentTag] = useState<NullableString>(null);
  const [tagAttributeClass, setTagAttributeClass] = useState<NullableString>(null);
  const [tagAttributeValue, setTagAttributeValue] = useState<NullableString>(null);
  const [uom, setUom] = useState<NullableString>(null);

  useEffect(() => {
    (async () => {   
      await initTagAttribute();
    })()
  }, []);

  const initTagAttribute = async () => {

    const id = urlParams.get("id");
    await masterAreaTagAttributesListStore.fetchTagAttributeById(parseInt(id!));

    setEquipmentTag(masterAreaTagAttributesListStore.tagAttributeResponse?.masterTagNumber);
    setTagAttributeClass(masterAreaTagAttributesListStore.tagAttributeResponse?.tagAttributeName);
    setTagAttributeValue(masterAreaTagAttributesListStore.tagAttributeResponse?.tagAttributeValue);
    setUom(masterAreaTagAttributesListStore.tagAttributeResponse?.uomName);
  }
  
  const handleCloseClick = () => {
    navigate(ROUTES.masterAreaGridAttributes);
  };

  return (
    masterAreaTagAttributesListStore.tagAttributeResponse?.id ?
    <form>
    <Box
          sx={ areaboxStyle }
        > 
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "50%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
          </Box>
        </Stack>
      </Box>
    {    
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
    <div style={ areaboxStyle }>
      <Tabs
        value={tabValue}
        variant="scrollable"
        aria-label="New-Tag-Tabs"
        onChange={handleTabChange}
      >
        <Tab label="General" />
      </Tabs>    
    </div>
    <CustomTabPanel value={tabValue} index={0}>
        <Box
          sx={ areaboxStyle }
        >  
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Equipment Tag"
            value={equipmentTag + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Tag Attribute Class"
            value={tagAttributeClass + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Tag Attribute Value"
            value={tagAttributeValue + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Unit of Measure"
            value={uom + ""}
            sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
            disabled
          />
        </Stack>
        </Box>
      </CustomTabPanel>
      </Box>
      }
      </form>
      :
      null
  );
});

export default MasterAreaEditTagAttribute;
