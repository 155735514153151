import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  Stack,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { SelectOption } from "../../../models/common/selectOption";
import { useCreateTagFetchOptionsData } from "./hooks/useFetchOptionsData";
import { createBBTag, createBBTagNoClose, checkDuplicateBBTag } from "./hooks/useFetchRequests";
import usePageTitle from "../../../hooks/usePageTitle";
import CustomTabPanel from "../../../components/common/CustomTabPanel"
import { areaboxStyle} from "../../../constants/styles";
import { useNavigate, useBlocker } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { TagLimitOptions } from "./constants/constants";
import { CreateBBTagRequest } from "../../../models/TagRequest/request/createBBTagRequest";
import { CheckDuplicateBBTag } from "../../../models/TagRequest/request/checkDuplicateBBTag";
import { a11yProps, validationColor, isPrimaryTagRequired, disciplineTagNumberColor, getPrimaryTagLabel,
         isFittingNumberRequired, isCircuitNumberRequired, isBuildingRequired, buildingColor, isLocationRequired, isInsulationTypeRequired,
         isNominalDiameterRequired, isPipeClassRequired, isFluidRequired, isServiceDescriptionRequired, validationColorWithControlAutonaming,
         validationPrimaryTagColor, isSubstationRequired } from "./utils/utils"
import MasterTagDetailAttributesTab from "./tabs/MasterTagDetailAttributesTab";
import MasterTagDetailDocumentsTab from "./tabs/MasterTagDetailDocumentsTab";
import MasterRelatedTagsTab from "./tabs/MasterTagDetailRelatedTagsTab";
import { NullableString } from "../../../models/common/response/types";
import ConfirmationDialogModal from "../../../components/common/ConfirmationDialog";
import DuplicateTagWarningModal from "../gridView/components/DuplicateTagWarningModal";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const MasterAreaAddTag = observer(() => {
  usePageTitle("Master Area - New Tag");

  const navigate = useNavigate();

  const { masterAssetsStore, masterAreaCardViewStore, notificationStore, dictionaryStore } = useStores();

  const [tabValue, setValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

  const [showSaveAndCloseConfirmationDialog, setShowSaveAndCloseConfirmationDialog] = useState<boolean>(false);
  const [saveWithoutDialog, setSaveWithoutDialog] = useState<boolean>(false);

  const [sequenceNumber, setSequenceNumber] = useState<NullableString>(null);
  const [requestNumber, setRequestNumber] = useState<NullableString>(null);
  const [isControlAutonaming, setIsControlAutonaming] = useState<boolean>(true);
  const [disciplineTagNumber, setDisciplineTagNumber] = useState<NullableString>(null);
  const [serviceDescription, setServiceDescription] = useState<NullableString>(null);
  const [suffix, setSuffix] = useState<NullableString>(null);
  const [remarks, setRemarks] = useState<NullableString>(null);
  const [multipleTags, setMultipleTags] = useState<NullableString>(null);
  const [tagRequestCategoryOption, setTagRequestCategoryOption] = useState<SelectOption | null>(null);
  const [projectOption, setProjectsOption] = useState<SelectOption | null>(null);
  const [facilityOption, setFacilityOption] = useState<SelectOption | null>(null);
  const [areaOption, setAreaOption] = useState<SelectOption | null>(null);
  const [unitOption, setUnitOption] = useState<SelectOption | null>(null);
  const [equipmentTypeOption, setEquipmentTypeOption] = useState<SelectOption | null>(null);
  const [buildingOption, setBuildingOption] = useState<SelectOption | null>(null);
  const [wellOption, setWellOption] = useState<SelectOption | null>(null);
  const [parentTagOption, setParentTagOption] = useState<SelectOption | null>(null);
  const [tagLimitOption, setTagLimitOption] = useState<SelectOption | null>(null);
  const [documents, setDocuments] = useState<NullableString>(null);
  const [primaryTagOption, setPrimaryTagOption] = useState<SelectOption | null>(null);
  const [insulationType, setInsulationType] = useState<NullableString>(null);
  const [circuitNumber, setCircuitNumber] = useState<NullableString>(null);
  const [fluidOption, setFluidOption] = useState<SelectOption | null>(null);
  const [fittingNumber, setFittingNumber] = useState<NullableString>(null);
  const [pipeClassOption, setPipeClassOption] = useState<SelectOption | null>(null);
  const [nominalDiameter, setNominalDiameter] = useState<NullableString>(null);
  const [locationOption, setLocationOption] = useState<SelectOption | null>(null);
  const [substationOption, setSubstationOption] = useState<SelectOption | null>(null);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && (!showSaveAndCloseConfirmationDialog || saveWithoutDialog) && isFormChanged
  );

  useCreateTagFetchOptionsData(dictionaryStore); 

  const debouncedHandleInputChange = useDebounce(500);

  const handlePrimaryTagChange = async (selectedOption: SelectOption) => {
    setPrimaryTagOption(selectedOption);
    if(selectedOption) {
      const primaryTag = dictionaryStore.primaryTagExtendedOptions.filter(p => p.id === selectedOption.id)[0];
      setSequenceNumber(primaryTag.sequence);
      /*if (primaryTag.tagTypeId) {
        const eqType = await dictionaryStore.fetchEqTypeById(primaryTag.tagTypeId);
        setEquipmentTypeOption({id: primaryTag.tagTypeId, label: eqType});
      } */
      if (primaryTag.facilityId) {
        const facility = dictionaryStore.facilityOptions.filter(f => f.id === primaryTag.facilityId)[0];
        setFacilityOption(facility);
        if (primaryTag.areaId) {
          const area = dictionaryStore.areaOptions.filter(a => a.id === primaryTag.areaId)[0];
          setAreaOption(area);
          if (primaryTag.unitId) {
            await dictionaryStore.fetchUnitOptions("", area?.id);
            const unit = dictionaryStore.unitOptions.filter(u => u.id === primaryTag.unitId)[0];   
            setUnitOption(unit);
          } 
        }
      }      
    }
  };
  
  const handleProjectInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectOptions(inputValue);
    });
  };

  const handleFacilityInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFacilityOptions(inputValue);
    });
  };

  const updateFacilityOption = async (selectedValue: SelectOption) => {
    if(!selectedValue) {
      setFacilityOption(null);
      setAreaOption(null);
      setUnitOption(null);
      setBuildingOption(null);
      setWellOption(null);
    }
    else setFacilityOption(selectedValue);
  };

  const handleAreaInputChange = async (inputValue: string, facilityId?: number, categoryCode?: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchAreaOptions(inputValue, facilityId, categoryCode);
    });
  };

  const updateAreaOption = async (selectedValue: SelectOption) => {
    if(!selectedValue) {
      setAreaOption(null);
      setUnitOption(null);
      setBuildingOption(null);
      setWellOption(null);
    }
    else setAreaOption(selectedValue);
  };

  const handleUnitInputChange = async (inputValue: string, areaId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOptions(inputValue, areaId);
    });
  };

  const updateUnitOption = async (selectedValue: SelectOption) => {
    if(!selectedValue) {
      setUnitOption(null);
      setBuildingOption(null);
      setWellOption(null);
    }
    else setUnitOption(selectedValue);
  };

  const handleEquipmentTypeAndPrimaryTagChange = async (inputValue: string, tagRequestCategoryId?: number, tagRequestCategoryCode?: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEqTypeOptions(inputValue, tagRequestCategoryId);
      dictionaryStore.fetchPrimaryTagNumberByCategoryOptions(inputValue, tagRequestCategoryCode);
    });
  };

  const handleEquipmentTypeInputChange = async (inputValue: string, tagRequestCategoryId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEqTypeOptions(inputValue, tagRequestCategoryId);
    });
  };

  const handleBuildingInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchBuildingOptions(inputValue);
    });
  };

  const handleWellInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchWellOptions(inputValue);
    });
  };

  const handleParentTagInputChange = async (inputValue: string, categoryId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchParentTagNumberByCategoryOptions(inputValue, categoryId);
    });
  };

 const handleTagCategoryInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagCategoryOptions(inputValue);
    });
  }; 

  const updateTagCategoryOption = async (selectedValue: SelectOption) => {
    if(!selectedValue) {
      setTagRequestCategoryOption(null);
      setPrimaryTagOption(null);
      setEquipmentTypeOption(null);
      setDisciplineTagNumber(null);
      setServiceDescription(null);
      setSuffix(null);
      setRemarks(null);
      setSequenceNumber(null);
      setRequestNumber(null);
      setFacilityOption(null);
      setAreaOption(null);
      setUnitOption(null);
      setProjectsOption(null);
      setBuildingOption(null);
      setWellOption(null);
      setParentTagOption(null);
      setPipeClassOption(null);
      setFluidOption(null);     
      setLocationOption(null);
      setSubstationOption(null);  
      setInsulationType(null);
      setNominalDiameter(null);
      setCircuitNumber(null);
      setFittingNumber(null);
      setMultipleTags(null);
      setTagLimitOption(null);
    }
    else setTagRequestCategoryOption(selectedValue);
  };

  const handlePrimaryTagInputChange = async (inputValue: string, categoryCode?: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchPrimaryTagNumberByCategoryOptions(inputValue, categoryCode);
    });
  };

  const handlePipeClassInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchPipeClassesOptions(inputValue);
    });
  };

  const handleFluidInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFluidsOptions(inputValue);
    });
  };

  const handleLocationInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchLocationOptions(inputValue);
    });
  };

  const handleSubstationInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchSubstationOptions(inputValue);
    });
  };
  
  const handleCloseClick = () => {
    navigate(ROUTES.masterAreaGrid);
  };

  const buildDuplicateBBTag = () : CheckDuplicateBBTag => {
    const duplicateBBTag: CheckDuplicateBBTag = {
      masterEquipmentId: null,
      categoryId: tagRequestCategoryOption?.id,
      categoryCode: tagRequestCategoryOption?.code,
      tagTypeId: equipmentTypeOption?.id,
      facilityId: facilityOption?.id,
      areaId: areaOption?.id,
      unitId: unitOption?.id,
      buildingId: buildingOption?.id,
      wellId: wellOption?.id,
      suffix: suffix,
      multipleTags: parseInt(multipleTags!),
      tagLimit: tagLimitOption?.id,
      sequenceNumber: sequenceNumber,
      locationId: locationOption?.id,
      disciplineTagNumber: disciplineTagNumber,
      controlAutonaming: isControlAutonaming,
      primaryTagId: primaryTagOption?.id,
      nominalDiameter: nominalDiameter,
      pipeClassId: pipeClassOption?.id,
      insulationType: insulationType,
      circuitNumber: circuitNumber,
      fittingNumber: fittingNumber,
      fluidId: fluidOption?.id
    };

    return duplicateBBTag;
  };

  const confirmSave = async () => {  
    const duplicateBBTag = buildDuplicateBBTag();
    const isDuplicate = await checkDuplicateBBTag(masterAssetsStore, duplicateBBTag);
    
    if(isDuplicate) {
      masterAreaCardViewStore.setShowDuplicateWarningModal(true);
    }
    else handleSaveClick();
  }; 

  const confirmSaveAndClose = async () => {  
    const duplicateBBTag = buildDuplicateBBTag();
    const isDuplicate = await checkDuplicateBBTag(masterAssetsStore, duplicateBBTag);
    
    if(isDuplicate) {
      masterAreaCardViewStore.setShowDuplicateWarningModal(true);
    }
    else setShowSaveAndCloseConfirmationDialog(true);
  };

  const buildBBTag = () : CreateBBTagRequest => {
    const createBBTag: CreateBBTagRequest = {
      tagRequestId: null, 
      categoryId: tagRequestCategoryOption?.id,
      categoryCode: tagRequestCategoryOption?.code,
      projectId: projectOption?.id,
      parentTagId: parentTagOption?.id,
      facilityId: facilityOption?.id,
      tagTypeId: equipmentTypeOption?.id,
      areaId: areaOption?.id,
      unitId: unitOption?.id,
      buildingId: buildingOption?.id,
      wellId: wellOption?.id,
      documents: documents,
      serviceDescription: serviceDescription,
      suffix: suffix,
      multipleTags: parseInt(multipleTags!),
      tagLimit: tagLimitOption?.id,
      remarks: remarks,
      sequenceNumber: sequenceNumber,
      locationId: locationOption?.id,
      disciplineTagNumber: disciplineTagNumber,
      controlAutonaming: isControlAutonaming,
      documentId: null,
      primaryTagId: primaryTagOption?.id,
      nominalDiameter: nominalDiameter,
      pipeClassId: pipeClassOption?.id,
      insulationType: insulationType,
      circuitNumber: circuitNumber,
      fittingNumber: fittingNumber,
      requestNumber: requestNumber,
      fluidId: fluidOption?.id,
      substationId: substationOption?.id
    };

    return createBBTag;
  };

  const handleSaveClick = async () => { 
    setSaveWithoutDialog(true);
    setIsFormChanged(false);
    setIsSavingInProgress(true);

    const createTag = buildBBTag(); 
    const newbbTagId = await createBBTagNoClose(masterAssetsStore, notificationStore, createTag);

    navigate(ROUTES.masterAreaEditTag + "?id=" + newbbTagId);
  }; 

  const handleSaveAndCloseClick = async () => {  
    const createTag = buildBBTag();
    createBBTag(masterAssetsStore, notificationStore, createTag);

    navigate(ROUTES.masterAreaGrid);
  }; 

  const handleSaveOnLeaveClick = async () => {  
    if(masterAreaCardViewStore.isValidForm) {
      const createTag = buildBBTag();  
      createBBTag(masterAssetsStore, notificationStore, createTag);

      blocker?.proceed?.();
    }
    else {
      blocker?.reset?.();
    }
  }; 

  const handleLeaveWithoutSavingClick = async () => {  
    blocker?.proceed?.();
  }; 

  return (
    isSavingInProgress ? <div style={{ margin: "0 10px 0 0", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}><CircularProgress /></div> :
    <form onChange={() => setIsFormChanged(true)}>
     <Box sx={ areaboxStyle }> 
          <Box
            sx={{
              backgroundColor: "#fff",
              display: { xs: "block", md: "flex", width: "98%" }
            }}
          >
            <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
              <ArrowBackOutlinedIcon />
            </Button>
            <Button 
              variant="contained" 
              disabled={!masterAreaCardViewStore.isValidForm} 
              sx={{ ml: 2 }} 
              onClick={() => confirmSave()}>
              <span>Save</span>
            </Button> 
            <Button 
              variant="contained" 
              disabled={!masterAreaCardViewStore.isValidForm} 
              sx={{ ml: 2 }} 
              onClick={() => confirmSaveAndClose()}>
              <span>Save & Close</span>
            </Button>
          </Box>
        </Box>
    <Box sx={{ "overflow-y": "scroll", height: "80vh" }}> 
    <div style={ areaboxStyle }>
      <Tabs
        value={tabValue}
        variant="scrollable"
        aria-label="New-Tag-Tabs"
        onChange={handleTabChange}
      >
        <Tab label="General" {...a11yProps(0)} />
        <Tab label="Related Tags" {...a11yProps(1)} />
        <Tab label="Attributes" {...a11yProps(2)}/>
        <Tab label="Documents" {...a11yProps(3)} />
      </Tabs>    
    </div>
    <CustomTabPanel value={tabValue} index={0}>
        <Box
          sx={ areaboxStyle }
        >   
        <Typography
          id="add-tag-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
        New Tag
        </Typography>  
        <Stack direction="row">
          <TextField
            variant="outlined"
            size="small"
            label="Tag Number"
            value={"-"}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            disabled
          />
          <TextField
            variant="outlined"
            size="small"
            label="Sequence Number"
            value={sequenceNumber ? sequenceNumber + "" : ""}
            sx={{ mr: 1, mb: 2, width: "50%" /*, "background-color": sequenceNumberColor(masterAreaCardViewStore, isControlAutonaming, sequenceNumber, tagRequestCategoryOption?.code) */}}
            onChange={(e) => setSequenceNumber(e.target.value)}
            disabled={primaryTagOption?.id != null}
          />
        </Stack>
        <Stack direction="row">  
        { 
          !isControlAutonaming ? 
          <TextField
            variant="outlined"
            size="small"
            label="Discipline Tag Number"
            value={disciplineTagNumber}
            sx={{ mr: 1, mb: 2, width: "50%", "background-color": disciplineTagNumberColor(masterAreaCardViewStore, isControlAutonaming, disciplineTagNumber) }}
            onChange={(e) => setDisciplineTagNumber(e.target.value)}
          /> : <div style={{ width: "51%" }}></div>
        }    
        <TextField
            variant="outlined"
            size="small"
            label="Suffix"
            value={suffix}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            onChange={(e) => setSuffix(e.target.value)}
          />
        </Stack>   
        <Stack direction="row">
        {
        isPrimaryTagRequired(tagRequestCategoryOption?.code) ?
            <Autocomplete
              disablePortal
              size="small"
              id="primaryTag"
              options={dictionaryStore.primaryTagOptions}
              value={primaryTagOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label={getPrimaryTagLabel(tagRequestCategoryOption?.code)} 
              sx={{"background-color": validationPrimaryTagColor(masterAreaCardViewStore, primaryTagOption?.label, tagRequestCategoryOption?.code, isControlAutonaming)}} />}
              onInputChange={(event, newInputValue) => {
                handlePrimaryTagInputChange(newInputValue, tagRequestCategoryOption?.code);
              }}
              onChange={(event, selectedValue) => {
                handlePrimaryTagChange(selectedValue);              
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              aria-required={true}
            />
          : <div style={{ width: "51%" }}></div>
          }
           <FormControlLabel control={
              <Checkbox value={isControlAutonaming} defaultChecked={true} onChange={(e) => setIsControlAutonaming(e.target.checked)} />
            } label="Control Autonaming"  sx={{ width: "50%" }} 
          /> 
        </Stack>   
        <Stack direction="row">  
        { 
          isControlAutonaming ? 
          <TextField
            variant="outlined"
            size="small"
            label="Generate Multiple Tags"
            type="number"
            value={multipleTags}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            onChange={(e) => setMultipleTags(e.target.value)}
          />  : null
        }
        {          
          isControlAutonaming ? 
          <Autocomplete
            disablePortal
            size="small"
            id="tagLimit"
            options={TagLimitOptions}
            value={tagLimitOption}
            getOptionLabel={(option) => option.label}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            renderInput={(params) => <TextField {...params} label="Tag Limit" />}
            onChange={(event, selectedValue) => {
              setTagLimitOption(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />  : null
        }  
        </Stack>
        
        </Box>
        <Box
          sx={ areaboxStyle }
        > 
          <Typography
            id="classification-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Classification
          </Typography> 
          <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="tagCategory"
              options={dictionaryStore.tagCategoryOptions}
              value={tagRequestCategoryOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%", "& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"} }}
              renderInput={(params) => <TextField {...params} label="Tag Category" sx={{"background-color": validationColor(masterAreaCardViewStore, tagRequestCategoryOption?.label)}}/>}
              onInputChange={(event, newInputValue) => {
                handleTagCategoryInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                updateTagCategoryOption(selectedValue);
                handleEquipmentTypeAndPrimaryTagChange("", selectedValue?.id, selectedValue?.code);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {
            isInsulationTypeRequired(tagRequestCategoryOption?.code) ?
            <TextField
              variant="outlined"
              size="small"
              label="Insulation Type"
              value={insulationType}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              onChange={(e) => setInsulationType(e.target.value)}
            /> : null
            }
            {
            isCircuitNumberRequired(tagRequestCategoryOption?.code) ?
            <TextField
                variant="outlined"
                size="small"
                label="Circuit Number"
                value={circuitNumber}
                sx={{ mr: 1, mb: 2, width: "50%", "background-color": validationColorWithControlAutonaming(masterAreaCardViewStore, circuitNumber, isControlAutonaming) }}
                onChange={(e) => setCircuitNumber(e.target.value)}
              /> : null
            }
          </Stack>
          <Stack direction="row">
          <Autocomplete
            disablePortal
            size="small"
            id="eqiupmentType"
            options={dictionaryStore.eqTypeOptions}
            value={equipmentTypeOption}
            getOptionLabel={(option) => option.label}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            renderInput={(params) => <TextField {...params} label="Type" sx={{"background-color": validationColor(masterAreaCardViewStore, equipmentTypeOption?.label)}}/>}
            onInputChange={(event, newInputValue) => {
              handleEquipmentTypeInputChange(newInputValue, tagRequestCategoryOption?.id);
            }}
            onChange={(event, selectedValue) => {
              setEquipmentTypeOption(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {
          isFittingNumberRequired(tagRequestCategoryOption?.code) ?
          <TextField
            variant="outlined"
            size="small"
            label="Fitting Number"
            value={fittingNumber}
            sx={{ mr: 1, mb: 2, width: "50%", "background-color": validationColorWithControlAutonaming(masterAreaCardViewStore, fittingNumber, isControlAutonaming) }}
            onChange={(e) => setFittingNumber(e.target.value)}
          /> : null
          }
          {
          isNominalDiameterRequired(tagRequestCategoryOption?.code) ?
          <TextField
            variant="outlined"
            size="small"
            label="Nominal Diameter"
            value={nominalDiameter}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            onChange={(e) => setNominalDiameter(e.target.value)}
          /> : null 
          }
        </Stack>
        <Stack direction="row">
          { 
          isFluidRequired(tagRequestCategoryOption?.code) ?
          <Autocomplete
            disablePortal
            size="small"
            id="fluid"
            options={dictionaryStore.fluidOptions}
            value={fluidOption}
            getOptionLabel={(option) => option.label}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            renderInput={(params) => <TextField {...params} label="Fluid" sx={{"background-color": validationColorWithControlAutonaming(masterAreaCardViewStore, fluidOption?.label, isControlAutonaming)}}/>}
            onInputChange={(event, newInputValue) => {
              handleFluidInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              setFluidOption(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          /> : null
          }
        </Stack>
        <Stack direction="row">
          {
          isPipeClassRequired(tagRequestCategoryOption?.code) ?
          <Autocomplete
            disablePortal
            size="small"
            id="pipeClass"
            options={dictionaryStore.pipeClassesOptions}
            value={pipeClassOption}
            getOptionLabel={(option) => option.label}
            sx={{ mr: 1, mb: 2, width: "50%" }}
            renderInput={(params) => <TextField {...params} label="Pipe Class"/>}
            onInputChange={(event, newInputValue) => {
              handlePipeClassInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              setPipeClassOption(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          /> : null
          }
          
        </Stack>
        </Box>
        <Box
          sx={ areaboxStyle }
        > 
          <Typography
            id="classification-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Location
          </Typography>
          <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="facility"
              options={dictionaryStore.facilityOptions}
              value={facilityOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Facility" sx={{"background-color": validationColor(masterAreaCardViewStore, facilityOption?.label)}}/>}
              onInputChange={(event, newInputValue) => {
                handleFacilityInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                updateFacilityOption(selectedValue);
                handleAreaInputChange("", selectedValue?.id, tagRequestCategoryOption?.code);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disabled={primaryTagOption?.id != null}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="well"
              options={dictionaryStore.wellOptions}
              value={wellOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Well" />}
              onInputChange={(event, newInputValue) => {
                handleWellInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setWellOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={primaryTagOption?.id != null}
            /> 
               
          </Stack>
          <Stack direction="row">
            <Autocomplete
              disablePortal
              size="small"
              id="area"
              options={dictionaryStore.areaOptions}
              value={areaOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Area" sx={{"background-color": validationColor(masterAreaCardViewStore, areaOption?.label)}}/>}
              onInputChange={(event, newInputValue) => {
                handleAreaInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                updateAreaOption(selectedValue);
                handleUnitInputChange("", selectedValue?.id);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disabled={primaryTagOption?.id != null}
            />
            {
            isBuildingRequired(tagRequestCategoryOption?.code) ?
            <Autocomplete
              disablePortal
              size="small"
              id="building"
              options={dictionaryStore.buildingOptions}
              value={buildingOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Building" sx={{"background-color": buildingColor(buildingOption?.label, tagRequestCategoryOption?.code, isControlAutonaming)}}/>}
              onInputChange={(event, newInputValue) => {
                handleBuildingInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setBuildingOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            /> : null
            }  
             { isLocationRequired(tagRequestCategoryOption?.code) ?
             <Autocomplete
                disablePortal
                size="small"
                id="location"
                options={dictionaryStore.locationOptions}
                value={locationOption}
                getOptionLabel={(option) => option.label}
                sx={{ mr: 1, mb: 2, width: "50%" }}
                renderInput={(params) => <TextField {...params} label="Location" sx={{"background-color": validationColorWithControlAutonaming(masterAreaCardViewStore, locationOption?.label, isControlAutonaming)}}/>}
                onInputChange={(event, newInputValue) => {
                  handleLocationInputChange(newInputValue);
                }}
                onChange={(event, selectedValue) => {
                  setLocationOption(selectedValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              /> : null
            }     
            { isSubstationRequired(tagRequestCategoryOption?.code) ?
             <Autocomplete
                disablePortal
                size="small"
                id="substation"
                options={dictionaryStore.substationOptions}
                value={substationOption}
                getOptionLabel={(option) => option.label}
                sx={{ mr: 1, mb: 2, width: "50%" }}
                renderInput={(params) => <TextField {...params} label="Substation"/>}
                onInputChange={(event, newInputValue) => {
                  handleSubstationInputChange(newInputValue);
                }}
                onChange={(event, selectedValue) => {
                  setSubstationOption(selectedValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              /> : null
            }           
          </Stack>
          <Stack direction="row"> 
            <Autocomplete
              disablePortal
              size="small"
              id="unit"
              options={dictionaryStore.unitOptions}
              value={unitOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Unit" sx={{"background-color": validationColor(masterAreaCardViewStore, unitOption?.label)}}/>}
              onInputChange={(event, newInputValue) => {
                handleUnitInputChange(newInputValue, areaOption?.id);
              }}
              onChange={(event, selectedValue) => {
                updateUnitOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disabled={primaryTagOption?.id != null}
            />   
          </Stack>
        </Box>
        <Box
          sx={ areaboxStyle }
        > 
          <Typography
            id="classification-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
          Details
          </Typography>
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Request Number"
              value={requestNumber}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              onChange={(e) => setRequestNumber(e.target.value)}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="parentTag"
              options={dictionaryStore.parentTagNumberByCategoryOptions}
              value={parentTagOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Parent Tag" />}
              onInputChange={(event, newInputValue) => {
                handleParentTagInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setParentTagOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Stack>
          <Stack direction="row">
          <Autocomplete
              disablePortal
              size="small"
              id="project"
              options={dictionaryStore.projectOptions}
              value={projectOption}
              getOptionLabel={(option) => option.label}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              renderInput={(params) => <TextField {...params} label="Project Number" />}
              onInputChange={(event, newInputValue) => {
                handleProjectInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setProjectsOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Stack>
          <Stack direction="row">
          { 
            isServiceDescriptionRequired(tagRequestCategoryOption?.code) ?
            <TextField
              variant="outlined"
              size="small"
              label="Service Description"
              value={serviceDescription}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              onChange={(e) => setServiceDescription(e.target.value)}
            />
            : null 
          }
          </Stack>
          <Stack direction="row">
          <TextField
              variant="outlined"
              size="small"
              label="Documents"
              value={documents}
              sx={{ mr: 1, mb: 2, width: "50%" }}
              onChange={(e) => setDocuments(e.target.value)}
            /> 
          </Stack>       
          <Stack direction="row">
            <TextField
              variant="outlined"
              size="small"
              label="Remarks"
              value={remarks}
              multiline
              rows={3}
              sx={{ mr: 1, mb: 2, width: "98%" }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Stack>
        </Box>     
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <MasterRelatedTagsTab Toolbar={false} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <MasterTagDetailAttributesTab Toolbar={false} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <MasterTagDetailDocumentsTab Toolbar={false} />
      </CustomTabPanel>
      </Box>
      <ConfirmationDialogModal 
        handleOkClick={handleSaveAndCloseClick} 
        handleCancelClick={() => setShowSaveAndCloseConfirmationDialog(false)} 
        showConfirmationDialog={showSaveAndCloseConfirmationDialog} 
        headerText="Confirm Saving Changes"
        confirmationText="Are you sure you want to save all changes?"
        okButtonText="Ok"
        cancelButtonText="Cancel"
      />
      {
      blocker ? 
        <ConfirmationDialogModal 
          handleOkClick={handleSaveOnLeaveClick} 
          handleCancelClick={handleLeaveWithoutSavingClick} 
          showConfirmationDialog={blocker.state === "blocked"} 
          headerText="Unsaved Changes"
          confirmationText="Do you want to save your changes before leaving this page?"
          okButtonText="Save and Continue"
          cancelButtonText="Discard Changes"
          blocker={blocker}
        />
      : null
      }
      <DuplicateTagWarningModal />
      </form>
  );
});

export default MasterAreaAddTag;
