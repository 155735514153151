import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../../api/clients";
import { SelectOption } from "../../models/common/selectOption";
import { GetDocumentByIdResponse } from "../../models/ProjectArea/response/getDocumentByIdResponse";
import { CreateDocumentRequest } from "../../models/ProjectArea/request/createDocumentRequest";
import { AddDocumentRequest } from "../../models/ProjectArea/request/addDocumentRequest";
import { DeleteDocumentRequest } from "../../models/ProjectArea/request/deleteDocumentRequest";
import { EditDocumentRequest } from "../../models/ProjectArea/request/editDocumentRequest";

class ProjectAreaCardViewDocumentStore {
  showAddDocumentModal = false;
  showCreateDocumentModal = false;
  showCreateDocumentAlert = false;
  projectDocsOption: SelectOption[] = [];
  projectDocDetails: GetDocumentByIdResponse = {} as GetDocumentByIdResponse;
  selectedProjectDocOption: SelectOption | null = null;
  selectedProjectDocTypeOption: SelectOption | null = null;
  projectDocUrl?: string;
  validationErrors?: string;
  newDocumentNumber?: string;
  newDocumentType: SelectOption | null = null;
  newDocumentUrl?: string;
  selectedIds: number[] = [];
  showEditDocumentModal = false;
  selectedId: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setShowAddDocumentModal = (value: boolean) => {
    this.showAddDocumentModal = value;
  };

  setShowCreateDocumentModal = (value: boolean) => {
    this.showCreateDocumentModal = value;
  };

  setShowCreateDocumentAlert = (value: boolean) => {
    this.showCreateDocumentAlert = value;
  };

  setSelectedProjectDocOption = (value: SelectOption | null) => {
    this.selectedProjectDocOption = value;
  };

  setSelectedProjectDocTypeOption = (value: SelectOption | null) => {
    this.selectedProjectDocTypeOption = value;
  };

  setProjectDocUrl = (value?: string) => {
    this.projectDocUrl = value;
  };

  setValidationErrors = (value?: string) => {
    this.validationErrors = value;
  };

  setNewDocumentNumber = (value?: string) => {
    this.newDocumentNumber = value;
  };

  setNewDocumentType = (value: SelectOption | null) => {
    this.newDocumentType = value;
  };

  setNewDocumentUrl = (value?: string) => {
    this.newDocumentUrl = value;
  };

  setSelectedIds = (ids: number[]) => {
    this.selectedIds = ids;
    this.setSelectedId(ids.length > 0 ? ids[0] : null);
  };

  setShowEditDocumentModal = (value: boolean) => {
    this.showEditDocumentModal = value;
  };

  setSelectedId(id: number | null) {
    this.selectedId = id;
  }

  async getProjDocOptions(docNo: string): Promise<void> {
    try {
      const response = await apiClient.getProjectDocuments(docNo);
      const newOptions = response.map((option) => ({
        id: option.id,
        label: option.documentNumber,
      })) as SelectOption[];

      runInAction(() => {
        this.projectDocsOption = newOptions;
      });

      if (newOptions.length === 0) {
        this.setShowCreateDocumentAlert(true);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  }

  async getProjDocDetails(id?: number): Promise<void> {
    if (!id) return;

    try {
      const response = await apiClient.getProjectDocumentById(id);

      runInAction(() => {
        this.projectDocDetails = response;
        this.setSelectedProjectDocTypeOption({
          id: response.documentTypeId,
          label: response.documentType,
        });
        this.setProjectDocUrl(response.documentUrl);
        this.setSelectedProjectDocOption({
          id: response.id,
          label: response.documentNumber,
        });
      });
    } catch (error) {
      console.error("Error while getting document details:", error);
    }
  }

  async createProjectDocument(): Promise<any> {
    const data: CreateDocumentRequest = {
      documentNumber: this.newDocumentNumber ?? "",
      documentTypeId: this.newDocumentType?.id ?? 0,
      documentUrl: this.newDocumentUrl ?? "",
    };

    try {
      const response = await apiClient.createProjectDocument(data);

      if (typeof response === "number") {
        this.handleSuccessfulResponse();
      } else if (
        response.status === 400 &&
        response.type === "BusinessRuleException"
      ) {
        this.handleBusinessRuleException(response.errors);
      } else {
        this.setValidationErrors("Error while creating document");
      }
    } catch (error) {
      console.error("Error creating project document:", error);
      throw error;
    }
  }

  private handleSuccessfulResponse(): void {
    this.setShowAddDocumentModal(false);
    this.setShowCreateDocumentAlert(false);
    this.setProjectDocUrl();
    this.setValidationErrors();
    this.setSelectedProjectDocOption(null);
    this.setSelectedProjectDocTypeOption(null);
  }

  private handleBusinessRuleException(
    errors: Record<string, unknown> | undefined
  ): void {
    if (errors?.errorProjectDocumentAlreadyExists) {
      this.setValidationErrors(
        errors.errorProjectDocumentAlreadyExists as string
      );
    } else {
      this.setValidationErrors("Unhandled business rule exception");
    }
  }

  async addProjectDocument(data: AddDocumentRequest): Promise<void> {
    try {
      const response = await apiClient.addDocumentToTag(data);

      if (
        response.status === 400 &&
        response.type === "BusinessRuleException"
      ) {
        for (const [key, value] of Object.entries(response.errors ?? {})) {
          if (key === "errorProjectDocumentMapAlreadyExists") {
            this.setValidationErrors(value as string);
          }
        }
      } else if (response.status === 200) {
        this.handleSuccessfulResponse();
      } else {
        this.setValidationErrors("Error while adding document");
      }
    } catch (error) {
      console.error("Error adding project document:", error);
      throw error;
    }
  }

  async deleteDocument(request: DeleteDocumentRequest) {
    try {
      await apiClient.deleteDocument(request);
    } catch (error) {
      console.error(error);
    }
  }

  async editDocument(request: EditDocumentRequest) {
    try {
      await apiClient.editDocument(request);
    } catch (error) {
      console.error("Error editing document:", error);
      throw error;
    }
  }
}

export default ProjectAreaCardViewDocumentStore;
