import { TextField } from "@mui/material";
import React from "react";

const TagDetailCMMSPopupTab = () => {
  return (
    <>
      <TextField
            disabled
            variant="outlined"
            size="small"
            label="Class"
            value="-"
            sx={{ mr: 1, mb: 2, width: "190px" }}
          />
          <TextField
            disabled
            variant="outlined"
            size="small"
            label="Sub-Class"
            value="-"
            sx={{ mr: 1, mb: 2, width: "190px" }}
          />
          <TextField
            disabled
            variant="outlined"
            size="small"
            label="E1 Asset"
            value="-"
            sx={{ mr: 1, mb: 2, width: "190px" }}
          />
          <TextField
            disabled
            variant="outlined"
            size="small"
            label="Equipment Criticality"
            value="-"
            sx={{ mr: 1, mb: 2, width: "190px" }}
          />
          <TextField
            disabled
            variant="outlined"
            size="small"
            label="Inspection date"
            value="-"
            sx={{ mr: 1, mb: 2, width: "190px" }}
          />
          <TextField
            disabled
            variant="outlined"
            size="small"
            label="Maintenance date"
            value="-"
            sx={{ mr: 1, mb: 2, width: "190px" }}
          />
    </>
  );
};

export default TagDetailCMMSPopupTab;
