import React from 'react';
import { Stack, Typography } from '@mui/material';
import usePageTitle from '../../hooks/usePageTitle';


export default function AboutPage() {
  usePageTitle('About');
  
  return (
    <Stack direction="column" alignItems="center">
      <img
        src="/images/tco_logo.svg"
        height={200}
        alt=""
      />
      <Typography variant="h3" mt={2} mb={4}>
        Asset Register Solution
      </Typography>
      <div>
      The Asset Register Solution (ARS) is used for engineering equipment tag number allocation and storing information related to tags.
      </div>
    </Stack>
  );
}
