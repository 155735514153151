import { Grid } from "@mui/material";
import { DataGridPro, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import usePageTitle from "../../../hooks/usePageTitle";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { DataGridBox } from "../../../styles/dataGridBox";
import { useNavigate } from "react-router-dom";
import { formatDateWithTime } from "../../../utils/formatDate";
import { ROUTES } from "../../../constants/routes";

const PipeClassPage = observer(() => {
  usePageTitle("References - Pipe Classes");

  const navigate = useNavigate();

  const { referencesStore } =
    useStores();

  useEffect(() => {    
    referencesStore.fetchPipeClasses("");
  }, []);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();
  const columns: GridColDef[] = useMemo(
    () => [
      { field: "pipeClass", headerName: "Pipe Class", flex: 1, minWidth: 250,
        renderCell: (params) => {
        return (
          <>
            <a href="#" rel="noreferrer" onClick={() => navigate(ROUTES.referencesPipeClass + "?id=" + params.row.id)}>
              {params.value}
            </a>
          </>
        );
      }  },
      { field: "pipeClassCode", headerName: "Pipe Class Code", flex: 1, minWidth: 150},
      { field: "pipeClassRemarks", headerName: "Pipe Class Remarks", flex: 1, minWidth: 200},
      { field: "createdDate", headerName: "Created On", flex: 1, minWidth: 150, valueFormatter: formatDateWithTime },
      { field: "createdBy", headerName: "Created By", flex: 1, minWidth: 200 },
      { field: "updatedDate", headerName: "Modified On", flex: 1, minWidth: 150, valueFormatter: formatDateWithTime },
      { field: "updatedBy", headerName: "Modified By", flex: 1, minWidth: 200 }
    ],
    []
  );

  return (
    <>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <DataGridBox>
            <DataGridPro
              //disableColumnFilter
              paginationMode="server"
              rows={referencesStore.pipeClasses ?? []}
              getRowId={(row) => row.id} 
              columns={columns}
              rowCount={referencesStore.pipeClasses?.length}
              loading={referencesStore.isLoading}
              density="compact"
              sx={{ fontSize: "0.75rem" }}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            />
          </DataGridBox>
        </Grid>
      </Grid>
    </>
  );
});

export default PipeClassPage;
