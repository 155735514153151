import { makeAutoObservable } from "mobx";
import authInstance from '../../components/Security/msalConfig';
import { AccountInfo } from "@azure/msal-common";

class UserStore {
  projectOptions: any[] = [];

  currentUserAccount: AccountInfo | null = null;

  constructor() {
    makeAutoObservable(this);
    this.fetchUserAccount();
  }

  hasCurrentUserRole(role: string) : boolean {
    if (this.currentUserAccount?.idTokenClaims?.roles) {
      return this.currentUserAccount.idTokenClaims.roles.some(item => item === role);
    }
    return false;
  }

  private fetchUserAccount() {
    try {
      this.currentUserAccount = authInstance.getActiveAccount();
    } catch (error) {
      console.error("Error creating tag request form: ", error);
      throw error;
    }
  }
}
export default UserStore;
