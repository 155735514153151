/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import usePageTitle from "../../../../hooks/usePageTitle";
import { useStores } from "../../../../hooks/useStores";
import { Grid } from "@mui/material";
import { DataGridBox } from "../../../../styles/dataGridBox";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import TagAuditPackagesDisplayModal from "./TagAuditPackagesDisplayModal";
import { useNavigate } from "react-router-dom";
import { TagRequestStatus } from "../../../../models/common/response/tagRequestFormStatus";

const TagAuditPackagesGridPage = observer(() => {
  usePageTitle("Tag Audit - Project Packages");

  const navigate = useNavigate();

  const { tagAuditStore, dictionaryStore } = useStores();

  const handleRedirectToGridPageClick = (id: number) => {
    tagAuditStore.setSelectedTagAuditProjectHandoverPackagesOption({
      label: "",
      id: id,
    });

    tagAuditStore.fetchTagAuditData();

    navigate("/tag-audit-handover");
  };

  const columns = [
    {
      field: "id",
      headerName: "Package ID",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "title",
      headerName: "Package Title",
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        console.log(params.row.id);
        return (
          <>
            <a
              style={{ cursor: "pointer" }}
              href="#"
              rel="noreferrer"
              onClick={() => handleRedirectToGridPageClick(params.row.id)}
            >
              {params.value}
            </a>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Project Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "system",
      headerName: "System",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "subSystem",
      headerName: "Subsystem",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Package Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        let color;
        switch (TagRequestStatus[params.value]) {
          case "Submitted":
            color = "orange";
            break;
          case "Rejected":
            color = "red";
            break;
          case "Completed": 
            color = "green";
            break;
          default:
            color = "inherit"; // Default text color
        }
        return (
          <strong style={{ color: color }}>
            {TagRequestStatus[params.value]}
          </strong>
        );
      },
    },
    {
      field: "tagsCount",
      headerName: "Quantity",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "tagsApproved",
      headerName: "Approved",
      flex: 1,
      minWidth: 150,
      valueGetter: (params: any) => params.row.tagsApproved ?? 0,
    },
    {
      field: "tagsRejected",
      headerName: "Rejected",
      flex: 1,
      minWidth: 150,
      valueGetter: (params: any) => params.row.tagsRejected ?? 0,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      await tagAuditStore.fetchTagAuditProjectHandoverPackagesData();
    };

    fetchData();
  }, [tagAuditStore, dictionaryStore]);

  return (
    <>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <DataGridBox>
            <DataGridPro
              //disableColumnFilter
              //   isRowSelectable={isRowSelectable} // Add this prop
              paginationMode="server"
              rows={tagAuditStore.tagAuditProjectHandoverPackagesResponse.items}
              columns={columns}
              rowCount={
                tagAuditStore.tagAuditProjectHandoverPackagesResponse.totalRecords
              }
              loading={tagAuditStore.isLoading}
              pagination
              paginationModel={tagAuditStore.paginationModel}
              pageSizeOptions={[tagAuditStore.paginationModel.pageSize]}
              onPaginationModelChange={tagAuditStore.setPaginationModel}
              // sortingMode="server"
              sortModel={tagAuditStore.sortModel}
              onSortModelChange={(model) => {
                tagAuditStore.setSortModel(model);
                tagAuditStore.fetchTagAuditProjectHandoverPackagesData();
              }}
              disableRowSelectionOnClick
              density="compact"
              sx={{ fontSize: "0.75rem" }}
              slots={{
                toolbar: GridToolbar,
              }}
              // columnVisibilityModel={columnVisibilityModel}
              // onColumnVisibilityModelChange={(newModel) =>
              //   setColumnVisibilityModel(newModel)
              // }
            />
          </DataGridBox>
        </Grid>
      </Grid>
      <TagAuditPackagesDisplayModal />
    </>
  );
});

export default TagAuditPackagesGridPage;
