import { DataGridBox } from "../../../../styles/dataGridBox";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const columns: GridColDef[] = [
  {
    field: "iecexCert",
    headerName: "IECEx Cert",
    flex: 1,
    minWidth: 150
  },
  {
    field: "eacexCert",
    headerName: "EACEx Cert",
    flex: 1,
    minWidth: 150
  },
  {
    field: "ipRating",
    headerName: "IP Rating",
    flex: 1,
    minWidth: 150
  },
  {
    field: "exProtectionConcept",
    headerName: "Ex Protection Concept",
    flex: 1,
    minWidth: 150
  },
  {
    field: "gasGroup",
    headerName: "Gas Group",
    flex: 1,
    minWidth: 150
  },
  {
    field: "tempClass",
    headerName: "Temp Class",
    flex: 1,
    minWidth: 150
  },
  {
    field: "ambTemRange",
    headerName: "Amb Tem Range",
    flex: 1,
    minWidth: 150
  },
  {
    field: "exSpecialCondition",
    headerName: "Ex Special Condition",
    flex: 1,
    minWidth: 150
  },
  {
    field: "icieCertifiedbody",
    headerName: "ICIE Certified body",
    flex: 1,
    minWidth: 150
  },
  {
    field: "zoneEquipment",
    headerName: "Zone Equipment",
    flex: 1,
    minWidth: 150
  },
  {
    field: "isBariercertbody",
    headerName: "IS Barier cert body",
    flex: 1,
    minWidth: 150
  },
  {
    field: "isBarriercertnumber",
    headerName: "IS Barrier cert number",
    flex: 1,
    minWidth: 150
  },
  {
    field: "isBariermanufacturer",
    headerName: "IS Barier manufacturer",
    flex: 1,
    minWidth: 150
  },
  {
    field: "isBariermodel",
    headerName: "IS Barier model",
    flex: 1,
    minWidth: 150
  },
  {
    field: "zone",
    headerName: "Zone",
    flex: 1,
    minWidth: 150
  },

];

const TagDetailExPopupTab = () => {
  return (
    <DataGridBox>
      <DataGrid columns={columns} rows={[]} density="comfortable" />
    </DataGridBox>
  );
};

export default TagDetailExPopupTab;
