import { observer } from "mobx-react";
import {
  Box,
  Button,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useStores } from "../../../../../hooks/useStores";
import { DataGridBox } from "../../../../../styles/dataGridBox";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { NOTIFICATION_TYPES } from "../../../../../constants/notificationTypes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const columns: GridColDef[] = [
  {
    field: "projectNumbers",
    headerName: "Project Number",
    flex: 1,
    minWidth: 180,
  },
];

const EditUserProjectMapButtonModal = observer(() => {
  const { userProjectMapStore, notificationStore } = useStores();

  const userEmail = userProjectMapStore.userProjectMapDetails.userEmail;
  const userName = userProjectMapStore.userProjectMapDetails.userName;

  const handleCloseClick = () => {
    userProjectMapStore.setShowEditModal(false);
  };

  const handleSaveClick = async () => {
    try {
      // Call deleteUserProjectMap with the selected project IDs
      await userProjectMapStore.deleteUserProjectMap({
        usersIds: [userProjectMapStore.userProjectMapDetails.userId ?? null],
        projectsIds: userProjectMapStore.selectedProjectIds,
      });
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
      notificationStore.setNotificationMessage("User projects deleted successfully!");
      notificationStore.setShowNotification(true);
      userProjectMapStore.fetchUserProjectMaps();
      userProjectMapStore.setShowEditModal(false);
    } catch (error) {
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
      notificationStore.setNotificationMessage("Failed to delete user project map");
      notificationStore.setShowNotification(true);
    }
  };

  // Create an array of rows, where each row represents a project number
  const projectRows = userProjectMapStore.userProjectMapDetails.projectNumbers.map(
    (projectNumber, index) => ({
      id: userProjectMapStore.userProjectMapDetails.projectIds[index], // Use projectIds as unique identifiers
      projectNumbers: projectNumber,
    })
  );

  return (
    <Modal
      open={userProjectMapStore.showEditModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="edit-user-project-map-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Edit user projects
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Stack direction="row" mb={2}>
            <Typography
              id="edit-user-project-map-title"
              variant="subtitle1"
              component="h6"
              sx={{ mr: 4 }}
            >
              User: {userName}
            </Typography>
            <Typography
              id="edit-user-project-map-email"
              variant="subtitle1"
              component="h6"
              sx={{ mr: 4 }}
            >
              Email: <a href={`mailto:${userEmail}`}>{userEmail}</a>
            </Typography>
          </Stack>
          <Stack direction="row" mb={2}>
            <DataGridBox>
              <DataGridPro
                checkboxSelection
                disableRowSelectionOnClick
                columns={columns}
                rows={projectRows}
                onRowSelectionModelChange={(ids) => {
                  userProjectMapStore.setSelectedProjectIds(ids.map(Number));
                }}
              ></DataGridPro>
            </DataGridBox>
          </Stack>
        </Box>
        <Tooltip
          title="Please select at least one project"
          disableHoverListener={userProjectMapStore.selectedProjectIds.length > 0}
        >
          <span>
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              onClick={handleSaveClick}
              disabled={userProjectMapStore.selectedProjectIds.length < 1}
            >
              <span>Delete</span>
            </Button>
          </span>
        </Tooltip>
        <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
          <span>Cancel</span>
        </Button>
      </Box>
    </Modal>
  );
});

export default EditUserProjectMapButtonModal;
