import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import {
  Box,
  Button,
  Modal,
  Typography,
  Stack
} from "@mui/material";
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const RelatedTagExistsWarningModal = observer(() => {
  const {
    masterAreaCardViewStore
  } = useStores();

  const handleOkClick = () => {
    masterAreaCardViewStore.setShowDuplicateRelatedTagModal(false);
  };

  return (
    <Modal
      open={masterAreaCardViewStore.showDuplicateRelatedTagModal}
      onClose={handleOkClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="add-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          <Stack direction="row">
            <Stack direction="row"><ReportOutlinedIcon style={{ color: 'red', marginTop: "3px" }} /></Stack> 
            <Stack direction="row">&nbsp;Duplicate Related Tag</Stack>
          </Stack>
        </Typography>
        <Box sx={{ mb: 2, backgroundColor: "#fff" }}>
          Related tag that has the same number and relation type already exists. Select unique values and try again. 
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Button 
            variant="contained" 
            disabled={false} 
            sx={{ ml: 2 }} 
            onClick={handleOkClick}>
            <span>OK</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default RelatedTagExistsWarningModal;
