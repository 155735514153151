import { Typography } from '@mui/material';

interface ErrorComponentProps {
    error: Error;
}

export function ErrorComponent({ error }: any) {
    return (
        <>
            <Typography variant="h6">An error occurred: </Typography>
            <code>{error.message}</code>
        </>
    );
}
