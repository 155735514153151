import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { Box, Modal, Typography, Button, TextField } from "@mui/material";
import { NOTIFICATION_TYPES } from "../../../../constants/notificationTypes";
import { CreateHandoverRequest } from "../../../../models/TagAudit/request/createHandoverRequest";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const CreateHandoverButtonModal = observer(() => {
  const { tagAuditStore, notificationStore } = useStores();

  // Get the count of selectedIds
  const selectedTagsCount = tagAuditStore.selectedIds.length;

  const handleCloseClick = () => {
    tagAuditStore.setshowCreateHandoverModal(false);
    tagAuditStore.createdHandoverPackageId = undefined;
    tagAuditStore.setComments("");
  };

  const handleSaveClick = async () => {
    const tagsIds = tagAuditStore.selectedIds;

    const request: CreateHandoverRequest = {
      ids: tagsIds,
      comments: tagAuditStore.comments,
    };

    tagAuditStore.createHandover(request).then(() => {
      if (tagAuditStore.validationErrors) {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
        notificationStore.setNotificationMessage(tagAuditStore.validationErrors);
        notificationStore.setShowNotification(true);
      } else {
        notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
        notificationStore.setNotificationMessage("Tags created to handover successfully");
        notificationStore.setShowNotification(true);
      }
    });
  };

  // Check if createdHandoverPackageId is defined
  const isHandoverPackageCreated = !!tagAuditStore.createdHandoverPackageId;

  return (
    <Modal
      open={tagAuditStore.showCreateHandoverModal}
      onClose={handleCloseClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="create-handover-title" variant="h6" component="h2" sx={{ mb: 4 }}>
          Create Handover Package
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          {!isHandoverPackageCreated && (
            <TextField
              variant="outlined"
              multiline
              rows={4}
              label="Comments"
              value={tagAuditStore.comments}
              sx={{ mr: 1, mb: 2, width: "100%" }}
              onChange={(e: any) => tagAuditStore.setComments(e.target.value)}
            />
          )}
          {isHandoverPackageCreated ? (
            <Typography
              id="created-handover-package-id"
              variant="subtitle1"
              component="h2"
              sx={{ mb: 4 }}
            >
              {`Created Handover Package ID: ${tagAuditStore.createdHandoverPackageId}`}
            </Typography>
          ) : (
            <Typography
              id="create-handover-subtitle"
              variant="subtitle1"
              component="h2"
              sx={{ mb: 4 }}
            >
              This will create a new Handover Package for{" "}
              <strong>{selectedTagsCount}</strong> tags
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          sx={{ ml: 2 }}
          onClick={handleSaveClick}
          disabled={isHandoverPackageCreated}
        >
          <span>Create</span>
        </Button>
        <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCloseClick}>
          <span>Cancel</span>
        </Button>
      </Box>
    </Modal>
  );
});

export default CreateHandoverButtonModal;
