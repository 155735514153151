import { Button } from "@mui/material";
import { observer } from "mobx-react";
import EditIcon from "@mui/icons-material/Edit";
import { useStores } from "../../../../../hooks/useStores";
import EditUserProjectMapButtonModal from "./EditUserProjectMapButtonModal";

const EditUserProjectMapButton = observer(() => {
  const { userProjectMapStore } = useStores();

  const handleEditClick = async () => {
    const userId = userProjectMapStore.selectedIds[0];
    console.log(userId);

    await userProjectMapStore.getUserProjectMapById(userId);

    userProjectMapStore.setShowEditModal(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<EditIcon />}
        onClick={handleEditClick}
        disabled={
          userProjectMapStore.selectedIds.length > 1 ||
          userProjectMapStore.selectedIds.length == 0
        }
      >
        <span>Edit</span>
      </Button>
      {userProjectMapStore.showEditModal ? <EditUserProjectMapButtonModal /> : null}
    </>
  );
});

export default EditUserProjectMapButton;
