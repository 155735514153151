import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import { Box, Button, Modal, TextField, Typography, Autocomplete } from "@mui/material";
import PermissionButton from "../../../../../components/common/PermissionButton";
import { useRef } from "react";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { MasterTagAttributes } from "../../../../../models/MasterArea/response/getMasterAssetsTagAttributesResponse";

import { ExtendedSelectOption } from "../../../../../models/common/extendedSelectOption";

interface TagDetailEditTagAttributesModalProps {
  Attributes: MasterTagAttributes[];
  handleEditAttribute: any;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const TagDetailEditTagAttributesModal = observer((props: TagDetailEditTagAttributesModalProps) => {
  const {
    masterAreaTagAttributesStore, dictionaryStore, userStore
  } = useStores();

  const tagAttributeValueRef = useRef<HTMLInputElement>(null);

  const handleCancelClick = () => {
    masterAreaTagAttributesStore.setTagAttributeValue(null);
    masterAreaTagAttributesStore.setSelectedEclTagAttributeOption(null);
    masterAreaTagAttributesStore.setSelectedUOMOption(null);
    masterAreaTagAttributesStore.setShowEditTagAttributesModal(false);
  };

  const handleSaveClick = async () => {
    const attr: MasterTagAttributes = { 
      id: masterAreaTagAttributesStore.selectedId,
      attributeClass: null,
      tagAttributeValue: masterAreaTagAttributesStore.tagAttributeValue,
      tagAttributeId: masterAreaTagAttributesStore.tagAttributeId,
      uom: masterAreaTagAttributesStore.selectedUOMOption?.label,
      uomId: masterAreaTagAttributesStore.selectedUOMOption?.id,
      category: null,
      tagNumber: null,
      attributeCompositeKey: null,
      compositeKey: null,
      createdDate: null,
      createdOn: null,
      createdBy: null,
      updatedDate: new Date(),
      updatedBy: userStore.currentUserAccount?.name,
      modifiedOn: new Date(),
      modifiedBy: userStore.currentUserAccount?.name
    };

    props.handleEditAttribute(attr);

    masterAreaTagAttributesStore.setShowEditTagAttributesModal(false);
  };

  const debouncedHandleInputChange = useDebounce(500);

  const handleAttributeUOMInputChange = async (inputValue: string, uomClassId? : number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOfMeasuresOptions(inputValue, uomClassId);
    });
  };

  const handleAttributeUOMChange = (
    selectedValue: ExtendedSelectOption | null
  ) => {
    masterAreaTagAttributesStore.setSelectedUOMOption(selectedValue);
  };

  return (
    <Modal
      open={masterAreaTagAttributesStore.showEditTagAttributesModal}
      onClose={handleCancelClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="edit-tag-attributes-title"
          variant="h6"
          component="h2"
          sx={{ mb: 4 }}
        >
          Edit Tag Attributes
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            label="Tag Attribute Value"
            value={masterAreaTagAttributesStore.tagAttributeValue}
            onChange={(e) =>
              masterAreaTagAttributesStore.setTagAttributeValue(
                e.target.value
              )
            }
            inputRef={tagAttributeValueRef}
            sx={{ mr: 1, mb: 2, width: "100%" }}
          />
         <Autocomplete
            size="small"
            id="UOM"
            options={dictionaryStore.uomOptions}
            value={masterAreaTagAttributesStore.selectedUOMOption}
            getOptionLabel={(option) => option.label}
            sx={{
              width: "100%",
              mr: 1,
              mb: 1,
            }}
            renderInput={(params) => (
              <TextField {...params} label="UOM" />
            )}
            onInputChange={(event, newInputValue) => {
              handleAttributeUOMInputChange(newInputValue);
            }}
            onChange={(event, selectedValue) => {
              handleAttributeUOMChange(selectedValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: "#fff",
            display: { xs: "block", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <PermissionButton
            buttonText="Save"
            buttonVariant="contained"
            buttonPermission={masterAreaTagAttributesStore.tagAttributeValue != ""} //userStore.hasCurrentUserRole(ROLES.arsProjectUserRole)
            handleButtonClick={handleSaveClick}
          />
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancelClick}>
            <span>Cancel</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

export default TagDetailEditTagAttributesModal;
