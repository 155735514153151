import { observer } from "mobx-react";
import TagDetailDeleteTagAttributes from "./TagDetailDeleteTagAttributes";
import TagDetailAddTagAttributes from "./TagDetailAddTagAttributes";
import TagDetailEditTagAttributes from "./TagDetailEditTagAttributes";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { MasterTagAttributes } from "../../../../../models/MasterArea/response/getMasterAssetsTagAttributesResponse";

interface TagDetailAttributeToolBarProps {
  Attributes: MasterTagAttributes[];
  handleAddAttribute: any;
  handleDeleteAttribute: any;
  handleEditAttribute: any;
}

const TagDetailTagAttributesToolBar = observer((props: TagDetailAttributeToolBarProps) => {
  return (
    <GridToolbarContainer>
      {<TagDetailAddTagAttributes Attributes={props.Attributes} handleAddAttribute={props.handleAddAttribute}/>}
      {<TagDetailEditTagAttributes Attributes={props.Attributes} handleEditAttribute={props.handleEditAttribute}/>}
      {<TagDetailDeleteTagAttributes Attributes={props.Attributes} handleDeleteAttribute={props.handleDeleteAttribute}/>}
    </GridToolbarContainer>
  );
});

export default TagDetailTagAttributesToolBar;
