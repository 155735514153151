import { observer } from "mobx-react";
import TagDetailDeleteTagAttributes from "./TagDetailDeleteTagAttributes";
import TagDetailEditTagAttributes from "./TagDetailEditTagAttributes";
import TagDetailAddTagAttributes from "./TagDetailAddTagAttributes";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { useStores } from "../../../../../hooks/useStores";
import { TagRequestStatus } from "../../../../../models/common/response/tagRequestFormStatus";

const TagDetailTagAttributesToolBar = observer(() => {
  const {
    projectAreaCardViewStore: { assetDetail },
  } = useStores();

  const isCompleted = assetDetail?.status === TagRequestStatus.Completed;

  return (
    <GridToolbarContainer>
      {!isCompleted && (
        <>
          <TagDetailAddTagAttributes />
          <TagDetailEditTagAttributes />
          <TagDetailDeleteTagAttributes />
        </>
      )}
    </GridToolbarContainer>
  );
});

export default TagDetailTagAttributesToolBar;
