import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridBox } from "../../../../styles/dataGridBox";
import { RelationType } from "../../../../models/common/response/relationType";
import TagDetailRelatedTagToolBar from "../components/tagRelated/TagDetailRelatedTagToolBar";
import TagDetailPopup from "../components/TagDetailPopup";

const RelatedTagsTab = observer(() => {
  const columns: GridColDef[] = [
    {
      field: "tagNumber",
      headerName: "Tag Number",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return (
          <>
            <a
              href="#"
              rel="noreferrer"
              onClick={() => handleShowCardModalClick(params.row.id)}
            >
              {params.value}
            </a>
          </>
        );
      },
    },
    {
      field: "relationType",
      headerName: "Relation Type",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <span>{RelationType[params.value]}</span>,
    },
    { field: "status", headerName: "Status", flex: 1, minWidth: 150 },
    {
      field: "serviceDescription",
      headerName: "Service Description",
      flex: 1,
      minWidth: 200,
    },
  ];

  const {
    projectAreaCardViewStore: { relatedTags },
    projectAreaPopupCardViewStore,
    projectAreaCardViewRelatedTagStore: relatedTagStore,
  } = useStores();

  const handleShowCardModalClick = async (id: number) => {
    await Promise.all([
      projectAreaPopupCardViewStore.setShowCardModal(true),
      projectAreaPopupCardViewStore.fetchAssetById(id),
      projectAreaPopupCardViewStore.fetchRelatedTagsById(id),
      projectAreaPopupCardViewStore.fetchTagAttributesById(id),
      projectAreaPopupCardViewStore.fetchDocumentsById(id),
    ]);
  };

  return (
    <>
      <DataGridBox>
        <DataGrid
          columns={columns}
          rows={relatedTags ?? []}
          density="comfortable"
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            const selectedIds = new Set(ids);
            relatedTagStore.setSelectedIds(
              Array.from(selectedIds).map((id) => parseInt(id as string))
            );
          }}
          slots={{
            toolbar: TagDetailRelatedTagToolBar,
          }}
          sx={{ height: "700px" }}
        />
      </DataGridBox>
      <TagDetailPopup />
    </>
  );
});

export default RelatedTagsTab;
