import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../../api/clients";
import { UpdateTagAttributesByIdRequest } from "../../models/ProjectArea/request/editTagAttributesRequest";
import { GetTagAttributeDetailsByIdResponse } from "../../models/ProjectArea/response/getTagAttributeDetailsByIdResponse";
import { CreateTagAttributesRequest } from "../../models/ProjectArea/request/createTagAttributesRequest";
import { SelectOption } from "../../models/common/selectOption";
import { ExtendedSelectOption, LinkedSelectOption } from "../../models/common/extendedSelectOption";
import { NullableNumber, NullableString } from "../../models/common/response/types";

class MasterAreaTagAttributesStore {
  selectedIds: number[] = [];
  showEditTagAttributesModal = false;
  tagAttributeDetails: GetTagAttributeDetailsByIdResponse = {} as GetTagAttributeDetailsByIdResponse;
  tagAttributeValue: NullableString;
  tagAttributeId: NullableNumber;
  uom: NullableString = "";
  uomId?: number;
  selectedId: number | null = null;
  validationErrors?: string;
  showAddTagAttributesModal = false;
  selectedEclTagAttributeOption: LinkedSelectOption | null = null;
  eclClassOptions: ExtendedSelectOption[] = [];
  selectedUOMOption: SelectOption | null = null;
  idCounter: number = 1;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedIds = (ids: number[]) => {
    this.selectedIds = ids;
    this.setSelectedId(ids.length > 0 ? ids[0] : null);
  };

  setSelectedId(id: number | null) {
    this.selectedId = id;
  }

  setShowEditTagAttributesModal = (value: boolean) => {
    this.showEditTagAttributesModal = value;
  };

  setTagAttributeValue = (value: NullableString) => {
    this.tagAttributeValue = value;
  };

  setTagAttributeId = (value: NullableNumber) => {
    this.tagAttributeId = value;
  };

  setUom = (value: NullableString) => {
    this.uom = value;
  };

  setUomId = (value?: number) => {
    this.uomId = value;
  };

  setValidationErrors = (value?: string) => {
    this.validationErrors = value;
  };

  setShowAddTagAttributesModal = (value: boolean) => {
    this.showAddTagAttributesModal = value;
  };

  setSelectedEclTagAttributeOption = (value: LinkedSelectOption | null) => {
    this.selectedEclTagAttributeOption = value;
  };

  setSelectedUOMOption = (value: SelectOption | null) => {
    this.selectedUOMOption = value;
  };

  private handleBusinessRuleException(
    errors: Record<string, unknown> | undefined
  ): void {
    if (errors?.errorTagAttributesAlreadyExists) {
      this.setValidationErrors(
        errors.errorTagAttributesAlreadyExists as string
      );
    } else {
      this.setValidationErrors("Project Tag Attributes with such a ECL Class already exists");
    }
  }

  private handleSuccessfulResponse(): void {
    this.setShowAddTagAttributesModal(false);
    this.setTagAttributeValue(null);
    this.setTagAttributeId(null);
    this.setUom(null);
    this.setValidationErrors();
  }

  async deleteTagAttributes(ids: number[]) {
    try {
      await apiClient.deleteTagAttributes(ids);
    } catch (error) {
      console.error(error);
    }
  }

  async getTagAttributeDetails(id: number): Promise<void> {
    if (!id) return;

    try {
      const response = await apiClient.getTagAttributeDetailsById(id);

      runInAction(() => {
        this.tagAttributeDetails = response;
        this.setTagAttributeValue(response.tagAttributeValue);
        this.setTagAttributeId(response.tagAttributeId);
        this.setUom(response.uom);
        this.setUomId(response.uomId);
      });
    } catch (error) {
      console.error("Error while getting tag attribute details:", error);
    }
  }

  async updateTagAttributes(request: UpdateTagAttributesByIdRequest) {
    try {
      await apiClient.updateTagAttributes(request);
    } catch (error) {
      console.error("Error editing tag attributes:", error);
      throw error;
    }
  }

  async createTagAttributes(data: CreateTagAttributesRequest): Promise<void> {
    try {
      const response = await apiClient.createTagAttributes(data);

      if (typeof response === "number") {
        this.handleSuccessfulResponse();
      } else if (
        response.status === 400 &&
        response.type === "BusinessRuleException"
      ) {
        this.handleBusinessRuleException(response.errors);
      } else {
        this.setValidationErrors("Error while creating tag attributes");
      }
    } catch (error) {
      console.error("Error adding project tag attributes:", error);
      throw error;
    }
  }
}

export default MasterAreaTagAttributesStore;
