import { makeAutoObservable, runInAction } from "mobx";
import { GetUserProjectMapsResponse } from "../../models/AdminArea/response/getUserProjectMapsResponse";
import { GridSortModel } from "@mui/x-data-grid-pro";
import {
  Filter,
  GetUserProjectMapsRequest,
  UserProjectMapFilterOptions,
  UserProjectMapSortOptions,
} from "../../models/AdminArea/request/getUserProjectMapsRequest";
import { getOptionId } from "../../utils/getOptionValue";
import { SelectOption } from "../../models/common/selectOption";
import { SortOrder } from "../../models/common/request/sorting";
import apiClient from "../../api/clients";
import { CreateUserProjectMapRequest } from "../../models/AdminArea/request/createUserProjectMapRequest";
import { DeleteUserProjectMapRequest } from "../../models/AdminArea/request/deleteUserProjectMapRequest";
import { GetUserProjectMapByIdResponse } from "../../models/AdminArea/response/getUserProjectMapsByIdResponse";

class UserProjectMapStore {
  selectedIds: number[] = [];
  selectedProjectOption: SelectOption | null = null;
  selectedUserOption: SelectOption | null = null;
  showCreateModal = false;
  showEditModal = false;
  userProjectMapDetails: GetUserProjectMapByIdResponse =
    {} as GetUserProjectMapByIdResponse;
  selectedProjectIds: number[] = [];

  userProjectMapsResponse: GetUserProjectMapsResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  paginationModel = {
    pageSize: 100,
    page: 0,
  };
  sortModel: GridSortModel = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setSortModel = (sortModel: any) => {
    this.sortModel = sortModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setShowCreateModal = (value: boolean) => {
    this.showCreateModal = value;
  };

  setShowEditModal = (value: boolean) => {
    this.showEditModal = value;
  };

  setSelectedUserOption = (option: SelectOption | null) => {
    this.selectedUserOption = option;
  };

  setSelectedProjectIds = (selectedIds: number[]) => {
    this.selectedProjectIds = selectedIds;
  };

  setSelectedProjectOption = (option: SelectOption | null) => {
    this.selectedProjectOption = option;
  };

  setSelectedIds = (selectedIds: number[]) => {
    this.selectedIds = selectedIds;
  };

  fetchUserProjectMaps = async () => {
    try {
      const { page, pageSize } = this.paginationModel;

      const filterOptions: UserProjectMapFilterOptions = {
        projectId: getOptionId(this.selectedProjectOption),
        userId: getOptionId(this.selectedUserOption),
      };

      const sortOptions: UserProjectMapSortOptions = {
        userName: "userName",
        createdDate: "createdDate",
      };

      const sortField = this.sortModel[0]?.field;
      const sortOrder =
        this.sortModel[0]?.sort === "asc" ? SortOrder.ASC : SortOrder.DESC;
      const sortProperty = sortField
        ? sortOptions[sortField as keyof UserProjectMapSortOptions]
        : undefined;
      const filter = Object.keys(filterOptions).reduce((acc, key) => {
        const filterValue = filterOptions[key as keyof UserProjectMapFilterOptions];
        if (filterValue !== undefined) {
          acc[key as keyof Filter] = {
            filterValue: filterValue as never,
          };
        }
        return acc;
      }, {} as Filter);
      const sort = sortProperty
        ? {
            [sortProperty]: { sortOrder },
          }
        : undefined;
      const request: GetUserProjectMapsRequest = {
        pagination: { pageIndex: page, pageSize },
        filter,
        sort,
      };
      this.setIsLoading(true);

      const data = await apiClient.getUserProjectMaps(request);

      runInAction(() => {
        this.userProjectMapsResponse = data;
        this.isLoading = false;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  async createUserProjectMap(data: CreateUserProjectMapRequest): Promise<void> {
    try {
      await apiClient.createUserProjectMap(data);
      this.setSelectedUserOption(null);
      this.setSelectedProjectOption(null);
      await this.fetchUserProjectMaps();
    } catch (error) {
      console.error("Error creating user project map:", error);
      throw error;
    }
  }

  async deleteUserProjectMap(data: DeleteUserProjectMapRequest) {
    try {
      await apiClient.deleteUserProjectMap(data);
    } catch (error) {
      console.error(error);
    }
  }

  async getUserProjectMapById(userId: number): Promise<void> {
    if (!userId) return;

    try {
      const response = await apiClient.getUserProjectMapById(userId);
      console.log(response);

      runInAction(() => {
        this.userProjectMapDetails = response;
      });
    } catch (error) {
      console.error("Error while getting details:", error);
    }
  }
}

export default UserProjectMapStore;
