import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Stack,
  Tabs,
  Tab,
  Box,
  Button
} from "@mui/material";
import CustomTabPanel from "./CustomTabPanel";

import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import { NOTIFICATION_TYPES } from "../../../../constants/notificationTypes";
import { useDebounce } from "../../../../hooks/useDebounce";
import { SelectOption } from "../../../../models/common/selectOption";
import { UpdateAssetByIdRequest } from "../../../../models/ProjectArea/request/updateAssetByIdRequest";
import { useTagDetailRefs } from "../hooks/useTagDetailRefs";
import TagDetailEditForm from "./TagDetailEditForm";
import TagDetailDocumentationTab from "../tabs/TagDetailDocumentationTab";
import TagDetailExTab from "../tabs/TagDetailExTab";
import TagDetailCMMSTab from "../tabs/TagDetailCMMSTab";
import { getRefValue } from "../../../../utils/getRefValue";
import { getOptionId } from "../../../../utils/getOptionValue";
import RelatedTagsTab from "../tabs/RelatedTagsTab";
import TagDetailAttributesTab from "../tabs/TagDetailAttributesTab";
import TagDetailValidationTab from "../tabs/TagDetailValidationTab";
import { findOptionById } from "../../../../utils/findOptionById";
import PermissionButton from "../../../../components/common/PermissionButton";
import { ROLES } from "../../../../constants/roles";
import { findOptionByLabel } from "../../../../utils/findOptionByLabel";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TagDetailEdit = observer(() => {
  const [value, setValue] = useState(0);

  const {
    projectAreaCardViewStore: { assetDetail, fetchAssetById },
    projectAreaUpdateStore,
    dictionaryStore,
    projectAreaCardViewStore,
    notificationStore,
    projectColumnStore,
    userStore
  } = useStores();

  const {
    serviceDescriptionRef,
    RemarksRef,
    circuitNumberRef,
    documentsRef,
    fittingNumberRef,
    insulationTypeRef,
    subSystemRef,
    systemNameRef,
  } = useTagDetailRefs();

  const [selectedEqStatusOption, setSelectedEqStatusOption] = useState<SelectOption | null>(null);
  const [selectedEqTypeOption, setSelectedEqTypeOption] = useState<SelectOption | null>(null);
  const [selectedLocationOption, setSelectedLocationOption] = useState<SelectOption | null>(null);
  const [selectedBuildingOption, setSelectedBuildingOption] = useState<SelectOption | null>(null);
  const [selectedParentTagOption, setSelectedParentTagOption] = useState<SelectOption | null>(null);
  const [selectedDocumentDatasheetOption, setSelectedDocumentDatasheetOption] = useState<SelectOption | null>(null);
  const [selectedDocumentPAIDOption, setSelectedDocumentPAIDOption] = useState<SelectOption | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCancelButtonClick = () => {
    projectAreaUpdateStore.setShowEditMode(false);
  };

  const debouncedHandleInputChange = useDebounce(500);
  const handleEqTypeInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchEqTypeOptions(inputValue);
    });
  };

  const handleLocationInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchLocationOptions(inputValue);
    });
  };

  const handleBuildingInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchBuildingOptions(inputValue);
    });
  };

  const handleParentTagInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectTagNumberCategoryOptions(inputValue);
    });
  };

  const handleDocumentDatasheetInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchDocumentDatasheetsOptions(inputValue);
    });
  };

  const handleDocumentPAIDInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchDocumentPAIDOptions(inputValue);
    });
  };

  const prepareUpdateData = () => {
    const id = assetDetail?.id ?? 0;
    const equipmentStatusId = selectedEqStatusOption?.id ?? null;
    const serviceDescription = getRefValue(serviceDescriptionRef);
    const remarks = getRefValue(RemarksRef)
    const manufacturer = assetDetail?.manufacturer;
    const modelNumber = assetDetail?.modelNumber;
    const purchaseOrderNumber =  assetDetail?.purchaseOrderNumber;
    const serialNumber = assetDetail?.serialNumber;
    const isMaintainable = assetDetail?.isMaintainable;
    const subSystem = getRefValue(subSystemRef);
    const systemName = getRefValue(systemNameRef);
    const exInspectionRequired = assetDetail?.exInspectionRequired;
    const exRegister = assetDetail?.exRegister;
    const parentEquipmentTagId = selectedParentTagOption?.id ?? null;
    const datasheetDocumentId = selectedDocumentDatasheetOption?.id;
    const pAndIDiagramDocumentId = selectedDocumentPAIDOption?.id;


    return {
      id,
      equipmentStatusId,
      serviceDescription,
      remarks,
      manufacturer,
      modelNumber,
      purchaseOrderNumber,
      serialNumber,
      isMaintainable,
      subSystem,
      systemName,
      exInspectionRequired,
      exRegister,
      parentEquipmentTagId,
      datasheetDocumentId,
      pAndIDiagramDocumentId
    };
  };

  const handleUpdate = async (data: UpdateAssetByIdRequest) => {
    const id = data.id;
    const res = await projectAreaUpdateStore.updateAssetById(data);
    const success = res?.status === 200;

    if (success) {
      handleUpdateSuccess(id);
    } else {
      handleUpdateError();
    }

    projectAreaUpdateStore.setShowEditMode(false);
  };

  const handleUpdateSuccess = (id: number) => {
    const successMessage = "Asset updated successfully.";
    notificationStore.setNotificationMessage(successMessage);
    notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.success);
    notificationStore.setShowNotification(true);
    projectAreaCardViewStore.fetchAssetsMin();
    fetchAssetById(id);
  };

  const handleUpdateError = () => {
    const errorMessage =
      "Error while updating the asset. Please try again later.";
    notificationStore.setNotificationMessage(errorMessage);
    notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.error);
    notificationStore.setShowNotification(true);
  };

  const handleSaveButtonClick = async () => {
    const data = prepareUpdateData();
    await handleUpdate(data);
  };

  useEffect(() => {
    const fetchOptions = async () => {
      await Promise.all([
        dictionaryStore.fetchEquipmentStatusOptions(""),
        dictionaryStore.fetchEqTypeOptions(
          assetDetail?.equipmentTypeDescription ?? ""
        ),
        dictionaryStore.fetchLocationOptions(
          assetDetail?.locationDescription ?? ""
        ),
        dictionaryStore.fetchBuildingOptions(
          assetDetail?.buildingDescription ?? ""
        ),
        dictionaryStore.fetchProjectTagNumberCategoryOptions(
          assetDetail?.parentTagNumber ?? ""
        ),
        dictionaryStore.fetchDocumentDatasheetsOptions(
          assetDetail?.datasheet ?? ""
        ),
        dictionaryStore.fetchDocumentPAIDOptions(
          assetDetail?.pipingAndInstrumentationDiagram ?? ""
        ),
      ]);
    };

    const setCurrentOptions = () => {
      setSelectedEqStatusOption(
        findOptionById(
          dictionaryStore.equipmentStatusOptions,
          assetDetail?.equipmentStatusId
        )
      );
      setSelectedEqTypeOption(
        findOptionById(
          dictionaryStore.eqTypeOptions,
          assetDetail?.equipmentTypeId
        )
      );
      setSelectedLocationOption(
        findOptionById(dictionaryStore.locationOptions, assetDetail?.locationId)
      );
      setSelectedBuildingOption(
        findOptionById(dictionaryStore.buildingOptions, assetDetail?.buildingId)
      );

      setSelectedParentTagOption(
        findOptionById(dictionaryStore.projectTagNumberCategoryOptions, assetDetail?.parentEquipmentTagId)
      );

      setSelectedDocumentDatasheetOption(
        findOptionByLabel(dictionaryStore.documentDatasheets, assetDetail?.datasheet)
      );

      setSelectedDocumentPAIDOption(
        findOptionByLabel(dictionaryStore.documentPAID, assetDetail?.pipingAndInstrumentationDiagram)
      );
    };


    fetchOptions().then(setCurrentOptions);
  }, [
    assetDetail?.equipmentTypeId,
    assetDetail?.equipmentTypeCode,
    assetDetail?.equipmentTypeDescription,
    assetDetail?.equipmentStatusId,
    assetDetail?.locationId,
    assetDetail?.locationDescription,
    assetDetail?.buildingId,
    assetDetail?.buildingDescription,
    assetDetail?.id,
    assetDetail?.parentEquipmentTagId,
    assetDetail?.parentTagNumberId,
    assetDetail?.parentTagNumber,
    dictionaryStore
  ]);

  return assetDetail ? (
    <>
    <Box>
    <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            aria-label="Detail-Scrollable-Tags"
            sx ={{ "& .MuiTabScrollButton-horizontal": { width : "25px"}}}
          >
            <Tab label="GENERAL" {...a11yProps(0)} />
            <Tab label="ATTRIBUTES" {...a11yProps(1)} />
            <Tab label="RELATED TAGS" {...a11yProps(2)} />
            <Tab label="DOCUMENTATION" {...a11yProps(3)} />
            <Tab label="EX" {...a11yProps(4)} />
            <Tab label="VALIDATION" {...a11yProps(5)} />
            <Tab label="CMMS" {...a11yProps(6)} />
            <Tab label="INFO" {...a11yProps(7)} />
            <Tab label="HISTORY" {...a11yProps(8)} />
          </Tabs>   
    </Box>
    <Box sx={{ width: "100%", marginBottom: 4 }}>
    <CustomTabPanel value={value} index={0}>
      <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {assetDetail.tagNumber}
        </Typography>
      </Stack>

      <TagDetailEditForm
        projectColumnStore={projectColumnStore}
        assetDetail={assetDetail}
        dictionaryStore={dictionaryStore}
        selectedEqStatusOption={selectedEqStatusOption}
        setSelectedEqStatusOption={setSelectedEqStatusOption}
        handleEqTypeInputChange={handleEqTypeInputChange}
        selectedEqTypeOption={selectedEqTypeOption}
        setSelectedEqTypeOption={setSelectedEqTypeOption}
        serviceDescriptionRef={serviceDescriptionRef}
        RemarksRef={RemarksRef}
        selectedLocationOption={selectedLocationOption}
        handleLocationInputChange={handleLocationInputChange}
        selectedBuildingOption={selectedBuildingOption}
        handleBuildingInputChange={handleBuildingInputChange}
        setSelectedBuildingOption={setSelectedBuildingOption}
        setSelectedLocationOption={setSelectedLocationOption}
        fittingNumberRef={fittingNumberRef}
        circuitNumberRef={circuitNumberRef}
        documentsRef={documentsRef}
        insulationTypeRef={insulationTypeRef}
        subSystemRef={subSystemRef}
        systemNameRef={systemNameRef}
        selectedParentTagOption={selectedParentTagOption}
        handleParentTagInputChange={handleParentTagInputChange}
        setSelectedParentTagOption={setSelectedParentTagOption}
        selectedDocumentDatasheetOption={selectedDocumentDatasheetOption}
        setSelectedDocumentDatasheetOption={setSelectedDocumentDatasheetOption}
        handleDocumentDatasheetInputChange={handleDocumentDatasheetInputChange}
        selectedDocumentPAIDOption={selectedDocumentPAIDOption}
        setSelectedDocumentPAIDOption={setSelectedDocumentPAIDOption}
        handleDocumentPAIDInputChange={handleDocumentPAIDInputChange}
      />
      </CustomTabPanel>
    
        <CustomTabPanel value={value} index={1}>
          <TagDetailAttributesTab />     
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <RelatedTagsTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <TagDetailDocumentationTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <TagDetailExTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <TagDetailValidationTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <TagDetailCMMSTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>     
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}></CustomTabPanel>
      </Box>

      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        position="absolute"
        bottom={0}
        right={0}
        width={"100%"}
        p={2}
        sx={{
          border: "solid",
          borderColor: "#f0f0f0",
          borderWidth: "1px 0px 0px 0px",
        }}
      >
        <Button variant="outlined" onClick={handleCancelButtonClick}>
          Cancel
        </Button>
        <PermissionButton
          buttonText="Save"
          buttonVariant="contained"
          buttonPermission={userStore.hasCurrentUserRole(ROLES.arsProjectUserRole)}
          handleButtonClick={handleSaveButtonClick}
        />
      </Stack>
    </>
  ) : (    
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography component={"span"} sx={{ textAlign: "center" }}>
          Please, select tag from gallery
        </Typography>
      </Container>    
  );
});

export default TagDetailEdit;
