import { DataGridPro } from "@mui/x-data-grid-pro";
import { useStores } from "../../../../hooks/useStores";
import { DataGridBox } from "../../../../styles/dataGridBox";
import { GridColDef } from "@mui/x-data-grid";
import TagDetailTagAttributesToolBar from "../components/tagAttributes/TagDetailTagAttributesToolBar";
import formatDate from "../../../../utils/formatDate";
import { useEffect, useState } from "react";
import { reaction } from "mobx";
import { observer } from "mobx-react";

const columns: GridColDef[] = [
  {
    field: "tagAttributeName",
    headerName: "Attribute Name",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "tagAttributeValue",
    headerName: "Attribute Value",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "uom",
    headerName: "UOM",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "createdDate",
    headerName: "Created On",
    flex: 1,
    minWidth: 150,
    valueFormatter: formatDate,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "updatedDate",
    headerName: "Modified On",
    flex: 1,
    minWidth: 150,
    valueFormatter: formatDate,
  },
  {
    field: "updatedBy",
    headerName: "Modified By",
    flex: 1,
    minWidth: 200,
  },
];

const TagDetailExTab = observer(() => {
  const {
    projectAreaCardViewStore: { exTagAttributes, fetchExTagAttributesById },
    projectAreaCardViewTagAttributesStore: tagAttributeStore,
    dictionaryStore,
  } = useStores();

  useEffect(() => {
    dictionaryStore.fetchTagAttributesNamesByTypeOptions("", 1);
    dictionaryStore.fetchUnitOfMeasuresOptions("");
  }, [dictionaryStore, fetchExTagAttributesById]);

  useEffect(() => {
    if (tagAttributeStore.isRefreshed) {
      tagAttributeStore.setIsRefreshed(false);
    }
  }, [
    tagAttributeStore.isRefreshed,
    fetchExTagAttributesById,
    tagAttributeStore.selectedId,
  ]);

  return (
    <DataGridBox>
      <DataGridPro
        columns={columns}
        rows={exTagAttributes ?? []}
        density="comfortable"
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
          const selectedIds = new Set(ids);
          tagAttributeStore.setSelectedIds(
            Array.from(selectedIds).map((id) => parseInt(id as string))
          );
          tagAttributeStore.setSelectedId(tagAttributeStore.selectedId);
        }}
        slots={{
          toolbar: TagDetailTagAttributesToolBar,
        }}
        sx={{ height: "700px" }}
      />
    </DataGridBox>
  );
});

export default TagDetailExTab;
