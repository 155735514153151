import { observer } from 'mobx-react';
import React, { useState } from 'react'
import { useStores } from '../../../../hooks/useStores';
import { NOTIFICATION_TYPES } from "../../../../constants/notificationTypes";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";


const CustomExcelExportButtonMasterArea = observer(() => {
  const { masterAreaGridViewExportStore, notificationStore } = useStores();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExportAllClick = () => {
    masterAreaGridViewExportStore.exportToExcel();
    handleMenuClose();
  };

  const handleExportSelectedClick = () => {
    if (masterAreaGridViewExportStore.selectedIds.length === 0) {
      notificationStore.setNotificationMessage(
        "Please select at least one item to export"
      );
      notificationStore.setNotificationSeverity(NOTIFICATION_TYPES.info);
      notificationStore.setShowNotification(true);
    } else {
        masterAreaGridViewExportStore.exportSelectedToExcel();
    }

    handleMenuClose();
  };


  return (
    <>
      <LoadingButton
        size="small"
        onClick={handleMenuClick}
        startIcon={<FileDownloadIcon />}
        loading={masterAreaGridViewExportStore.isLoading}
        loadingPosition="start"
        variant="text"
      >
        <span>Export to Excel</span>
      </LoadingButton>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleExportAllClick}>Export All</MenuItem>
        <MenuItem onClick={handleExportSelectedClick}>Export Selected</MenuItem>
      </Menu>
    </>
  );
});

export default CustomExcelExportButtonMasterArea;
