import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { observer } from "mobx-react";
import CreateHandoverButton from "../features/create/CreateHandoverButton";
import RejectHandoverButton from "../features/reject/RejectHandoverButton";
import ApproveHandoverButton from "../features/approve/ApproveHandoverButton";
import { useStores } from "../../../hooks/useStores";
import { ROLES } from "../../../constants/roles";
import CustomFilterButtonTagAudit from "./CustomFilterButtonTagAudit";

const CustomToolBarTagAudit = observer(() => {
  const { userStore } = useStores();

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 0 0 10px",
        }}
      >
        <GridToolbarContainer>
          <CustomFilterButtonTagAudit />
          {userStore.hasCurrentUserRole(ROLES.arsProjectUserRole) && (
            <CreateHandoverButton />
          )}
          {userStore.hasCurrentUserRole(ROLES.arsMasterUserRole) && (
            <>
              <RejectHandoverButton />
              <ApproveHandoverButton />
            </>
          )}
        </GridToolbarContainer>
      </div>
    </>
  );
});

export default CustomToolBarTagAudit;
