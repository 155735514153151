import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStores } from "../../../../hooks/useStores";
import { Button, Menu, MenuItem } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

const CustomMultiActionButtonMasterArea = observer(() => {
  const { masterAreaGridBulkUpdateStore } = useStores();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBulkUpdateClick = () => {
    handleMenuClose();
    masterAreaGridBulkUpdateStore.setShowBulkUpdateForm(true);
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleMenuClick}
        endIcon={<ExpandMore />}
        size="small"
      >
        <span>Bulk Actions</span>
      </Button>
      <Menu
        id="bulk-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleBulkUpdateClick}>Bulk Update</MenuItem>
      </Menu>
    </>
  );
});

export default CustomMultiActionButtonMasterArea;
