import { observer } from "mobx-react";
import React, { useEffect } from "react";
import usePageTitle from "../../hooks/usePageTitle";
import { useStores } from "../../hooks/useStores";
import { Grid } from "@mui/material";
import { DataGridBox } from "../../styles/dataGridBox";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { TagRequestStatus } from "../../models/common/response/tagRequestFormStatus";
import formatDate from "../../utils/formatDate";
import CustomToolBarTagAudit from "./components/CustomToolBarTagAudit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import TagDetailPopup from "../projectArea/cardView/components/TagDetailPopup";

const TagAuditGridPage = observer(() => {
  usePageTitle("Tag Audit - Main");

  const columns = [
    {
      field: "tagNumber",
      headerName: "Tag Number",
      flex: 1,
      minWidth: 180,
      renderCell: (params: any) => {
        return (
          <>
            <a
              href="#"
              rel="noreferrer"
              onClick={() => handleShowCardModalClick(params.row.id)}
            >
              {params.value}
            </a>
          </>
        );
      },
    },
    {
      field: "projectNumber",
      headerName: "Project Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Tag Status",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "requestStatus",
      headerName: "Handover Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        let color;
        switch (TagRequestStatus[params.value]) {
          case "Submitted":
            color = "orange";
            break;
          case "Rejected":
            color = "red";
            break;
          case "Completed": // Assuming 'Completed' is equivalent to 'Approved'
            color = "green";
            break;
          default:
            color = "inherit"; // Default text color
        }
        return <strong style={{ color: color }}>{TagRequestStatus[params.value]}</strong>;
      },
    },
    {
      field: "handoverRemarks",
      headerName: "Handover Remarks",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "validationStatus",
      headerName: "Validation Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return params.value ? <CheckIcon /> : <CloseIcon />;
      },
    },
    {
      field: "statusCorrectionField",
      headerName: "Status Correction",
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => {
        return (
          <a href="#" onClick={() => handleShowCardModalClick(params.row.id)}>
            Correction details
          </a>
        );
      },
    },
    {
      field: "projectHandoverPackageName",
      headerName: "Package Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "system",
      headerName: "System",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "subSystem",
      headerName: "Sub System",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "purchaseOrder",
      headerName: "Purchase Order",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "updatedBy",
      headerName: "Modified By",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "updatedDate",
      headerName: "Modified on",
      flex: 1,
      minWidth: 150,
      valueFormatter: formatDate,
    },
  ];

  const {
    tagAuditStore,
    dictionaryStore,
    projectAreaPopupCardViewStore: {
      fetchAssetById,
      fetchRelatedTagsById,
      fetchDocumentsById,
      fetchTagAttributesById,
      setShowCardModal,
    },
    projectAreaCardViewStore: { fetchValidationsById },
  } = useStores();

  const handleShowCardModalClick = (id: number) => {
    fetchAssetById(id);
    fetchRelatedTagsById(id);
    fetchDocumentsById(id);
    fetchTagAttributesById(id);
    fetchValidationsById(id);
    setShowCardModal(true);
  };

  useEffect(() => {
    dictionaryStore.fetchProjectsFromProjectEquipments("");
    dictionaryStore.fetchProjectSystemnames("");
    dictionaryStore.fetchProjectSubsystems("");

    const fetchData = async () => {
      await tagAuditStore.fetchTagAuditData();
    };

    fetchData();
  }, [
    tagAuditStore,
    tagAuditStore.createHandover,
    tagAuditStore.selectedTagAuditProjectOption,
    tagAuditStore.selectedProjectSystemnameOption,
    tagAuditStore.selectedProjectSubsystemOption,
    dictionaryStore,
  ]);

  const isRowSelectable = (params: any) => {
    return params.row.requestStatus !== TagRequestStatus.Completed;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <DataGridBox>
            <DataGridPro
              isRowSelectable={isRowSelectable}
              paginationMode="server"
              rows={tagAuditStore.tagAuditGridResponse.items}
              columns={columns}
              rowCount={tagAuditStore.tagAuditGridResponse.totalRecords}
              loading={tagAuditStore.isLoading}
              pagination
              paginationModel={tagAuditStore.paginationModel}
              pageSizeOptions={[tagAuditStore.paginationModel.pageSize]}
              onPaginationModelChange={tagAuditStore.setPaginationModel}
              sortingMode="server"
              sortModel={tagAuditStore.sortModel}
              onSortModelChange={(model) => {
                tagAuditStore.setSortModel(model);
                tagAuditStore.fetchTagAuditData();
              }}
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                const selectedIds = new Set(ids);
                tagAuditStore.setSelectedIds(
                  Array.from(selectedIds).map((id) => parseInt(id as string))
                );
              }}
              disableRowSelectionOnClick
              density="compact"
              sx={{ fontSize: "0.75rem" }}
              slots={{
                toolbar: CustomToolBarTagAudit,
              }}
            />
          </DataGridBox>
        </Grid>
      </Grid>
      <TagDetailPopup />
    </>
  );
});

export default TagAuditGridPage;
