import { useSearchParams } from 'react-router-dom';
import { SelectOption } from '../models/common/selectOption';
import { useEffect, useRef } from 'react';

type ValueType = string | number | string[] | Record<string, unknown> | SelectOption | null | undefined;

function encodeValue(value: ValueType): string {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value);
  }
  return value?.toString() ?? '';
}

function decodeValue<T extends ValueType>(value: string): T {
  try {
    return JSON.parse(value) as T;
  } catch {
    return value as T;
  }
}

export function useSearchParamsState<T extends ValueType>(
  searchParamName: string,
  defaultValue: T
): readonly [T, (newState: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const acquiredSearchParam = searchParams.get(searchParamName);

  const searchParamsStateRef = useRef<T>(acquiredSearchParam ? decodeValue(acquiredSearchParam) : defaultValue);
  
  const setSearchParamsState = (newState: T) => {
    if (newState !== searchParamsStateRef.current) {
      const currentSearchParams = new URLSearchParams(window.location.search);
      currentSearchParams.set(searchParamName, encodeValue(newState));
      setSearchParams(currentSearchParams);
      searchParamsStateRef.current = newState;
    }
  };

  useEffect(() => {
    setSearchParamsState(searchParamsStateRef.current);
  }, []);

  return [searchParamsStateRef.current, setSearchParamsState];
}
