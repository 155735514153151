import { observer } from "mobx-react";
import TagDetailDeleteRelatedTag from "./TagDetailDeleteRelatedTag";
import TagDetailAddRelatedTag from "./TagDetailAddRelatedTag";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { useStores } from "../../../../../hooks/useStores";
import { TagRequestStatus } from "../../../../../models/common/response/tagRequestFormStatus";

const TagDetailRelatedTagToolBar = observer(() => {
  const {
    projectAreaCardViewStore: { assetDetail },
  } = useStores();

  const isCompleted = assetDetail?.status === TagRequestStatus.Completed;

  return (
    <GridToolbarContainer>
      {!isCompleted && (
        <>
          <TagDetailAddRelatedTag />
          <TagDetailDeleteRelatedTag />
        </>
      )}
    </GridToolbarContainer>
  );
});

export default TagDetailRelatedTagToolBar;
