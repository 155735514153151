import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useStores } from "../../../../../hooks/useStores";
import { useEffect } from "react";
import TagDetailAddDocumentModal from "./TagDetailAddDocumentModal";
// import TagDetailsAddDocumentDialog from "./TagDetailAddDocumentDialog";

const TagDetailAddDocument = observer(() => {
  const { projectAreaCardViewDocumentStore, dictionaryStore } = useStores();  

  const handleAddClick = () => {
    projectAreaCardViewDocumentStore.setShowAddDocumentModal(true);
    projectAreaCardViewDocumentStore.setShowCreateDocumentAlert(false);
    projectAreaCardViewDocumentStore.setSelectedProjectDocOption(null);
    projectAreaCardViewDocumentStore.setSelectedProjectDocTypeOption(null);
    projectAreaCardViewDocumentStore.setProjectDocUrl();
  };

  useEffect(() => {
    dictionaryStore.fetchDocTypeOptions("");
    projectAreaCardViewDocumentStore.getProjDocOptions("");
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        disabled={projectAreaCardViewDocumentStore.selectedIds.length > 0}
        onClick={handleAddClick}
      >
        <span>Add</span>
      </Button>
      {/* <TagDetailsAddDocumentDialog />       */}
      <TagDetailAddDocumentModal />
    </>
  );
});

export default TagDetailAddDocument;
