import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import TagDetailsAddRelatedTagModal from "./TagDetailAddRelatedTagModal";

const TagDetailAddRelatedTag = observer(() => {
  const {
    projectAreaCardViewRelatedTagStore: relatedTagStore,
    dictionaryStore
  } = useStores();

  const handleAddClick = () => {
    relatedTagStore.setShowAddRelatedTagModal(true);
    relatedTagStore.setSelectedRelationType(null);
    relatedTagStore.setSelectedRelatedTag(null);
    dictionaryStore.fetchProjectTagNumberCategoryOptions("");
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        disabled={relatedTagStore.selectedIds.length > 0}
        onClick={handleAddClick}
      >
        <span>Add</span>
      </Button>
      <TagDetailsAddRelatedTagModal />
    </>
  );
});

export default TagDetailAddRelatedTag;
