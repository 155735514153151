import authInstance, { loginRequest } from '../components/Security/msalConfig';

export const acquireAccessToken = async () => {
    const activeAccount = authInstance.getActiveAccount();
    const accounts = authInstance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
      throw new Error('User is not signed in');
    }
    const authResult = await authInstance.acquireTokenSilent(loginRequest);
  
    return authResult.accessToken;
  };

class ApiBase {
    protected getBaseUrl(): string {
      return process.env.REACT_APP_API_BASE_URI ?? ''; 
    }
 
    protected async getRequestInit<T>(method: string, body?: T): Promise<RequestInit> {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        
        const accessToken = await acquireAccessToken();    

        if (accessToken) {
            headers.append("Authorization", `Bearer ${accessToken}`);
        }
    
        const requestOptions: RequestInit = {
          method,
          headers,
          body: body ? JSON.stringify(body) : null,
        };
    
        return requestOptions;
      }
    
      protected async handleResponse<T>(response: Response): Promise<T> {
        if (!response.ok) {
          throw new Error('API request failed');
        }
        const data = await response.json();
        return data as T;
      }
    }
  
  
  export default ApiBase;
  