import { Button } from "@mui/material";
import { observer } from "mobx-react";
import CloseIcon from "@mui/icons-material/Close";
import { useStores } from "../../../../hooks/useStores";
import RejectHandoverButtonModal from "./RejectHandoverButtonModal";
import { TagRequestStatus } from "../../../../models/common/response/tagRequestFormStatus";

const RejectHandoverButton = observer(() => {
  const { tagAuditStore } = useStores();

  const handleRejectHandoverClick = () => {
    tagAuditStore.setshowRejectHandoverModal(true);
  };

  const isButtonDisabled = () => {
    return (
      tagAuditStore.selectedIds.some((id) => {
        const row = tagAuditStore.tagAuditGridResponse.items.find(
          (item) => item.id === id
        ) as any;
        return (
          row &&
          (!row.validationStatus || row.requestStatus !== TagRequestStatus.Submitted)
        );
      }) || tagAuditStore.selectedIds.length < 1
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<CloseIcon />}
        disabled={isButtonDisabled()}
        onClick={handleRejectHandoverClick}
      >
        <span>Reject</span>
      </Button>
      {tagAuditStore.showRejectHandoverModal ? <RejectHandoverButtonModal /> : null}
    </>
  );
});

export default RejectHandoverButton;
