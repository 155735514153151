import { observer } from "mobx-react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import CreateUserProjectMapButton from "../features/create/CreateUserProjectMapButton";
import DeleteUserProjectMapButton from "../features/delete/DeleteUserProjectMapButton";
import EditUserProjectMapButton from "../features/edit/EditUserProjectMapButton";

const CustomToolBarUserProjectsManagement = observer(() => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 0 0 10px",
        }}
      >
        <GridToolbarContainer>
          <CreateUserProjectMapButton />
          <EditUserProjectMapButton />
          <DeleteUserProjectMapButton />
        </GridToolbarContainer>
      </div>
    </>
  );
});

export default CustomToolBarUserProjectsManagement;
