import ApiBase from "./apiBase";
import { GetAssetsRequest } from "../models/ProjectArea/request/getAssetsRequest";
import { GetAssetResponse } from "../models/ProjectArea/response/getAssetsResponse";
import { GetAssetDetailResponse } from "../models/ProjectArea/response/getAssetsDetailResponse";
import { GetMocNumbersResponse } from "../models/Dictionaries/response/getMocNumbersResponse";
import { GetProjectNumbersResponse } from "../models/Dictionaries/response/getProjectNumbersResponse";
import { GetMasterTagNumbersResponse } from "../models/Dictionaries/response/getMasterTagNumbersResponse";
import { GetMasterTagNumberCategoryResponse } from "../models/Dictionaries/response/getMasterTagNumberCategoryResponse";
import { GetAreasResponse } from "../models/Dictionaries/response/getAreasResponse";
import { GetFacilitiesResponse } from "../models/Dictionaries/response/getFacilitiesResponse";
import { GetUnitsResponse } from "../models/Dictionaries/response/getUnitsResponse";
import { GetTagTypesResponse } from "../models/Dictionaries/response/getTagTypesResponse";
import { GetEquipmentStatusesResponse } from "../models/Dictionaries/response/getEquipmentStatusesResponse";
import { GetDisciplineCodesResponse } from "../models/Dictionaries/response/getDisciplineCodesResponse";
import { GetTagCategoriesResponse } from "../models/Dictionaries/response/getTagCategoriesResponse";
import { GetEclDisciplinesResponse } from "../models/Dictionaries/response/getEclDisciplinesResponse";
import { GetMasterAssetsRequest } from "../models/MasterArea/request/getMasterAssetsRequest";
import { GetMasterAssetResponse } from "../models/MasterArea/response/getMasterAssetsResponse";
import { GetMasterAreaProjectsRequest } from "../models/MasterArea/request/getMasterAreaProjectsRequest";
import { GetMasterAreaProjectsResponse } from "../models/MasterArea/response/getMasterAreaProjectsResponse";
import { GetMasterAreaProjectByIdResponse } from "../models/MasterArea/response/getMasterAreaProjectByIdResponse";
import { GetMasterAreaTagAttributeByIdResponse } from "../models/MasterArea/response/getMasterAreaTagAttributeByIdResponse";
import { GetMasterAreaDocumentByIdResponse } from "../models/MasterArea/response/getMasterAreaDocumentByIdResponse";
import { GetMasterAreaTagAttributesRequest } from "../models/MasterArea/request/getMasterAreaTagAttributesRequest";
import { GetMasterAreaTagAttributesResponse } from "../models/MasterArea/response/getMasterAreaTagAttributesResponse";
import { GetMasterAreaDocumentsRequest } from "../models/MasterArea/request/getMasterAreaDocumentsRequest";
import { GetMasterAreaDocumentsResponse } from "../models/MasterArea/response/getMasterAreaDocumentsResponse";
import { GetAssetsExportRequest } from "../models/ProjectArea/request/getAssetsExportRequest";
import { GetAssetsMinRequest } from "../models/ProjectArea/request/getAssetsMinRequest";
import { GetAssetsMinResponse } from "../models/ProjectArea/response/getAssetsMinResponse";
import { UpdateAssetByIdRequest } from "../models/ProjectArea/request/updateAssetByIdRequest";
import { GetColumnConfigResponse } from "../models/Configuration/response/getColumnConfigResponse";
import { GetLocationsResponse } from "../models/Dictionaries/response/getLocationsResponse";
import { GetBuildingsResponse } from "../models/Dictionaries/response/getBuildingsResponse";
import { BulkUpdateAssetsRequest } from "../models/ProjectArea/request/bulkUpdateAssetsRequest";
import { GetMasterAssetsMinRequest } from "../models/MasterArea/request/getMasterAssetsMinRequest";
import { GetMasterAssetsMinResponse } from "../models/MasterArea/response/getMasterAssetsMinResponse";
import { GetMasterAssetDetailResponse } from "../models/MasterArea/response/getAssetsDetailResponse";
import { UpdateMasterAssetByIdRequest } from "../models/MasterArea/request/updateMasterAssetByIdRequest";
import { GetMasterAssetsExportRequest } from "../models/MasterArea/request/getMasterAssetsExportRequest";
import { BulkUpdateMasterAssetsRequest } from "../models/MasterArea/request/bulkUpdateMasterAssetsRequest";
import { GetSequenceValidationRequest } from "../models/MasterArea/request/getSequenceValidationRequest";
import { GetSequenceValidationResponse } from "../models/MasterArea/response/getSequenceValidationResponse";
import { GetMasterRelatedTagsByIdResponse } from "../models/MasterArea/response/getMasterRelatedTagsByIdResponse";
import { GetMasterTagAttributesById } from "../models/MasterArea/response/getMasterTagAttributesByIdResponse";
import { GetRelatedTagsByIdResponse } from "../models/ProjectArea/response/getRelatedTagsById";
import { GetTagAttributesByIdResponse } from "../models/ProjectArea/response/getTagAttributesByIdResponse";
import { GetTagValidationResponse } from "../models/ProjectArea/response/getTagValidationResponse";
import { GetDocumentsByIdResponse } from "../models/ProjectArea/response/getDocumentsByIdResponse";
import { GetMasterDocumentsByIdResponse } from "../models/MasterArea/response/getMasterDocumentsResponse";
import { UpdateCounterRequest } from "../models/MasterArea/request/updateCounterRequest";
import { GetMasterDocumentsByDocNumberResponse } from "../models/MasterArea/response/getMasterDocumentsByDocNumberResponse";
import { AddDocumentRequest } from "../models/ProjectArea/request/addDocumentRequest";
import { CreateDocumentRequest } from "../models/ProjectArea/request/createDocumentRequest";
import { GetDocumentTypesResponse } from "../models/Dictionaries/response/getDocumentTypesResponse";
import { GetDocumentByIdResponse } from "../models/ProjectArea/response/getDocumentByIdResponse";
import { GetDocumentsResponse } from "../models/ProjectArea/response/getDocumentsResponse";
import { DeleteDocumentRequest } from "../models/ProjectArea/request/deleteDocumentRequest";
import { EditDocumentRequest } from "../models/ProjectArea/request/editDocumentRequest";
import { UpdateTagAttributesByIdRequest } from "../models/ProjectArea/request/editTagAttributesRequest";
import { GetTagAttributeDetailsByIdResponse } from "../models/ProjectArea/response/getTagAttributeDetailsByIdResponse";
import { GetEclTagAttributesResponse } from "../models/Dictionaries/response/getEclTagAttributesResponse";
import { CreateTagAttributesRequest } from "../models/ProjectArea/request/createTagAttributesRequest";
import { GetProjectTagNumberCategoryResponse } from "../models/ProjectArea/response/getProjectTagNumberCategoryResponse";
import { CreateRelatedTagRequest } from "../models/ProjectArea/request/createRelatedTagRequest";
import { DeleteRelatedTagRequest } from "../models/ProjectArea/request/deleteRelatedTagRequest";
import { GetTagRequestFormsRequest } from "../models/TagRequest/request/getTagRequestFormsRequest";
import { GetTagRequestFormsResponse } from "../models/TagRequest/response/getTagRequestFormsResponse";
import { EditTagRequestByIdRequest } from "../models/TagRequest/request/editTagRequestByIdRequest";
import { GetTagRequestByIdResponse } from "../models/TagRequest/response/getTagRequestByIdResponse";
import { CreateTagRequest } from "../models/TagRequest/request/createTagRequest";
import { GetWellsResponse } from "../models/Dictionaries/response/getWellsResponse";
import { CreateBBTagRequest } from "../models/TagRequest/request/createBBTagRequest";
import { CheckDuplicateBBTag } from "../models/TagRequest/request/checkDuplicateBBTag";
import { EditBBTagRequest } from "../models/TagRequest/request/editBBTagRequest";
import { WorkingCopyBBTagRequest } from "../models/TagRequest/request/workingCopyBBTagRequest";
import { GetWorkingCopy } from "../models/TagRequest/request/getWorkingCopy";
import { GetUOMResponse } from "../models/Dictionaries/response/getUOMResponse";
import { GetPrimaryTagsResponse } from "../models/Dictionaries/response/getPrimaryTagsResponse";
import { RejectTagAuditRequest } from "../models/TagAudit/request/rejectTagAuditRequest";
import { GetProjectsFromProjectEquipmentsResponse } from "../models/Dictionaries/response/getProjectsFromProjectEquipmentsResponse";
import { GetPipeClassesResponse } from "../models/Dictionaries/response/getPipeClassesResponse";
import { GetFluidsResponse } from "../models/Dictionaries/response/getFluidsResponse";
import { GetSubstationsResponse } from "../models/Dictionaries/response/getSubstationsResponse";
import { GetProjectHandoverPackagesRequest } from "../models/TagAudit/request/getProjectHandoverPackagesRequest";
import { GetProjectHandoverPackagesResponse } from "../models/TagAudit/response/getProjectHandoverPackagesResponse";
import { GetProjectHandoverPackageByIdResponse } from "../models/TagAudit/response/getProjectHandoverPackageByIdResponse";
import { GetProjectSystemnamesResponse } from "../models/Dictionaries/response/getProjectSystemnamesResponse";
import { GetProjectSubsystemsResponse } from "../models/Dictionaries/response/getProjectSubsystemsResponse";
import { GetUsersResponse } from "../models/Dictionaries/response/getUsersResponse";
import { GetUserProjectMapsRequest } from "../models/AdminArea/request/getUserProjectMapsRequest";
import { GetUserProjectMapsResponse } from "../models/AdminArea/response/getUserProjectMapsResponse";
import { CreateUserProjectMapRequest } from "../models/AdminArea/request/createUserProjectMapRequest";
import { DeleteUserProjectMapRequest } from "../models/AdminArea/request/deleteUserProjectMapRequest";
import { GetUserProjectMapByIdResponse } from "../models/AdminArea/response/getUserProjectMapsByIdResponse";
import { CreateHandoverRequest } from "../models/TagAudit/request/createHandoverRequest";
import { GetDocumentDatasheetsResponse } from "../models/Dictionaries/response/getDocumentDatasheetsResponse";
import { GetDocumentPAIDResponse } from "../models/Dictionaries/response/getDocumentPAIDResponse";
import { NullableNumber } from "../models/common/response/types";

class ApiClient extends ApiBase {
  // Get all moc numbers
  async fetchMocNumbers(searchText: string): Promise<GetMocNumbersResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetMocNumbers?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMocNumbersResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all project numbers
  async fetchProjectNumbers(
    searchText: string
  ): Promise<GetProjectNumbersResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetProjects?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetProjectNumbersResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

   // Get all master tag numbers
   async fetchMasterTagNumbers(searchText: string): Promise<GetMasterTagNumbersResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetMasterTagNumbers?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterTagNumbersResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

   // Get all master tag numbers by Categories
   async fetchMasterParentTagsByCategory(searchText: string, categoryId?: number): Promise<GetMasterTagNumbersResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetMasterParentTagByCategory?SearchText=${searchText}${categoryId ? "&CategoryId=" + categoryId : ""}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterTagNumbersResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all tag request categories
  async fetchTagRequestCategories(searchText: string): Promise<GetMasterTagNumberCategoryResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetMasterTagNumberCategory?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterTagNumberCategoryResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all areas
  async fetchAreas(searchText: string, facilityId?: number, categoryCode?: string): Promise<GetAreasResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetAreas?SearchText=${searchText}${facilityId ? "&FacilityId=" + facilityId : ""}${categoryCode ? "&CategoryCode=" + categoryCode : ""}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetAreasResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all facilities
  async fetchFacilities(searchText: string): Promise<GetFacilitiesResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetFacilities?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetFacilitiesResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all units
  async fetchUnits(searchText: string, areaId?: number): Promise<GetUnitsResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetUnits?SearchText=${searchText}${areaId ? "&AreaId=" + areaId : ""}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetUnitsResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get tag type by id
  async fetchTagTypeById(id: number): Promise<GetTagTypesResponse> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetTagTypeById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetTagTypesResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all tag types
  async fetchTagTypes(searchText: string, categoryId?: number): Promise<GetTagTypesResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetTagTypes?SearchText=${searchText}${categoryId ? "&CategoryId=" + categoryId : ""}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetTagTypesResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all unit of measures
  async fetchUnitOfMeasures(searchText: string, uomClassId?: number): Promise<GetUOMResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetUnitsOfMeasureByUomClass?SearchText=${searchText}${uomClassId ? "&UomClassId=" + uomClassId : ""}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetUOMResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all primary tags
  async fetchPrimaryTags(searchText: string, categoryCode?: string): Promise<GetPrimaryTagsResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetMasterPrimaryTagByCategory?SearchText=${searchText}${categoryCode ? "&CategoryCode=" + categoryCode : ""}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetPrimaryTagsResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all discipline codes
  async fetchDisciplineCodes(searchText: string): Promise<GetDisciplineCodesResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetTagDisciplines?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetDisciplineCodesResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all pipe classes
  async fetchPipeClasses(searchText: string): Promise<GetPipeClassesResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetPipeClasses?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetPipeClassesResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  
  // Get pipe class
  async fetchPipeClassById(id: number): Promise<GetPipeClassesResponse> {
    const url = `${this.getBaseUrl()}/api/Admin/GetPipeClassById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetPipeClassesResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all substations
  async fetchSubstations(searchText: string): Promise<GetSubstationsResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetSubstations?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetSubstationsResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

   // Get substation
   async fetchSubstationById(id: number): Promise<GetSubstationsResponse> {
    const url = `${this.getBaseUrl()}/api/Admin/GetSubstationById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetSubstationsResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all fluids
  async fetchFluids(searchText: string): Promise<GetFluidsResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetFluids?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetFluidsResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get fluid
  async fetchFluidById(id: number): Promise<GetFluidsResponse> {
    const url = `${this.getBaseUrl()}/api/Admin/GetFluidById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetFluidsResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all equipment statuses
  async fetchEquipmentStatuses(): Promise<GetEquipmentStatusesResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetEquipmentStatuses`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetEquipmentStatusesResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all tag categories
  async fetchTagCategories(
    searchText: string
  ): Promise<GetTagCategoriesResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetTagCategories?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetTagCategoriesResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all ecl disciplines
  async fetchEclsDisciplines(
    searchText: string
  ): Promise<GetEclDisciplinesResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetEclDisciplines?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetEclDisciplinesResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all assets
  async getAssets(request: GetAssetsRequest): Promise<GetAssetResponse> {
    const url = `${this.getBaseUrl()}/api/Projects/GetAssets`;

    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetAssetResponse>(response);
    } catch (error) {
      throw new Error("Error fetching assets");
    }
  }

  // Get asset detail
  async getAssetDetail(id: number): Promise<GetAssetDetailResponse> {
    const url = `${this.getBaseUrl()}/api/Projects/GetAssetById?id=${id}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetAssetDetailResponse>(response);
    } catch (error) {
      throw new Error("Error fetching assets");
    }
  }

  // Get all master assets
  async getMasterAssets(
    request: GetMasterAssetsRequest
  ): Promise<GetMasterAssetResponse> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterAssets`;

    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterAssetResponse>(response);
    } catch (error) {
      throw new Error("Error fetching assets");
    }
  }

  // Get all master area projects
  async getMasterAreaProjects(
    request: GetMasterAreaProjectsRequest
  ): Promise<GetMasterAreaProjectsResponse> {
    const url = `${this.getBaseUrl()}/api/Projects/GetProjectsDataTable`;

    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterAreaProjectsResponse>(response);
    } catch (error) {
      throw new Error("Error fetching projects");
    }
  }

  // Get master area project by id
  async getMasterAreaProjectById(id: number): Promise<GetMasterAreaProjectByIdResponse> {
    const url = `${this.getBaseUrl()}/api/Projects/GetProjectById?id=${id}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterAreaProjectByIdResponse>(response);
    } catch (error) {
      throw new Error("Error fetching project");
    }
  }

  // Get all master area tag attributes
  async getMasterAreaTagAttributes(
    request: GetMasterAreaTagAttributesRequest
  ): Promise<GetMasterAreaTagAttributesResponse> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterTagAttributesDataTable`;

    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterAreaTagAttributesResponse>(response);
    } catch (error) {
      throw new Error("Error fetching tag attributes");
    }
  }

  // Get master area tag attribute by id
  async getMasterAreaTagAttributeById(id: number): Promise<GetMasterAreaTagAttributeByIdResponse> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterTagAttributeDetailsById?id=${id}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterAreaTagAttributeByIdResponse>(response);
    } catch (error) {
      throw new Error("Error fetching tag attribute");
    }
  }

   // Get all master area documents
   async getMasterAreaDocuments(
    request: GetMasterAreaDocumentsRequest
  ): Promise<GetMasterAreaDocumentsResponse> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterDocumentsDataTable`;

    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterAreaDocumentsResponse>(response);
    } catch (error) {
      throw new Error("Error fetching documents");
    }
  }

  // Get master area tag attribute by id
  async getMasterAreaDocumentById(id: number): Promise<GetMasterAreaDocumentByIdResponse> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterDocumentById?id=${id}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterAreaDocumentByIdResponse>(response);
    } catch (error) {
      throw new Error("Error fetching document");
    }
  }

  // Get all assets export
  async getAssetsExport(request: GetAssetsExportRequest) {
    const url = `${this.getBaseUrl()}/api/Projects/GetAssetsExport`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error("API request failed");
      }
      const date = new Date().toISOString().replace(/:/g, "-");
      const contentDisposition = response.headers.get("Content-Disposition");
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("=")[1].trim()
        : `project-tags-${date}.xlsx`;

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    } catch (error) {
      throw new Error("Error fetching assets");
    }
  }

  /**
   * Get all assets min
   * @param {GetAssetsMinRequest} request
   * @returns {Promise<GetAssetsMinResponse>}
   */
  async getAssetsMin(
    request: GetAssetsMinRequest
  ): Promise<GetAssetsMinResponse> {
    const url = `${this.getBaseUrl()}/api/Projects/GetAssetsMin`;
    console.log(request)
    const options = await this.getRequestInit("POST", request);

    try {
      console.log(await fetch(url, options))

      const response = await fetch(url, options);
      return this.handleResponse<GetAssetsMinResponse>(response);
    } catch (error) {
      throw new Error("Error fetching assets");
    }
  }

  /**
   * Export selected assets
   * @param {number[]} ids
   */
  async getSelectedAssetsExport(ids: number[]) {
    const idParams = ids.map((id) => `ids=${id}`).join("&");
    const url = `${this.getBaseUrl()}/api/Projects/GetSelectedAssetsExport?${idParams}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error("API request failed");
      }
      const date = new Date().toISOString().replace(/:/g, "-");
      const contentDisposition = response.headers.get("Content-Disposition");
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("=")[1].trim()
        : `project-tags-${date}.xlsx`;

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    } catch (error) {
      throw new Error("Error fetching selected assets export");
    }
  }

  /**
   * Update asset by id
   * @param {UpdateAssetByIdRequest} request
   * @returns {Promise<Response>}
   */
  async updateAssetById(request: UpdateAssetByIdRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/Projects/UpdateAssetById`;
    const options = await this.getRequestInit("PUT", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error updating asset");
      }
    } catch (error) {
      throw new Error("Error updating asset");
    }
  }

  /**
   * Get all column configs
   * @returns {Promise<GetColumnConfigResponse[]>}
   */
  async fetchColumnConfigs(): Promise<GetColumnConfigResponse[]> {
    const url = `${this.getBaseUrl()}/api/Configuration/GetColumnConfigs`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetColumnConfigResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all locations
  async fetchLocations(searchText: string): Promise<GetLocationsResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetLocations?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetLocationsResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

   // Get location by Id
   async fetchLocationById(id: number): Promise<GetLocationsResponse> {
    const url = `${this.getBaseUrl()}/api/Admin/GetLocationById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetLocationsResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all buldings
  async fetchBuildings(searchText: string): Promise<GetBuildingsResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetBuildings?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetBuildingsResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get building by Id
  async fetchBuildingById(id: number): Promise<GetBuildingsResponse> {
    const url = `${this.getBaseUrl()}/api/Admin/GetBuildingById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetBuildingsResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all wells
  async fetchWells(searchText: string): Promise<GetWellsResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetWells?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetWellsResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get well
  async fetchWellById(id: number): Promise<GetWellsResponse> {
    const url = `${this.getBaseUrl()}/api/Admin/GetWellById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetWellsResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  /**
   * Bulk Update assets
   * @param {BulkUpdateAssetsRequest} request
   * @returns {Promise<Response>}
   */
  async bulkUpdateAssets(request: BulkUpdateAssetsRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/Projects/BulkUpdateAssets`;
    const options = await this.getRequestInit("PUT", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error updating assets");
      }
    } catch (error) {
      throw new Error("Error updating assets");
    }
  }

  /**
   * Get all master assets min
   * @param {GetMasterAssetsMinRequest} request
   * @returns {Promise<GetMasterAssetsMinResponse>}
   */
  async getMasterAssetsMin(
    request: GetMasterAssetsMinRequest
  ): Promise<GetMasterAssetsMinResponse> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterAssetsMin`;

    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetAssetsMinResponse>(response);
    } catch (error) {
      throw new Error("Error fetching master assets");
    }
  }

  // Get master asset detail
  async getMasterAssetDetail(
    id: number
  ): Promise<GetMasterAssetDetailResponse> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterAssetById?id=${id}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterAssetDetailResponse>(response);
    } catch (error) {
      throw new Error("Error fetching assets");
    }
  }

  /**
   * Update master asset by id
   * @param {UpdateMasterAssetByIdRequest} request
   * @returns {Promise<Response>}
   */
  async updateMasterAssetById(
    request: UpdateMasterAssetByIdRequest
  ): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/Master/UpdateMasterAssetById`;
    const options = await this.getRequestInit("PUT", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error updating master asset");
      }
    } catch (error) {
      throw new Error("Error updating master asset");
    }
  }

  // Get all Master Assets export
  async getMasterAssetsExport(request: GetMasterAssetsExportRequest) {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterAssetsExport`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error("API request failed");
      }
      const date = new Date().toISOString().replace(/:/g, "-");
      const contentDisposition = response.headers.get("Content-Disposition");
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("=")[1].trim()
        : `master-tags-${date}.xlsx`;

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    } catch (error) {
      throw new Error("Error fetching assets");
    }
  }

  /**
   * Export selected Master Assets
   * @param {number[]} ids
   */
  async getSelectedMasterAssetsExport(ids: number[]) {
    const idParams = ids.map((id) => `ids=${id}`).join("&");
    const url = `${this.getBaseUrl()}/api/Master/GetSelectedMasterAssetsExport?${idParams}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error("API request failed");
      }
      const date = new Date().toISOString().replace(/:/g, "-");
      const contentDisposition = response.headers.get("Content-Disposition");
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("=")[1].trim()
        : `master-tags-${date}.xlsx`;

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    } catch (error) {
      throw new Error("Error fetching selected assets export");
    }
  }

  /**
   * Bulk Update Master Assets
   * @param {BulkUpdateMasterAssetsRequest} request
   * @returns {Promise<Response>}
   */
  async bulkUpdateMasterAssets(
    request: BulkUpdateMasterAssetsRequest
  ): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/TagCreation/BulkEditTags`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error updating assets");
      }
    } catch (error) {
      throw new Error("Error updating assets");
    }
  }

  /**
   * Get related tags by id
   * @param {number} id
   * @returns {Promise<GetRelatedTagsByIdResponse[]>}
   */
  async getRelatedTagsById(id: number): Promise<GetRelatedTagsByIdResponse[]> {
    const url = `${this.getBaseUrl()}/api/Projects/GetRelatedTagsById?id=${id}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetRelatedTagsByIdResponse[]>(response);
    } catch (error) {
      throw new Error("Error fetching related tags");
    }
  }

  /**
   * Get tag attributes by id
   * @param {number} id
   * @returns {Promise<GetTagAttributesByIdResponse[]>}
   */
  async getTagAttributesById(
    id: number,
    type?: number
  ): Promise<GetTagAttributesByIdResponse[]> {
    const url = `${this.getBaseUrl()}/api/Projects/GetTagAttributesById?id=${id}&type=${type}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetTagAttributesByIdResponse[]>(response);
    } catch (error) {
      throw new Error("Error fetching tag attributes");
    }
  }

  /**
   * Get master related tags by id
   * @param {number} id
   * @returns {Promise<GetMasterRelatedTagsByIdResponse[]>}
   */
  async getMasterRelatedTagsById(
    id: number
  ): Promise<GetMasterRelatedTagsByIdResponse[]> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterRelatedTagsById?id=${id}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterRelatedTagsByIdResponse[]>(response);
    } catch (error) {
      throw new Error("Error fetching assets");
    }
  }

  /**
   * Get master tag attributes by id
   * @param {number} id
   * @returns {Promise<GetMasterTagAttributesById[]>}
   */
  async getMasterTagAttributesById(
    id: number
  ): Promise<GetMasterTagAttributesById[]> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterTagAttributesById?id=${id}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterTagAttributesById[]>(response);
    } catch (error) {
      throw new Error("Error fetching assets");
    }
  }

  // GET Documents By Id
  async getDocumentsById(id: number): Promise<GetDocumentsByIdResponse[]> {
    const url = `${this.getBaseUrl()}/api/Projects/GetDocumentsById?id=${id}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetDocumentsByIdResponse[]>(response);
    } catch (error) {
      throw new Error("Error fetching documents");
    }
  }

   /**
   * Get master documents by documentNumber
   * @param {string} documentNumber
   * @returns {Promise<GetMasterDocumentsByDocNumberResponse[]>}
   */
   async getMasterDocumentsByDocNumber(documentNumber?: string): Promise<GetMasterDocumentsByDocNumberResponse[]> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterDocuments?DocumentNumber=${documentNumber}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterDocumentsByDocNumberResponse[]>(response);
    } catch (error) {
      throw new Error("Error fetching master documents");
    }
  }

  /**
   * Get master documents by id
   * @param {number} id
   * @returns {Promise<GetMasterDocumentsByIdResponse[]>}
   */
  async getMasterDocumentsById(
    id: number
  ): Promise<GetMasterDocumentsByIdResponse[]> {
    const url = `${this.getBaseUrl()}/api/Master/GetMasterDocumentsById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetMasterDocumentsByIdResponse[]>(response);
    } catch (error) {
      throw new Error("Error fetching master documents");
    }
  }

  /**
   * Add document to tag
   * @param {AddDocumentRequest} request
   * @returns {Promise<any>}
   */
  async addDocumentToTag(request: AddDocumentRequest): Promise<any> {
    const url = `${this.getBaseUrl()}/api/Projects/AddDocument`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else if (response.status === 400) {
        const errorResponse = await response.json();
        return errorResponse;
      } else {
        throw new Error("Error adding document");
      }
    } catch (error) {
      throw new Error("Error adding document");
    }
  }

  /**
   * Create project document
   * @param {CreateDocumentRequest} request
   * @returns {Promise<any>}
   */
  async createProjectDocument(request: CreateDocumentRequest): Promise<any> {
    const url = `${this.getBaseUrl()}/api/Projects/CreateDocument`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return this.handleResponse<number>(response);
      } else if (response.status === 400) {
        console.log("response", response);
        const errorResponse = await response.json();
        return errorResponse;
      } else {
        throw new Error("Error creating document");
      }
    } catch (error) {
      throw new Error("Error creating document");
    }
  }

  // Get all doc types
  async fetchDocTypes(searchText: string): Promise<GetDocumentTypesResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetDocumentTypes?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetDocumentTypesResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get all project documents
  async getProjectDocuments(docNo: string): Promise<GetDocumentsResponse[]> {
    const url = `${this.getBaseUrl()}/api/Projects/GetDocuments?DocumentNumber=${docNo}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetDocumentsResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get project document by id
  async getProjectDocumentById(id: number): Promise<GetDocumentByIdResponse> {
    const url = `${this.getBaseUrl()}/api/Projects/GetDocumentById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetDocumentByIdResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  /**
   * Delete document by id for Projects
   * @param {request} DeleteDocumentRequest
   * @returns {Promise<void>}
   */
  async deleteDocument(request: DeleteDocumentRequest): Promise<void> {
    const url = `${this.getBaseUrl()}/api/Projects/DeleteDocument`;
    const options = await this.getRequestInit("DELETE", request);
    try {
      await fetch(url, options);
    } catch (error) {
      throw new Error("Error deleting document");
    }
  }

  /**
   * Update document by id for Projects
   * @param {request} EditDocumentRequest
   * @returns {Promise<Response>}
   */
  async editDocument(request: EditDocumentRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/Projects/EditDocument`;
    const options = await this.getRequestInit("PUT", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error updating document");
      }
    } catch (error) {
      throw new Error("Error updating document");
    }
  }

  //Delete tag attributes by id for Projects
  async deleteTagAttributes(ids: number[]): Promise<void> {
    const idParams = ids.map((id) => `ids=${id}`).join("&");
    const url = `${this.getBaseUrl()}/api/Projects/DeleteTagAttributesById?${idParams}`;
    const options = await this.getRequestInit("DELETE", ids);
    try {
      await fetch(url, options);
    } catch (error) {
      throw new Error("Error deleting tag attributes");
    }
  }

  // Get tag attribute details by id
  async getTagAttributeDetailsById(id: number): Promise<GetTagAttributeDetailsByIdResponse> {
    const url = `${this.getBaseUrl()}/api/Projects/GetTagAttributeDetailsById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetTagAttributeDetailsByIdResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

   // Get tag validation details by id
   async getTagValidationsById(id: number): Promise<GetTagValidationResponse> {
    const url = `${this.getBaseUrl()}/api/Projects/GetValidationsById?id=${id}`;
    const options = await this.getRequestInit("GET");
    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetTagValidationResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  //Update tag attributes by id for Projects
  async updateTagAttributes(request: UpdateTagAttributesByIdRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/Projects/UpdateTagAttributesById`;
    const options = await this.getRequestInit("PUT", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error updating tag attributes");
      }
    } catch (error) {
      throw new Error("Error updating tag attributes");
    }
  }

  // Get Attributes
  async fetchEclTagAttributes(searchText: string, type?: NullableNumber): Promise<GetEclTagAttributesResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetTagAttributesByType?SearchText=${searchText}${type ? "&TypeId=" + type : ""}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetEclTagAttributesResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Get Tag Attributes with TypeId
  async fetchTagAttributesByType(searchText: string, type?: NullableNumber): Promise<GetEclTagAttributesResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetTagAttributesByType?SearchText=${searchText}&TypeId=${type}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetEclTagAttributesResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Validate sequence number for tag
  async validateSequenceNumber(request: GetSequenceValidationRequest): Promise<GetSequenceValidationResponse> {
    const url = `${this.getBaseUrl()}/api/TagCreation/ValidateSequenceNumber`;
    const options = await this.getRequestInit("POST", request);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return this.handleResponse<GetSequenceValidationResponse>(response);
      } else {
        throw new Error("Error validating sequence number");
      }
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  //Create tag attributes for Projects
  async createTagAttributes(request: CreateTagAttributesRequest): Promise<any> {
    const url = `${this.getBaseUrl()}/api/Projects/CreateTagAttributes`;
    const options = await this.getRequestInit("POST", request);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return this.handleResponse<number>(response);
      } else if (response.status === 400) {
        const errorResponse = await response.json();
        return errorResponse;
      } else {
        throw new Error("Error creating tag attributes");
      }
    } catch (error) {
      throw new Error("Error creating tag attributes");
    }
  }

  // GET: TAG_NUMBER_CATEGORY from Projects
  async fetchProjectTagNumberCategory(
    searchText: string
  ): Promise<GetProjectTagNumberCategoryResponse[]> {
    const url = `${this.getBaseUrl()}/api/Dictionaries/GetProjectTagNumberCategory?SearchText=${searchText}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetProjectTagNumberCategoryResponse[]>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

    //Delete related tags by id for Projects
    async deleteRelatedTag(request: DeleteRelatedTagRequest): Promise<void> {
      const url = `${this.getBaseUrl()}/api/Projects/DeleteRelatedTags`;
      const options = await this.getRequestInit("DELETE", request);
      try {
        await fetch(url, options);
      } catch (error) {
        throw new Error("Error deleting related tag");
      }
    }

    //Create related tag for Project
  async createRelatedTag(request: CreateRelatedTagRequest): Promise<any> {
    const url = `${this.getBaseUrl()}/api/Projects/AddRelatedTags`;
    const options = await this.getRequestInit("POST", request);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return response;
      } else if (response.status === 400) {
        const errorResponse = await response.json();
        return errorResponse;
      } else {
        throw new Error("Error creating related tag");
      }
    } catch (error) {
      throw new Error("Error creating related tag");
    }
  }


  // Get all tag request forms
  async getTagRequestForms(
    request: GetTagRequestFormsRequest
  ): Promise<GetTagRequestFormsResponse> {
    const url = `${this.getBaseUrl()}/api/TagRequest/GetTagRequestForms`;

    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetTagRequestFormsResponse>(response);
    } catch (error) {
      throw new Error("Error fetching tag request forms");
    }
  }

  // Create tag request form
  async createTagRequest(request: CreateTagRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/TagRequest/CreateTagRequest`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error creating tag request form");
      }
    } catch (error) {
      throw new Error("Error creating tag request form");
    }
  }

  // Update Counter
  async updateCounter(request: UpdateCounterRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/TagCreation/UpdateCounter`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(response.body, { status: 200 });
      } else {
        throw new Error("Error updating counter");
      }
    } catch (error) {
      throw new Error("Error updating counter");
    }
  }

  // Create BB tag
  async createBBTag(request: CreateBBTagRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/TagCreation/CreateBBTag`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(response.body, { status: 200 });
      } else {
        throw new Error("Error creating BB tag");
      }
    } catch (error) {
      throw new Error("Error creating BB tag");
    }
  }

  // Check duplicate tags
  async checkDuplicateBBTag(request: CheckDuplicateBBTag): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/TagCreation/CheckTagForDuplicate`;
    const options = await this.getRequestInit("POST", request);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(response.body, { status: 200 });
      } else {
        throw new Error("Error checking for duplicate tag");
      }
    } catch (error) {
      throw new Error("Error checking for duplicate tag");
    }
  }

  // Edit BB tag
  async editBBTag(request: EditBBTagRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/TagCreation/EditTagById`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error editing BB tag");
      }
    } catch (error) {
      throw new Error("Error editing BB tag");
    }
  }

  // Delete BB tag
  async deleteBBTag(ids: number[]): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/TagCreation/DeleteTagsByIds`;
    const options = await this.getRequestInit("POST", ids);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error deleting BB tags");
      }
    } catch (error) {
      throw new Error("Error deleting BB tags");
    }
  }

  // Working Copy BB tag
  async workingCopyBBTag(request: WorkingCopyBBTagRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/TagRequest/WorkingCopy`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error creating working copy for BB tag");
      }
    } catch (error) {
      throw new Error("Error creating working copy for BB tag");
    }
  }

  // Edit tag request form by id
  async editTagRequestById(request: EditTagRequestByIdRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/TagRequest/EditTagRequestById`;
    const options = await this.getRequestInit("PUT", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else {
        throw new Error("Error updating tag request form");
      }
    } catch (error) {
      throw new Error("Error updating tag request form");
    }
  }

  //Get working copy by id
  async getWorkingCopyById(id: number): Promise<GetWorkingCopy> {
    const url = `${this.getBaseUrl()}/api/TagRequest/GetWorkingCopyById?id=${id}`;
    const options = await this.getRequestInit("GET");
    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetWorkingCopy>(response);
    } catch (error) {
      throw new Error("Error getting working copy form");
    }
  }

  //Approve working copy form by id
  async approveWorkingCopyById(id: number): Promise<void> {
    const url = `${this.getBaseUrl()}/api/TagRequest/ApproveWorkingCopy?id=${id}`;
    const options = await this.getRequestInit("POST", id);
    try {
      await fetch(url, options);
    } catch (error) {
      throw new Error("Error approving working copy form");
    }
  }

  //Reject tag request form by id
  async rejectTagRequestById(id: number): Promise<void> {
    const url = `${this.getBaseUrl()}/api/TagRequest/RejectTagRequest?id=${id}`;
    const options = await this.getRequestInit("POST", id);
    try {
      await fetch(url, options);
    } catch (error) {
      throw new Error("Error rejecting tag request form");
    }
  }

  //Delete tag request form by id
  async deleteTagRequestById(id: number): Promise<void> {
    const url = `${this.getBaseUrl()}/api/TagRequest/DeleteTagRequestById?id=${id}`;
    const options = await this.getRequestInit("DELETE", id);
    try {
      await fetch(url, options);
    } catch (error) {
      throw new Error("Error deleting tag request form");
    }
  }

  // Get tag request by id
  async getTagRequestById(id: number): Promise<GetTagRequestByIdResponse> {
    const url = `${this.getBaseUrl()}/api/TagRequest/GetTagRequestById?id=${id}`;
    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetTagRequestByIdResponse>(response);
    } catch (error) {
      throw new Error("Error during the API call");
    }
  }

  // Create request to handover
  async createHandover(request: CreateHandoverRequest): Promise<Response> {
    const url = `${this.getBaseUrl()}/api/Handover/CreateHandover`;
    const options = await this.getRequestInit("POST", request);

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(response.body, { status: 200 });
      } else {
        throw new Error("Error creating handover");
      }
    } catch (error) {
      throw new Error("Error creating handover");
    }
  }

  // Approve a handover request
  async approveHandover(ids: number[]): Promise<any> {
    const idParams = ids.map((id) => `ids=${id}`).join("&");
    const url = `${this.getBaseUrl()}/api/Handover/ApproveHandover?${idParams}`;
    const options = await this.getRequestInit("POST", ids);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else if (response.status === 400) {
        const errorResponse = await response.json();
        return errorResponse;
      }
      else {
        throw new Error("Error approving handover");
      }
    } catch (error) {
      throw new Error("Error approving handover");
    }
  }

  // Reject a handover request
  async rejectHandover(request: RejectTagAuditRequest): Promise<any> {
    const url = `${this.getBaseUrl()}/api/Handover/RejectHandover`;
    const options = await this.getRequestInit("POST", request);
    
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return new Response(null, { status: 200 });
      } else if (response.status === 400) {
        const errorResponse = await response.json();
        return errorResponse;
      }
      else {
        throw new Error("Error rejecting handover");
      }
    } catch (error) {
      throw new Error("Error rejectinghandover");
    }
  }

    // GET: Distinct Projects only from project_equipment table
    async fetchProjectsFromProjectEquipments(
      searchText: string
    ): Promise<GetProjectsFromProjectEquipmentsResponse[]> {
      const url = `${this.getBaseUrl()}/api/Dictionaries/GetProjectsFromProjectEquipments?SearchText=${searchText}`;
      const options = await this.getRequestInit("GET");
  
      try {
        const response = await fetch(url, options);
        return this.handleResponse<GetProjectsFromProjectEquipmentsResponse[]>(response);
      } catch (error) {
        throw new Error("Error during the API call");
      }
    }

    // GET: All project handover packages
    async getProjectHandoverPackages(
      request: GetProjectHandoverPackagesRequest
    ): Promise<GetProjectHandoverPackagesResponse> {
      const url = `${this.getBaseUrl()}/api/Handover/GetProjectHandoverPackages`;
      console.log(request)
      const options = await this.getRequestInit("POST", request);
  
      try {
        console.log(await fetch(url, options))
  
        const response = await fetch(url, options);
        return this.handleResponse<GetProjectHandoverPackagesResponse>(response);
      } catch (error) {
        throw new Error("Error fetching project handover packages");
      }
    }

  // GET: Project Handover Package details
  async getProjectHandoverPackageById(id: number): Promise<GetProjectHandoverPackageByIdResponse> {
    const url = `${this.getBaseUrl()}/api/Handover/GetProjectHandoverPackageById?id=${id}`;

    const options = await this.getRequestInit("GET");

    try {
      const response = await fetch(url, options);
      return this.handleResponse<GetProjectHandoverPackageByIdResponse>(response);
    } catch (error) {
      throw new Error("Error fetching details");
    }
  }

    // Get all project SYSTEMNAME
    async fetchProjectSystemnames(
      searchText: string
    ): Promise<GetProjectSystemnamesResponse[]> {
      const url = `${this.getBaseUrl()}/api/Dictionaries/GetProjectSystemnames?SearchText=${searchText}`;
      const options = await this.getRequestInit("GET");
  
      try {
        const response = await fetch(url, options);
        return this.handleResponse<GetProjectSystemnamesResponse[]>(response);
      } catch (error) {
        throw new Error("Error during the API call");
      }
    }

    // Get all project SUBSYTSTEM
    async fetchProjectSubsystems(
      searchText: string
    ): Promise<GetProjectSubsystemsResponse[]> {
      const url = `${this.getBaseUrl()}/api/Dictionaries/GetProjectSubsystems?SearchText=${searchText}`;
      const options = await this.getRequestInit("GET");
  
      try {
        const response = await fetch(url, options);
        return this.handleResponse<GetProjectSubsystemsResponse[]>(response);
      } catch (error) {
        throw new Error("Error during the API call");
      }
    }

    // Method to import master tag attributes data from Excel
    async importMasterTagAttributesFromExcel(file: File) {
      const url = `${this.getBaseUrl()}/api/Master/ImportMasterTagAttributes`;
      const formData = new FormData();
      formData.append('excelFile', file);
    
      // Get the initial options without modifying the getRequestInit method
      const options = await this.getRequestInit("POST");
    
      // Append the FormData to the body of the request
      options.body = formData;
    
    // Ensure options.headers is a Headers object before attempting to delete 'Content-Type'
    if (options.headers instanceof Headers) {
      options.headers.delete('Content-Type');
    } else if (Array.isArray(options.headers)) {
      // If options.headers is an array, filter out the 'Content-Type' header
      options.headers = options.headers.filter(header => header[0].toLowerCase() !== 'content-type');
    } else if (options.headers && typeof options.headers === 'object') {
      // If options.headers is an object, delete the 'Content-Type' property
      delete options.headers['Content-Type'];
    }
    
      try {
        const response = await fetch(url, options);
      
        if (!response.ok) {
          throw new Error(`API request failed with status: ${response.status} ${response.statusText}`);
        }
      
        // Check the content type of the response
        const contentType = response.headers.get('Content-Type');
        if (contentType?.includes('application/json')) {
          // If the response is JSON, parse it as JSON
          const result = await response.json();
          console.log("Import successful:", result);
        } else {
          // If the response is not JSON, handle it as text
          const result = await response.text();
          console.log("Import successful:", result);
        }
      } catch (error) {
        console.error("Error importing data from Excel:", error);
        throw new Error("Error importing data from Excel");
      }
    }

    // Get all users
    async fetchUsers(searchText: string): Promise<GetUsersResponse[]> {
      const url = `${this.getBaseUrl()}/api/Dictionaries/GetUsers?SearchText=${searchText}`;
      const options = await this.getRequestInit("GET");
    
      try {
        const response = await fetch(url, options);
        return this.handleResponse<GetUsersResponse[]>(response);
      } catch (error) {
        throw new Error("Error during the API call");
      }
    }


    // Get all list of user project maps
    async getUserProjectMaps(request: GetUserProjectMapsRequest): Promise<GetUserProjectMapsResponse> {
      const url = `${this.getBaseUrl()}/api/Admin/GetUserProjectMaps`;

      const options = await this.getRequestInit("POST", request);

      try {
        const response = await fetch(url, options);
        return this.handleResponse<GetUserProjectMapsResponse>(response);
      } catch (error) {
        throw new Error("Error fetching assets");
      }
    }


    // Create user project map
    async createUserProjectMap(request: CreateUserProjectMapRequest): Promise<Response> {
      const url = `${this.getBaseUrl()}/api/Admin/CreateUserProjectMap`;
      const options = await this.getRequestInit("POST", request);

      try {
        const response = await fetch(url, options);
        if (response.ok) {
          return new Response(null, { status: 200 });
        } else {
          throw new Error("Error creating user project map");
        }
      } catch (error) {
        throw new Error("Error creating user project map");
      }
    }

    // Delete user project map
    async deleteUserProjectMap(request: DeleteUserProjectMapRequest): Promise<void> {
      const url = `${this.getBaseUrl()}/api/Admin/DeleteUserProjectMap`;
      const options = await this.getRequestInit("DELETE", request);
      try {
        await fetch(url, options);
      } catch (error) {
        throw new Error("Error deleting document");
      }
    }

    // Get user project map by user id
    async getUserProjectMapById(id: number): Promise<GetUserProjectMapByIdResponse> {
      const url = `${this.getBaseUrl()}/api/Admin/GetUserProjectMapById?id=${id}`;
      const options = await this.getRequestInit("GET");

      try {
        const response = await fetch(url, options);
        return this.handleResponse<GetUserProjectMapByIdResponse>(response);
      } catch (error) {
        throw new Error("Error during the API call");
      }
    }

    // Get all documents datasheets
    async fetchDocumentDatasheets(searchText: string): Promise<GetDocumentDatasheetsResponse[]> {
      const url = `${this.getBaseUrl()}/api/Dictionaries/GetDocumentDatasheets?SearchText=${searchText}`;
      const options = await this.getRequestInit("GET");

      try {
        const response = await fetch(url, options);
        return this.handleResponse<GetDocumentDatasheetsResponse[]>(response);
      } catch (error) {
        throw new Error("Error during the API call");
      }
    }

    // Get all documents PIPING AND INSTRUMENTATION DIAGRAM
    async fetchDocumentPAID(searchText: string): Promise<GetDocumentPAIDResponse[]> {
      const url = `${this.getBaseUrl()}/api/Dictionaries/GetDocumentPAID?SearchText=${searchText}`;
      const options = await this.getRequestInit("GET");

      try {
        const response = await fetch(url, options);
        return this.handleResponse<GetDocumentPAIDResponse[]>(response);
      } catch (error) {
        throw new Error("Error during the API call");
      }
    }




}

const apiClient = new ApiClient();
export default apiClient;
