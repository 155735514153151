import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { useMemo } from "react";
import { formatDateWithTime } from "../../../../utils/formatDate";
import { ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";

const useMasterGridColumns = () => {
  const navigate = useNavigate();
  
  const columns: GridColDef[] = useMemo(
    () => [
      { field: "createdDate", headerName: "Created On", flex: 1, minWidth: 150, valueFormatter: formatDateWithTime },
      { field: "createdBy", headerName: "Created By", flex: 1, minWidth: 200 },
      { field: "masterTagNumber", headerName: "Master Tag Number", flex: 1, minWidth: 170,
        renderCell: (params) => {
          return (
            <>
              <a href="#" rel="noreferrer" onClick={() => navigate(ROUTES.masterAreaEditTagAttribute + "?id=" + params.row.masterTagAttributeId)}>
                {params.value}
              </a>
            </>
          );
        } },
      { field: "masterCategory", headerName: "Master Category", flex: 1, minWidth: 150 },
      { field: "tagAttributeName", headerName: "Tag Attribute Name", flex: 1, minWidth: 150},
      { field: "tagAttributeValue", headerName: "Tag Attribute Value", flex: 1, minWidth: 250},
      { field: "uom", headerName: "UOM", flex: 1, minWidth: 120},
      { field: "updatedDate", headerName: "Modified On", flex: 1, minWidth: 150, valueFormatter: formatDateWithTime },
      { field: "updatedBy", headerName: "Modified By", flex: 1, minWidth: 200 } 
    ],
    []
  );

  return columns;
};

export default useMasterGridColumns;
