import { Button } from "@mui/material";
import { observer } from "mobx-react";
import AddIcon from "@mui/icons-material/Add";
import { useStores } from "../../../../../hooks/useStores";
import CreateUserProjectMapButtonModal from "./CreateUserProjectMapButtonModal";

const CreateUserProjectMapButton = observer(() => {
  const { userProjectMapStore } = useStores();

  const handleCreateUserProjectMapClick = async () => {
    userProjectMapStore.setShowCreateModal(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleCreateUserProjectMapClick}
      >
        <span>Add</span>
      </Button>
      {userProjectMapStore.showCreateModal ? <CreateUserProjectMapButtonModal /> : null}
    </>
  );
});

export default CreateUserProjectMapButton;
