import { SelectOption } from "../../../models/common/selectOption";

export const TagLimitOptions: SelectOption[] = [{id: 0, label: "None"}, {id: 1, label: "1-99"}, {id: 2, label: "A-Z"}]

export const modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1200px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
  };
            