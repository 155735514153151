import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

interface HeaderProps {
  toggleSidebar: () => void;
}

const PersonIconWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  marginLeft: "auto",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const Header = (props: HeaderProps) => {
  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={props.toggleSidebar}
        >
          <MenuIcon />
        </IconButton>
        <Link
          to="/"
          component={NavLink}
          sx={{ textDecoration: "none", display: "flex", alignItems: "center" }}
        >
          <img
            style={{ maxWidth: 50 }}
            src="/images/tco_logo.svg"
            alt="TCO Asset Register Solution"
          />
          <Typography
            variant="h6"
            sx={{ ml: 1, color: "white", display: { xs: "none", sm: "block" } }}
          >
            TCO Asset Register Solution
          </Typography>
        </Link>
        <PersonIconWrapper>
          <PersonIcon />
        </PersonIconWrapper>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
