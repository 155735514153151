import { Box, Typography, Grid, Stack, CircularProgress, FormControlLabel, Checkbox } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import TagDetail from "../cardView/components/TagDetail";
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import { useDebounce } from "../../../hooks/useDebounce";
import usePageTitle from "../../../hooks/usePageTitle";
import TagDetailEdit from "./components/TagDetailEdit";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { SelectOption } from "../../../models/common/selectOption";
import { useFetchOptionsData } from "./hooks/useFetchOptionsData";
import { getOptionId, getOptionLabel } from "../../../utils/getOptionValue";
import { commonStyle } from "../../../styles/autoComplete";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TagRequestStatus } from "../../../models/common/response/tagRequestFormStatus";

const columns: GridColDef[] = [
  {
    field: "tagNumber",
    headerName: "Tag Number",
    flex: 1,
    minWidth: 150
  },
  {
    field: "projectNumber",
    headerName: "Project Number",
    flex: 1,
    minWidth: 150
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 150
  },
  {
    field: "validationStatus",
    headerName: "Validation",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return (
        <>
          { params.value ? <CheckIcon /> : <CloseIcon /> }
        </>
      );
    }
  },
  {
    field: "requestStatus",
    headerName: "Handover Status",
    flex: 1,
    minWidth: 150,
    renderCell: (params: any) => {
      let color;
      switch (TagRequestStatus[params.value]) {
        case "Submitted":
          color = "orange";
          break;
        case "Rejected":
          color = "red";
          break;
        case "Completed": 
          color = "green";
          break;
        default:
          color = "inherit"; 
      }
      return <strong style={{ color: color }}>{TagRequestStatus[params.value]}</strong>;
    },
  },
];

const ProjectAreaPage = observer(() => {
  usePageTitle("Project Area - Card View");

  const {
    projectAreaCardViewStore,
    dictionaryStore,
    projectAreaUpdateStore,
    projectColumnStore,
  } = useStores();

  const [tagNumberOption, setTagNumberOption] =
    useSearchParamsState<SelectOption | null>("tagOpt", null);
  const [projectOption, setProjectOption] =
    useSearchParamsState<SelectOption | null>("projOpt", null);
  const [areaOption, setAreaOption] = useSearchParamsState<SelectOption | null>(
    "areaOpt",
    null
  );
  const [facilityOption, setFacilityOption] =
    useSearchParamsState<SelectOption | null>("facOpt", null);
  const [unitOption, setUnitOption] = useSearchParamsState<SelectOption | null>(
    "unitOpt",
    null
  );
  const [isActiveProjectOption, setIsActiveProjectOption] = useState<boolean>(false);

  useFetchOptionsData(dictionaryStore, projectColumnStore);

  useEffect(() => {
    projectAreaCardViewStore.fetchAssetsMin(
      getOptionId(projectOption),
      getOptionLabel(tagNumberOption),
      getOptionId(areaOption),
      getOptionId(facilityOption),
      getOptionId(unitOption),
      "",
      isActiveProjectOption
    );
  }, [
    projectAreaCardViewStore.paginationModel,
    projectAreaCardViewStore.sortModel,
    areaOption,
    facilityOption,
    projectOption,
    unitOption,
    tagNumberOption,
    isActiveProjectOption,
    projectAreaCardViewStore
  ]);

  const debouncedHandleInputChange = useDebounce(500);

  const handleProjectInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchProjectsFromProjectEquipments(inputValue);
    });
  };

  const handleAreaInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchAreaOptions(inputValue);
    });
  };

  const handleUnitInputChange = async (inputValue: string, areaId?: number) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchUnitOptions(inputValue, areaId);
    });
  };

  const handleFacilityInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchFacilityOptions(inputValue);
    });
  };

  const handleTagNumberInputChange = async (inputValue: string) => {
    debouncedHandleInputChange(() => {
      dictionaryStore.fetchTagNumberOptions(inputValue);
    });
  };

  const handleTagNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      const inputValue = (event.target as HTMLInputElement).value;
      setTagNumberOption({ id: 0, label: inputValue });
    }
  };

  const handleOnRowClick = async (id: number) => {
    projectAreaCardViewStore.setShowDetailLoading(true);

    await Promise.all([
      projectAreaCardViewStore.fetchAssetById(id),
      projectAreaCardViewStore.fetchRelatedTagsById(id),
      projectAreaCardViewStore.fetchTagAttributesById(id),
      projectAreaCardViewStore.fetchExTagAttributesById(id),
      projectAreaCardViewStore.fetchCmmsTagAttributesById(id),
      projectAreaCardViewStore.fetchDocumentsById(id),
      projectAreaCardViewStore.fetchValidationsById(id)
    ]);

    projectAreaUpdateStore.setShowEditMode(false);
    projectAreaCardViewStore.setShowDetailLoading(false);
  };

  return (
    <Grid container spacing={2} sx={{ height: "100%" }}>
      <Grid
        item
        xs={12}
        md={5}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "#fff",
          }}
        >
          <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 2 }}>
            <FilterAltIcon color="primary" fontSize="small" />
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              Filters
            </Typography>
          </Stack>
          <div style={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Autocomplete
              id="tagNumber"
              size="small"
              freeSolo
              sx={commonStyle}
              options={dictionaryStore.tagNumberOptions}
              value={tagNumberOption}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tag Number"
                  onKeyDown={handleTagNumberKeyDown}
                />
              )}
              onInputChange={(event, newInputValue, reason) => {
                handleTagNumberInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setTagNumberOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />          
            <Autocomplete
              size="small"
              disablePortal
              id="projectNumber"
              options={dictionaryStore.projectTagAuditOptions}
              value={projectOption}
              getOptionLabel={(option) => option.label}
              sx={commonStyle}
              renderInput={(params) => (
                <TextField {...params} label="Project Number" />
              )}
              onInputChange={(event, newInputValue) => {
                handleProjectInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setProjectOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="area"
              options={dictionaryStore.areaOptions}
              value={areaOption}
              getOptionLabel={(option) => option.label}
              sx={commonStyle}
              renderInput={(params) => <TextField {...params} label="Area" />}
              onInputChange={(event, newInputValue) => {
                handleAreaInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setAreaOption(selectedValue);
                handleUnitInputChange("", selectedValue?.id);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="unit"
              options={dictionaryStore.unitOptions}
              value={unitOption}
              getOptionLabel={(option) => option.label}
              sx={commonStyle}
              renderInput={(params) => <TextField {...params} label="Unit " />}
              onInputChange={(event, newInputValue) => {
                handleUnitInputChange(newInputValue, areaOption?.id);
              }}
              onChange={(event, selectedValue) => {
                setUnitOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="facility"
              options={dictionaryStore.facilityOptions}
              value={facilityOption}
              getOptionLabel={(option) => option.label}
              sx={commonStyle}
              renderInput={(params) => (
                <TextField {...params} label="Facility" />
              )}
              onInputChange={(event, newInputValue) => {
                handleFacilityInputChange(newInputValue);
              }}
              onChange={(event, selectedValue) => {
                setFacilityOption(selectedValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <FormControlLabel control={
              <Checkbox 
                onChange={(e) => setIsActiveProjectOption(e.target.checked)}
              />             
              } label="Active Projects" sx={{"margin": "0 0 0 0" }} />
          </div>
        </Box>
        <Box
          component="div"
          sx={{
            p: 2,
            backgroundColor: "#fff",
            flexGrow: 1,
            mt: 2,
            height: "500px",
            overflowY: "hidden",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              fontSize: "0.9rem",
            },
            "& .MuiDataGrid-row": {
              borderBottom: "solid",
              borderBottomColor: "#f0f0f0",
              maxHeight: "80px !important",
            },
            "& .MuiDataGrid-cellContent": {
              whiteSpace: "normal",
            },
            "& .MuiDataGrid-cell": {
              maxHeight: "80px !important",
            },
          }}
        >
          <DataGrid
            disableColumnFilter
            paginationMode="server"
            columns={columns}
            rows={projectAreaCardViewStore.assetsMinResponse.items}
            rowCount={projectAreaCardViewStore.assetsMinResponse.totalRecords}
            loading={projectAreaCardViewStore.isLoading}
            pagination
            paginationModel={projectAreaCardViewStore.paginationModel}
            onPaginationModelChange={
              projectAreaCardViewStore.setPaginationModel
            }
            sortingMode="server"
            sortModel={projectAreaCardViewStore.sortModel}
            onSortModelChange={projectAreaCardViewStore.setSortModel}
            onRowClick={(params) => {
              handleOnRowClick(params.row.id);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box
          sx={{
            p: 2,
            backgroundColor: projectAreaCardViewStore.showDetailLoading
              ? "#FBFBFB"
              : "#FFF",
            height: "100%",
            position: "relative",
          }}
        >
          {projectAreaCardViewStore.showDetailLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          {projectAreaUpdateStore.showEditMode ? (
            <TagDetailEdit />
          ) : (
            <TagDetail />
          )}
        </Box>
      </Grid>
    </Grid>
  );
});

export default ProjectAreaPage;
