import { observer } from "mobx-react";
import { useStores } from "../../../../hooks/useStores";
import Button from "@mui/material/Button";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";

const MasterAreaCopyTag = observer(() => {
  const { masterAreaGridViewExportStore } = useStores();

  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<ContentCopy />}
        onClick={() => navigate(ROUTES.masterAreaCopyTag + "?id=" + masterAreaGridViewExportStore.selectedIds[0])}
        disabled={masterAreaGridViewExportStore.selectedIds.length !== 1}
        >
        Copy
      </Button>
    </>
  );
});

export default MasterAreaCopyTag;
