import {  createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Palette {
      extended: Record<string, string>;
    }
    interface PaletteOptions {
      extended: Record<string, string>;
    }
  }

const MyTheme = createTheme({
    palette: {
        extended: {
          gray10: '#FAFAFA',
          blue: '#0B79D0',
        },
        primary: {
          main: '#0066B2',
          light: '#009DD9',
          dark: '#0B2D71',
          contrastText: '#FFFFFF'
        },
        secondary: {
          main: '#03A9F4',
          light: '#03A9F4',
          dark: '#0288D1',
          contrastText: '#FFFFFF'
        },
        success: {
          main: '#4CAF50',
          light: '#7BC67E',
          dark: '#3B873E',
          contrastText: '#FFFFFF'
        },
        info: {
          main: '#42454A',
          light: '#6C6E72',
          dark: '#2E3136',
          contrastText: '#FFFFFF'
        },
        warning: {
          main: '#FFC000',
          light: '#FFCC33',
          dark: '#B28600',
          contrastText: 'rgba(0,0,0,0.87)'
        },
        error: {
          main: '#F44336',
          light: '#F88078',
          dark: '#E31B0C'
        },
        background: {
          default: '#F4F4F4'
        },
        text: {
          secondary: 'rgba(0,0,0,0.54)'
        },
        divider: '#E0E0E0',
      },
});

export default MyTheme;  