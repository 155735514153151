import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Key from '@mui/icons-material/Key';
import { ROLES } from '../../constants/roles';

const SideBarItems = [
  {
    id: 0,
    icon: <LocalOfferIcon />,
    label: 'Master Area',    
    route: '/master-area-grid',
    disabled: false,
    subItems: [
      {
        id: 0.1,
        icon: <LocalOfferIcon />,
        label: 'Equipment Tags',
        route: '/master-area-grid/tags',
        disabled: false,
      },
      {
        id: 0.2,
        icon: <LocalOfferIcon />,
        label: 'Tag Attributes',
        route: '/master-area-grid/attributes',
        disabled: false,
      },
      {
        id: 0.3,
        icon: <LocalOfferIcon />,
        label: 'Documents',
        route: '/master-area-grid/documents',
        disabled: false,
      },
      {
        id: 0.4,
        icon: <LocalOfferIcon />,
        label: 'Projects',
        route: '/master-area-grid/projects',
        disabled: false,
      }
    ],
  },
  {
    id: 1,
    icon: <LocalOfferIcon />,
    label: 'Projects Area',    
    route: '/project-area-card',
    disabled: false,
    subItems: null
  },
  {
    id: 2,
    icon: <Key />,
    label: 'Admin Area',
    route: '/admin-area',
    disabled: false,
    subItems: [
      {
        id: 2.1,
        icon: <LocalOfferIcon />,
        label: 'References',    
        route: '/references',
        disabled: false,
        subItems: [
          {
            id: 2.11,
            icon: <LocalOfferIcon />,
            label: 'Facilities',
            route: '/references/facilities',
            disabled: false,
          },
          {
            id: 2.12,
            icon: <LocalOfferIcon />,
            label: 'Areas',
            route: '/references/areas',
            disabled: false,
          },
          {
            id: 2.13,
            icon: <LocalOfferIcon />,
            label: 'Units',
            route: '/references/units',
            disabled: false,
          },
          {
            id: 2.14,
            icon: <LocalOfferIcon />,
            label: 'Locations',
            route: '/references/locations',
            disabled: false,
          },
          {
            id: 2.15,
            icon: <LocalOfferIcon />,
            label: 'Buildings',
            route: '/references/buildings',
            disabled: false,
          },
          {
            id: 2.16,
            icon: <LocalOfferIcon />,
            label: 'Wells',
            route: '/references/wells',
            disabled: false,
          },
          {
            id: 2.17,
            icon: <LocalOfferIcon />,
            label: 'Substations',
            route: '/references/substations',
            disabled: false,
          },
          {
            id: 2.18,
            icon: <LocalOfferIcon />,
            label: 'Pipe Classes',
            route: '/references/pipeclasses',
            disabled: false,
          },
          {
            id: 2.19,
            icon: <LocalOfferIcon />,
            label: 'Fluids',
            route: '/references/fluids',
            disabled: false,
          },
        ],
      },
      {
        id: 2.1,
        icon: <LocalOfferIcon />,
        label: 'User Projects Management',
        route: '/admin-area/user-projects-management',
        disabled: false,
      },
    ],
    //roles: [ROLES.arsImcUserRole],
  },
  {
    id: 3,
    icon: <LocalOfferIcon />,
    label: 'Tag Request Forms',    
    route: '/tag-request-forms',
    disabled: false,
    subItems: null
  },
  {
    id: 4,
    icon: <LocalOfferIcon />,
    label: 'Tag Audit & Handover',    
    route: '/tag-audit-handover',
    disabled: false,
    subItems: null,
    roles: [ROLES.arsProjectUserRole],
  },
  {
    id: 5,
    icon: <LocalOfferIcon />,
    label: 'Tag Handover Packages',    
    route: '/tag-handover-packages',
    disabled: false,
    subItems: null,
  }
];

export default SideBarItems;
