import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import "./App.css";

import {
  Box,
  CssBaseline,
  Grid,  
  ThemeProvider
} from "@mui/material";
import Header from "./components/Header";

import Sidebar from "./components/Sidebar";
import { SuspenseLoader } from "./components/common/SuspenseLoader";
import AuthProvider from "./components/Security/AuthProvider";
import MyTheme from "./styles/theme";
import { RootStoreContext } from "./stores/RootStoreContext";
import RootStore from "./stores/RootStore";
import NotificationAlert from "./components/common/NotificationAlert";
import ErrorBoundary from "./components/common/ErrorBoundary";
import { appInsights } from "./components/Security/appInsights";
import { LicenseInfo } from '@mui/x-license-pro';
import { useNavigate } from "react-router-dom";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY ?? '');

const App = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const toggleSidebar = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
      navigate("master-area-grid/tags");
  }, [navigate]);

  const rootStore = useMemo(() => new RootStore(), []);  

  return (
    <RootStoreContext.Provider value={rootStore}>
      <ThemeProvider theme={MyTheme}>
        <CssBaseline />
        <AuthProvider>
          <Header toggleSidebar={toggleSidebar} />
          <Grid container spacing={0} sx={{ flexGrow: 1 }}>
            <Grid item xs="auto">
              <Sidebar open={open} />
            </Grid>
            <Grid item xs>
              <Box
                component="main"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  pr: 3,
                  pb: 3,
                  pt: 11,
                  transition: (theme) =>
                    theme.transitions.create("padding-left"),
                  pl: 3,
                  ...(open && {
                    transition: (theme) =>
                      theme.transitions.create("padding-left"),
                    pl: 35,
                  }),
                }}
              >
                <ErrorBoundary appInsights={appInsights}>
                <SuspenseLoader>
                  <Outlet />
                </SuspenseLoader>    
                </ErrorBoundary>            
                <NotificationAlert />
              </Box>
            </Grid>            
          </Grid>
        </AuthProvider>
      </ThemeProvider>
    </RootStoreContext.Provider>
  );
};

export default App;
