import { Button } from "@mui/material";
import { observer } from "mobx-react";
import AddIcon from "@mui/icons-material/Add";
import { useStores } from "../../../../hooks/useStores";
import CreateHandoverButtonModal from "./CreateHandoverButtonModal";
import { TagRequestStatus } from "../../../../models/common/response/tagRequestFormStatus";

const CreateHandoverButton = observer(() => {
  const { tagAuditStore } = useStores();

  const handleCreateHandoverClick = async () => {
    tagAuditStore.setshowCreateHandoverModal(true);
  };

  const isButtonDisabled = () => {
    return (
      tagAuditStore.selectedIds.some((id) => {
        const row = tagAuditStore.tagAuditGridResponse.items.find(
          (item) => item.id === id
        ) as any;
        return (
          row &&
          (!row.validationStatus || row.requestStatus === TagRequestStatus.Submitted)
        );
      }) || tagAuditStore.selectedIds.length < 1
    ); 
  };
  

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<AddIcon />}
        disabled={isButtonDisabled()}
        onClick={handleCreateHandoverClick}
      >
        <span>Create Handover Package</span>
      </Button>
      {tagAuditStore.showCreateHandoverModal ? (
        <CreateHandoverButtonModal />
      ) : null}
    </>
  );
});

export default CreateHandoverButton;
