import { GridToolbarContainer } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import TagDetailAddDocument from "./TagDetailAddDocument";
import TagDetailDeleteDocuments from "./TagDetailDeleteDocuments";
import { MasterDocuments } from "../../../../../models/MasterArea/response/getAssetsDetailResponse";

interface TagDetailDocumentToolBarProps {
  Documents: MasterDocuments[];
  handleAddDocuments: any;
  handleDeleteDocument: any;
}

const TagDetailDocumentToolBar = observer((props: TagDetailDocumentToolBarProps) => {

  return (
    <GridToolbarContainer>
      {<TagDetailAddDocument Documents={props.Documents} handleAddDocuments={props.handleAddDocuments} />}
      {<TagDetailDeleteDocuments Documents={props.Documents} handleDeleteDocument={props.handleDeleteDocument} />}
    </GridToolbarContainer>
  );
});

export default TagDetailDocumentToolBar;
