import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteTagRequests } from "../hooks/useFetchRequests";

const TagRequestFormsDeleteTag = observer(() => {
  const { tagRequestFormStore, notificationStore } = useStores();

  const handleDeleteClick = async () => {
    deleteTagRequests(tagRequestFormStore, notificationStore);
  };

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<DeleteIcon />}
      disabled={tagRequestFormStore.selectedIds.length < 1}
      onClick={handleDeleteClick}
    >
      Delete
    </Button>
  );
});

export default TagRequestFormsDeleteTag;
