export default function formatDate(params: any) {
  if (!params.value) return "";
  const date = new Date(params.value);
  return date.toISOString().slice(0, 10);
}

export function formatDateWithTime(params: any) {
  if (!params.value) return "";
  const date = new Date(params.value);
  return date.toISOString().slice(0, 19).replace("T", " ");
}

export function formatDateTime(params: any) {
  if (!params.value) return "";
  const date = new Date(params.value);
  const isoDate = date.toISOString().slice(0, 19).replace("T", " ");
  return isoDate;
}

export function formatDBDate(date: Date) {
  return date.toString().slice(0, 10);
}
