import { GridSortModel } from "@mui/x-data-grid-pro";
import { GetAssetsMinResponse } from "../../models/ProjectArea/response/getAssetsMinResponse";
import { makeAutoObservable, runInAction } from "mobx";
import {
  AssetsMinSortOptions,
  AssetsMinTagAuditFilterOptions,
  FilterTagAudit,
  GetAssetsMinRequest,
} from "../../models/ProjectArea/request/getAssetsMinRequest";
import { SortOrder } from "../../models/common/request/sorting";
import apiClient from "../../api/clients";
import { RejectTagAuditRequest } from "../../models/TagAudit/request/rejectTagAuditRequest";
import { SelectOption } from "../../models/common/selectOption";
import { getOptionId, getOptionLabel } from "../../utils/getOptionValue";
import { GetProjectHandoverPackagesResponse } from "../../models/TagAudit/response/getProjectHandoverPackagesResponse";
import { GetProjectHandoverPackagesRequest } from "../../models/TagAudit/request/getProjectHandoverPackagesRequest";
import { GetProjectHandoverPackageByIdResponse } from "../../models/TagAudit/response/getProjectHandoverPackageByIdResponse";
import { CreateHandoverRequest } from "../../models/TagAudit/request/createHandoverRequest";

class TagAuditStore {
  selectedIds: number[] = [];
  showCreateHandoverModal = false;
  showRejectHandoverModal = false;
  showApproveHandoverModal = false;
  validationErrors?: string;
  selectedTagAuditProjectOption: SelectOption | null = null;
  selectedTagAuditProjectHandoverPackagesOption: SelectOption | null = null;
  selectedProjectSystemnameOption: string | undefined;
  selectedProjectSubsystemOption: string | undefined;
  tagAuditPackage: GetProjectHandoverPackageByIdResponse =
    {} as GetProjectHandoverPackageByIdResponse;
  showDisplayRequestTagFormModal = false;
  showDisplayTagDetailsModal = false;
  comments: string | undefined;
  createdHandoverPackageId: number | undefined;

  // Use GetAssetsMin model and API
  tagAuditGridResponse: GetAssetsMinResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  // GET All project handover packages for PSI team view
  tagAuditProjectHandoverPackagesResponse: GetProjectHandoverPackagesResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  paginationModel = {
    pageSize: 100,
    page: 0,
  };

  sortModel: GridSortModel = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPaginationModel = (newModel: any) => {
    runInAction(() => {
      this.paginationModel = newModel;
      this.fetchTagAuditData(); // Fetch new data whenever the pagination model changes
    });
  };

  setSortModel = (sortModel: any) => {
    this.sortModel = sortModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setShowDisplayRequestTagFormModal = (value: boolean) => {
    this.showDisplayRequestTagFormModal = value;
  };

  setSelectedIds = (selectedIds: number[]) => {
    this.selectedIds = selectedIds;
  };

  setshowCreateHandoverModal = (value: boolean) => {
    this.showCreateHandoverModal = value;
  };

  setshowRejectHandoverModal = (value: boolean) => {
    this.showRejectHandoverModal = value;
  };

  setshowApproveHandoverModal = (value: boolean) => {
    this.showApproveHandoverModal = value;
  };

  setValidationErrors = (value?: string) => {
    this.validationErrors = value;
  };

  setComments = (value?: string) => {
    this.comments = value;
  };

  setSelectedTagAuditProjectOption = (option: SelectOption | null) => {
    this.selectedTagAuditProjectOption = option;
  };

  setSelectedTagAuditProjectHandoverPackagesOption = (option: SelectOption | null) => {
    this.selectedTagAuditProjectHandoverPackagesOption = option;
  };

  setSelectedProjectSystemnameOption = (option: string) => {
    this.selectedProjectSystemnameOption = option;
  };

  setSelectedProjectSubsystemOption = (option: string) => {
    this.selectedProjectSubsystemOption = option;
  };

  setShowDisplayTagDetailsModal = (value: boolean) => {
    this.showDisplayTagDetailsModal = value;
  };

  // For Business Partners
  fetchTagAuditData = async () => {
    try {
      const { page, pageSize } = this.paginationModel;

      const filterOptions: AssetsMinTagAuditFilterOptions = {
        projectNumberId: getOptionId(this.selectedTagAuditProjectOption),
        projectHandoverPackagesId: getOptionId(
          this.selectedTagAuditProjectHandoverPackagesOption
        ),
        systemname: getOptionLabel(this.selectedProjectSystemnameOption),
        subsystem: getOptionLabel(this.selectedProjectSubsystemOption),
      };

      const sortOptions: AssetsMinSortOptions = {
        projectNumber: "projectNumber",
        tagNumber: "tagNumber",
        status: "status",
      };

      const sortField = this.sortModel[0]?.field;
      const sortOrder =
        this.sortModel[0]?.sort === "asc" ? SortOrder.ASC : SortOrder.DESC;
      const sortProperty = sortField
        ? sortOptions[sortField as keyof AssetsMinSortOptions]
        : undefined;
      const filter = Object.keys(filterOptions).reduce((acc, key) => {
        const filterValue = filterOptions[key as keyof AssetsMinTagAuditFilterOptions];
        if (filterValue !== undefined) {
          acc[key as keyof FilterTagAudit] = {
            filterValue: filterValue as never,
          };
        }
        return acc;
      }, {} as FilterTagAudit);

      const sort = sortProperty
        ? {
            [sortProperty]: { sortOrder },
          }
        : undefined;

      const request: GetAssetsMinRequest = {
        pagination: { pageIndex: page, pageSize },
        filter,
        sort,
      };
      this.setIsLoading(true);

      const data = await apiClient.getAssetsMin(request);

      runInAction(() => {
        this.tagAuditGridResponse = data;
        this.isLoading = false;
      });
    } finally {
      this.setIsLoading(false);
      this.setSelectedTagAuditProjectHandoverPackagesOption(null);
    }
  };

  // For PSI team
  fetchTagAuditProjectHandoverPackagesData = async () => {
    try {
      const { page, pageSize } = this.paginationModel;

      const request: GetProjectHandoverPackagesRequest = {
        pagination: { pageIndex: page, pageSize },
      };
      this.setIsLoading(true);

      const data = await apiClient.getProjectHandoverPackages(request);

      runInAction(() => {
        this.tagAuditProjectHandoverPackagesResponse = data;
        this.isLoading = false;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  fixTagAuditPackageFormDisplaying = () => {
    this.tagAuditPackage.tagNumbers = this.tagAuditPackage.tagNumbers ?? "";
    this.tagAuditPackage.title = this.tagAuditPackage.title ?? "";
  };

  //GET Project Handover Package details by id
  async fetchProjectHandoverPackageById(id: number): Promise<void> {
    if (!id) return;

    try {
      const response = await apiClient.getProjectHandoverPackageById(id);

      runInAction(() => {
        this.tagAuditPackage = response;
        this.fixTagAuditPackageFormDisplaying();
      });
    } catch (error) {
      console.error("Error while getting tag audit package details:", error);
    }
  }

  async createHandover(request: CreateHandoverRequest): Promise<string> {
    try {
      this.setIsLoading(true);
      const response = await apiClient.createHandover(request);
      const createdHandoverId = await response.json();
      this.createdHandoverPackageId = createdHandoverId;
      await this.fetchTagAuditData();
      this.setIsLoading(false);
      return createdHandoverId;
    } catch (error) {
      this.setValidationErrors("Failed to create handover!");
      console.error("Error creating tag request: ", error);
      this.setIsLoading(false);
      throw error;
    }
  }

  rejectHandover = async (request: RejectTagAuditRequest) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.rejectHandover(request);
      if (response.ok) {
        await this.fetchTagAuditData();
        this.setValidationErrors();
      } else {
        this.setValidationErrors("Failed to reject handover!");
      }
      this.setIsLoading(false);
    } catch (error) {
      console.error("Error rejecting handover request:", error);
      this.setIsLoading(false);
    }
  };

  async approveHandover(ids: number[]): Promise<void> {
    try {
      this.setIsLoading(true);
      const response = await apiClient.approveHandover(ids);
      if (response.ok) {
        await this.fetchTagAuditData();
        this.setValidationErrors();
      } else {
        this.setValidationErrors("Failed to approve handover!");
      }
      this.setIsLoading(false);
    } catch (error) {
      this.setValidationErrors("Failed to approve handover. " + error);
      this.setIsLoading(false);
    }
  }
}

export default TagAuditStore;
