import { observer } from "mobx-react";
import { useStores } from "../../../../../hooks/useStores";
import { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialogModal from "../../../../../components/common/ConfirmationDialog";

const TagDetailDeleteTagAttributes = observer(() => {
  const {
    projectAreaCardViewTagAttributesStore,
    projectAreaCardViewStore: {
      fetchTagAttributesById,
      assetDetail,
      fetchExTagAttributesById,
      fetchCmmsTagAttributesById,
    },
    notificationStore,
  } = useStores();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const handleDeleteClick = async () => {
    const tagAttributesIds = projectAreaCardViewTagAttributesStore.selectedIds;

    await projectAreaCardViewTagAttributesStore.deleteTagAttributes(tagAttributesIds);

    fetchTagAttributesById(assetDetail?.id!);
    fetchExTagAttributesById(assetDetail?.id!);
    fetchCmmsTagAttributesById(assetDetail?.id!);
    notificationStore.setNotificationMessage("Tag Attributes deleted successfully");
    notificationStore.setShowNotification(true);

    setShowConfirm(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<DeleteIcon />}
        disabled={projectAreaCardViewTagAttributesStore.selectedIds.length < 1}
        onClick={() => setShowConfirm(true)}
      >
        Delete
      </Button>
      <ConfirmationDialogModal
        handleOkClick={handleDeleteClick}
        handleCancelClick={() => setShowConfirm(false)}
        showConfirmationDialog={showConfirm}
        headerText="Confirm Deleting Attributes"
        confirmationText="Are you sure you want to delete selected attributes?"
        okButtonText="Ok"
        cancelButtonText="Cancel"
      />
    </>
  );
});

export default TagDetailDeleteTagAttributes;
