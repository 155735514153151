import { makeAutoObservable, runInAction } from "mobx";
import { GetMasterAreaDocumentsResponse } from "../../models/MasterArea/response/getMasterAreaDocumentsResponse";
import { GetMasterAreaDocumentsRequest } from "../../models/MasterArea/request/getMasterAreaDocumentsRequest";
import { GetMasterAreaDocumentByIdResponse } from "../../models/MasterArea/response/getMasterAreaDocumentByIdResponse";
import apiClient from "../../api/clients";

class MasterAreaDocumentsStore {
  documentResponse: GetMasterAreaDocumentByIdResponse | null = null;
  documentsResponse: GetMasterAreaDocumentsResponse = {
    items: [],
    pageIndex: 0,
    pageSize: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    totalRecords: 0,
  };

  paginationModel = {
    pageSize: 250,
    page: 0,
  };
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPaginationModel = (paginationModel: any) => {
    this.paginationModel = paginationModel;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  fetchDocuments = async () => {    
    const { page, pageSize } = this.paginationModel;

    const request: GetMasterAreaDocumentsRequest = {
      pagination: { pageIndex: page, pageSize }
    };

    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAreaDocuments(request);
      runInAction(() => {
        this.documentsResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

  fetchDocumentById = async (id: number) => {    
    try {
      this.setIsLoading(true);
      const data = await apiClient.getMasterAreaDocumentById(id);
      runInAction(() => {
        this.documentResponse = data;
        this.setIsLoading(false);
      });
    } catch (error) {
      console.error(error);
      this.setIsLoading(false);
    }
  }

}

export default MasterAreaDocumentsStore;
