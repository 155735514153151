import { Button } from "@mui/material";
import { observer } from "mobx-react";
import DoneIcon from "@mui/icons-material/Done";
import { useStores } from "../../../../hooks/useStores";
import ApproveHandoverButtonModal from "./ApproveHandoverButtonModal";
import { TagRequestStatus } from "../../../../models/common/response/tagRequestFormStatus";

const ApproveHandoverButton = observer(() => {
  const { tagAuditStore } = useStores();

  const handleApproveHandoverClick = () => {
    tagAuditStore.setshowApproveHandoverModal(true);
  };

  const isButtonDisabled = () => {
    return (
      tagAuditStore.selectedIds.some((id) => {
        const row = tagAuditStore.tagAuditGridResponse.items.find(
          (item) => item.id === id
        ) as any;
        return (
          row &&
          (!row.validationStatus || row.requestStatus !== TagRequestStatus.Submitted)
        );
      }) || tagAuditStore.selectedIds.length < 1
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<DoneIcon />}
        disabled={isButtonDisabled()}
        onClick={handleApproveHandoverClick}
      >
        <span>Approve</span>
      </Button>
      {tagAuditStore.showApproveHandoverModal ? <ApproveHandoverButtonModal /> : null}
    </>
  );
});

export default ApproveHandoverButton;
