import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStores } from "../../../../../hooks/useStores";
import { MasterDocuments } from "../../../../../models/MasterArea/response/getAssetsDetailResponse";

interface TagDetailDeleteDocumentsProps {
  Documents: MasterDocuments[];
  handleDeleteDocument: any;
}

const TagDetailDeleteDocuments = observer((props: TagDetailDeleteDocumentsProps) => {
  const {
    masterAreaCardViewDocumentStore
  } = useStores();

  const handleDeleteClick = async () => {
    props.handleDeleteDocument();

    masterAreaCardViewDocumentStore.setShowAddDocumentModal(false);
    masterAreaCardViewDocumentStore.setShowCreateDocumentAlert(false);
  };

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<DeleteIcon />}
      disabled={masterAreaCardViewDocumentStore.selectedIds.length < 1}
      onClick={handleDeleteClick}
    >
      Delete
    </Button>
  );
});

export default TagDetailDeleteDocuments;
