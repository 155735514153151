import { makeAutoObservable } from "mobx";
import apiClient from "../../api/clients";

class MasterAreaImportFromExcelStore {
  isLoading = false;
  importResponse = "";
  showImportFromExcelModal = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setImportResponse = (response: string) => {
    this.importResponse = response;
  };

  setShowImportFromExcelModal = (show: boolean) => {
    this.showImportFromExcelModal = show;
  };

  importFromExcelMasterTagAttributes = async (file: File) => {
    try {
      this.setIsLoading(true);
      const response = await apiClient.importMasterTagAttributesFromExcel(file);
      this.setIsLoading(false);
      this.setImportResponse("Import successful.");
      console.log(response);
    } catch (error) {
      this.setIsLoading(false);
      this.setImportResponse("Error importing data.");
      console.error("Error importing data from Excel:", error);
    }
  };
}

export default MasterAreaImportFromExcelStore;
