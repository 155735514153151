import { Box, styled } from "@mui/material";

const DataGridBoxSx = {
  p: 2,
  backgroundColor: "#fff",
  flexGrow: 1,
  height: "500px",
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  "& .MuiDataGrid-row": {
    borderBottom: "solid",
    borderBottomColor: "#f0f0f0",
    maxHeight: "80px !important",
  },
  "& .MuiDataGrid-cellContent": {
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-cell": {
    maxHeight: "80px !important",
  },
};

export const DataGridBox = styled(Box)(({ theme }) => theme.unstable_sx(DataGridBoxSx));
